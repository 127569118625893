import { BioInfoDiv, TextWrapperDiv, BioWrapperDiv, MainTextP, TextBioInfSpan, WrapperImg, CharactersCounterWrapper, Counter, MaxCharacter } from './style';
import { useEffect, useState } from 'react';
import icon from './About.png'
import iconDesktop from './AboutDesktop.png'
import { TextField } from '@mui/material';
import { IBiography } from '../types/types';
import { useParams } from 'react-router-dom';
import { useGetBiographyQuery, usePutBiographyMutation } from 'store/storeQuery/bioApi';
import { useDebounce } from 'hooks/debounce';



export const BlockBioAbout = () => {
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const params = useParams()
    const { data: biography, isSuccess } = useGetBiographyQuery(params.id)
    const [putBiography] = usePutBiographyMutation()
    const [religion, setReligion] = useState("");
    const [personalQualities, setPersonalQualities] = useState("");
    const [favoriteQuote, setFavoriteQuote] = useState("");
    const putBioDebounce = useDebounce(putBiography, 500)


    const handlerChangeReligion = (e: string) => {
        setReligion(e);
    };
    const handlerChangePersonalQualities = (e: string) => {
        setPersonalQualities(e);
    };
    const handlerChangeFavoriteQuote = (e: string) => {
        setFavoriteQuote(e);
    };

    useEffect(() => {
        const data = {
            religion,
            personalQualities,
            favoriteQuote
        }
        if (params.id)
            putBioDebounce({ body: data, id: params.id })
    }, [religion, personalQualities, favoriteQuote]);

    useEffect(() => {
        if (biography && biography.religion) {
            setReligion(biography.religion);
        }
        if (biography && biography.personalQualities) {
            setPersonalQualities(biography.personalQualities);
        }
        if (biography && biography.favoriteQuote) {
            setFavoriteQuote(biography.favoriteQuote);
        }
    }, [isSuccess]);
    return (
        <BioWrapperDiv>
            <WrapperImg src={!mediaQueryCheck ? icon : iconDesktop} alt='err' />

            <TextWrapperDiv>

                <MainTextP>О человеке</MainTextP>
                <BioInfoDiv>
                    <TextBioInfSpan>Вероисповедание</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangeReligion(e.target.value)} value={religion} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={religion.length >= 230}>{religion.length}/</Counter>
                        <MaxCharacter warning={religion.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
                <BioInfoDiv>
                    <TextBioInfSpan>Личные качества</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangePersonalQualities(e.target.value)} value={personalQualities} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={personalQualities.length >= 230}>{personalQualities.length}/</Counter>
                        <MaxCharacter warning={personalQualities.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
                <BioInfoDiv>
                    <TextBioInfSpan>Любимая цитата</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangeFavoriteQuote(e.target.value)} value={favoriteQuote} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={favoriteQuote.length >= 230}>{favoriteQuote.length}/</Counter>
                        <MaxCharacter warning={favoriteQuote.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
            </TextWrapperDiv>

        </BioWrapperDiv>
    )
}