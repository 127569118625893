import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 546px;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 1);
    outline: none;
`
export const ModalContent = styled.div`
    position: relative;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const ModalTitle = styled.h2`
    margin: 0;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    text-align: center;
`
export const ModalSubtitle = styled.h3`
    margin: 0;
    padding-top: 12px;
    padding-bottom: 40px;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: rgba(127, 127, 127, 1);
    text-align: center;
`
export const ModalText = styled.p`
    margin: 0;
    padding-top: 40px;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    max-width: 421px;
    font-style: normal;
    font-weight: 400;
    color: rgba(127, 127, 127, 1);
    text-align: center;
`
export const WrapperClose = styled.div`
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -40px;
    right: -40px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #1A1A1E;
`

// error desktop popup

export const ErrorPopup = styled.div`
    position: absolute;
    width: 343px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    bottom: 20px;
    left: 50%;
    border-radius: 16px;
    transform: translateX(-50%);
    background-color: rgba(255, 235, 235, 1);
    z-index: 2000;
`
export const ErrorPopupText = styled.div`
    margin: 0;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: rgba(250, 83, 83, 1);

`