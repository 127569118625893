import styled from 'styled-components'

export const Boxstyle = {
    letterSpacing: 0,
    borderRightWidth: 1,
    borderRightColor: 'rgb(224,224,224)',
    borderRightStyle: 'solid',
    height: '100%'
}
export const IconButtonStyle = {
    borderRadius: '10px',
    height: '76px',
    width: '76px',
    gridArea: 'image',
    padding: 0,
    top: 15,
    cursor: 'auto',
}
export const CloseIconStyle = {
    marginTop: 19,
    right: 20,
    padding: 0
}
export const BigIconButtonStyle = {
    fontSize: '12px',
    lineHeight: '15.6px',
    fontWeight: 500,
    marginTop: 6
}
export const LastIconButtonStyle = {
    fontSize: '12px',
    lineHeight: '15.6px',
    fontWeight: 500,
    marginTop: 6,
    marginLeft: 4
}
export const CardDiv = styled.div`
    @media(max-width: 844px){
        width:100%;
        display:flex;
        align-items: flex-start;
    }
    @media(min-width: 844px){
        width:100%;
        display:flex;
        align-items: flex-start;
    }
`
export const InitialsDiv = styled.div`
        width:100%;
        direction: 'ltr';
        margin-left: 20px;
        margin-top: 15px;
    
`


