import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, SvgIcon, TextField } from '@mui/material';
import { sxBox } from 'components/AuthorizationModal/ErrEndAuthModal/style';
import styles from '../styles.module.css';
import { baseURL, customAxios } from 'network/axiosConfig';
import { useNavigate } from 'react-router-dom';
import {
  AddPhotoModal,
  ChangePhotoModal,
  ErrorPhotoModal,
  PreviewPhotoModal,
} from 'components/Tree/TreeCard/AddPhotoModal';
import { DateMaskFieldupd } from 'components/DateMaskField/DateMaskFieldUpd';
import {
  validateFirstName,
  validateDateBirthTreeCard,
} from 'components/RegistarionModal/validator/validator';
import {
  ChangeSettingsHeaderDiv,
  ChangeSettingsModalheader,
  ChangeSettingsIconClose,
  ChangeSettingsImage,
  ChangeSettingsChangeImageButton,
} from '../style';
import { CloseIcon } from 'icons/CloseIcon';
import { AddIcon } from 'icons/AddIcon';

interface IAuthModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}
export const ChangeSettingsModal = ({ isOpen, setIsOpen }: IAuthModalProps) => {
  const [CheckCoincidence, setCoincidence] = useState(true);
  const [preview, setPreview] = useState<string | ArrayBuffer | null>();
  const [fName, setFName] = useState<any>();
  const [sName, setSName] = useState<any>();
  const [mName, setMName] = useState<any>();
  const [dateBirth, setDateBirth] = useState();
  const [errorSName, setErrorSName] = useState(false);
  const [errorMaidName, setErrorMaidName] = useState(false);
  const [errorFName, setErrorFName] = useState(false);
  const [errorMName, setErrorMName] = useState(false);
  const [errorDateBirth, setErrorDateBirth] = useState<any>(false);
  const [isOpenAddPhoto, setIsOpenAddPhoto] = useState(false);
  const [step, setStep] = useState(0);
  const [File, setFile] = useState<any>();
  const array = localStorage.getItem('treeArray');
  const navigate = useNavigate();
  const treeArray = array ? JSON.parse(array) : null;

  const handleClickClose = (): void => {
    setIsOpen(false);
  };
  const handleClickCloseAddPhotoModal = () => {
    setIsOpenAddPhoto(false);
  };
  const handleClickOpenChangePhotoModal = (e: any): void => {
    if (!isOpenAddPhoto) {
      setStep(0);
      setIsOpenAddPhoto(true);
    }
  };
  const GetAllTreeAxios = async () => {
    const response = await customAxios
      .get('tree', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(async (resp) => {
        const data = await resp.data;
        localStorage.setItem('treeArray', JSON.stringify(data));
      });
  };
  const isCheckError = (e: any, fieldID: number) => {
    switch (fieldID) {
      case 0:
        if (!validateFirstName(e.target.value)) {
          setErrorSName(true);
        } else {
          setErrorSName(false);
        }
        break;
      case 1:
        if (!validateFirstName(e.target.value) && e.target.value) {
          setErrorMaidName(true);
        } else {
          setErrorMaidName(false);
        }
        break;
      case 2:
        if (!validateFirstName(e.target.value)) {
          setErrorFName(true);
        } else {
          setErrorFName(false);
        }
        break;
      case 4:
        if (!validateFirstName(e.target.value) && e.target.value) {
          setErrorMName(true);
        } else {
          setErrorMName(false);
        }
        break;
      case 3:
        if (!validateDateBirthTreeCard(e.target.value)) {
          setErrorDateBirth(true);
        } else {
          setErrorDateBirth(false);
        }
        break;
      default:
        break;
    }
  };

  const CoincidenceCheck = (e: any, fieldID: number) => {
    switch (fieldID) {
      case 0:
        if (e.target.value !== treeArray[0].surname) {
          setCoincidence(false);
        } else {
          setCoincidence(true);
        }
        break;
      case 2:
        if (e.target.value !== treeArray[0].name) {
          setCoincidence(false);
        } else {
          setCoincidence(true);
        }
        break;
      case 3:
        if (e.target.value !== treeArray[0].middleName) {
          setCoincidence(false);
        } else {
          setCoincidence(true);
        }
        break;
      case 4:
        if (e.target.value !== treeArray[0].dateOfBirth) {
          setCoincidence(false);
        } else {
          setCoincidence(true);
        }
        break;
      default:
        break;
    }
  };

  const handleAxiosChangeSettings = async () => {
    const prom = await customAxios
      .put(
        'user',
        {
          userName: fName,
          userMiddleName: mName,
          userLastName: sName,
          userDataBirth: dateBirth,
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
        },
      )
      .then(async (r) => {
        await GetAllTreeAxios().then(() => {
          navigate(0);
        });
      });
  };
  const handleSetDate = (e: any) => {
    setDateBirth(e);
  };
  const handleClickOpenAddPhotoModal = (e: any) => {
    if (!isOpenAddPhoto) {
      setStep(1);
      setIsOpenAddPhoto(true);
    }
  };
  return (
    <Box sx={{ ...sxBox, width: 657, height: 479 }}>
      <div style={{ ...ChangeSettingsHeaderDiv }}>
        <h2 id="parent-modal-title" style={{ ...ChangeSettingsModalheader }}>
          Настройки
        </h2>
        <IconButton
          onClick={() => {
            handleClickClose();
          }}
          disableRipple
          style={{ padding: 0 }}>
          <SvgIcon
            component={CloseIcon}
            style={{ ...ChangeSettingsIconClose }}
            viewBox="0 0 24 24"
          />
        </IconButton>
      </div>
      <div
        id="parent-modal-description"
        style={{ display: 'flex', justifyContent: 'space-between', columnGap: '36px' }}>
        <div
          style={{ marginLeft: '24px', height: '216px', width: '216px' }}
          className={styles.card}>
          <IconButton
            className="add"
            color="secondary"
            component="label"
            style={{ height: '216px', width: '216px' }}
            onClick={(e) => {
              if (!treeArray[0].avatar) {
                handleClickOpenAddPhotoModal(e);
              }
            }}>
            {treeArray[0].avatar ? (
              <img
                src={`${baseURL}avatar?avatarPath=${treeArray[0].avatar}&id=${treeArray[0].id}`}
                className={styles.imageArea}
                style={{ ...ChangeSettingsImage }}
                alt="test"
              />
            ) : (
              <SvgIcon
                component={AddIcon}
                className={styles.imageArea}
                style={{ width: '216px', height: '216px' }}
                viewBox="-25 -20 100 100"
              />
            )}
          </IconButton>
          {treeArray[0].avatar && (
            <Button
              variant="contained"
              className={styles.imageChangeButton}
              style={{ ...ChangeSettingsChangeImageButton }}
              onClick={(e) => {
                handleClickOpenChangePhotoModal(e);
              }}>
              Изменить фото
            </Button>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginRight: '24px',
            flexDirection: 'column',
            rowGap: '22px',
          }}>
          <TextField
            style={{ width: '100%', margin: 0 }}
            onChange={(e) => {
              setSName(e.target.value);
              isCheckError(e, 0);
              CoincidenceCheck(e, 0);
            }}
            defaultValue={treeArray[0].surname}
            margin="dense"
            id="name"
            error={errorSName}
            placeholder='Фамилия'
            type="text"
            size="medium"
            variant="outlined"
          />
          <TextField
            style={{ width: '100%', margin: 0 }}
            onChange={(e) => {
              setFName(e.target.value);
              isCheckError(e, 2);
              CoincidenceCheck(e, 2);
            }}
            defaultValue={treeArray[0].name}
            margin="dense"
            id="surname"
            error={errorFName}
            placeholder='Имя'

            type="text"
            size="medium"
            variant="outlined"
          />

          <TextField
            style={{ width: '100%', margin: 0 }}
            onChange={(e) => {
              setMName(e.target.value);
              isCheckError(e, 4);
              CoincidenceCheck(e, 3);
            }}
            defaultValue={treeArray[0].middleName}
            margin="dense"
            id="middleName"
            error={errorMName}
            placeholder='Отчество'

            type="text"
            size="medium"
            variant="outlined"
          />

          <DateMaskFieldupd
            defaultvalue={treeArray[0].dateOfBirth}
            errorDate={errorDateBirth}
            isCheckError={isCheckError}
            setDate={handleSetDate}
            CoincidenceCheck={CoincidenceCheck}
            styles={{ width: '100%', margin: '0px' }}
          />
          <Button
            disabled={
              errorFName ||
              errorMName ||
              errorSName ||
              errorDateBirth ||
              fName === '' ||
              sName === '' ||
              mName === '' ||
              CheckCoincidence
            }
            variant="contained"
            color="primary"
            size="large"
            style={{ width: '100%' }}
            onClick={handleAxiosChangeSettings}>
            Сохранить и выйти
          </Button>
        </div>
      </div>
      <Modal
        open={isOpenAddPhoto}
        onClose={() => {
          handleClickCloseAddPhotoModal();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Box>
          {step === 0 && (
            <ChangePhotoModal
              avatarURL={treeArray[0].avatar}
              setFile={setFile}
              step={step}
              setStep={setStep}
              setPreview={setPreview}
              preview={`${baseURL}avatar?avatarPath=${treeArray[0].avatar}&id=${treeArray[0].id}`}
              setIsOpenAddPhoto={setIsOpenAddPhoto}
            />
          )}
          {step === 1 && (
            <AddPhotoModal
              setFile={setFile}
              step={step}
              setStep={setStep}
              setPreview={setPreview}
              preview={preview}
              setIsOpenAddPhoto={setIsOpenAddPhoto}
            />
          )}
          {step === 2 && (
            <PreviewPhotoModal
              userID={treeArray[0].id}
              file={File}
              step={step}
              setStep={setStep}
              setPreview={setPreview}
              preview={preview}
              setIsOpenAddPhoto={setIsOpenAddPhoto}
            />
          )}
          {step === 5 && (
            <ErrorPhotoModal
              setFile={setFile}
              step={step}
              setStep={setStep}
              setPreview={setPreview}
              preview={preview}
              setIsOpenAddPhoto={setIsOpenAddPhoto}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};
