import { createSvgIcon } from "@mui/material";

export const ArrowBackLeftIcon = createSvgIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id='Arrow'>
          <path d="M2.83337 7.8151L12.8334 7.8151" stroke="white" strokeWidth="0.666667" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.86658 3.79936L2.83324 7.81536L6.86658 11.832" stroke="white" strokeWidth="0.666667" strokeLinecap="round" strokeLinejoin="round" />
      </g>
  </svg>,
  "ArrowBackLeftIcon"
)