import { createSvgIcon } from "@mui/material";

export const SideBarEditIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="20 8 100 100" fill="none">
        <g filter="url(#filter0_ddd_11270_74694)">
            <rect x="20" y="8" width="40" height="40" rx="8" fill="#149C84" shapeRendering="crispEdges" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.3764 36.0269L46.1628 24.6645C46.6403 24.0517 46.8101 23.3433 46.6509 22.622C46.513 21.9663 46.1097 21.3428 45.5049 20.8698L44.0299 19.6981C42.7459 18.6769 41.1541 18.7844 40.2415 19.9561L39.2546 21.2364C39.1273 21.3965 39.1591 21.633 39.3183 21.762C39.3183 21.762 41.812 23.7615 41.8651 23.8045C42.0349 23.9657 42.1622 24.1807 42.1941 24.4387C42.2471 24.944 41.8969 25.4169 41.377 25.4814C41.1329 25.5137 40.8994 25.4384 40.7297 25.2987L38.1086 23.2132C37.9813 23.1176 37.7902 23.138 37.6841 23.267L31.4551 31.3293C31.0519 31.8345 30.9139 32.4903 31.0519 33.1245L31.8478 36.5752C31.8902 36.7579 32.0494 36.8869 32.2404 36.8869L35.7422 36.8439C36.3789 36.8332 36.9732 36.5429 37.3764 36.0269ZM42.2807 34.9516H47.9908C48.5479 34.9516 49.001 35.4107 49.001 35.975C49.001 36.5405 48.5479 36.9984 47.9908 36.9984H42.2807C41.7236 36.9984 41.2705 36.5405 41.2705 35.975C41.2705 35.4107 41.7236 34.9516 42.2807 34.9516Z" fill="white" />
        </g>
    </svg>
    ,
    "SideBarEditIcon"
)