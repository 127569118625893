import React from 'react';
import { Box, Button, IconButton, SvgIcon } from '@mui/material';
import { sxGridThree, sxBox, textTwo } from 'components/AuthorizationModal/ErrEndAuthModal/style';
import {
  ChangePasswordStepOneCloseIcon,
  ChangePasswordStepThreeSpan,
} from 'components/ProfileModal/style';
import { CloseIcon } from 'icons/CloseIcon';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

export const StepThreeModal = ({ isOpen, setIsOpen }: IModalProps) => {
  const handleClickClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ ...sxBox, width: '656px', height: '472px', ...sxGridThree }}>
      <IconButton
        onClick={() => {
          handleClickClose();
        }}
        disableRipple
        style={{ ...ChangePasswordStepOneCloseIcon, position: 'absolute' }}>
        <SvgIcon
          component={CloseIcon}
          style={{ width: '18px', height: '18px' }}
          viewBox="0 0 18 18"
        />
      </IconButton>

      <span style={{ textAlign: 'center', ...ChangePasswordStepThreeSpan }}>
        Family <p style={{ color: '#149C84', display: 'inline-block' }}>Tree</p>
      </span>

      <span style={{ textAlign: 'center', margin: '-40px 0px 0px 0px' }}>
        <p style={{ ...textTwo }}>Пароль успешно изменён</p>
      </span>

      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ zIndex: 1, width: '100%', margin: '-35px 0px 0px' }}
        onClick={() => {
          handleClickClose();
        }}>
        Вернуться к древу
      </Button>
    </Box>
  );
};
