import { useState } from "react";
import { ImageAction, ImagePopup, PhantomDiv, SettingButDiv } from "./style"
import { customAxios } from "network/axiosConfig";
import { IImage } from "../types/types";
import { SettingIcon } from "icons/SettingIcon";

interface ISettingProps {
    imagesArr: IImage[]
    currentSlide: number
    setRerend: (val: boolean) => void
}

export const SettingButPhoto = ({ imagesArr, currentSlide, setRerend }: ISettingProps) => {
    const [isOpenImagePopup, setIsOpenImagePopup] = useState(false);
    const handleDeletePhoto = async () => {
        await customAxios.delete(`images`, {
            params: {
                imagesPath: imagesArr[currentSlide].name,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });

        setRerend(true);
        setIsOpenImagePopup(false);
    };
    return (
        <SettingButDiv onClick={() => setIsOpenImagePopup(!isOpenImagePopup)}>
            <SettingIcon />
            {isOpenImagePopup && (
                <>
                    <ImagePopup>
                        <ImageAction >Обновить фото</ImageAction>
                        <ImageAction onClick={handleDeletePhoto}>Удалить фото</ImageAction>
                    </ImagePopup>
                    <PhantomDiv onClick={() => setIsOpenImagePopup(false)} />
                </>
            )}
        </SettingButDiv>
    )
}