export const sxBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  borderRadius: '16px',

  boxShadow: 24,
};
export const sxGrid = {

  display: 'grid',
  gridTemplateAreas: " 'text number' 'field field' ' button button'  ",
  justifyContent: 'center',
  gridRowGap: '24px',
  gridTemplateRows: '192px 71px 56px',
  gridTemplateColumns: '180px 180px',
}
export const textCont = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  height: '31px'
}
export const textNumb = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  color: '#149C84',
  paddingTop: '4px'

}

export const textNumTwo = {
  fontFamily: 'Golos Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',
  paddingTop: '5px'

}
export const fieldMail = {
  padding: '32px 0px 80px 0px'
}
export const sxGridTwo = {

  display: 'grid',
  gridTemplateAreas: " 'text number' 'field1 field1' 'field2 field2' 'field3 field3' 'field4 field5'' button1 button2'  ",
  justifyContent: 'center',
  gridTemplateRows: '180px 190px 50px',
  gridTemplateColumns: '180px 190px',
}
