import { CreateStyled } from "@emotion/styled";

/* 
  Транзитные пропсы
  Пропсы стилизованных компонентов с помощью функции styled из @mui/material
  Начинаются с знака доллара $

  Нужны, чтобы прокидывать пропсы в стилизацию
    Например: 
      $isActive: boolean - для отдельной стилизации только активных ссылок
      $padding: number - если нужно задать отступ в зависимости от логики приложения
      
  Использовать при стилизации этот тип как в компоненте Header
    Пример: StyledWithIconLink - расположен в components/Header/HeaderMenuItem/styles.ts ~ 20 строка

  Так эти пропсы не попадут в конечную верстку как атрибуты тегов
  или вроде того
*/

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
}
