import { Button, Modal } from "@mui/material";
import { ModalContent, ModalSubtitle, ModalText, ModalTitle, ModalWrapper, WrapperClose, ErrorPopup, ErrorPopupText } from "./style";
import { CloseIconProfile } from "icons/CloseIconProfile";
import { useRef } from "react";

interface IIsOpen {
    errorPhotoType: boolean;
    errorPhotoSize: boolean;
    isopen: boolean,
    setisopen: (val: boolean) => void;
    addphoto: (val: any) => void;
    setErrorPhotoType: (val: boolean) => void;
    setErrorPhotoSize: (val: boolean) => void;
}

export const AddPhotoModalDesktop = ({ setErrorPhotoType, setErrorPhotoSize, errorPhotoType, errorPhotoSize, isopen, setisopen, addphoto }: IIsOpen) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClickRef = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleClickClose = () => {
        setisopen(false)
        setErrorPhotoSize(false)
        setErrorPhotoType(false)
    }
    return (
        <Modal onClose={() => handleClickClose()} open={isopen}>
            <>
                {(errorPhotoType || errorPhotoSize) &&
                    <ErrorPopup>
                        {errorPhotoSize && <ErrorPopupText>Фото не должно превышать размер 10MB</ErrorPopupText>}
                        {errorPhotoType && <ErrorPopupText>Неверный формат фото. Пожалуйста, используйте только формат JPEG, PNG или GIF</ErrorPopupText>}
                    </ErrorPopup>
                }
                <ModalWrapper>
                    <ModalContent>
                        <ModalTitle>Добавление новой фотографии</ModalTitle>
                        <ModalSubtitle>Фотография должна быть в формате JPEG, PNG или GIF</ModalSubtitle>
                        <Button onClick={handleClickRef} style={{ width: '320px' }} color="primary" variant="contained" size="large">Выбрать фото
                            <input
                                hidden
                                accept="image/*,.png,.jpeg,.jpg"
                                style={{ width: '2px' }}
                                ref={fileInputRef}
                                type="file"
                                onChange={(e) => {
                                    addphoto(e);
                                    setisopen(false);
                                }
                                }
                            />
                        </Button>
                        <ModalText>Если возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера</ModalText>
                    </ModalContent>
                    <WrapperClose onClick={() => handleClickClose()}>
                        <CloseIconProfile />
                    </WrapperClose>
                </ModalWrapper>
            </>
        </Modal>
    )
}