import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import ReactFlow, { ReactFlowProvider, useStoreApi, useReactFlow, addEdge, Controls, MarkerType, useNodesState, useEdgesState, } from 'reactflow';

import 'reactflow/dist/style.css';
import FloatingEdge from './FloatingEdge.js';
import FloatingConnectionLine from './FloatingConnectionLine.js';
import { createNodesAndEdges } from './utils.js';
import CustomNode from './CustomNode.jsx'
import ZeroNode from './ZeroNode.jsx';
import { changeNodeParents, addNodes, addEdges, changeNodePositionX, getNode } from '../../store/slices/tree/slice.js'
import TreeCard from 'components/TreeReactFlow/TreeCard/index.tsx';
import { useAutoLayout } from './autoLayout';


const nodeTypes = {
  newNodeCustom: TreeCard,
  treeCard: CustomNode,
  zeroNode: ZeroNode,
}
const exchX = 198.5
const exchY = 27
const initialNodes = [
  { id: '1', type: 'selectorNode', position: { x: 0, y: 0 }, data: { autoLayout: true, rank: 0, pos: 'l', globalPos: 'l', label: '1' }, parents: [], partners: ['2'], children: ['3', '4', '5'] },
  { id: '2', type: 'selectorNode', position: { x: 300, y: 0 }, data: { autoLayout: true, rank: 0, pos: 'r', globalPos: 'r', label: '2' }, parents: [], partners: ['1'], children: ['3', '4', '5'] },
  { id: '3', type: 'selectorNode', position: { x: 150, y: 350 }, data: { autoLayout: true, rank: -1, pos: 'd', globalPos: 'm', label: '3' }, parents: ['1', '2', 'z1'], partners: [], children: [] },
  { id: '4', type: 'selectorNode', position: { x: -50, y: 350 }, data: { autoLayout: true, rank: -1, pos: 'd', globalPos: 'l', label: '4' }, parents: ['1', '2', 'z1'], partners: [], children: [] },
  { id: '5', type: 'selectorNode', position: { x: 350, y: 350 }, data: { autoLayout: true, rank: -1, pos: 'd', globalPos: 'r', label: '5' }, parents: ['1', '2', 'z1'], partners: [], children: [] },
  { id: 'z1', type: 'zeroNode', position: { x: 198.5, y: 27 }, data: { autoLayout: true, rank: 0, pos: 'z', globalPos: 'm', label: '5' }, parents: [], partners: [], children: [] },
];

const initialEdges = [{ type: 'step', sourceHandle: 'SourceBottom', targetHandle: 'TarTop', id: 'ez1-2', source: 'z1', target: '4' },
{ type: 'step', sourceHandle: 'SourceBottom', targetHandle: 'TarTop', id: 'ez1-5', source: 'z1', target: '5' },
{ type: 'step', sourceHandle: 'SourceBottom', targetHandle: 'TarTop', id: 'ez1-3', source: 'z1', target: '3' },
{ type: 'smoothstep', sourceHandle: 'SourceRight', targetHandle: 'TarLeft', id: 'e1-2', source: '1', target: '2' },

  /*
  {type:'smoothstep',sourceHandle:'SourceRight',targetHandle:'TarTop', id: 'e1-3', source: '1', target: '3' },
  {type:'smoothstep',sourceHandle:'SourceLeft',targetHandle:'TarTop', id: 'e2-3', source: '2', target: '3' },
  {type:'smoothstep',sourceHandle:'SourceRight',targetHandle:'TarTop', id: 'e1-4', source: '1', target: '4' },
  {type:'smoothstep',sourceHandle:'SourceRight',targetHandle:'TarTop', id: 'e1-5', source: '1', target: '5' },
  {type:'smoothstep',sourceHandle:'SourceLeft',targetHandle:'TarTop', id: 'e2-4', source: '2', target: '4' },
  {type:'smoothstep',sourceHandle:'SourceLeft',targetHandle:'TarTop', id: 'e2-5', source: '2', target: '5' },
  */
];
const edgeTypes = {
  floating: FloatingEdge,
};
let idG = 6;

export const NewTree = () => {
  const nodes = useSelector(state => state.tree.nodes)
  const edges = useSelector(state => state.tree.edges)
  const [node, setNode] = useState()
  const [edge, setEdge] = useState()
  const dispatch = useDispatch()
  const setNodes = (nodes) => dispatch(setNodes(nodes))
  const setEdges = (edges) => dispatch(setEdges(edges))
  const changeNode = (nodeId, parArr) => dispatch(changeNodeParents({ id: nodeId, par: parArr }))
  const addNode = (node) => dispatch(addNodes({ newNode: node }))
  const addEdge = (edge) => dispatch(addEdges({ newEdge: edge }))
  const changeNodePosition = (index, x) => dispatch(changeNodePositionX({ ind: index, positionX: x }))
  const getNod = (nodeId) => dispatch(getNode({ id: nodeId }))

  const addRightNodes = (node) => {
    const parArr = []

    const idF = idG
    const idS = idG + 1
    const idZero = idG + 2
    const newNode = { id: `${idF}`, parents: [], partners: [`${idS}`], children: [`${node.id}`], type: 'treeCard', position: { x: node.position.x + 260, y: node.position.y - 200 }, data: { autoLayout: false, globalPos: node.data.globalPos, rank: node.data.rank + 1, pos: 'r', label: idF } }
    const newNode2 = { id: `${idS}`, parents: [], partners: [`${idF}`], children: [`${node.id}`], type: 'treeCard', position: { x: node.position.x + 100, y: node.position.y - 200 }, data: { autoLayout: false, globalPos: node.data.globalPos, rank: node.data.rank + 1, pos: 'l', label: idS } }
    const newNodeZero = { id: `z${idZero}`, parents: [], partners: [], children: [], type: 'zeroNode', position: { x: node.position.x + 230, y: node.position.y - 173 }, data: { autoLayout: false, globalPos: node.data.globalPos, rank: node.data.rank + 1, pos: 'z', label: `z${idG}` } }
    const newEdge = { id: `e${idG}`, type: 'smoothstep', sourceHandle: 'SourceRight', targetHandle: 'TarLeft', source: `${idS}`, target: `${idF}` }
    const newEdgeZero = { id: `e${idZero + 1}`, type: 'smoothstep', sourceHandle: 'SourceBottom', targetHandle: 'TarTop', source: `z${idZero}`, target: `${node.id}` }
    parArr.push(`${idF}`)
    parArr.push(`${idS}`)
    parArr.push(`z${idZero}`)
    changeNode(node.id, parArr)
    addNode(newNode)
    addNode(newNode2)
    addNode(newNodeZero)
    addEdge(newEdge)
    addEdge(newEdgeZero)
    console.log(nodes)
    console.log(edges)
    idG += 2
  }

  const addLeftNodes = (node) => {
    const parArr = []

    const idF = idG
    const idS = idG + 1
    const idZero = idG + 2
    console.log(node)
    const changeNodes = nodes.slice()
    const newNode = { id: `${idF}`, parents: [], partners: [`${idS}`], children: [`${node.id}`], type: 'treeCard', position: { x: node.position.x - 260, y: node.position.y - 200 }, data: { autoLayout: false, globalPos: node.data.globalPos, rank: node.data.rank + 1, pos: 'l', label: idF } }
    const newNode2 = { id: `${idS}`, parents: [], partners: [`${idF}`], children: [`${node.id}`], type: 'treeCard', position: { x: node.position.x - 100, y: node.position.y - 200 }, data: { autoLayout: false, globalPos: node.data.globalPos, rank: node.data.rank + 1, pos: 'r', label: idS } }
    const newNodeZero = { id: `z${idZero}`, parents: [], partners: [], children: [], type: 'zeroNode', position: { x: node.position.x - 130, y: node.position.y - 173 }, data: { autoLayout: false, globalPos: node.data.globalPos, rank: node.data.rank + 1, pos: 'z', label: `z${idG}` } }

    const newEdge = { id: `e${idG}`, type: 'smoothstep', sourceHandle: 'SourceRight', targetHandle: 'TarLeft', source: `${idF}`, target: `${idS}` }
    const newEdgeZero = { id: `e${idZero + 1}`, type: 'smoothstep', sourceHandle: 'SourceBottom', targetHandle: 'TarTop', source: `z${idZero}`, target: `${node.id}` }
    parArr.push(`${idF}`)
    parArr.push(`${idS}`)
    parArr.push(`z${idZero}`)
    changeNode(node.id, parArr)
    addNode(newNode)
    addNode(newNode2)
    addNode(newNodeZero)
    addEdge(newEdge)
    addEdge(newEdgeZero)
    console.log(nodes)

    idG += 2
  }


  const handleClickNode = (node) => {


    if (node.data.pos === 'r') {
      addRightNodes(node)
    } if (node.data.pos === 'l') {
      addLeftNodes(node)
    }

  }



  useEffect(() => {
    useAutoLayout(dispatch, nodes)
  }, [nodes])
  return (
    <div style={{ width: '100vw', height: '100vh' }}>

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}

          onNodeClick={(ev, node) => { handleClickNode(node) }}
          style={{ backgroundColor: "white", height: "50px" }}

          nodesDraggable={false}
        >
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
}
