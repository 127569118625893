import { useState } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import {
  AddDelete,
  birthArea,
  card,
  imageArea,
  imageButton,
  imageChangeButton,
  root,
  textMName,
  textName,
  textSName,
} from './style';
import { Box, Button, IconButton, Modal, SvgIcon } from '@mui/material';
import {
  AddPhotoModal,
  ChangePhotoModal,
  DeleteModal,
  ErrorPhotoModal,
  PreviewPhotoModal,
} from './Modals';
import { NotCompleteModal } from 'components/NotCompleteModal';
import { AddIcon } from 'icons/AddIcon';

type NodeData = {
  cardId: string;
  lastName: string;
  firstName: string;
  midleName: string;
  relation: string;
  dateOfBirth: string;
  dateOfDeath: string;
  isDead: boolean;
  avatar: string;
  selectedCard: number;
  isConnectable: boolean;
  setSelectedCard: (arg: number) => any;
};

function arrayBufferToString(buffer: ArrayBuffer | string) {
  if (typeof buffer === 'string') {
    return buffer;
  }

  let str = '';
  const array = new Uint8Array(buffer);
  for (let i = 0; i < array.length; i += 1) {
    str += String.fromCharCode(array[i]);
  }
  return str;
}

const TreeCard = ({ data }: NodeProps<NodeData>) => {
  const [isOpenAddPhoto, setIsOpenAddPhoto] = useState(false);
  const [step, setStep] = useState(1);
  const [fName, setFName] = useState();
  const [sName, setSName] = useState();
  const [mName, setMName] = useState();
  const [maidName, setMaidName] = useState();
  const [dateBirth, setDateBirth] = useState();
  const [dateDeath, setDateDeath] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile]: [null | File, (newData: null | File) => void] = useState<null | File>(
    null,
  );
  const [preview, setPreview]: [
    string | ArrayBuffer | null,
    (newData: string | ArrayBuffer | null) => void,
  ] = useState<string | ArrayBuffer | null>(null);

  const [cardHovered, setCardHovered] = useState(false);
  const [buttonsStatus, setButtonStatus] = useState(['none', '0']);
  const [cardHeight, setCardHeight] = useState('285px');

  const handleClickOpenAddPhotoModal = () => {
    if (!isOpenAddPhoto) {
      setStep(1);
      setIsOpenAddPhoto(true);
    }
  };

  const handleClickDel = () => {
    if (!isOpenAddPhoto) {
      setStep(3);
      setIsOpenAddPhoto(true);
    }
  };

  const handleClickNotCompl = () => {
    if (!isOpenAddPhoto) {
      setStep(6);
      setIsOpenAddPhoto(true);
    }
  };

  const handleMouseEnterCard = (e: any) => {
    setCardHovered(true);
    setCardHeight('408px');
    setTimeout(() => setButtonStatus(['flex', '0']), 10);
    setTimeout(() => setButtonStatus(['flex', '1']), 100);
  };

  const handleMouseLeaveCard = (e: any) => {
    setCardHovered(false);
    setTimeout(() => setCardHeight('285px'), 120);
    setTimeout(() => setButtonStatus(['none', '0']), 100);
  };

  const handleClickCloseAddPhotoModal = () => {
    setIsOpenAddPhoto(false);
    setStep(1);
  };

  const handleClickOpenChangePhotoModal = () => {
    if (!isOpenAddPhoto) {
      setStep(4);
      setIsOpenAddPhoto(true);
    }
  };

  const changePhotoHandler = (e: any) => {
    if (e.target && e.target.files) {
      setSelectedFile(e.target.files[0]);
      const f = e.target.files[0];
      const reader = new FileReader();
      console.log(f);

      reader.readAsDataURL(f);

      reader.onload = async (ev) => {
        await setPreview(reader.result);
      };
    }
  };

  return (
    <button
      type="button"
      style={{ ...root() }}
      onClick={() => data.setSelectedCard(Number(data.cardId))}>
      <div
        className="card"
        style={{ ...card(cardHovered, cardHeight) }}
        onMouseEnter={handleMouseEnterCard}
        onMouseLeave={handleMouseLeaveCard}>
        <button
          type="button"
          style={{ ...imageButton(buttonsStatus) }}
          onClick={() => {
            handleClickNotCompl();
          }}>
          Подробнее
        </button>
        <div style={{ ...imageArea() }}>
          <IconButton
            className="add"
            color="secondary"
            component="label"
            style={{
              height: '180px',
              width: '180px',
              gridArea: 'image',
              margin: '5px',
              top: -4,
              left: -7,
            }}
            onClick={() => {
              if (!data.avatar) {
                handleClickOpenAddPhotoModal();
              }
            }}>
            {data.avatar ? (
              <img
                src={`https://genelogy-tree-back.lad-academy.ru/avatar?avatarPath=${data.avatar}`}
                style={{ ...imageArea(), margin: '0px' }}
                alt="test"
              />
            ) : (
              <SvgIcon
                component={AddIcon}
                style={{ width: '53px', height: '53px' }}
                viewBox="0 0 53 53"
              />
            )}
          </IconButton>
          {data.avatar && (
            <Button
              variant="contained"
              style={{ ...imageChangeButton(), width: '127px', height: '32px', top: -45 }}
              onClick={() => {
                handleClickOpenChangePhotoModal();
              }}>
              Изменить фото
            </Button>
          )}
        </div>
        <div style={{ textAlign: 'center' }}>
          <p style={{ ...textName() }}>{data.lastName}</p>
          <p style={{ ...textSName() }}>{data.firstName}</p>
          <p style={{ ...textMName() }}>{data.midleName}</p>
          {data.isDead ? (
            <>
              <p style={{ ...birthArea() }}>{data.relation}</p>
              <p style={{ ...birthArea() }}>{`${data.dateOfBirth} - ${data.dateOfDeath}`}</p>
            </>
          ) : (
            <p style={{ ...birthArea() }}>{`${data.relation}, ${data.dateOfBirth}`}</p>
          )}
        </div>
        <div style={{ ...AddDelete(buttonsStatus) }}>
          <Button
            className="addRelative"
            variant="contained"
            onClick={() => {
              handleClickNotCompl();
            }}>
            Добавить родственника
          </Button>
          <Button
            className="deleteRelative"
            style={{ margin: '11px 0px 0px', fontSize: '12px', color: 'red', border: 0 }}
            variant="outlined"
            onClick={() => {
              handleClickDel();
            }}>
            Удалить из древа
          </Button>
        </div>
      </div>

      <Handle
        type="target"
        style={{ visibility: 'hidden' }}
        id="TarTop"
        position={Position.Top}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="target"
        style={{ visibility: 'hidden' }}
        position={Position.Right}
        id="TarRigth"
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="target"
        id="TarLeft"
        style={{ visibility: 'hidden' }}
        position={Position.Left}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="target"
        id="TarBottom"
        style={{ visibility: 'hidden' }}
        position={Position.Bottom}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="source"
        id="SourceLeft"
        style={{ visibility: 'hidden' }}
        position={Position.Left}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="source"
        id="SourceRight"
        style={{ visibility: 'hidden' }}
        position={Position.Right}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="source"
        id="SourceTop"
        style={{ visibility: 'hidden' }}
        position={Position.Top}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />
      <Handle
        type="source"
        id="SourceBottom"
        style={{ visibility: 'hidden' }}
        position={Position.Bottom}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={data.isConnectable}
      />

      <Modal
        open={isOpenAddPhoto}
        onClose={() => {
          handleClickCloseAddPhotoModal();
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Box>
          {step === 1 && (
            <AddPhotoModal
              setFile={setFile}
              step={step}
              setStep={setStep}
              setPreview={setPreview}
            />
          )}
          {step === 2 && (
            <PreviewPhotoModal
              userID={data.cardId}
              file={file}
              setStep={setStep}
              preview={preview}
              setIsOpenAddPhoto={setIsOpenAddPhoto}
            />
          )}
          {step === 3 && (
            <DeleteModal setIsOpenAddPhoto={setIsOpenAddPhoto} delId={Number(data.cardId)} />
          )}
          {step === 4 && (
            <ChangePhotoModal
              avatarURL={data.avatar}
              setFile={setFile}
              setStep={setStep}
              setPreview={setPreview}
              preview={`https://genelogy-tree-back.lad-academy.ru/avatar?avatarPath=${data.avatar}`}
              setIsOpenAddPhoto={setIsOpenAddPhoto}
            />
          )}
          {step === 5 && (
            <ErrorPhotoModal
              setFile={setFile}
              step={step}
              setStep={setStep}
              setPreview={setPreview}
            />
          )}
          {step === 6 && <NotCompleteModal isOpen={isOpenAddPhoto} setIsOpen={setIsOpenAddPhoto} />}
        </Box>
      </Modal>
    </button>
  );
};

export default TreeCard;
