import { getAddChildCard } from "./getAddChildCards";
import { getAddChildEdges } from "./getAddChildEdges";
import { getAddParentsCard } from "./getAddParentsCard";
import { getAddParentsEdges } from "./getAddParentsEdges";
import { getAddSiblingsCard } from "./getAddSiblingsCards";
import { getAddSiblingsEdges } from "./getAddSiblingsEdges";
import { getAddSpousesCard } from "./getAddSpousesCard";



export const getAllAddCardsAndEdges = (nodeAsync: any, setNodes: any, edgeAsync: any, setEdges: any) => {
    const tempAddCard: any[] = []
    const tempAddEdge: any[] = []
    const tempNode: any[] = []
    if (nodeAsync && edgeAsync)
        if (nodeAsync) {

            nodeAsync.forEach((element: any) => {
                if (element.data.spouses.length !== 0 && element.data.pos === 'l' && element.data.children.length === 0) {
                    tempAddCard.push(...getAddChildCard(element))
                    tempAddEdge.push(...getAddChildEdges(element))
                }

                if (element.data.parents.length >= 2 && element.data.isSibling === false) {

                    tempAddCard.push(...getAddSiblingsCard(element, element.data.siblings.length))
                    tempAddEdge.push(...getAddSiblingsEdges(element))
                }
                if (element.data.parents.length === 0 && (element.type === 'treeCard' || element.type === 'phantomNode') && element.data.rank >= 0) {
                    tempAddCard.push(...getAddParentsCard(element))
                    tempAddEdge.push(...getAddParentsEdges(element))

                }
                if (element.data.spouses.length === 0 && (element.type === 'treeCard' || element.type === 'phantomNode') && element.data.isSibling === false) {
                    tempAddCard.push(...getAddSpousesCard(element))
                }

            });


            setNodes([...nodeAsync, ...tempAddCard])
            setEdges([...edgeAsync, ...tempAddEdge])
        }
}