import styled from "styled-components";
import { SwipeableDrawer } from "@mui/material";
import { ErrorAddingPhotoGallery } from "icons/ErrorAddingPhotoGallery";

interface IIsSelectedPhoto {
    isselectedphoto: string | ArrayBuffer | null
}

export const SwipeableDrawerMobile = styled(SwipeableDrawer) <IIsSelectedPhoto>`
& .MuiPaper-root {
    border-radius: ${props => props.isselectedphoto ? '32px 32px 0px 0px' : '16px 16px 0px 0px'};
    background-color: ${props => props.isselectedphoto ? 'rgba(26, 26, 30, 1)' : ''};
}

& .MuiBackdrop-root .MuiModal-backdrop {
    opacity: 0;
}

`
export const ErrorPopup = styled.div`
    position: absolute;
    width: 343px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    top: 0;
    left: 50%;
    border-radius: 16px;
    transform: translateX(-50%);
    background-color: rgba(255, 235, 235, 1);
    z-index: 2000;
`
export const ErrorPopupText = styled.div`
    margin: 0;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: rgba(250, 83, 83, 1);

`
export const DrawerWrapper = styled.div`
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
`
export const DrawerContent = styled.div`
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const DrawerTitle = styled.p`
    margin: 0;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
`
export const DrawerSubtitle = styled.p`
    margin: 0;
    max-width: 315px;
    padding-top: 12px;
    padding-bottom: 40px;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: rgba(127, 127, 127, 1);
    text-align: center;
`
export const DrawerText = styled.p`
    margin: 0;
    padding-top: 40px;
    max-width: 343px;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(127, 127, 127, 1);
    text-align: center;
`
export const CircularWrapper = styled.div`
    margin-right: 20px; 
    position: absolute; top: 40%;
    left: 50%; 
    transform: translate(-40%, -50%);
`
export const ErrorWrapper = styled.div`
    margin-right: 20px;
    position: absolute; 
    top: 40%;
    left: 50%; 
    transform: translate(-40%, -50%);
`
export const ErrorIcon = styled.div`
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: rgba(250, 83, 83, 1);
`
// selected photo

export const CropDrawer = styled.div`
    height: 77vh;
    position: relative;
    display: flex;
    justify-content: center;
`
export const CropDrawerContent = styled.div`
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px 16px 0px 0px;
`
export const CropDrawerContentWrapper = styled.div`
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
`
export const CropDrawerButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
`
export const ErrorAddingPhoto = styled(ErrorAddingPhotoGallery)`
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`