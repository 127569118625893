
export const getAddSiblingsCard = (node: any, count: number) => {
    const SCALE_RIGHT = 320 * count
    const SCALE_LEFT = 280 * count
    const siblingsArr = [
        {
            id: `${node.id}rs`,

            type: 'addSiblingsCard',
            position: { x: (node.data.pos === "l" ? node.position.x - (280 + SCALE_LEFT) : node.position.x + (325 + SCALE_RIGHT)), y: node.position.y + 180 },

            data: {
                gender: "female",
                name: 'сестру для ',
                middleName: node.data.name,
                isSibling: true,
                rank: node.data.rank,
                pos: node.data.pos,
                globalPos: node.data.globalPos,
                parents: [...node.data.parents],
                spouses: [],
                children: [],
                siblings: [{ id: node.id }],
            },


        },
        {
            id: `${node.id}ls`,
            type: 'addSiblingsCard',
            position: { x: (node.data.pos === "l" ? node.position.x - (280 + SCALE_LEFT) : node.position.x + (325 + SCALE_RIGHT)), y: node.position.y + 40 },
            data: {
                gender: "male",
                name: 'брата для ',
                middleName: node.data.name,
                rank: node.data.rank,
                pos: node.data.pos,
                globalPos: node.data.globalPos,
                parents: [...node.data.parents],
                isSibling: true,

                spouses: [],
                children: [],
                siblings: [{ id: node.id }],

            },

        },

        {
            id: `${node.id}zs`,
            type: 'zeroAddNode',
            position: { x: (node.data.pos === "l" ? node.position.x - (80 + SCALE_LEFT) : node.position.x + (280 + SCALE_RIGHT)), y: node.position.y + 68 },
            data: {

                rank: node.data.rank,
                pos: 'z',
                globalPos: node.data.globalPos,
                parents: [...node.data.parents],
                isSibling: true,

                spouses: [],
                children: [],
                siblings: [{ id: node.id }],


            },

        },
    ]
    return siblingsArr
}
