import { styled } from "@mui/material";

export const StyledFooterContainer = styled('div')(({ theme }) => ({
  bottom: 0,
  width: '100%',
  padding: '16px 24px',
  boxSizing: 'border-box',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10,

  backgroundColor: theme.palette.common.white,
  boxShadow: '0px -6px 8px 0px rgba(32, 33, 40, 0.08)',
  zIndex: 2,

  [theme.breakpoints.down(844)]: {
    // display: 'none',
    // visibility: 'hidden',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 16,
  }
}));

export const StyledFooterLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  fontWeight: 500,
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.dark,
  }
}));