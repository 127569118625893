export const Boxstyle = {
    letterSpacing: 0,
    borderRightWidth: 1,
    borderRightColor: 'rgb(224,224,224)',
    borderRightStyle: 'solid',
    height: '100%'
}
export const BigDivstyle = {
    width: '328px',
    justifyContent: 'space-between',
    alignItems: 'start',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '4px'
}
export const IconButtonStyle = {
    height: '88px',
    width: '88px',
    gridArea: 'image',
    padding: 0,
    left: 24,
    top: 14
}
export const InitialsDiv = {
    marginLeft: '130px',
    marginTop: '16px'

}
export const CloseIconStyle = {
    marginTop: 19,
    right: 20,
    padding: 0
}
export const BigIconButtonStyle = {
    fontSize: '12px',
    lineHeight: '15.6px',
    fontWeight: 500,
    marginTop: 6
}
export const LastIconButtonStyle = {
    fontSize: '12px',
    lineHeight: '15.6px',
    fontWeight: 500,
    marginTop: 6,
    marginLeft: 4
}
export const SpanStyle = {
    font: 'Golos Text VF',
    lineHeight: '18.2px',
    display: 'flex',
    fontWeight: 700,
    margin: 12,
    marginTop: 18,
    marginLeft: 23,
    fontSize: '14px'
}