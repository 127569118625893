import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import {
    ButtonWrapper,
    CustomFooter,
    CustomTypography,
    ErrorTypography,
    LoginButton,
    RegButton,
    RegisterWrapper,
    StageContent,
    StageWrapper,
    TextFieldWrapper,
    TextWrapper,
    TitleWrapper,
} from './style';
import { Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { FirstStage } from './FirstStage';
import { SecondStage } from './SecondStage';
import { error } from 'console';
import { useFirstRegMutation, useSecondRegMutation } from 'store/storeQuery/auth';
import { useNavigate } from 'react-router-dom';
import { useGetTreeQuery } from 'store/storeQuery/treeApi';

export const Registration = () => {
    const navigate = useNavigate()

    const [actualStage, setActualStage] = useState(1);
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [gender, setGender] = useState('')
    const [dateBirth, setDateBirth] = useState('')
    const [isError, setIsError] = useState(false)
    const [sendFirstStage] = useFirstRegMutation()
    const [sendSecondStage] = useSecondRegMutation()
    const [isErrAsync, setIsErrorAsync] = useState(false)
    const [textErrorAsync, setTextErrorAsync] = useState("")

    const { data: tree } = useGetTreeQuery(0)

    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const handleReg = async () => {
        if (actualStage === 1) {
            const body = {
                userEmail: email,
                userPass: pass,
                userPassConfm: pass,
            }
            const result = await sendFirstStage({ body }).unwrap()
                .catch((res) => {
                    if (res.error) {
                        setIsErrorAsync(true)
                        setTextErrorAsync(res.data);

                    }
                })
            localStorage.setItem("idRoot", result.id)
            localStorage.setItem("accessToken", result.accessToken)
            console.log(result);
            setIsErrorAsync(false)
            setTextErrorAsync("");
            setActualStage(2);

        }
        if (actualStage === 2) {
            console.log("not fin");
            const body = {
                userName: firstName,
                userMiddleName: middleName,
                userLastName: lastName,
                userDataBirth: dateBirth,
                gender: gender === "Мужской" ? "male" : "female"
            }
            const result = await sendSecondStage({ body }).unwrap().catch((res) => {
                if (res.error) {
                    setIsErrorAsync(true)
                    setTextErrorAsync(res.data);

                }
            })

            localStorage.setItem("idRoot", result.id)
            // localStorage.setItem("accessToken", result.accessToken)
            localStorage.setItem("treeArray", JSON.stringify(tree))

            navigate('/tree')
        }
    };
    return (
        <>
            <Header />
            <RegisterWrapper>
                <TitleWrapper>
                    <Typography variant={mediaQueryCheck ? 'h3' : 'subtitle2'}>Регистрация</Typography>
                    <StageWrapper>
                        <StageContent isActual={actualStage === 1}>
                            <Typography>1 шаг</Typography>
                        </StageContent>
                        <StageContent isActual={actualStage === 2}>
                            <Typography>2 шаг</Typography>
                        </StageContent>
                    </StageWrapper>
                </TitleWrapper>
                {actualStage === 1 ? <FirstStage setIsError={setIsError} email={email} setEmail={setEmail} pass={pass} setPass={setPass} /> : <SecondStage
                    setFirstName={setFirstName} setLastName={setLastName} setMiddleName={setMiddleName} dateBirth={dateBirth} setDateBirth={setDateBirth} gender={gender} setGender={setGender} setIsError={setIsError}
                />}
                <ButtonWrapper>
                    <RegButton variant="contained" onClick={handleReg} disabled={isError}>
                        <Typography variant="button" >Зарегистрироваться</Typography>
                    </RegButton>
                    <TextWrapper>
                        {actualStage === 1 ?
                            <CustomTypography variant="caption2">Уже есть аккаунт?&nbsp;
                                <LoginButton onClick={() => navigate("/login")}>
                                    Войти
                                </LoginButton>
                            </CustomTypography> :
                            <CustomTypography variant="caption2">
                                Регистрируясь, я принимаю условия политики конфиденциальности&nbsp;

                                <LoginButton>
                                    политики конфиденциальности
                                </LoginButton>
                                &nbsp;и&nbsp;
                                <LoginButton>
                                    обработки персональных данных
                                </LoginButton>
                            </CustomTypography>
                        }
                    </TextWrapper>
                    {isErrAsync && <TextWrapper><ErrorTypography variant="caption2">{textErrorAsync}</ErrorTypography></TextWrapper>}
                </ButtonWrapper>
            </RegisterWrapper>
        </>
    );
};
