import { AddIconPlus } from 'icons/AddIconPlus'
import styled from 'styled-components'

interface IPosPlusProps {
    pos: string
}

export const CardWrapperDiv = styled.div`
    width: 113px;
    height: 32px;
    border-radius:8px;
    background: #FFFFFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08);

    display:flex;
    flex-direcion: row;
    padding:12px;
    gap:12px;
    &:hover{
        box-shadow: 0px 4px 20px 0px rgba(6, 165, 127, 0.45);
    }
`
export const PlusDiv = styled.div<IPosPlusProps>`
    background-color: #149C84;
    width:16px;
    height:16px;
    border-radius: 50%;
    display:flex;
    align-items:center;
    justify-content:center;
    position: absolute;
    top: 20px;
    left: ${props => props.pos === "r" ? "-7px" : "130px"};
`
export const AddIconPlusStyled = styled(AddIconPlus)`
    color: white;
    height: 8px;
    width: 8px;
`
