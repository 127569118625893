import { Components, Theme } from '@mui/material';

/*
  Переопределение стилей для компонентов форм, кнопок и тп
  Компоненты, с которыми можно активно взаимодействовать
*/

export const formComponents: Components<Omit<Theme, 'components'>> = {
  MuiButton: {

    styleOverrides: {
      // для Button (для кнопок без иконок)
      root: ({ ownerState }) => ({
        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' &&
          ownerState.size === 'large' && {
          'color': '#FFFFFF',
          'fontSize': '16px',
          'backgroundColor': '#149C84',
          'width': '360px',
          'height': '56px',
          'lineHeight': '20.8px',

          '&:hover': {
            boxShadow: '0px 4px 20px rgba(6, 165, 127, 0.45)',
            backgroundColor: '#149C84',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#149F94',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'secondary' &&
          ownerState.size === 'large' && {
          'color': '#1A1A1E',
          'fontSize': '16px',
          'width': '360px',
          'height': '56px',
          'border': '2px solid #E5E5E5',
          'lineHeight': '20.8px',
          '&:hover': {
            backgroundColor: '#E5E5E5',
            borderColor: '#E5E5E5',
          },
        }),

        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' &&
          ownerState.size === 'medium' && {
          'color': '#FFFFFF',
          'backgroundColor': '#149C84',
          'fontSize': '12px',
          'width': '180px',
          'height': '32px',
          'lineHeight': '15.6px',
          'padding': 0,
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(6, 165, 127, 0.45)',
            backgroundColor: '#149C84',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#149F94',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'secondary' &&
          ownerState.size === 'medium' && {
          'color': '#1A1A1E',
          'fontSize': '12px',
          'width': '180px',
          'height': '32px',
          'lineHeight': '15.6px',
          'padding': 0,
          'border': '2px solid #E5E5E5',
          '&:hover': {
            border: '2px solid #E5E5E5',
            backgroundColor: '#E5E5E5',
            borderColor: '#E5E5E5',
          },
        }),

        ...(ownerState.variant === 'contained' &&
          ownerState.color === 'primary' &&
          ownerState.size === 'small' && {
          'color': '#FFFFFF',
          'backgroundColor': '#149C84',
          'fontSize': '12px',
          'lineHeight': '15.6px',
          'padding': '8px 18px 8px 18px',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(6, 165, 127, 0.45)',
            backgroundColor: '#149C84',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#149F94',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'secondary' &&
          ownerState.size === 'small' && {
          'color': '#1A1A1E',
          'fontSize': '12px',
          'lineHeight': '15.6px',
          'padding': '8px 18px 8px 18px',
          'border': '2px solid #E5E5E5',
          '&:hover': {
            backgroundColor: '#E5E5E5',
            borderColor: '#E5E5E5',
          },
        }),

        ...(ownerState.className === 'share' &&
          ownerState.variant === 'contained' && {
          'color': '#FFFFFF',
          'backgroundColor': '#149C84',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'padding': '18px 24px 18px 24px',
          'height': 'auto',
          'width': 'auto',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(6, 165, 127, 0.45)',
            backgroundColor: '#149C84',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#149F94',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.className === 'share' &&
          ownerState.variant === 'outlined' && {
          'color': '#1A1A1E',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'padding': '18px 24px 18px 24px',
          'height': 'auto',
          'width': 'auto',
          'border': '2px solid #E5E5E5',
          '&:hover': {
            backgroundColor: '#E5E5E5',
            borderColor: '#E5E5E5',
          },
        }),

        ...(ownerState.className === 'delete' &&
          ownerState.variant === 'contained' && {
          'color': '#FFFFFF',
          'backgroundColor': '#FA5353',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'padding': '18px 24px 18px 24px',
          'height': 'auto',
          'width': 'auto',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(250, 83, 83, 0.45)',
            backgroundColor: '#FA5353',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FA5353',
            borderColor: '#FA5353',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FDCBCB',
            color: '#AF3A3A',
          },
        }),

        ...(ownerState.className === 'deleteProfile' &&
          ownerState.variant === 'contained' && {
          'color': '#FFFFFF',
          'backgroundColor': '#FA5353',
          'fontSize': '16px',
          'lineHeight': '130%',
          'padding': '16px 40px 16px 40px',
          'borderRadius': '16px',
          'height': 'auto',
          'width': 'auto',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(250, 83, 83, 0.45)',
            backgroundColor: '#FA5353',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#FA5353',
            borderColor: '#FA5353',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FDCBCB',
            color: '#AF3A3A',
          },
        }),

        ...(ownerState.variant === 'text' &&
          ownerState.color === 'primary' && {
          'color': '#0E6E5D',
          'backgroundColor': '#DCF0ED',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'padding': '8px 16px 8px 16px',
          'height': 'auto',
          'width': 'auto',
          '&:hover': {
            color: '#149C84',
            backgroundColor: 'none',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: '#7F7F7F',
          },
        }),

        ...(ownerState.variant === 'text' &&
          ownerState.color === 'secondary' && {
          'color': '#1A1A1E',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'height': 'auto',
          'width': 'auto',
          '&:hover': {
            color: '#149C84',
            backgroundColor: 'transparent',
          },
        }),
        ...(ownerState.className === 'deletePerson' &&
        {
          all: 'unset',
          backgroundColor: '#FA5353',

        }),
        ...(ownerState.className === 'navigation' && {
          'color': '#1A1A1E',
          'fontSize': '16px',
          'padding': '10px 8px 10px 8px',
          'border': '2px solid #E5E5E5',
          'lineHeight': '20.8px',
          'height': 'auto',
          'width': 'auto',
          '&:hover': {
            color: '#1A1A1E',
            backgroundColor: 'transparent',
          },
        }),
        boxShadow: 'none',
        textTransform: 'none',
        borderRadius: '16px',
        fontFamily: 'Gilroy',
      }),
    },
  },

  MuiIconButton: {
    // для IconButton (для кнопок с иконками)
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.className === 'share' &&
          ownerState.color === 'primary' && {
          'color': '#FFFFFF',
          'backgroundColor': '#149C84',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'padding': '18px 24px 18px 24px',
          'gap': '18px',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(6, 165, 127, 0.45)',
            backgroundColor: '#149C84',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#149F94',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.className === 'share' &&
          ownerState.color === 'secondary' && {
          'color': '#1A1A1E',
          'fontSize': '16px',
          'lineHeight': '20.8px',
          'padding': '18px 24px 18px 24px',
          'gap': '18px',
          'border': '2px solid #E5E5E5',
          '&:hover': {
            backgroundColor: '#E5E5E5',
            borderColor: '#E5E5E5',
          },
        }),

        ...(ownerState.className === 'back' &&
          ownerState.color === 'primary' && {
          'color': '#FFFFFF',
          'backgroundColor': '#149C84',
          'padding': '22px 24px 22px 24px',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(6, 165, 127, 0.45)',
            backgroundColor: '#149C84',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#149F94',
            borderColor: '#149C84',
          },
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.className === 'add' &&
          ownerState.color === 'secondary' && {
          'color': '#E5E5E5',
          'border': '1.5px solid #E5E5E5',
          'padding': '84px 84px 84px 84px',
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#DCF0ED',
            color: '#149C84',
          },
        }),

        ...(ownerState.className === 'buttonSettingsChangePassword' && {
          'padding': 0,
          'color': '#1A1A1E',
          'fontSize': '16px',
          'fontWeight': 500,
          'lineHeight': '20.8px',
          '&:hover': {
            color: '#149C84',
          },
        }),
        ...(ownerState.className === 'deletePers' && {
          backgroundColor: '#FA5353',
        }),
        ...(ownerState.className === 'buttonLogout' && {
          'padding': 0,
          'color': '#1A1A1E',
          'fontSize': '16px',
          'fontWeight': 500,
          'lineHeight': '20.8px',
          '&:hover': {
            color: '#FA5353',
          },
        }),

        lineHeight: 0,
        textTransform: 'none',
        borderRadius: '16px',
        fontFamily: 'Golos Text',
        boxShadow: 'none',
      }),
    },
  },

  MuiTextField: {
    // для полей
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.type === 'text' &&
          ownerState.size === 'medium' &&
          ownerState.variant === 'outlined' && {
          'width': '360px',
          '&.Mui-disabled': {
            backgroundColor: '#DCF0ED',
            color: '#0E6E5D',
          },
        }),

        ...(ownerState.type === 'test' &&
          ownerState.color === 'primary' &&
          ownerState.size === 'medium' && {
          'caretColor': '#149C84',
          'fontSamily': 'Gilroy',
          'paddingTop': '2px',
          'fontSize': '16px',
          'fontStyle': 'normal',
          'fontWeight': '500',
          'lineHeight': '130%',
          'width': '100%',
          '& .MuiOutlinedInput-root': {
            padding: '16px 4px',
          },
          '&:hover': {
            color: '#DCF0ED',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
            border: '#E5E5E5',
          },
        }),
        ...(ownerState.type === 'newNone' && {
          'maxHeight': '31px',
          'caretColor': '#149C84',
          'fontSamily': 'Gilroy',
          'padding': '0px',
          'margin': '0px',
          'paddingBottom': '10px',
          'fontSize': '16px',
          'fontStyle': 'normal',
          'fontWeight': '500',
          'lineHeight': '130%',
          '& fieldset': { border: 'none' },

          '&:hover': {
            'color': '#DCF0ED',
            '& fieldset': { border: 'none' },
          },
          '& .MuiOutlinedInput-input': {
            padding: '0px',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
            border: '#E5E5E5',
          },
        }),
        ...(ownerState.type === 'none' && {
          '& label': {
            padding: '0px',
            color: 'red',
          },
          'maxHeight': '31px',
          'caretColor': '#149C84',
          'fontSamily': 'Gilroy',
          'padding': '0px',
          'margin': '0px',
          'paddingBottom': '10px',
          'fontSize': '16px',
          'fontStyle': 'normal',
          'fontWeight': '500',
          'lineHeight': '130%',
          '& fieldset': { border: 'none' },
          'borderBottom': '1px solid #E5E5E5',

          '&:hover': {
            'color': '#DCF0ED',
            '& fieldset': { border: 'none' },
          },
          '& .MuiOutlinedInput-input': {
            paddingLeft: '0px',
          },
          '&.Mui-disabled': {
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
            border: '#E5E5E5',
          },
        }),
        ...(ownerState.error &&
          ownerState.type === 'newNone' && {
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Задайте желаемый цвет границы
          },
          '& fieldset': { border: 'none' },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-error': {
            border: 'none',
          },
        }),
        ...(ownerState.error &&
          ownerState.type === 'none' && {
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Задайте желаемый цвет границы
          },
          '& fieldset': { border: 'none' },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          'borderBottom': '1px solid #FA5353',
          '&.Mui-error': {
            border: 'none',
          },
        }),
        ...(ownerState.type === 'empty' && {
          'padding': 0,
          '& .MuiInputBase-root': {
            padding: 0,
          },
          '& .MuiOutlinedInput-input': {
            padding: 0,
            height: '29px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            padding: 0,
          },
        }),
      }),
    },
  },

  MuiSelect: {
    styleOverrides: {
      outlined: { '& .MuiOutlinedInput-select': { '& fieldset': { border: '3px solid black' } } },
    },
  },

  MuiOutlinedInput: {
    // border
    styleOverrides: {
      root: ({ ownerState }) => ({
        '& .MuiSelect': {
          borderColor: 'green',
        },

        ':hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#DCF0ED',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#149C84',
        },
        '& fieldset': {
          borderRadius: '16px',
          borderColor: '#E5E5E5',
        },
        '&.Mui-disabled': {
          borderColor: '#FFFFFF',
        },
        ...(ownerState.disabled && {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderRadius: '16px',
            borderColor: '#E5E5E5',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E5E5E5',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            color: '#FA5353',
            borderColor: '#E5E5E5',
          },
          ':hover .MuiOutlinedInput-notchedOutline': {
            color: '#FA5353',
            borderColor: '#E5E5E5',
          },
        }),
        ...(ownerState.error && {
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            color: '#FA5353',

            borderColor: '#FA5353',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            color: '#FA5353',
            border: '1px solid #FA5353',
          },
          ':hover .MuiOutlinedInput-notchedOutline': {
            color: '#FA5353',
            borderColor: '#FA5353',
          },
        }),
        ...(ownerState.className === 'none' && {
          '& fieldset': {
            border: 'none',
          },
        }),
      }),
    },
  },

  MuiInputBase: {
    // text
    styleOverrides: {
      root: ({ ownerState }) => ({
        color: '#1A1A1E',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20.8px',
      }),
    },
  },

  MuiCheckbox: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        'backgroundColor': '#E5E5E5',
        'borderRadius': '8px',
        'padding': '7px 5px 7px 5px',
        'color': '#E5E5E5',
        ':hover': {
          backgroundColor: 'white',
          color: '#7F7F7F',
        },
        ...(ownerState.color === 'primary' && {
          'backgroundColor': 'white',
          'border': '1px solid #E5E5E5',
          'borderRadius': '4px',
          ':hover': {
            border: '1px solid #149C84',
            backgroundColor: '#FFFFFF',
          },
          '&.Mui-checked': {
            backgroundColor: '#149C84',
          },
        }),
        ...(ownerState.checked === true && {
          ':hover': {
            backgroundColor: '#E5E5E5',
          },
          '&.Mui-checked': {
            backgroundColor: '#149C84',
          },
        }),
      }),
    },
  },

  MuiInputLabel: {
    // lable
    styleOverrides: {
      root: ({ ownerState }) => ({
        'color': '#1A1A1E',
        'position': 'absolute',
        'backgroundColor': '#FFFFFF',
        'fontSamily': 'Gilroy',
        'paddingTop': '2px',
        'fontSize': '16px',
        'fontStyle': 'normal',
        'fontWeight': '500',
        'lineHeight': '130%',
        '&.Mui-focused': {
          color: '#1A1A1E',
        },
        '&.Mui-error': {
          color: '#000000',
        },
      }),
    },
  }
};
