import styled from 'styled-components'
import {Button, TextField } from '@mui/material';



export const CardWrapperDiv = styled.div`
    width: 100%;
    border-radius:20px 20px 0 0;
    background: #FFFFFF;
    position:absolute;
    bottom:0;
    left:50%;
    transform: translate(-50%, 0);
    display: grid;
    grid-template-columns: 1fr;
    overflow: clip;

    @media (width > 844px) {
        width: 726px;
        height: 504px;
        border-radius:8px;
        grid-template-columns: 379px 347px;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
`
export const LeftColumnDiv = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
    background-color: #eee;
    &::before {
        content: '';
        display: block;
        width: 40px;
        height: 2px;
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translate(-50%, 0);
        background: #7F7F7F;
    }
    @media (width > 844px) {
        &::before {
            content: '';
            display: none;
        }
    }
`
export const PhotoBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 254px;
    @media (width > 844px) {
        height: 100%;
    }
`
export const PhotoWrapper =styled.div`
  position: relative;
    width: 100%;
    height: 100%;
`
export const Photo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
export const PhotoDeleted = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
`
export const PhotoReplace = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    gap: 8px;
    padding: 16px;
    @media (width > 844px) {
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity .1s;
        &:hover{
            opacity: 1;
        }
    }
`
export const PRSettings = styled.button`
 background-color: rgb(26, 26, 30);
display: flex;
 align-items: center;
 justify-content: center;
 width: 28px;
 height: 28px;
 border-radius: 4px;
 padding: 0;
 border: none;
 @media (width > 844px) {
  display: none;
    
 }
`
export const PRButtonsWrapper = styled.div`
    padding: 8px 0;
    min-width: 234px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    border-radius: 12px;
    @media (width > 844px) {
        background-color: transparent;
        flex-direction: row;
        gap: 16px;
    }
`
export const PhotoReplaceButton = styled.button`
    position: relative;
    padding: 8px 12px 10px;
    background-color: #fff;
    color: #7F7F7F;
    outline: none;
    border: none;
    transition: all .2s;
    text-align: start;
    font-size: 16px;
    line-height: 1.3;
    &:hover{
        background-color: #DCF0ED;
        color: #000;
        cursor: pointer;
    }
    @media (width > 844px) {
     border-radius: 16px;
     background: rgb(20, 156, 132);
     color: #fff;
     padding: 7px 30px;
     box-sizing: border-box;
     &:first-of-type{
      background: rgb(250, 83, 83);
     }
    }
;
 `
export const AddPhotoInputWrapper = styled.div`
    padding: 0 20px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 168px;
    height: 168px;
    border: 2px dashed #149C84;
    border-radius: 12px;
    color: #0E6E5D;
    font-family: Gilroy;
    text-align: center;
  
`

export const AddPhotoInput = styled.input`
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    &:hover{
        cursor: pointer;
    }
`
export const NotKnowWrapper = styled.div`
    background-color: #fff;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: start;
    padding: 24px;
    @media (width > 844px) {
        justify-content: center;
        padding: 25px 0;
    }
`


export const RightColumnDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: none;
    padding: 0 24px 24px;
    @media (width > 844px) {
        box-shadow: -12px 2px 12px 3px rgb(22 22 22 /0.1);
        padding: 24px;
    }
`

export const TextFieldsWrapper = styled.div`
    margin: 16px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const AddPersonTextField = styled(TextField)`
    border: none;
    outline: none;
    caret-color: #149C84;
    && {
        & label{
            color: #7F7F7F;
        }
    }
    @media (width > 844px) {
        border-radius: 16px!important;
        border: 1px solid #E5E5E5 !important;
        padding: 13px 16px!important;
        &:hover{
            border: 1px solid #149C84 !important;
        }
        &:focus{
            border: 1px solid #149C84 !important;
        }
    }
`
export const MobileYears = styled.div`
    display: flex;
    flex-direction: column;
`
export const DesctopYears = styled.div `

`
export const DateWrapper = styled.div`
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    font-family: Gilroy;
    font-size: 14px;
    align-items: center;
`
export const DateWrapperDesctop = styled.div`
    margin-bottom: 16px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
`
export const Buttons = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: stretch;
    gap: 16px;
`
export const CheckBoxWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
    
`
export const ButtonCancel = styled.button`
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: none;
    padding: 10px 0;
    font-size: 16px;
    border: 2px solid #E5E5E5;
`
export const ButtonAdd = styled.button`
    width: 100%;
    max-width: 160px;
    border-radius: 8px;
    padding: 10px 0;
    background: #149C84;
    font-size: 16px;
    color: #fff;
    border: none;
    &:hover{
        cursor: pointer;
    }
`
