import { IBaseDataCard, IParentData } from "components/CurrentNewTree/type"

export const getParentData = ({ firstName, dateBirth, lastName, rank, globalPos, pos, xPos, yPos, gender, middleName, isDead, dateOfDeath, isUnknown }: IParentData) => {

    const data: IBaseDataCard = {
        type: "treeCard",
        userMaidenName: "s",
        gender,
        userIsDead: isDead,
        rank,
        globalPosition: globalPos,
        position: (pos),
        positionX: xPos - 50,
        positionY: yPos - 220
    }
    if (firstName) {
        data.userName = firstName
    }
    if (middleName) {
        data.userMiddleName = middleName
    }
    if (lastName) {
        data.userLastName = lastName
    }
    if (dateBirth) {
        data.userDataBirth = dateBirth
    } else {
        data.userDataBirth = '__.__.____'

    }
    if (isDead)
        if (dateOfDeath) {
            data.userDataDead = dateOfDeath

        } else {
            data.userDataDead = '__.__.____'

        }
    if (isUnknown) {
        data.type = "phantomNode"
    }
    return data
}