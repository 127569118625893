import { createSlice } from '@reduxjs/toolkit';
import { TSettingsSliceState } from './types';
import { LOCAL_STORAGE_IS_ACCEPT_COOKIE } from 'resources/constants';


const initialState: TSettingsSliceState = {
  isAcceptCookie: Boolean(localStorage.getItem(LOCAL_STORAGE_IS_ACCEPT_COOKIE)),
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    acceptCookie(state) {
      state.isAcceptCookie = true;
      localStorage.setItem(LOCAL_STORAGE_IS_ACCEPT_COOKIE, 'accept');
    },
  },
});

export const {
  acceptCookie,
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;