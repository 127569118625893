import { useDelPersonMutation, useGetTreeQuery, } from "store/storeQuery/treeApi"
import { DelButton, BackButton, ButtonDiv, DeleteWrapper, TextWrapper, LineDiv } from "./style"
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getTreeCurrentCardData } from "store/slices/tree/selectors";

interface IDelProps {
    setRerend: (val: boolean) => void
    setIsOpenModal: (val: boolean) => void
}

export const DeletePersonModal = ({ setRerend, setIsOpenModal }: IDelProps) => {
    const [delPersonAsync] = useDelPersonMutation()
    const CardInfo = useSelector(getTreeCurrentCardData);
    const params = useParams()
    const { data: tree } = useGetTreeQuery(CardInfo.id)
    const navigate = useNavigate()
    const handleClickDelete = () => {
        if (CardInfo.id !== localStorage.getItem('idRoot')) {
            delPersonAsync({ id: CardInfo.id })
        }
        setRerend(true)
        setIsOpenModal(false)
        navigate(0)
    }
    return (
        <DeleteWrapper>
            <LineDiv />
            <TextWrapper>
                Хотите удалить карточку родственника из древа?<br />
                Отменить это действие будет невозможно
            </TextWrapper>
            <ButtonDiv>
                <BackButton variant='outlined' color="secondary" onClick={() => { setIsOpenModal(false) }}>Отменить</BackButton>
                <DelButton variant='contained' className='deleteProfile' onClick={handleClickDelete}>Удалить</DelButton>

            </ButtonDiv>
        </DeleteWrapper>
    )
}