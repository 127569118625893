import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/auth/slice';
import { treeReducer } from './slices/tree/slice';
import { treeCardReducer } from './slices/tree';
import { settingsReducer } from './slices/settings/slice';
import { profileReducer } from './slices/profile/slice';
import { baseApi } from './storeQuery/api';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    tree: treeReducer,
    treeCard: treeCardReducer,
    settings: settingsReducer,
    profile: profileReducer,
    [baseApi.reducerPath]: baseApi.reducer
  },
  middleware: (getDefaultMidleware) => getDefaultMidleware().concat(baseApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


// в hooks лежат типизированный селектор и диспач

