import React from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IBiography } from "pages/Profile/types/types";

export const baseURL = 'https://genelogy-tree-back.lad-academy.ru/'

export const customAxios = axios.create({
    baseURL: 'https://genelogy-tree-back.lad-academy.ru/',
    headers: {
        'Content-Type': 'application/json',


    }
})

export const getBiography = async (id: number) => {
    const biography = await customAxios.get(`biography/${id}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
        },
    });
    return biography;
}

export const putBiography = async (id: number, data: any) => {
    const result = await customAxios.put(`biography/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
        },
    });
    return result;
}

export const putPersonalData = async (id: number, data: any) => {
    const result = await customAxios.put(`relative/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
        },
    });
    return result;
}

export const getRelative = async (id: number) => {
    const relative = await customAxios.get(`relative/${id}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
        },
    });
    return relative;
}

customAxios.interceptors.response.use(
    response => response,
    async error => {
        console.log("cool")
        const conf = error.config;
        if (error.response?.status === 401) {
            const t = await customAxios.post('authentication/refresh', undefined, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(async res => {
                const tok = await res.data;
                localStorage.setItem('accessToken', tok.accessToken)

            })
            console.log(localStorage.getItem("accessToken"))
            if (localStorage.getItem("accessToken") === "undefined") {
                localStorage.removeItem('accessToken')
                window.location.reload()
            }
            conf.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
            return customAxios.request(conf);
        }
    }
);