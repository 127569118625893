/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Button, Modal, TextField, SvgIcon, IconButton, Checkbox } from '@mui/material';
import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { customAxios, baseURL } from 'network/axiosConfig';
import { AddPhotoModal, PreviewPhotoModal, DeleteModal, ChangePhotoModal, ErrorPhotoModal } from "./AddPhotoModal";
import { validateFirstName, validateFirstDoubleName, validateDateBirthTreeCard, validateDateDeathTreeCard } from "components/RegistarionModal/validator/validator";
import { NotCompleteModal } from "components/NotCompleteModal";
import { treeActions } from "store/slices/tree";
import { getIsClickedCard, getIsOpenCardInfo, getTreeCurrentCardData } from 'store/slices/tree/selectors';
import { AddIcon } from 'icons/AddIcon';
import { CloseIcon } from 'icons/CloseIcon';
import { AddRelationIcon } from 'icons/AddRelationIcon';
import { VectorCheckboxLine } from 'icons/VectorCheckboxLine';
import { VectorCheckboxFill } from 'icons/VectorCheckboxFill';
import { VectorDataBorthAndDeath } from 'icons/VectorDataBorthAndDeath';

const TreeCard = ({ node, style, dataArrMain, genderArr, ...props }) => {
  const sxBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24px',
    borderRadius: '24px',
    border: 'none',
    outline: 'none',
    padding: '24px',
  };
  const dispatch = useDispatch()
  const IsClickedCard = useSelector(getIsClickedCard);
  const CurrentCard = useSelector(getTreeCurrentCardData);
  const [isOpenNotCompl, setIsOpenNotCompl] = useState(false);
  const [checked, setChecked] = useState(true); // контроль состояния checkbox
  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  const isOpenCardInfo = useSelector(getIsOpenCardInfo);

  const [addDeleteButtonsState, setAddDeleteButtonsState] = useState(['none', '0', 'none', '0']);


  const [rootId, setRootId] = useState(JSON.parse(localStorage.getItem("idRoot")));
  const [isOpen, setIsOpen] = useState(false); //  контроль модалки
  const [isOpenAddPhoto, setIsOpenAddPhoto] = useState(false)
  const handleChange = (event) => { // функция изменения состояния
    setChecked(event.target.checked)
  }
  const [preview, setPreview] = useState()
  if (node.middleName === 'undefined') {
    node.middleName = ''
  }

  const [fName, setFName] = useState()
  const [sName, setSName] = useState()
  const [mName, setMName] = useState()
  const [maidName, setMaidName] = useState()
  const [dateBirth, setDateBirth] = useState("__.__.____")
  const [dateDeath, setDateDeath] = useState("__.__.____")
  const [selectedFile, setSelectedFile] = useState(null)
  const [file, setFile] = useState(null)

  const [errorSName, setErrorSName] = useState(false);
  const [errorMaidName, setErrorMaidName] = useState(false);
  const [errorFName, setErrorFName] = useState(false);
  const [errorMName, setErrorMName] = useState(false);
  const [errorDateBirth, setErrorDateBirth] = useState(false);
  const [errorDateDeath, setErrorDateDeath] = useState(false);


  const generationArr = [["сына", "дочери"], [["мужа", "жены"], ["брата", "сестры"]], ["отца", "матери"], ["дедушки", "бабушки"], ["прадедушки", "прабабушки"]]
  const relatiomArr = [["Cын", "Дочь"], [["Муж", "Жена"], ["Брат", "Сестра"]], ["Отец", "Мать"], ["Дедушка", "Бабушка"], ["Прадедушка", "Прабабушка"]]

  let d = ''
  if (node.dateOfBirth instanceof Date) {
    d = `, ${node.dateOfBirth.getMonth() + 1}.${node.dateOfBirth.getDate()}.${node.dateOfBirth.getFullYear()}`
  }
  else
    if (node.isDead) {
      if (node.dateOfDeath === null) {
        node.dateOfDeath = '__.__.____'
      }
      if (node.dateOfBirth === null) {
        node.dateOfBirth = '__.__.____'
      }
      if (node.dateOfBirth === '__.__.____' && node.dateOfDeath === '__.__.____') {
        d = 'Годы жизни неизвестны'
      } else
        if (node.dateOfBirth === '__.__.____') {
          d = `Неизвестно - ${node.dateOfDeath}`
        } else
          if (node.dateOfDeath === '__.__.____') {
            d = `${node.dateOfBirth} - Неизвестно`
          } else
            d = `${node.dateOfBirth} - ${node.dateOfDeath}`
    } else {
      if (node.dateOfBirth === null) {
        node.dateOfBirth = '__.__.____'
      }
      if (node.dateOfBirth === '__.__.____') {
        d = ', Неизвестно'
      } else
        d = `, ${node.dateOfBirth}`
    }


  function getRelation(node) {
    let gender;
    if (node.gender === genderArr[0]) {
      gender = 0;
    } else {
      gender = 1;
    }
    if (node.generation === 1) {
      if (node.spouses.length !== 0) {
        if (node.spouses[0].id === rootId) {
          return generationArr[node.generation][0][gender];
        }
      }
      if (node.siblings.length !== 0) {
        if (node.siblings[0].id === rootId) {
          return generationArr[node.generation][1][gender]
        }
      }
    }
    if (node.generation > 4) {
      if (gender === 0) {
        return `пра(${node.generation - 3})дедушки`
      }
      if (gender === 1) {
        return `пра(${node.generation - 3})бабушки`
      }
    }
    return generationArr[node.generation][gender]
  }

  function getRelationForSign(node) {
    console.log(node)
    if (node.id === rootId) {
      return "Я"
    }
    let gender;
    if (node.gender === genderArr[0]) {
      gender = 0;
    } else {
      gender = 1;
    }
    if (node.generation === 1) {
      if (node.spouses[0].id === rootId) {
        return relatiomArr[node.generation][0][gender];
      }
      if (node.siblings[0].id === rootId) {
        return relatiomArr[node.generation][1][gender]
      }
    }
    if (node.generation > 4) {
      if (gender === 0) {
        return `Пра(${node.generation - 3})дедушка`
      }
      if (gender === 1) {
        return `Пра(${node.generation - 3})бабушка`
      }
    }
    return relatiomArr[node.generation][gender]
  }

  const toggleDrawer = (anchor, open) => (event) => {

    dispatch(treeActions.setIsOpenCardInfo(open))
    dispatch(treeActions.setIsClickedCard(true))
    dispatch(treeActions.setCurrenCardData({
      id: node.id,
      photo: node.avatar,
      name: node.name,
      surname: node.surname,
      fatherName: node.middleName,
      relationName: getRelationForSign(node),
      dateOfBirth: String(node.dateOfBirth),
      dateOfDeath: String(node.dateOfDeath),
      isDead: node.isDead,
      children: node.children,
      parents: node.parents,
      siblings: node.siblings,
      spouses: node.spouses,
      gender: node.gender,
    }))
  }

  const isCheckError = (e, fieldID) => {
    switch (fieldID) {
      case 0:
        if (!validateFirstName(e.target.value)) {
          setErrorSName(true)
        } else { setErrorSName(false) }
        break;
      case 1:
        if (!validateFirstName(e.target.value) && e.target.value) {
          setErrorMaidName(true)
        } else { setErrorMaidName(false) }
        break;
      case 2:
        if (!validateFirstDoubleName(e.target.value) && !validateFirstName(e.target.value)) {
          setErrorFName(true)
        } else { setErrorFName(false) }
        break;
      case 3:
        if (!validateFirstName(e.target.value) && e.target.value) {
          setErrorMName(true)
        } else { setErrorMName(false) }
        break;
      case 4:
        if ((!validateDateBirthTreeCard(e.target.value)) || (!validateDateDeathTreeCard(e.target.value, dateBirth)) || (!validateDateDeathTreeCard(dateDeath, e.target.value))) {
          setErrorDateBirth(true)
        } else { setErrorDateBirth(false) }

        break;
      case 5:
        if ((!validateDateBirthTreeCard(e.target.value)) || (!validateDateDeathTreeCard(e.target.value, dateBirth)) || (!validateDateDeathTreeCard(dateDeath, e.target.value))) {
          setErrorDateDeath(true)
        } else { setErrorDateDeath(false) }

        break;
      default:
        break;

    }
  }


  const addPhotoHandler = (node) => {
    console.log(node)
  }

  const getChild = (node) => {
    const childArr = []
    node.children.forEach((el) => { if (el.id >= 1) { childArr.push(el.id) } })
    console.log(childArr)
    return childArr
  }
  const handleClickCloseAddPhotoModal = (node) => {
    setIsOpenAddPhoto(false)
    setStep(1)
  }



  const handleClickClose = (node) => {
    if (typeof node === 'undefined') {
      setIsOpen(false);
      return true;
    }

    setErrorSName(false);
    setErrorMaidName(false);
    setErrorFName(false);
    setErrorMName(false);
    setErrorDateBirth(false);



    setErrorDateDeath(false)
    setDateBirth("__.__.____")
    setIsOpen(false)
    setFName(null)
    setSName(null)
    setMaidName(null)
    setMName(null)
    setDateDeath("__.__.____")
    setSelectedFile(null)
    setChecked(true)



  }


  const PostParentAxios = async (id, sex,) => {
    const formData = new FormData()
    if (dateBirth === null) {
      setDateBirth('__.__.____')
    }
    formData.append('userName', fName)
    if (mName) {
      formData.append('userMiddleName', mName)
    }

    formData.append('userLastName', sName)
    formData.append('userMaidenName', maidName)
    formData.append('gender', node.gender)
    if (dateBirth) {
      formData.append('userDataBirth', dateBirth)
    }
    formData.append('userIsDead', !checked)
    if (!checked) {
      if (dateDeath) {
        formData.append('userDataDead', dateDeath)
      }
    }
    if (selectedFile) {
      formData.append('userAvatar', selectedFile)
    }
    formData.append('userGeneration', node.generation)

    if (selectedFile) {

      console.log(selectedFile)

    }
    const prom = await customAxios.post(`parent/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }).then(async resp => {
      const data = await resp.data;
      node.avatar = data.avatar
      console.log(node);
    })

  }
  const PostPartnerAxios = async (sex, childArr) => {
    const formData = new FormData()
    if (dateBirth === null) {
      setDateBirth('__.__.____')
    }
    console.log(dateBirth)
    formData.append('userName', fName)
    if (mName) {
      formData.append('userMiddleName', mName)
    }

    formData.append('userLastName', sName)
    if (node.gender === 'female') {
      formData.append('userMaidenName', maidName)
    }
    formData.append('gender', node.gender)
    if (dateBirth) {
      formData.append('userDataBirth', dateBirth)
    }
    if (selectedFile) {
      formData.append('userAvatar', selectedFile)
    }
    formData.append('userGeneration', node.generation)
    formData.append('userIsDead', !checked)
    if (!checked) {
      if (dateDeath) {
        formData.append('userDataDead', dateDeath)
      }
    }
    console.log(childArr.length)

    if ((childArr.length !== 0)) {
      childArr.forEach((el) => { formData.append('partnerChildren', el) })


    }

    console.log(...formData)

    const prom = await customAxios.post(`partner/${localStorage.getItem("idRoot")}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }).then(async resp => {
      const data = await resp.data;


      console.log(JSON.stringify(data));
    })
  }
  const PostChildAxios = async (sex) => {
    const formData = new FormData()
    if (dateBirth === null) {
      setDateBirth('__.__.____')
    }
    formData.append('userName', fName)
    if (mName) {
      formData.append('userMiddleName', mName)
    }

    formData.append('userLastName', sName)
    if (node.gender === 'female') {
      formData.append('userMaidenName', maidName)
    }
    formData.append('gender', node.gender)
    if (dateBirth) {
      formData.append('userDataBirth', dateBirth)
    }
    formData.append('userIsDead', !checked)
    if (!checked) {
      if (dateDeath) {
        formData.append('userDataDead', dateDeath)
      }
    }
    if (selectedFile) {
      formData.append('userAvatar', selectedFile)
    }
    formData.append('userGeneration', node.generation)
    if (node.generation === 1) {
      const addpath = `?isSibling=true`

    }
    const prom = await customAxios.post(`child/${localStorage.getItem("idRoot")}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }).then(async resp => {
      const data = await resp.data;
      console.log(JSON.stringify(data));
    })
  }

  const GetAllTreeAxios = async () => {
    const response = await customAxios.get('tree', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      },
      withCredentials: true
    }).then(async resp => {
      const data = await resp.data;
      localStorage.setItem('treeArray', JSON.stringify(data))

    })

  }

  const PutRelativeAxios = async () => {
    const formData = new FormData()
    if (dateBirth === null) {
      setDateBirth('__.__.____')
    }
    formData.append('userName', fName)
    formData.append('userMiddleName', mName)
    formData.append('userLastName', sName)
    if (node.gender === 'female') {
      formData.append('userMaidenName', maidName)
    }
    formData.append('gender', node.gender)
    formData.append('userDataBirth', dateBirth)
    formData.append('userIsDead', false)
    if (selectedFile) {
      formData.append('userAvatar', selectedFile)
    }
    formData.append('userGeneration', node.generation)

    if (selectedFile) {

      console.log(selectedFile)

    }
    const prom = await customAxios.put(`relative/${node.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      }
    }).then(async resp => {
      await GetAllTreeAxios().then(() => { navigate(0) })
      setIsOpen(false)
    })

  }

  const handleClickOpenAddPhotoModal = (e) => {
    e.stopPropagation();
    if (!isOpenAddPhoto) {
      setStep(1)
      setIsOpenAddPhoto(true)
    }
  }
  const handleClickOpenChangePhotoModal = (e) => {
    e.stopPropagation();
    if (!isOpenAddPhoto) {
      setStep(4)
      setIsOpenAddPhoto(true)
    }
  }
  const handleClickOpenAddPhotoModalDrawer = (e) => {
    if (!isOpenAddPhoto) {
      setStep(1)
      setIsOpenAddPhoto(true)
    }
  }
  const handleClickOpenChangePhotoModalDrawer = (e) => {
    if (!isOpenAddPhoto) {
      setStep(4)
      setIsOpenAddPhoto(true)
    }
  }
  const HandleClick = (node) => {
    if (!isOpen) {
      console.log(node)
      setIsOpen(true)
    }
  }



  const t = node.imgUrl;

  const onClickHandler = async (node) => {
    setIsOpen(false);
    const idFetch = node.children.find(e => e.id > 0)

    if (node.spouses.find(e => e.id > 0)?.id === +localStorage.getItem('idRoot')) {
      const childArr = getChild(node)
      await PostPartnerAxios(node.gender, childArr)
    } else
      if (idFetch?.id > 0) {
        await PostParentAxios(idFetch.id, node.gender)


      } else
        if (node.parents.find(e => e.id > 0)?.id > 0) {
          await PostChildAxios(node.gender)
        }

    await GetAllTreeAxios().then(() => { navigate(0) })

  }

  const handleMouseEnterCard = (e) => {
    setAddDeleteButtonsState(["block", "0", "inline", "0"]);
    setTimeout(() => { setAddDeleteButtonsState(["block", "1", "inline", "16px"]); }, 200)
  }

  const handleMouseLeaveCard = (e) => {
    setTimeout(() => { setAddDeleteButtonsState(["block", "0", "inline", "0px"]); }, 300)
    setTimeout(() => { setAddDeleteButtonsState(["none", "0", "none", "0px"]); }, 550)
  }

  const isPhantom = node.id < 0;

  const changePhotoHandler = (e) => {
    if (e.target && e.target.files) {
      setSelectedFile(e.target.files[0])
      const f = e.target.files[0];

      if (f.size >= 2 * 1024 * 1024) {
        setStep(5);
        setIsOpenAddPhoto(true);
      }
      const reader = new FileReader()

      reader.readAsDataURL(f)

      reader.onload = async (e) => { await setPreview(reader.result) }

    }

  }
  const handleClickNotCompl = (e) => {
    e.stopPropagation();
    if (!isOpenAddPhoto) {
      setStep(6)
      setIsOpenAddPhoto(true)
    }
  }
  const handleClickDel = (e) => {
    e.stopPropagation();
    if (!isOpenAddPhoto) {
      setStep(3)
      setIsOpenAddPhoto(true)
    }
  }
  let cardStyle;


  if ((CurrentCard.id === node.id) && isOpenCardInfo) {
    if (IsClickedCard) {
      if (node.id !== +localStorage.getItem('idRoot')) {
        cardStyle = styles.card2Clicked;
      } else {
        cardStyle = styles.cardClicked;
      }
      if (node.isDead) {
        cardStyle = styles.card3Clicked;
      }
    }
  }
  else {
    if (node.id !== +localStorage.getItem('idRoot')) {
      cardStyle = styles.card2;
    } else {
      cardStyle = styles.card;
    }
    if (node.isDead) {
      cardStyle = styles.card3;
    }
  }

  return (
    <div style={{ transform: 'perspective(1px)' }}>

      {node.isPhantom ? (
        <button type="button" className={styles.root} style={style} >

          <div className={styles.cardphantom}>
            <button type="button" className={styles.unnownUserInner1} onClick={() => { HandleClick(node) }}>
              <IconButton className="add" color="secondary" component="label" style={{ width: '180px', height: '180px', padding: '65px', position: 'relative', top: '80%', margin: '16px' }}>
                <SvgIcon component={AddIcon} style={{ width: '53px', height: '53px' }} viewBox="0 0 53 53" />
              </IconButton>
              <p className={styles.unnownUserTextName}>Родственник неизвестен</p>
            </button>
          </div>
          <Modal
            open={isOpen}
            onClose={() => { handleClickClose(node) }}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
          >

            <Box sx={{ ...sxBox }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                <h2 id='parent-modal-title' style={{ fontSize: '24px', lineHeight: '31.2px', paddingBottom: '40px', margin: 0, fontFamily: 'Golos Text', fontWeight: 500, color: '#1A1A1E' }}>Добавление {getRelation(node)} в древо</h2>
                <IconButton onClick={() => { handleClickClose(node) }} disableRipple style={{ padding: 0 }}><SvgIcon component={CloseIcon} style={{ width: '18px', height: '18px', marginTop: '4px' }} viewBox="0 0 18 18" /></IconButton>
              </div>
              <div id='parent-modal-description' style={{ display: 'flex', justifyContent: 'space-between', columnGap: '40px' }}>
                <div>
                  <IconButton className="add" color="secondary" component="label" style={{ height: '216px' }}>
                    {selectedFile ? <img src={preview} alt="test" style={{ width: '140px', height: '140px', position: 'center', repeat: 'no-repeat' }} /> : <SvgIcon component={AddIcon} style={{ width: '53px', height: '53px' }} viewBox="0 0 53 53" />}
                    <input type="file" accept="image/*,.png,.jpeg,.jpg" hidden onChange={(e) => changePhotoHandler(e)} />
                  </IconButton>
                  <div style={{ display: 'flex', columnGap: '8px', alignItems: 'center', paddingTop: '24px' }}>
                    <Checkbox checked={checked} onChange={handleChange} icon={<VectorCheckboxLine style={{ width: '16px', height: '12px' }} viewBox="0 0 16 12" />} checkedIcon={<VectorCheckboxFill style={{ width: '16px', height: '12px' }} viewBox="0 0 16 12" />} />
                    <span style={{ fontFamily: 'Golos Text', fontSize: '12px', lineHeight: '15.6px', color: '#1A1A1E', fontWeight: '400' }}>Человек жив</span>
                  </div>
                </div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', rowGap: '24px' }}>
                  <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => {
                    setSName(e.target.value);
                    isCheckError(e, 0);
                  }}
                    margin='dense'
                    id='name'
                    error={errorSName}
                    label={errorSName ? 'Некорректная фамилия' : 'Фамилия'}
                    type='text'
                    size="medium"
                    variant="outlined"
                  />
                  {node.gender === "female" && <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => { // Дополнительное поле, если узел женского пола
                    setMaidName(e.target.value);
                    isCheckError(e, 1);
                  }}
                    margin='dense'
                    id='maidenName'
                    error={errorMaidName}
                    label={errorMaidName ? 'Некорректная девичья фамилия' : 'Девичья фамилия'}
                    type='text'
                    size="medium"
                    variant="outlined"
                  />}
                  <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => {
                    setFName(e.target.value);
                    isCheckError(e, 2);
                  }}

                    margin='dense'
                    id='surname'
                    error={errorFName}
                    label={errorFName ? 'Некорректное имя' : 'Имя'}
                    type='text'
                    size="medium"
                    variant="outlined"
                  />

                  <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => {
                    setMName(e.target.value);
                    isCheckError(e, 3);
                  }}
                    margin='dense'
                    id='middleName'
                    error={errorMName}
                    label={errorMName ? 'Некорректное отчество' : 'Отчество'}
                    type='text'
                    size="medium"
                    variant="outlined"
                  />


                  {checked && <InputMask style={{ width: '100%', margin: 0 }} onChange={(e) => {
                    setDateBirth(e.target.value);
                    isCheckError(e, 4);
                  }}
                    mask="99.99.9999"
                    placeholder="дд.мм.гггг"
                    error={errorDateBirth}
                    margin='dense'
                    id='dateOfBirth'
                    label='Дата рождения'
                    size="medium"
                    variant="outlined"
                  >

                    {(props) => <TextField placeholder="дд.мм.гггг"  {...props} />}

                  </InputMask>}

                  {checked === false && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '12px' }}>
                    <InputMask style={{ width: '100%', margin: 0 }} onChange={(e) => {
                      setDateBirth(e.target.value);
                      if (dateDeath === "__.__.____") {
                        setErrorDateDeath(false);
                      }

                      else if (dateDeath && validateDateDeathTreeCard(dateDeath, e.target.value)) {

                        setErrorDateDeath(false);
                      }
                      isCheckError(e, 4);
                    }}
                      mask="99.99.9999"
                      placeholder="дд.мм.гггг"
                      error={errorDateBirth}
                      margin='dense'
                      id='dateOfBirth'
                      label='Дата рождения'
                      size="medium"
                      variant="outlined"
                    >

                      {(props) => <TextField {...props} />}

                    </InputMask>

                    <VectorDataBorthAndDeath style={{ width: '16px', height: '2px' }} viewBox="0 0 16 2" />

                    <InputMask style={{ width: '100%', margin: 0 }} onChange={(e) => {
                      if (dateBirth === "__.__.____") {
                        setErrorDateDeath(false);
                      }
                      setDateDeath(e.target.value);
                      isCheckError(e, 5);
                    }}
                      mask="99.99.9999"
                      placeholder="дд.мм.гггг"
                      error={errorDateDeath}
                      margin='dense'
                      id='dateOfDeath'
                      label='Дата смерти'
                      size="medium"
                      variant="outlined">
                      {(props) => <TextField {...props} />}
                    </InputMask>

                  </div>}


                  <Button disabled={(!checked && errorDateDeath) || errorSName || !sName || errorMaidName || errorFName || !fName || errorMName || errorDateBirth} style={{ width: '100%', marginTop: '16px' }} variant="contained" color="primary" size="large" onClick={() => { onClickHandler(node) }}>Добавить родственника</Button>

                </div>
              </div>

            </Box>

          </Modal>
        </button>
      ) : (
        <div>
          {isPhantom ? (
            <div className={styles.phantomRoot} style={style} >
              {node.generation > 4 ? (
                <button type="button" className={styles.phantomInnerForPra} onClick={() => { HandleClick(node) }}>
                  <SvgIcon component={AddRelationIcon} className='AddRelationIcon' viewBox="0 0 24 24" />
                  <p className={styles.phantomTextName}>{node.name}</p>
                </button>
              ) : (
                <button type="button" className={styles.phantomInner} onClick={() => { HandleClick(node) }}>
                  <SvgIcon component={AddRelationIcon} className='AddRelationIcon' viewBox="0 0 24 24" />
                  <p className={styles.phantomTextName}>{node.name}</p>
                </button>
              )}

              <Modal
                open={isOpen}
                onClose={() => { handleClickClose(node) }}
                aria-labelledby='parent-modal-title'
                aria-describedby='parent-modal-description'
              >

                <Box sx={{ ...sxBox }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', letterSpacing: 0 }}>
                    <h2 id='parent-modal-title' style={{ fontSize: '24px', lineHeight: '31.2px', paddingBottom: '40px', margin: 0, fontFamily: 'Golos Text', fontWeight: 500, color: '#1A1A1E' }}>Добавление {getRelation(node)} в древо</h2>
                    <IconButton onClick={() => { handleClickClose(node) }} disableRipple style={{ padding: 0 }}><SvgIcon component={CloseIcon} style={{ width: '18px', height: '18px', marginTop: '4px' }} viewBox="0 0 18 18" /></IconButton>
                  </div>
                  <div id='parent-modal-description' style={{ display: 'flex', justifyContent: 'space-between', columnGap: '40px' }}>
                    <div>
                      <IconButton className="add" color="secondary" component="label" style={{ width: '216px', height: '216px' }}>
                        {selectedFile ? <img src={preview} alt="test" style={{ borderRadius: '16px', width: '214px', height: '214px', position: 'absolute', repeat: 'no-repeat' }} /> : <SvgIcon component={AddIcon} style={{ width: '53px', height: '53px' }} viewBox="0 0 53 53" />}
                        <input type="file" accept="image/*,.png,.jpeg,.jpg" hidden onChange={(e) => changePhotoHandler(e)} />
                      </IconButton>
                      <div style={{ display: 'flex', columnGap: '8px', alignItems: 'center', paddingTop: '24px' }}>
                        <Checkbox checked={checked} onChange={handleChange} icon={<VectorCheckboxLine style={{ width: '16px', height: '12px' }} viewBox="0 0 16 12" />} checkedIcon={<VectorCheckboxFill style={{ width: '16px', height: '12px' }} viewBox="0 0 16 12" />} />
                        <span style={{ fontFamily: 'Golos Text', fontSize: '12px', lineHeight: '15.6px', color: '#1A1A1E', fontWeight: '400', letterSpacing: 0 }}>Человек жив</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', width: '360px', flexDirection: 'column', rowGap: '24px' }}>
                      <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => {
                        setSName(e.target.value);
                        isCheckError(e, 0);
                      }}
                        margin='dense'
                        id='name'
                        error={errorSName}
                        label={errorSName ? 'Некорректная фамилия' : 'Фамилия'}
                        type='text'
                        size="medium"
                        variant="outlined"
                      />
                      {node.gender === "female" && <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => { // Дополнительное поле, если узел женского пола
                        setMaidName(e.target.value);
                        isCheckError(e, 1);
                      }}
                        margin='dense'
                        id='maidenName'
                        error={errorMaidName}
                        label={errorMaidName ? 'Некорректная девичья фамилия' : 'Девичья фамилия'}
                        type='text'
                        size="medium"
                        variant="outlined"
                      />}
                      <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => {
                        setFName(e.target.value);
                        isCheckError(e, 2);
                      }}

                        margin='dense'
                        id='surname'
                        error={errorFName}
                        label={errorFName ? 'Некорректное имя' : 'Имя'}
                        type='text'
                        size="medium"
                        variant="outlined"
                      />

                      <TextField style={{ width: '100%', margin: 0 }} onChange={(e) => {
                        setMName(e.target.value);
                        isCheckError(e, 3);
                      }}
                        margin='dense'
                        id='middleName'
                        error={errorMName}
                        label={errorMName ? 'Некорректное отчество' : 'Отчество'}
                        type='text'
                        size="medium"
                        variant="outlined"
                      />

                      {checked && <InputMask style={{ width: '100%', margin: 0 }} onChange={(e) => {
                        setDateBirth(e.target.value);
                        isCheckError(e, 4);
                      }}
                        mask="99.99.9999"
                        placeholder="дд.мм.гггг"
                        error={errorDateBirth}
                        margin='dense'
                        id='dateOfBirth'
                        label='Дата рождения'
                        size="medium"
                        variant="outlined"
                      >

                        {(props) => <TextField placeholder="дд.мм.гггг"  {...props} />}

                      </InputMask>}

                      {checked === false && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '12px' }}>
                        <InputMask style={{ width: '100%', margin: 0 }} onChange={(e) => {
                          setDateBirth(e.target.value);
                          if (dateDeath === "__.__.____") {
                            setErrorDateDeath(false);
                          }

                          else if (dateDeath && validateDateDeathTreeCard(dateDeath, e.target.value)) {

                            setErrorDateDeath(false);
                          }
                          isCheckError(e, 4);
                        }}
                          mask="99.99.9999"
                          placeholder="дд.мм.гггг"
                          error={errorDateBirth}
                          margin='dense'
                          id='dateOfBirth'
                          label='Дата рождения'
                          size="medium"
                          variant="outlined"
                        >

                          {(props) => <TextField {...props} />}

                        </InputMask>

                        <VectorDataBorthAndDeath style={{ width: '16px', height: '2px' }} viewBox="0 0 16 2" />

                        <InputMask style={{ width: '100%', margin: 0 }} onChange={(e) => {
                          if (dateBirth === "__.__.____") {
                            setErrorDateDeath(false);
                          }
                          setDateDeath(e.target.value);
                          isCheckError(e, 5);
                        }}
                          mask="99.99.9999"
                          placeholder="дд.мм.гггг"
                          error={errorDateDeath}
                          margin='dense'
                          id='dateOfDeath'
                          label='Дата смерти'
                          size="medium"
                          variant="outlined">
                          {(props) => <TextField {...props} />}
                        </InputMask>

                      </div>}

                      <Button disabled={(!checked && errorDateDeath) || errorSName || !sName || errorMaidName || errorFName || !fName || errorMName || errorDateBirth} style={{ width: '100%', marginTop: '16px' }} variant="contained" color="primary" size="large" onClick={() => { onClickHandler(node) }}>Добавить родственника</Button>
                    </div>
                  </div>

                </Box>

              </Modal>
            </div>
          ) : (
            <button type="button" className={styles.root} style={style} >

              <div>
                <div className={cardStyle} role="button" tabIndex={0} onKeyDown={toggleDrawer("left", true)} onClick={toggleDrawer("left", true)}>
                  <button type="button" className={styles.imageButton} onClick={(e) => { handleClickNotCompl(e) }} >Перейти в профиль</button>
                  <div className={styles.imageArea} >
                    <IconButton className="add" color="secondary" component="label" style={{ height: '180px', width: '180px', gridArea: 'image', margin: '5px', top: -4, left: -7 }} onClick={(e) => { if (!node.avatar) { handleClickOpenAddPhotoModal(e) } }}>
                      {node.avatar ? <img src={`${baseURL}avatar?avatarPath=${node.avatar}&id=${node.id}`} className={styles.imageArea} style={{ margin: '0px' }} alt="test" /> : <SvgIcon component={AddIcon} style={{ width: '53px', height: '53px' }} viewBox="0 0 53 53" />}
                    </IconButton>
                    {node.avatar && <Button className={styles.imageChangeButton} variant="contained" style={{ width: '127px', height: '32px', top: -45 }} onClick={(e) => { handleClickOpenChangePhotoModal(e) }}>Изменить фото</Button>}
                  </div>
                  <div className={styles.infoP}>
                    <p className={styles.textName}>{node.surname}</p>
                    <p className={styles.textSName}>{node.name}</p>
                    {node.middleName !== null ? (<p className={styles.textMName}>{node.middleName}</p>) : (<>ㅤ</>)}
                    {node.isDead ? (
                      <>
                        <p className={styles.relationAreaForDead}>{getRelationForSign(node)}</p>
                        <p className={styles.birthAreaForDead}>{d}</p>
                      </>
                    ) : (
                      <p className={styles.birthArea}>{getRelationForSign(node)}{d}</p>
                    )}
                  </div>
                  <div className={styles.AddDelete}>
                    <Button className={styles.addRelative} variant="contained" onClick={(e) => { handleClickNotCompl(e) }}>Добавить родственника</Button>
                    {(node.id !== +localStorage.getItem('idRoot')) && <Button className={styles.deleteRelative} style={{ margin: '11px 0px 0px', fontSize: '12px', color: 'red', border: 0 }} variant="outlined" onClick={(e) => { handleClickDel(e) }}>Удалить из древа</Button>}
                  </div>


                </div>
              </div>


            </button>
          )}
        </div>
      )
      }

      <Modal
        open={isOpenAddPhoto}
        onClose={() => { handleClickCloseAddPhotoModal(node) }}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box >
          {step === 1 && <AddPhotoModal setFile={setFile} userID={node.id} step={step} setStep={setStep} setPreview={setPreview} preview={preview} setIsOpenAddPhoto={setIsOpenAddPhoto} />}
          {step === 2 && <PreviewPhotoModal userID={node.id} file={file} step={step} setStep={setStep} setPreview={setPreview} preview={preview} setIsOpenAddPhoto={setIsOpenAddPhoto} />}
          {step === 3 && <DeleteModal setFile={setFile} step={step} setStep={setStep} setPreview={setPreview} preview={preview} setIsOpenAddPhoto={setIsOpenAddPhoto} delId={node.id} />}
          {step === 4 && <ChangePhotoModal avatarURL={node.avatar} setFile={setFile} step={step} setStep={setStep} setPreview={setPreview} preview={`${baseURL}avatar?avatarPath=${node.avatar}&id=${node.id}`} setIsOpenAddPhoto={setIsOpenAddPhoto} />}
          {step === 5 && <ErrorPhotoModal setFile={setFile} step={step} setStep={setStep} setPreview={setPreview} preview={preview} setIsOpenAddPhoto={setIsOpenAddPhoto} />}
          {step === 6 && <NotCompleteModal isOpen={isOpenAddPhoto} setIsOpen={setIsOpenAddPhoto} />}

        </Box>
      </Modal>


    </div>
  );
}

export default TreeCard;