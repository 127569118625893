import { SerializedError, createAsyncThunk, miniSerializeError } from "@reduxjs/toolkit";
import { RootState } from "store";
import { useNavigate } from "react-router-dom";
import { customAxios } from "network/axiosConfig";

export const registrationAsync = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
  'auth/registration',
  async (_, { getState, rejectWithValue }) => {
    try {

      const response = await fetch('https://genelogy-tree-back.lad-academy.ru/register', {
        method: 'POST',
        body: JSON.stringify(getState().auth.data),
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json();
      localStorage.setItem("idRoot", data.id)
      console.log(getState().auth.data);
      localStorage.setItem("rootUser", JSON.stringify(data))
      localStorage.setItem("accessToken", data.accessToken)
      return data;
    } catch (error) {
      const serializedError = miniSerializeError(error);
      return rejectWithValue(serializedError);
    }
  }
);
export const regAsyncAxios = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
  'auth/registration',
  async (_, { getState, rejectWithValue }) => {
    try {

      const data = await customAxios.post('register', {

        userName: getState().auth.data.userName,
        userMiddleName: getState().auth.data.userMiddleName,
        userLastName: getState().auth.data.userLastName,
        userMaidenName: getState().auth.data.userMaidenName,
        gender: getState().auth.data.gender,
        userEmail: getState().auth.data.userEmail,
        userPass: getState().auth.data.userPass,
        userPassConfm: getState().auth.data.userPassConfm,
        userDataBirth: getState().auth.data.userDataBirth,
        userCity: 'тест город'
      },

        { withCredentials: true }
      )
      const datas = await data.data;
      console.log(getState().auth.data)
      localStorage.setItem("idRoot", datas.id)
      localStorage.setItem("rootUser", JSON.stringify(datas))
      localStorage.setItem("accessToken", datas.accessToken)
      console.log(typeof datas === 'string' || datas instanceof String)
      console.log(datas)
      return typeof datas === 'string' || datas instanceof String;
    } catch (error) {
      localStorage.setItem("idRoot", 'undefined')

      const serializedError = miniSerializeError(error);
      return rejectWithValue(serializedError);
    }
  }
);
export const getUserAsync = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
  'auth/registration',
  async (_, { getState, rejectWithValue }) => {
    try {

      const response = await fetch('https://genelogy-tree-back.lad-academy.ru/user', {
        method: 'GET',
        body: localStorage.getItem("idRoot"),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      const serializedError = miniSerializeError(error);
      return rejectWithValue(serializedError);
    }
  }
);

export const postParentsAxios = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
  'auth/registration',
  async (_, { getState, rejectWithValue }) => {
    try {

      const response = await fetch('https://genelogy-tree-back.lad-academy.ru/user', {
        method: 'POST',
        body: localStorage.getItem("idRoot"),
        headers: {
          'Content-Type': 'application/json',
        }
      })
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      const serializedError = miniSerializeError(error);
      return rejectWithValue(serializedError);
    }
  }
);

export const authAsyncAxios = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
  'auth/authentication',
  async (_, { getState, rejectWithValue }) => {
    try {
      const data = await customAxios.post('authentication', {
        email: getState().auth.data.userEmail,
        password: getState().auth.data.userPass,
      },

        { withCredentials: true }
      )
      const datas = await data.data;
      console.log(datas)
      localStorage.setItem("idRoot", datas.id)
      console.log(data);
      localStorage.setItem("rootUser", JSON.stringify(datas))
      localStorage.setItem("accessToken", datas.accessToken)
      return datas;
    } catch (error) {
      const serializedError = miniSerializeError(error);
      return rejectWithValue(serializedError);
    }
  }
);



