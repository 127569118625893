import { Typography } from "@mui/material";
import styled from "styled-components";

export const TypographyCustom = styled(Typography)`
&&{
  color:#7F7F7F ;
}

`

export const sxSelect = {
  width: '100%',
  fontFamily: 'Golos Text',
  fontWeight: '500',
  lineHeight: '21px',
};

export const sxMenuItem = {
  padding: '8px, 12px, 10px, 12px',
  color: '#7F7F7F',
  left: '100px ',
  fontFamily: 'Golos Text',
  fontWeight: '500',
  lineHeight: '21px',
};
