import React from "react";
import { header, logoTextBlack, logoTextGreen, buttonText } from './style'

export const FooterTree = () => {

    const mediaQueryCheck = window.matchMedia('(max-width: 1280px)').matches;
    const handleMouseEnter = (e: any) => {
        e.target.style.borderBottom = '1px #149C84 solid '
        e.target.style.lineHeight = '12px'
    }

    const handleMouseLeave = (e: any) => {
        e.target.style.borderBottom = '0px '


    }
    // cgeck
    return (
        <div style={{ ...header(mediaQueryCheck), overflowX: 'hidden' }}>

            <div style={{ display: 'flex', margin: '0 0 0 24px', letterSpacing: 0 }}>
                <span style={{ fontSize: '12px', fontFamily: 'Golos Text', color: '#7F7F7F', fontWeight: 500 }}>Разработано <a onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ fontSize: '13px', all: 'unset', color: "#149C84", cursor: 'pointer' }} href="https://lad-academy.ru/main">LAD Academy</a> и <a onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ fontSize: '13px', all: 'unset', color: "#149C84", cursor: 'pointer' }} href="http://www.itmm.unn.ru/">ИИТММ ННГУ</a></span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '40px', margin: '0 24px 0 0', flexShrink: '0', letterSpacing: 0 }}>
                <span style={{ fontSize: '12px', fontFamily: 'Golos Text', color: '#7F7F7F', fontWeight: 500 }}>Это ранняя версия сайта. При возникновении вопросов или нахождении багов, можете <a onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ all: 'unset', color: "#149C84", cursor: 'pointer', fontSize: '13px', }} href="mailto:lad-academy@lad24.ru">связаться с нами</a>.</span>
            </div>
        </div>
    )
}
