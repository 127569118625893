import { CompSelect } from "components/Select";
import { SelectCustom } from "pages/Profile/ProfileInfo/select";
import styled from "styled-components";

export const TextFieldWrapper = styled.div`
display: flex;
justify-content:center;
flex-direction: column;

gap:16px;
`
export const CustSelectNew = styled(SelectCustom)`

&& {
    border: 1px solid green;
}
`