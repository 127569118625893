import { Button, styled } from "@mui/material";

export const StyledNotifyContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 24,
  left: 'calc(50% - 744px / 2)',
  width: 744,
  padding: 24,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  background: theme.palette.background.paper,
  boxShadow: '0px 24px 40px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06)',
  borderRadius: 16,
  zIndex: 100000,

  [theme.breakpoints.down(780)]: {
    left: 12,
    right: 12,
    bottom: 0,
    width: 'calc(100% - 12px * 2)',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: '0px -6px 8px 0px #20212814',
    flexDirection: 'column',
    padding: 16,
  }
}));

export const StyledNotifyButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  maxWidth: 'auto',
  width: 168,
  height: 40,
  boxSizing: 'border-box',
  background: theme.palette.info.main,
  borderRadius: 8,
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.info.dark,
    color: theme.palette.common.white,
  },

  [theme.breakpoints.down(780)]: {
    margin: '0 28px',
    width: '100%',
  }
}));