import { Footer } from 'components/Footer'
import styled from 'styled-components'

export const TreeWrapper = styled.div`
    width: 100vw;
    height: 100vh;
`
export const BackgroundImg = styled.img`
    width:100vw;
    height:100vh;
    position: fixed;
    z-index: 0;
    overflow: hidden;
    object-fit:cover;
    background-blend-mode: multiply;

`
export const FooterStyled = styled(Footer)`
    position:fixed;
<<<<<<< HEAD
=======
    z-index:100;
>>>>>>> 01436631b89705942587bd513549281669d22c01
`