import { AppBar, Toolbar, css, styled } from "@mui/material";

export const StyledLogoImage = styled('img')`
  height: 50px;
  width: 134px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      height: 40px;
      width: 107px;
    } 
  `}
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledMenuContainer = styled('div')`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;