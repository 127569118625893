import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Landing } from "../pages/Landing";
import TreePage from "pages/TreePage";
import NewTreePage from "pages/NewTreePage";
import NewTreePageReactFlow from "pages/NewTreePageCard";
import { Profile } from "pages/Profile";
import { CurrentNewTree } from "components/CurrentNewTree";
import { Registration } from "pages/Registration";
import { Login } from "pages/Login";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: '/treee',
    element: <TreePage />
  },
  {
    path: '/newtree',
    element: <NewTreePage />
  },
  {
    path: '/newtreecard',
    element: <NewTreePageReactFlow />
  },
  {
    path: "/profile/:id",
    element: <Profile />,
  },
  {
    path: '/tree',
    element: <CurrentNewTree />
  },
  {
    path: '/registration',
    element: <Registration />
  },
  {
    path: '/login',
    element: <Login />
  }
]);
