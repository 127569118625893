import { Drawer, Box, CssBaseline, List, IconButton, SvgIcon, Divider, Modal } from '@mui/material';
import styles from './style.module.css';
import { useDispatch, useSelector } from "react-redux";
import { getTreeCurrentCardData, getIsOpenCardInfo } from "store/slices/tree/selectors";
import { useState } from "react";
import { treeActions } from "store/slices/tree";
import { AddPhotoModal, ChangePhotoModal, ErrorPhotoModal, PreviewPhotoModal } from "components/Tree/TreeCard/AddPhotoModal";
import { baseURL } from "network/axiosConfig";
import { BigDivstyle, BigIconButtonStyle, Boxstyle, CloseIconStyle, IconButtonStyle, InitialsDiv, LastIconButtonStyle, SpanStyle } from "./style";
import { useNavigate } from "react-router-dom";
import { RelativeList } from "./RelativeList";
import { getBioAsync, getRelativeAsync } from "store/slices/profile/asyncActions";
import { useAppDispatch } from "store/hooks";
import { AddIcon } from 'icons/AddIcon';
import { CloseIcon } from 'icons/CloseIcon';
import { IconSideBarProfile } from 'icons/IconSideBarProfile';
import { IconSideBarEdit } from 'icons/IconSideBarEdit';
import { IconSideBarDelete } from 'icons/IconSideBarDelete';

export const SideBar = () => {
    const navigate = useNavigate();
    const [preview, setPreview] = useState<string | ArrayBuffer | null>();
    const [File, setFile] = useState<any>();
    const [isOpenAddPhoto, setIsOpenAddPhoto] = useState(false);
    const [step, setStep] = useState(1);
    const CardInfo = useSelector(getTreeCurrentCardData);
    const isOpenCardInfo = useSelector(getIsOpenCardInfo);

    const dispatch = useAppDispatch();

    const goToProfilePage = () => {
        navigate(`/profile/${CardInfo.id}`, { state: { prop: CardInfo } });

    };

    const onCardInfoClose = () => {
        dispatch(treeActions.setIsOpenCardInfo(false));
        dispatch(treeActions.setIsClickedCard(false));
    };
    const handleClickCloseAddPhotoModal = () => {
        setIsOpenAddPhoto(false);
        setStep(1);
    };
    const handleClickOpenAddPhotoModalDrawer = () => {
        if (!isOpenAddPhoto) {
            setStep(1);
            setIsOpenAddPhoto(true);
        }
    };
    const handleClickOpenChangePhotoModalDrawer = () => {
        if (!isOpenAddPhoto) {
            setStep(0);
            setIsOpenAddPhoto(true);
        }
    };

    return (
        <div>
            <Drawer
                hideBackdrop
                disablePortal
                variant="temporary"
                ModalProps={{
                    className: styles.underslice,
                }}
                PaperProps={{
                    className: styles.staticPosition,
                }}
                anchor="left"
                open={isOpenCardInfo}
                sx={{
                    flexshrink: 0,
                }}>
                <Box sx={{ ...Boxstyle }}>
                    <CssBaseline />
                    <List>
                        <div style={{ display: 'flex', ...BigDivstyle }}>
                            {/* <IconButton
                                className="add"
                                color="secondary"
                                style={{ ...IconButtonStyle }}
                                onClick={() => {
                                    if (!CardInfo.photo) {
                                        handleClickOpenAddPhotoModalDrawer();
                                    } else {
                                        handleClickOpenChangePhotoModalDrawer();
                                    }
                                }}>
                                {CardInfo.photo ? (
                                    <img
                                        src={`${baseURL}avatar?avatarPath=${CardInfo.photo}&id=${CardInfo.id}`}
                                        className={styles.sideBarImageArea}
                                        style={{ margin: '0px' }}
                                        alt="test"
                                    />
                                ) : (
                                    <SvgIcon
                                        component={AddIcon}
                                        style={{ width: '53px', height: '53px' }}
                                        viewBox="0 0 53 53"
                                    />
                                )}
                            </IconButton> */}
                            <div style={{ position: 'absolute', direction: 'ltr', ...InitialsDiv }}>
                                <p className={styles.textDrawer}>{CardInfo.surname}</p>
                                <p className={styles.textDrawer}>{CardInfo.name}</p>
                                {CardInfo.fatherName !== null ? (
                                    <p className={styles.textDrawer}>{CardInfo.fatherName}</p>
                                ) : (
                                    <>ㅤ</>
                                )}
                                {CardInfo.isDead ? (
                                    <p className={styles.relationAreaForDead}>
                                        {CardInfo.relationName},{' '}
                                        {CardInfo.dateOfBirth === '__.__.____' ? 'Неизвестно' : CardInfo.dateOfBirth} -{' '}
                                        {CardInfo.dateOfDeath}
                                    </p>
                                ) : (
                                    <p className={styles.birthArea}>{CardInfo.relationName}, {CardInfo.dateOfBirth === "__.__.____" ? "Неизвестно" : CardInfo.dateOfBirth}</p>
                                )}
                            </div>
                            <IconButton
                                onClick={onCardInfoClose}
                                disableRipple
                                style={{ textAlign: 'right', ...CloseIconStyle }}>
                                <SvgIcon
                                    component={CloseIcon}
                                    style={{ width: '24px', height: '24px' }}
                                    viewBox="0 0 24 24"
                                />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex', marginTop: '27px', gap: '35px', marginLeft: 22 }}>
                            <IconButton onClick={goToProfilePage} className={styles.headerButton}>
                                <SvgIcon
                                    style={{
                                        backgroundColor: 'F0F0F0',
                                        borderRadius: 16,
                                        width: '36px',
                                        height: '36px',
                                    }}
                                    viewBox="2 1 36 36"
                                    component={IconSideBarProfile}
                                />
                                <span style={{ ...BigIconButtonStyle }}>Профиль</span>
                            </IconButton>
                            <IconButton className={styles.headerButton}>
                                <SvgIcon
                                    style={{
                                        backgroundColor: 'F0F0F0',
                                        borderRadius: 16,
                                        width: '36px',
                                        height: '36px',
                                    }}
                                    viewBox="2 1 36 36"
                                    component={IconSideBarEdit}
                                />
                                <span style={{ ...BigIconButtonStyle }}>Изменить</span>
                            </IconButton>
                            <IconButton className={styles.headerButton}>
                                <SvgIcon
                                    style={{
                                        backgroundColor: 'F0F0F0',
                                        borderRadius: 16,
                                        width: '36px',
                                        height: '36px',
                                    }}
                                    viewBox="2 1 36 36"
                                    component={IconSideBarDelete}
                                />
                                <span style={{ ...LastIconButtonStyle }}>Удалить</span>
                            </IconButton>
                        </div>
                    </List>
                    <Divider />
                    <List>
                        <span style={{ ...SpanStyle }}>Биография</span>
                    </List>
                    <Divider />
                    <RelativeList />
                </Box>
            </Drawer>
            {/* <Modal
                open={isOpenAddPhoto}
                onClose={() => {
                    handleClickCloseAddPhotoModal();
                }}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">
                <Box>
                    {step === 0 && (
                        <ChangePhotoModal
                            avatarURL={CardInfo.photo}
                            setFile={setFile}
                            step={step}
                            setStep={setStep}
                            setPreview={setPreview}
                            preview={`${baseURL}avatar?avatarPath=${CardInfo.photo}&id=${CardInfo.id}`}
                            setIsOpenAddPhoto={setIsOpenAddPhoto}
                        />
                    )}
                    {step === 1 && (
                        <AddPhotoModal
                            setFile={setFile}
                            step={step}
                            setStep={setStep}
                            setPreview={setPreview}
                            preview={preview}
                            setIsOpenAddPhoto={setIsOpenAddPhoto}
                        />
                    )}
                    {step === 2 && (
                        <PreviewPhotoModal
                            userID={CardInfo.id}
                            file={File}
                            step={step}
                            setStep={setStep}
                            setPreview={setPreview}
                            preview={preview}
                            setIsOpenAddPhoto={setIsOpenAddPhoto}
                        />
                    )}
                    {step === 5 && (
                        <ErrorPhotoModal
                            setFile={setFile}
                            step={step}
                            setStep={setStep}
                            setPreview={setPreview}
                            preview={preview}
                            setIsOpenAddPhoto={setIsOpenAddPhoto}
                        />
                    )}
                </Box>
            </Modal> */}
        </div>
    );
};
