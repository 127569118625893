import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuRounded, CloseRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { THeaderProps } from '../types';
import { StyledMenuButton, StyledBurgerMenu, StyledBurgerMenuItem } from './styles';
import { Typography } from '@mui/material';

export const HeaderBurgerMenu: React.FC<THeaderProps> = ({ menu = [] }) => {
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(menuElement);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuElement(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuElement(null);
  };

  if (!menu.length) return null;

  return (
    <>
      <StyledMenuButton
        id="burger-menu-button"
        aria-controls={isOpen ? 'burger-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={openMenu}
        $isOpen={isOpen}
      >
        {isOpen ? <CloseRounded /> : <MenuRounded />}
      </StyledMenuButton>
      <StyledBurgerMenu
        id="burger-menu"
        anchorEl={menuElement}
        open={isOpen}
        onClose={closeMenu}
        MenuListProps={{ 'aria-labelledby': 'burger-menu-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menu.map((item) => (

          item.link !== "setting" &&
          <StyledBurgerMenuItem key={item.link} onClick={closeMenu}>
            <Link to={item.link}>
              <Typography variant='subtitle3'>{item.label}</Typography>
              <KeyboardArrowRightRounded />
            </Link>
          </StyledBurgerMenuItem>

        ))}
      </StyledBurgerMenu>
    </>
  );
};
