

export const GetAge = (dateOfBirth: string, dateOfDeath?: string): string => { // Возвращает строку "25 лет", "31 год" и тп
    const today: Date = new Date();
    const [day, month, year] = dateOfBirth.split('.');
    let age: any = ''
    let aboutAge: any = ''
    if (year === '____') {
        age = '?'
        return age;
    }
    const declensionAge = (age: any) => {
        const years: number = age % 100;
        if (years >= 5 && years <= 20) {
            return `${age} лет`;
        }
        const lastDigit: number = age % 10;
        if (lastDigit === 1) {
            return `${age} год`;
        }
        if (lastDigit >= 2 && lastDigit <= 4) {
            return `${age} года`;
        }
        return `${age} лет`;
    }
    const birthday: Date = new Date(parseInt(year, 10), parseInt(month, 10), parseInt(day, 10));
    if (dateOfDeath) {
        const [dDay, dMonth, dYear] = dateOfDeath.split('.');
        if (dYear === '____') {
            age = '?'
            return age;
        }
        const Deathdata: Date = new Date(parseInt(dYear, 10), parseInt(dMonth, 10), parseInt(dDay, 10));
        age = Deathdata.getFullYear() - birthday.getFullYear();

        const dayDiff: number = Deathdata.getDate() - birthday.getDate();
        let monthDiff: number = Deathdata.getMonth() - birthday.getMonth();
        if (dayDiff < 0 || (dayDiff === 0 && Deathdata.getDate() < birthday.getDate())) {
            monthDiff -= 1;
        }


        if (monthDiff < 0 || (monthDiff === 0 && Deathdata.getDate() < birthday.getDate())) {
            age -= 1;
        }
        if (dDay === '__' || dMonth === '__') {
            aboutAge = Number(dYear) - Number(year)
        }
    }
    else {
        age = today.getFullYear() - birthday.getFullYear();

        const monthDiff: number = today.getMonth() - birthday.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
            age -= 1;
        }
        if (day === '__' || month === '__') {
            aboutAge = today.getFullYear() - Number(year)
        }
    }

    const years: number = aboutAge !== '' ? aboutAge % 100 : age % 100;
    if (years >= 5 && years <= 20) {
        return aboutAge !== '' ? `~${aboutAge} лет` : `${age} лет`;
    }
    const lastDigit: number = aboutAge !== '' ? aboutAge % 10 : age % 10;
    if (lastDigit === 1) {
        return aboutAge !== '' ? `~${aboutAge} год` : `${age} год`;
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
        return aboutAge !== '' ? `~${aboutAge} года` : `${age} года`;
    }
    return aboutAge !== '' ? `~${aboutAge} лет` : `${age} лет`;

}