import styled from "@emotion/styled";

interface IPropsSelect {
    nowValue: string,

}
interface IPropsOption {
    isSelected: boolean,

}
export const SelectWrapper = styled.div`
    display: flex;
    gap: 52px;
`
export const Select = styled.div`
    position: relative;
`
export const NewSelect = styled.a<IPropsSelect>`
    font-family: Gilroy, sans-serif;
    position: relative;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
    color: ${props => props.nowValue === "Не заполнено" ? '#7F7F7F' : '#1A1A1E'}
`
export const NewOption = styled.p<IPropsOption>`
    padding: 8px 12px 10px 12px;
    margin: 0;
    color: ${props => props.isSelected ? '#1A1A1E' : '#7F7F7F'};
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    background-color: ${props => props.isSelected ? '#DCF0ED' : '#FFFFFF'};   
`
export const SelectIcon = styled.div`
    width: 24px;
    height: 24px;
`
export const PhantomDiv = styled.div`
    width:100vw;
    height: 100vh;
    position: fixed;
    z-index:2;
    top:0;
    left:0;
`
export const SelectPopup = styled.div`
    position: absolute;
    padding: 8px 0px;
    left: -10px;
    top: 40px;
    z-index: 3;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
`