import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, { Controls, Background, ReactFlowProvider, getIncomers, getConnectedEdges, SmoothStepEdge } from 'reactflow';
import 'reactflow/dist/style.css';
import ZeroNode from './algoritm/ZeroNode';
import { BackgroundImg, FooterStyled, TreeWrapper } from './style';
import { TreeCard } from './TreeCard';
import { AddCard } from './TreeCard/AddCard';
import { AutoLayoutParent } from './algoritm/autoLayout';
import { useGetTreeQuery, useGetEdgeQuery, useLayoutMutation } from 'store/storeQuery/treeApi';
import backImg from '../../images/optimized_BackgroundTree.png'
import { Header } from 'components/Header';
import { Box, Modal } from '@mui/material';
import { AddPersonModal } from './TreeCard/AddCard/AddPersonModal';
import { CustomEdge } from './algoritm/customEdge';
import { AddSpousesCard } from './TreeCard/AddCard/AddSpouseCard/idnex';
import ZeroAddNode from './algoritm/ZeroAddNode';
import { getAllAddCardsAndEdges } from './utils/GetAddCardAndEdge/getAllAddCardsAndEdges';
import { AddSiblingsCard } from './TreeCard/AddCard/AddSiblingsCard';
import { SwipableDrawer } from './SwipeSideBar';
import { SideBar } from './NewSideBar/index';
import { customAxios } from 'network/axiosConfig';
import { IImage } from 'pages/Profile/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOpenCardInfo, getTreeCurrentCardData } from 'store/slices/tree/selectors';
import { treeActions } from 'store/slices/tree';
import { AddChildCard } from './TreeCard/AddCard/AddChildCard';
import { CropComponentModal } from './NewSideBar/CropComponentModal';
import { useGetBiographyQuery } from 'store/storeQuery/bioApi';
import { IconTree } from 'icons/IconTree';
import { IconPeople } from 'icons/IconPeople';
import { ProfileModal } from 'components/ProfileModal';
import { IconProfile } from 'icons/IconProfile';
import { PhantomCard } from './TreeCard/AddCard/PhantomCard';




const nodeTypes = {
    treeCard: TreeCard,
    addCard: AddCard,
    zeroNode: ZeroNode,
    addSpousesCard: AddSpousesCard,
    zeroAddNode: ZeroAddNode,
    addSiblingsCard: AddSiblingsCard,
    addChildCard: AddChildCard,
    phantomNode: PhantomCard
};
const edgeTypes = {
    step: CustomEdge,
    smoothstep: SmoothStepEdge
}


export const CurrentNewTree = () => {

    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const defViewport = {
        x: screenWidth / 3,
        y: screenHeight / 2,
        zoom: 0.8
    };

    const tempCard = {
        id: `0`,
        type: 'zeroNode',
        position: { x: 0, y: 0 },
        data: {

            rank: 0,
            pos: 'z',
            globalPos: 0,
            parents: [],
            spouses: [],
            children: [],
            siblings: [],

        },

    }
    const [isOpenSet, setIsOpenSet] = useState(false)

    const landingLinks = [
        { label: 'Древо', link: '/tree', icon: <IconTree /> },
        { label: 'Профиль', link: `setting`, icon: <IconProfile />, data: () => setIsOpenSet(true) },


    ];
    const dispatch = useDispatch()
    const [sendLayout] = useLayoutMutation()
    const { data: nodeAsync, isSuccess: isSuccessNode, } = useGetTreeQuery(0)
    const { data: edgeAsync, isSuccess: isSuccessEdge } = useGetEdgeQuery(localStorage.getItem("idRoot"))
    const [nodes, setNodes] = useState(nodeAsync)
    const [edges, setEdges] = useState(edgeAsync)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedCard, setSelectedCard] = useState(tempCard);
    const [avatar, setAvatar] = useState<string>('');
    const [imagesArr, setImagesArr] = useState<IImage[]>([]);
    const CardInfo = useSelector(getTreeCurrentCardData);
    const isOpenCardInfo = useSelector(getIsOpenCardInfo);
    const [selectedPhoto, setSelectedPhoto] = useState<string | ArrayBuffer | null>(null);
    const [isRerend, setRerend] = useState(false)
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const { data: treeArray, isSuccess, isLoading } = useGetTreeQuery(0);
    const [tree, setTree] = useState(treeArray)
    const toggleDrawer = (isOpenCardInfo: boolean) => {
        dispatch(treeActions.setIsOpenCardInfo(isOpenCardInfo))
    }
    const handleOpenModal = useCallback((node: any) => {

        if (node.type === "addCard" || node.type === "addSpousesCard" || node.type === 'addSiblingsCard' || node.type === 'addChildCard' || node.type === "phantomNode") {
            setSelectedCard(node)
            setIsOpen(true)
        }
        else {
            dispatch(treeActions.setIsOpenCardInfo(true))

            dispatch(treeActions.setCurrenCardData({
                id: node.id,
                name: node.data.name,
                surname: node.data.surname,
                fatherName: node.data.middleName,
                relationName: node.data.relation,
                dateOfBirth: node.data.dateOfBirth,
                dateOfDeath: node.data.dateOfDeath,
                isDead: node.data.isDead,
                children: node.data.children,
                parents: node.data.parents,
                siblings: node.data.siblings,
                spouses: node.data.spouses,
                gender: node.data.gender,
            }))
            customAxios
                .get(`images?id=${node.id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                })
                .then(async (resp) => {
                    const data = await resp.data;
                    setImagesArr(data);
                });
            customAxios
                .get(`avatars?id=${node.id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                })
                .then(async (resp) => {
                    const data = await resp && resp.data ? resp.data : null;
                    setAvatar(data ? data.path : null);
                });
        }
        console.log(node)
    }, [])

    useEffect(() => {
        customAxios
            .get(`images?id=${CardInfo.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then(async (resp) => {
                const data = await resp.data;
                setImagesArr(data);
            });
        customAxios
            .get(`avatars?id=${CardInfo.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then(async (resp) => {
                const data = await resp && resp.data ? resp.data : null;
                setAvatar(data ? data.path : null);
            });
        setTree(treeArray)
        setRerend(false)
    }, [isSuccess, treeArray, isRerend])
    useEffect(() => {
        setEdges(edgeAsync)
        setRerend(false)
    }, [isSuccessEdge, edgeAsync, isRerend])
    useEffect(() => {
        setNodes(nodeAsync)
        setRerend(false)
    }, [isSuccessNode, nodeAsync, isRerend])

    useEffect(() => {
        getAllAddCardsAndEdges(nodeAsync, setNodes, edgeAsync, setEdges)
        setRerend(false)
    }, [isSuccessNode, isSuccessEdge, nodeAsync, edgeAsync, isRerend])

    useEffect(() => {
        if (isSuccessNode && isSuccessEdge) {
            const zeroNodesData = AutoLayoutParent(nodes)
            sendLayout({ body: zeroNodesData })
            // console.log(getIncomers(nodes[1], nodes, edges)) получает массив нод к которым ведет 1 параметр те если у ноды0 исходит source связь к ноде1 вернет ноду1
        }
        setRerend(false)
    }, [nodes, isRerend])


    const handleClickClose = () => {
        setIsOpen(false)
    }
    return (
        <>
            <Header menu={landingLinks} />
            {selectedPhoto && (
                <CropComponentModal
                    setIsOpen={setIsOpen}
                    imagesArr={imagesArr}
                    ismobile={false}
                    setRerend={setRerend}
                    setSelectedPhoto={setSelectedPhoto}
                    selectedPhoto={selectedPhoto}
                    setAvatar={setAvatar}
                />
            )}


            <TreeWrapper>
                <ReactFlowProvider >
                    <ReactFlow defaultViewport={defViewport} nodes={nodes} nodeTypes={nodeTypes} edges={edges} onNodeClick={(ev, node) => handleOpenModal(node)} >
                        {mediaQueryCheck ? <SideBar setRerend={setRerend} isRerend={isRerend} setSelectedPhoto={setSelectedPhoto} setAvatar={setAvatar} avatar={avatar} setImagesArr={setImagesArr} imagesArr={imagesArr} /> : <SwipableDrawer setImagesArr={setImagesArr} setRerend={setRerend} isRerend={isRerend} imagesArr={imagesArr} isopen={isOpenCardInfo} setisopen={toggleDrawer} />}
                        <BackgroundImg src={backImg} />
                        <Controls />
                    </ReactFlow>
                </ReactFlowProvider>

            </TreeWrapper>
            <Modal
                open={isOpen}
                onClose={handleClickClose}
            >
                <Box >
                    <AddPersonModal handleClickClose={handleClickClose} type={selectedCard.type} id={selectedCard.id} data={selectedCard.data} xPos={selectedCard.position.x} yPos={selectedCard.position.y} />
                </Box>
            </Modal>
            <Modal
                open={isOpenSet}
                onClose={() => setIsOpenSet(false)}
            >
                <Box>
                    <ProfileModal isOpen={isOpenSet} setIsOpen={setIsOpenSet} />
                </Box>
            </Modal>
        </>

    );

}

