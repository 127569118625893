import { IBaseDataCard, ISpouseData } from "components/CurrentNewTree/type"

export const getSpouseData = ({ id, firstName, dateBirth, lastName, rank, globalPos, pos, xPos, yPos, childArr, gender, middleName, isDead, dateOfDeath, isUnknown }: ISpouseData) => {

    const data: IBaseDataCard = {
        type: "treeCard",
        userMaidenName: "s",
        gender,
        userIsDead: isDead,
        rank,
        globalPosition: (id === localStorage.getItem("idRoot") ? "r" : globalPos),
        position: (pos === 'r' ? 'l' : 'r'),
        positionX: (pos === 'l' ? xPos + 350 : xPos - 350),
        positionY: yPos - 175,
    }

    if (firstName) {
        data.userName = firstName
    }
    if (middleName) {
        data.userMiddleName = middleName
    }
    if (lastName) {
        data.userLastName = lastName
    }
    if (dateBirth) {
        data.userDataBirth = dateBirth
    } else {
        data.userDataBirth = '__.__.____'
    }
    if (childArr.length >= 1) {
        data.partnerChildren = [...childArr]

    }
    if (isDead)
        if (dateOfDeath) {
            data.userDataDead = dateOfDeath

        } else {
            data.userDataDead = '__.__.____'

        }
    if (isUnknown) {
        data.type = "phantomNode"
    }
    return data
}