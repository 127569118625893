import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

export const UnknownBut = styled(Typography)`
    padding: 5px;
    background-color: #F0F0F0;
    width: 25px;
    height: 25px;
    border-radius: 5px;

`
export const PhantomDiv = styled.div`
    width:100vw;
    height: 100vh;
    position: fixed;
    z-index:2;
    top:0;
    left:0;
`

export const ImagePopup = styled.div`
    z-index:100000;
    padding: 8px 0px;
    position: absolute;
    width: 50vw;
    top: 5vh;
    right: 5vw;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`

export const ImageAction = styled.p`
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-color: #1A1A1E;

   
`