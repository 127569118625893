import { FormControl, InputLabel, Select, SelectChangeEvent, TextField } from "@mui/material";
import { TextFieldWrapper } from "./style";
import { CompSelect } from "./Select";
import React, { useState } from "react";
import { validateDateBirthTreeCard, validateFirstDoubleName, validateFirstName } from "components/RegistarionModal/validator/validator";
import { DateMaskField } from "components/DateMaskField";

interface ISecondStageProps {

    setFirstName: (val: string) => void

    setLastName: (val: string) => void

    setMiddleName: (val: string) => void
    dateBirth: string
    setDateBirth: (val: string) => void
    gender: string
    setGender: (val: string) => void
    setIsError: (val: boolean) => void
}
export const SecondStage = ({ setFirstName, setLastName, setMiddleName, dateBirth, setDateBirth, gender, setGender, setIsError }: ISecondStageProps) => {
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [middleNameError, setMiddleNameError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const handleChangeGender = (event: SelectChangeEvent) => {
        setGender(event.target.value)
    }
    const isCheckError = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        fieldID: number,
    ) => {
        switch (fieldID) {
            case 0:
                if (!validateFirstDoubleName(e.target.value) && !validateFirstName(e.target.value)) {
                    setFirstNameError(true);
                } else {
                    setFirstNameError(false);
                }
                break;
            case 1:
                if (!validateFirstName(e.target.value)) {
                    setLastNameError(true);
                } else {
                    setLastNameError(false);
                }
                break;
            case 2:
                if (!validateFirstName(e.target.value) && e.target.value) {
                    setMiddleNameError(true);
                } else {
                    setMiddleNameError(false);
                }
                break;
            case 3:
                if (!validateDateBirthTreeCard(e.target.value) || !(e.target.value.indexOf('_') === -1)) {
                    setDateError(true);
                } else {
                    setDateError(false);
                }
                break;
            default:
                break;
        }
    };
    return (
        <TextFieldWrapper>

            <TextField
                error={lastNameError}

                onChange={(e) => {
                    setLastName(e.target.value);
                    isCheckError(e, 1);
                }}
                placeholder="Фамилия" />
            <TextField
                error={firstNameError}
                onChange={(e) => {
                    setFirstName(e.target.value);
                    isCheckError(e, 0);
                }}
                placeholder="Имя" />
            <TextField
                error={middleNameError}

                onChange={(e) => {
                    setMiddleName(e.target.value);
                    isCheckError(e, 2);
                }}
                placeholder="Отчество" />
            <FormControl >
                <CompSelect
                    value={gender}
                    item={['Мужской', 'Женский']}
                    handleChange={(event) => handleChangeGender(event)}
                />
            </FormControl>
            <DateMaskField
                errorDate={dateError}
                isCheckError={isCheckError}
                setDate={setDateBirth}
                styles={{}}
            />

        </TextFieldWrapper>
    )
}