import { Button, Menu, MenuItem, css, keyframes, styled } from "@mui/material";
import { transientOptions } from "styles/types";

type StyledMenuButtonProps = {
  $isOpen: boolean,
}

export const StyledMenuButton = styled(Button, transientOptions)<StyledMenuButtonProps>(({
  theme, $isOpen
}) => css`
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;

  border-radius: 4px;
  background-color: ${ $isOpen ? theme.palette.common.black : theme.palette.primary.main };

  &:hover {
    background-color: ${ $isOpen ? theme.palette.common.black : theme.palette.primary.main };
  }

  & svg {
    width: 26px;
    min-width: 26px;
    height: 26px;
    min-height: 26px;

    path {
      fill: ${ theme.palette.common.white };
    }
  }
`);

const moveAnimation = keyframes`
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
`;

export const StyledBurgerMenu = styled(Menu)(({ theme }) => css`
  & .MuiPopover-paper {
    margin-top: 50px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 16px;
    background-color: ${ theme.palette.common.white };

    animation: ${moveAnimation} 0.3s linear;
  }
`);

export const StyledBurgerMenuItem = styled(MenuItem)(({ theme }) => css`
  height: 55px;
  min-width: 340px;
  margin: 9px 0;
  padding: 0 16px;
  
  & a {
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    text-decoration: none;
    color: ${ theme.palette.text.primary };

    & svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${ theme.palette.text.secondary };
      }
    }

    &::before {
      content: '';
      width: 260px;
      height: 1px;
      border-bottom: 1px solid ${ theme.palette.text.disabled };
      position: absolute;
      bottom: -5px;
      left: calc(50% - 260px / 2);
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
    & a::before {
      border: none;
    }
  }
`);