import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'network/axiosConfig'
import { baseQueryWithReauth } from './reauth'

export interface IRelative {
    id: number
    firstName: string | null
    lastName: string | null
    middleName: string | null
    maidenName: string | null
    gender: "male" | "female" | null
    dateOfBirth: string | null
    isDead: boolean
    dateOfDeath: string | null
    children: IRelative[]
    parents: IRelative[]
    partner: IRelative[]
}


export const baseApi = createApi({
    reducerPath: "baseApi",
    baseQuery: baseQueryWithReauth,
    tagTypes: ['relative', 'biography', 'node', 'edge', 'tree', 'avatar'],
    endpoints: () => ({})
})



// попробовать через Lazy и вместо state использовать сразу те данные которые приходят из Get
