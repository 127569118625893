import People from './People.svg';
import Profile from './Profile.svg';
import Tree from './Tree.svg';
import { IconButton, Modal, SvgIcon, Box, Button } from '@mui/material';
import { useState } from 'react';
import { type ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { sxBox, textCont } from 'components/RegistarionModal/style';
import { textTwo } from 'components/RegistarionModal/EndRegModal/style';
import { sxGridThree } from 'components/AuthorizationModal/ErrEndAuthModal/style';
import { NotCompleteModal } from 'components/NotCompleteModal';
import { ProfileModal } from 'components/ProfileModal';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { SetingBurgerMobileIcon } from 'icons/SetingBurgerMobileIcon';
import { IconTree } from 'icons/IconTree';
import { IconPeople } from 'icons/IconPeople';
import { IconProfile } from 'icons/IconProfile';

export const TreeHeaderComp = () => {
  const navigate = useNavigate();

  const [isOpenNotCompl, setIsOpenNotCompl] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const mediaQueryCheck = window.matchMedia('(max-width: 844px)').matches;

  const handleClickClose = () => {
    setIsOpenNotCompl(false);
    setIsOpen(false);
  };

  return (
    <div
      style={{
        borderBottomWidth: 1,
        borderBottomColor: 'rgb(224,224,224)',
        borderBottomStyle: 'solid',
        letterSpacing: 0,
        lineHeight: 1,
      }}>
      <div
        style={{
          display: 'flex',
          fontFamily: 'Golos Text',
          justifyContent: 'space-between',
          position: 'relative',
          marginLeft: '24px',
          bottom: '3px',
          alignItems: 'center',
        }}>
        <h3>
          <span style={{ color: 'black', fontSize: '20px', fontWeight: 500 }}>Family</span>
          <span style={{ color: '#149C84', fontSize: '20px', fontWeight: 500, paddingLeft: '5px' }}>
            Tree
          </span>
          <button type="button" onClick={() => navigate('/newtree')}>
            new tree lib111111
          </button>
          <button type="button" onClick={() => navigate('/newtreecard')}>
            new tree lib Card
          </button>
        </h3>
        <div
          style={{
            paddingRight: '24px',
            paddingTop: '8px',
            paddingBottom: '8px',
            display: 'flex',
            gap: '8px',
          }}>
          {mediaQueryCheck ? (
            <SetingBurgerMobileIcon />
          ) : (
            <>
              <IconButton
                className={styles.headerButton}
                style={isOpen || isOpenNotCompl ? { color: '#7F7F7F' } : { color: '#149C84' }}>
                <SvgIcon component={IconTree} />
                <span style={{ fontSize: '12px', lineHeight: '15.6px', fontWeight: 500 }}>
                  Древо
                </span>
              </IconButton>
              <IconButton
                className={styles.headerButton}
                style={isOpenNotCompl ? { color: '#149C84' } : { color: '#7F7F7F' }}
                onClick={() => {
                  setIsOpenNotCompl(true);
                }}>
                <SvgIcon component={IconPeople} />
                <span style={{ fontSize: '12px', lineHeight: '15.6px', fontWeight: 500 }}>
                  Люди
                </span>
              </IconButton>
              <IconButton
                className={styles.headerButton}
                style={isOpen ? { color: '#149C84' } : { color: '#7F7F7F' }}
                onClick={() => {
                  setIsOpen(true);
                }}>
                <SvgIcon component={IconProfile} />
                <span style={{ fontSize: '12px', lineHeight: '15.6px', fontWeight: 500 }}>
                  Профиль
                </span>
              </IconButton>
            </>
          )}
        </div>
      </div>
      <Modal
        open={isOpenNotCompl || isOpen}
        onClose={handleClickClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Box>
          {isOpenNotCompl && (
            <NotCompleteModal isOpen={isOpenNotCompl} setIsOpen={setIsOpenNotCompl} />
          )}
          {isOpen && <ProfileModal isOpen={isOpen} setIsOpen={setIsOpen} />}
        </Box>
      </Modal>
    </div>
  );
};
