import React, { useRef, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { AddButton, AddButtonText, AddButtonWrapper, GallereyComp, GallereyDiv, GallereyImage, GallereyName, GallereyTitle, NextArrowButton, StyledSwiper, PrevArrowButton } from "./style"
import { AddIconPlus } from 'icons/AddIconPlus';
import { AddPhotoIcon } from 'icons/AddPhotoIcon';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { ArrowNextPhotoSwiperButton } from 'icons/ArrowNextPhotoSwiperButton';
import { SwipableDrawer } from '../Drawer';
import { AddPhotoModalDesktop } from '../AddPhotoModalDesktop/AddPhotoModalDesktop';

interface IImage {
    path: string;
    name: string;

}
interface IGallereyProps {
    setIsError: (val: boolean) => void;
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    setRerend: (val: boolean) => void;
    setSelectedPhoto: (val: string | ArrayBuffer | null) => void;
    imagesArr: IImage[];
    setCurrentSlide: (val: number | ((a: number) => number)) => void;
    setOpenGallery: (val: boolean) => void;
    isOpenAddPhotoModal: boolean;
    setIsOpenAddPhotoModal: (val: boolean) => void;
    setAvatar: (val: {path: string, name: string}) => void
}
export const Gallerey = ({ setIsError, isOpen, setIsOpen, setRerend, setSelectedPhoto, imagesArr, setCurrentSlide, setOpenGallery, isOpenAddPhotoModal, setIsOpenAddPhotoModal, setAvatar}: IGallereyProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const [errorPhotoType, setErrorPhotoType] = useState(false);
    const [errorPhotoSize, setErrorPhotoSize] = useState(false);

    const handleClickRef = () => {        
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleAddPhotos = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            setErrorPhotoSize(false);
            setErrorPhotoType(false);
            const file = event.target.files[0];
            const reader = new FileReader();

            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
                setIsError(true);
                setErrorPhotoType(true);
                setSelectedPhoto('error');
            }

            else if (file.size / (1024) > 10 * 1024) {
                setIsError(true);
                setErrorPhotoSize(true);
                setSelectedPhoto('error');
            }

            reader.onload = () => {
                setSelectedPhoto(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleOpenGallery = (index: number) => {
        setCurrentSlide(index);
        setOpenGallery(true);
    };

    useEffect(() => {
        if (isOpenAddPhotoModal) {
            setIsOpen(true);
        }

        if (!isOpen) {
            setIsOpenAddPhotoModal(false);
        }
    }, [isOpenAddPhotoModal, isOpen])

    return (
        <GallereyComp>
            <GallereyTitle>
                <GallereyName>Галерея фотографий</GallereyName>
                {!mediaQueryCheck && (
                    <Button
                        onClick={() => setIsOpen(true)}
                        variant="contained"
                        size="small"
                        style={{ height: '28px', padding: '0px', borderRadius: '4px', minWidth: '28px' }}>
                        <AddIconPlus onClick={handleClickRef} style={{ height: '16px', width: '16px' }} />
                    </Button>
                )}

            </GallereyTitle>

            <GallereyDiv>
                <StyledSwiper
                    navigation={{ nextEl: '.next-arrow', prevEl: '.prev-arrow' }}
                    modules={[Navigation]}
                    slidesPerView='auto'
                    spaceBetween={20}
                    centeredSlides={false}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper: any) => console.log(swiper)}>
                    {(mediaQueryCheck || (!mediaQueryCheck && imagesArr.length === 0)) &&
                        <SwiperSlide onClick={() => setIsOpen(true)} style={{ width: !mediaQueryCheck && imagesArr.length === 0 ? '100%' : 'auto' }}>
                            <AddButtonWrapper>
                                <AddButton onClick={handleClickRef}>
                                    <AddPhotoIcon />
                                    <AddButtonText>Добавить фото</AddButtonText>
                                </AddButton>
                            </AddButtonWrapper>
                        </SwiperSlide>}
                    {imagesArr ? (
                        imagesArr.map((el: IImage, index: number) => (
                            <SwiperSlide style={{ width: 'auto' }} onClick={() => handleOpenGallery(index)}>
                                <GallereyImage src={`${el.path}`} alt="err" />
                            </SwiperSlide>
                        ))
                    ) : (
                        <div>errr</div>
                    )}
                </StyledSwiper>
                <NextArrowButton className='next-arrow'><ArrowNextPhotoSwiperButton /></NextArrowButton>
                <PrevArrowButton className='prev-arrow'><ArrowNextPhotoSwiperButton /></PrevArrowButton>
            </GallereyDiv>

            {!mediaQueryCheck && <SwipableDrawer setRerend={setRerend} isopen={isOpen} setisopen={setIsOpen} imagesArr={imagesArr} setAvatar={setAvatar}/>}
            {mediaQueryCheck && <AddPhotoModalDesktop setErrorPhotoSize={setErrorPhotoSize} setErrorPhotoType={setErrorPhotoType} errorPhotoType={errorPhotoType} errorPhotoSize={errorPhotoSize} isopen={isOpen} setisopen={setIsOpen} addphoto={handleAddPhotos} />}
        </GallereyComp>
    )
}