import { Button, Checkbox, Typography, TextField } from '@mui/material';
import { DateMaskNoField } from '../../../../DateMaskField/DateMaskNoField';
import {
    Buttons,
    CardWrapperDiv,
    LeftColumnDiv,
    PhotoBlock,
    PhotoWrapper,
    AddPhotoInputWrapper,
    NotKnowWrapper,
    RightColumnDiv,
    TextFieldsWrapper,
    AddPhotoInput,
    Photo,
    MobileYears,
    DesctopYears,
    DateWrapper,
    DateWrapperDesctop,
    CheckBoxWrapper,
    ButtonCancel,
    ButtonAdd,
    PhotoDeleted,
    PhotoReplace,
    PRButtonsWrapper,
    PhotoReplaceButton, PRSettings,
} from './style';
import { ParentIconMale } from "icons/ParentIconMale"
import { ParentIconFemale } from "icons/ParentIconFemale"
import { VectorCheckboxFillNew } from 'icons/VectorCheckboxFillNew';
import { SettingIcon } from 'icons/SettingIcon';
import React, { Children, useState } from 'react';
import {
    useAddParentMutation,
    useAddParentEdgeMutation,
    useAddPartnerMutation,
    useDeleteEdgeMutation,
    useAddZeroMutation,
    useAddSiblingsMutation,
    useAddChildMutation,
    usePutPersonMutation,
} from 'store/storeQuery/treeApi';
import { getParentData } from 'components/CurrentNewTree/utils/GetDataOnSend/getParentData';
import { getSpouseData } from 'components/CurrentNewTree/utils/GetDataOnSend/getSpouseData';
import { getZeroData } from 'components/CurrentNewTree/utils/GetDataOnSend/getZeroData';
import { TextCheckBoxSpan } from '../../../../../pages/Profile/ProfileInfo/style';
import { getSiblingsData } from 'components/CurrentNewTree/utils/GetDataOnSend/getSiblingsData';
import { getParentEdgeData } from 'components/CurrentNewTree/utils/GetDataOnSend/getParentEdgeData';
import { getSpousesEdgeData } from 'components/CurrentNewTree/utils/GetDataOnSend/getSpousesEdgeData';
import { getSiblingsEdgeData } from 'components/CurrentNewTree/utils/GetDataOnSend/getSiblingsEdgeData';
import { getChildEdgeData } from 'components/CurrentNewTree/utils/GetDataOnSend/getChildEdgeData';
import { getChildData } from 'components/CurrentNewTree/utils/GetDataOnSend/getChildData';
import { validateDateBirthTreeCard } from 'components/RegistarionModal/validator/validator';
import { usePostAvatarMutation } from 'store/storeQuery/photo';

interface IAddModalProps {
    handleClickClose: (val: boolean) => void;
    data: any;
    xPos: number;
    yPos: number;
    id: string;
    type: string;
}
export const AddPersonModal = ({
    handleClickClose,
    data,
    xPos,
    yPos,
    id,
    type,
}: IAddModalProps) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [date, setDate] = useState('')
    const [dateDeath, setDateDeath] = useState('')
    const [isUnknown, setIsUnknown] = useState(false)
    const [addParent] = useAddParentMutation();
    const [addPartner] = useAddPartnerMutation();
    const [errorDateBirth, setErrorDateBirth] = useState(false)
    const [deleteEdges] = useDeleteEdgeMutation();
    const [addEdge] = useAddParentEdgeMutation();
    const [addZero] = useAddZeroMutation();
    const [addSiblings] = useAddSiblingsMutation();
    const [addChild] = useAddChildMutation();
    const [putPerson] = usePutPersonMutation()
    const [isAlive, setIsAlive] = useState(true);
    const [photo, setPhoto] = useState<string | ArrayBuffer | null>(null);
    const [photoDeleted, setPhotoDeleted] = useState(false);
    const [preview, setPreview] = useState<string>('');
    const [bufferdate, setbufferDate] = useState()
    const [postAvatar] = usePostAvatarMutation()

    const SendAvatar = async (img: any, id: any) => {
        const formData = new FormData();
        formData.append('userAvatar', img);
        console.log(formData);

        const res = await postAvatar({ id, body: formData }).unwrap()
    }
    const handleAddParent = async () => {
        const resPar = await addParent({
            id: id.slice(0, id.length - 2),
            body: getParentData({
                isUnknown,
                firstName,
                lastName,
                middleName,
                rank: data.rank,
                dateBirth: date,
                globalPos: data.globalPos,
                pos: data.pos,
                xPos,
                yPos,
                gender: data.gender,
                isDead: !isAlive,
                dateOfDeath: dateDeath
            }),
        }).unwrap()
        if (photo && !photoDeleted) {
            console.log(photo);

            SendAvatar(photo, resPar.id)
        }
        await addEdge({
            id: localStorage.getItem('idRoot'),
            body: getParentEdgeData({
                sourceId: String(id.slice(0, id.length - 2)),
                targetId: String(resPar.id),
                pos: String(data.pos),
            }),
        });

    };
    const handleAddSpouses = async () => {
        const temp: number[] = [];
        data.children.forEach((el: any) => {
            temp.push(Number(el.id));
        });

        const resPar = await addPartner({
            id: id.slice(0, id.length - 2),
            body: getSpouseData({
                id: id.slice(0, id.length - 2),
                isUnknown,
                firstName,
                lastName,
                middleName,
                rank: data.rank,
                dateBirth: date,
                globalPos: data.globalPos,
                pos: data.pos,
                xPos,
                yPos,
                gender: data.gender,
                childArr: temp,
                isDead: !isAlive,
                dateOfDeath: dateDeath
            }),
        }).unwrap()
        if (photo && !photoDeleted) {

            console.log(photo);

            SendAvatar(photo, resPar.id)
        }
        addEdge({
            id: localStorage.getItem('idRoot'), body: getSpousesEdgeData({
                sourceId: String(id.slice(0, id.length - 2)),
                targetId: String(resPar.id),
                pos: String(data.pos),
            })
        });


        const relArr = [resPar.data.spouses[0].id, Number(resPar.id)];
        const resZero = await addZero({
            body: getZeroData({
                rank: data.rank,
                globalPos: data.globalPos,
                pos: data.pos,
                xPos,
                yPos,
                relationNodes: relArr,
            }),
        })
            .unwrap()
        if (data.children.length > 0) {
            await addEdge({
                id: localStorage.getItem('idRoot'), body: getParentEdgeData({
                    sourceId: String(data.children[0]?.id),
                    targetId: String(resZero.id),
                    pos: String(data.pos),
                })
            });
            await deleteEdges({ source: String(data.children[0].id), target: id.slice(0, id.length - 2) });
        }
    };
    const handleAddSiblings = async () => {
        const resSib = await addSiblings({
            id: id.slice(0, id.length - 2),
            body: getSiblingsData({
                isUnknown,
                firstName,
                lastName,
                middleName,
                rank: data.rank,
                dateBirth: date,
                globalPos: data.globalPos,
                pos: data.pos,
                xPos,
                yPos,
                gender: data.gender,
                isDead: !isAlive,
                dateOfDeath: dateDeath
            }),
        }).unwrap()
        if (photo && !photoDeleted) {

            console.log(photo);

            SendAvatar(photo, resSib.id)
        }
        await addEdge({
            id: localStorage.getItem('idRoot'), body: getSiblingsEdgeData({
                sourceId: String(id.slice(0, id.length - 2)),
                targetId: String(resSib.id),
                pos: String(data.pos),
            })
        });

    };
    const handleAddChild = async () => {
        console.log("CHIIIILD")
        const resChild = await addChild({
            id: id.slice(0, id.length - 2),
            body: getChildData({
                isUnknown,
                firstName,
                lastName,
                middleName,
                rank: data.rank,
                dateBirth: date,
                globalPos: data.globalPos,
                pos: data.pos,
                xPos,
                yPos,
                gender: data.gender,
                isDead: !isAlive,
                dateOfDeath: dateDeath
            }),
        }).unwrap()
        if (photo && !photoDeleted) {

            console.log(photo);

            SendAvatar(photo, resChild.id)
        }
        addEdge({
            id: localStorage.getItem('idRoot'), body: getChildEdgeData({
                sourceId: String(resChild.id),
                targetId: String(data.zeroNodes[0].id),
                pos: String(data.pos),
            })
        });


    };
    const handlePutPhantom = async () => {
        const resPerson = await putPerson({
            id,
            body: getChildData({
                isUnknown,
                firstName,
                lastName,
                middleName,
                rank: data.rank,
                dateBirth: date,
                globalPos: data.globalPos,
                pos: data.pos,
                xPos,
                yPos,
                gender: data.gender,
                isDead: !isAlive,
                dateOfDeath: dateDeath
            }),
        }).unwrap()
        if (photo && !photoDeleted) {

            console.log(photo);

            SendAvatar(photo, resPerson.id)
        }
    }
    const handleChoseAdd = () => {
        switch (type) {
            case 'addCard':
                handleAddParent();
                break;
            case 'addSpousesCard':
                handleAddSpouses();
                break;
            case 'addSiblingsCard':
                handleAddSiblings();
                break;
            case 'addChildCard':
                handleAddChild()
                break;
            case 'phantomNode':
                handlePutPhantom()
                break;
            default:
                break;
        }
    }

    const handleAddPhoto = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const reader = new FileReader();


            setPhoto(file);

            reader.readAsDataURL(file);
            const imageUrl = URL.createObjectURL(file);
            setPreview(imageUrl);
        }
    }
    const handleDeletePhoto = (isDeleted: boolean) => {
        setPhotoDeleted(isDeleted);

    }

    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    let variant = ""
    if (!mediaQueryCheck) {
        variant = "empty"
    }
    const isCheckError = (e: any) => {
        if ((!validateDateBirthTreeCard(e.target.value))) {
            setErrorDateBirth(true)
        } else { setErrorDateBirth(false) }
    }
    console.log(`${dateDeath} дата смерти`)
    return (
        <CardWrapperDiv>
            <LeftColumnDiv>
                <PhotoBlock>
                    {photo ?
                        <PhotoWrapper>
                            <Photo
                                src={preview}
                                alt="Фотография родственника" />
                            {photoDeleted ?
                                <PhotoDeleted>
                                    <Typography variant="textOnPhoto">Вы удалили фото. Восстановить?</Typography>
                                    <ButtonAdd onClick={() => { handleDeletePhoto(false) }}>Восстановить</ButtonAdd>
                                </PhotoDeleted>
                                :
                                <PhotoReplace>
                                    {!mediaQueryCheck ?
                                        <PRSettings>
                                            <SettingIcon viewBox="0 0 25 25" />
                                        </PRSettings>
                                        :
                                        ''
                                    }
                                    <PRButtonsWrapper>
                                        <PhotoReplaceButton onClick={() => { handleDeletePhoto(true) }}>
                                            {!mediaQueryCheck ? 'Удалить фото' : 'Удалить'}
                                        </PhotoReplaceButton>
                                        <PhotoReplaceButton>
                                            <AddPhotoInput
                                                accept="image/*,.png,.jpeg,.jpg"
                                                type="file"
                                                onChange={(e) => handleAddPhoto(e)}
                                            />
                                            {!mediaQueryCheck ? 'Изменить фото' : 'Изменить'}
                                        </PhotoReplaceButton>

                                    </PRButtonsWrapper>
                                </PhotoReplace>
                            }
                        </PhotoWrapper>
                        :
                        <AddPhotoInputWrapper onClick={handleAddPhoto}>
                            <AddPhotoInput
                                accept="image/*,.png,.jpeg,.jpg"
                                type="file"
                                onChange={(e) => handleAddPhoto(e)}
                            />
                            {data.gender === 'male' ?
                                <ParentIconMale style={{ width: '73px', height: '73px' }} viewBox="0 0 74 74" /> :
                                <ParentIconFemale style={{ width: '73px', height: '73px' }} viewBox="0 0 73 74" />}
                            <Typography variant="subtitle3">Добавить фото</Typography>
                        </AddPhotoInputWrapper>
                    }


                </PhotoBlock>
                {type !== "phantomNode" &&

                    <NotKnowWrapper>
                        <Checkbox
                            color="primary"
                            style={{ height: '22px' }}
                            checked={(isUnknown)}
                            onClick={() => { setIsUnknown(!isUnknown) }}
                            icon={
                                <VectorCheckboxFillNew style={{ width: '10px', height: '8px' }} viewBox="0 0 11 8" />
                            }
                            checkedIcon={
                                <VectorCheckboxFillNew style={{ width: '10px', height: '8px' }} viewBox="0 0 11 8" />
                            }
                        />
                        <TextCheckBoxSpan>Я не знаю информацию об этом человеке</TextCheckBoxSpan>
                    </NotKnowWrapper>
                }

            </LeftColumnDiv>
            <RightColumnDiv>
                <div>
                    <Typography variant="subtitle3">Добавить {data.name} {data.middleName}</Typography>
                    <TextFieldsWrapper>
                        <TextField
                            type={variant}
                            placeholder="Фамилия"
                            onChange={(e: any) => {
                                setLastName(e.target.value);
                            }}
                        />
                        <TextField
                            type={variant}
                            placeholder="Имя"
                            onChange={(e: any) => {
                                setFirstName(e.target.value);
                            }}
                        />
                        <TextField
                            type={variant}
                            placeholder="Отчество"
                            onChange={(e: any) => {
                                setMiddleName(e.target.value);
                            }}
                        />
                    </TextFieldsWrapper>
                    {!mediaQueryCheck ?
                        <MobileYears>
                            <DateWrapper>
                                Дата рождения
                                <DateMaskNoField setbufferDate={setbufferDate} setDate={setDate} isCheckError={isCheckError} errorDate={errorDateBirth} defaultvalue={date} styles={undefined} />
                            </DateWrapper>
                            {!isAlive ?
                                <DateWrapper>
                                    Дата смерти
                                    <DateMaskNoField setbufferDate={setbufferDate} setDate={setDateDeath} isCheckError={isCheckError} errorDate={errorDateBirth} defaultvalue={dateDeath} styles={undefined} />
                                </DateWrapper>
                                : ''}
                        </MobileYears>
                        :
                        <DesctopYears>
                            {isAlive ?
                                <DateWrapper>
                                    Дата рождения
                                    <DateMaskNoField setbufferDate={setbufferDate} setDate={setDate} isCheckError={isCheckError} errorDate={errorDateBirth} defaultvalue={date} styles={undefined} />
                                </DateWrapper>
                                :
                                <DateWrapperDesctop>
                                    <DateMaskNoField setbufferDate={setbufferDate} setDate={setDate} isCheckError={isCheckError} errorDate={errorDateBirth} defaultvalue={date} styles={undefined} />
                                    -
                                    <DateMaskNoField setbufferDate={setbufferDate} setDate={setDateDeath} isCheckError={isCheckError} errorDate={errorDateBirth} defaultvalue={dateDeath} styles={undefined} />
                                </DateWrapperDesctop>
                            }
                        </DesctopYears>}


                    <CheckBoxWrapper>
                        <Checkbox
                            color="primary"
                            style={{ height: '22px' }}

                            checked={(isAlive)}
                            onClick={() => { setIsAlive(!isAlive) }}

                            icon={
                                <VectorCheckboxFillNew style={{ width: '10px', height: '8px' }} viewBox="0 0 11 8" />
                            }
                            checkedIcon={
                                <VectorCheckboxFillNew style={{ width: '10px', height: '8px' }} viewBox="0 0 11 8" />
                            }
                        />
                        <TextCheckBoxSpan>Человек жив</TextCheckBoxSpan>
                    </CheckBoxWrapper>
                </div>
                <Buttons>
                    <ButtonCancel
                        onClick={() => {
                            handleClickClose(false)
                        }}>
                        Отмена
                    </ButtonCancel>
                    <ButtonAdd
                        onClick={() => {
                            handleChoseAdd();
                            handleClickClose(false)

                        }}>
                        Добавить
                    </ButtonAdd>
                </Buttons>

            </RightColumnDiv>

        </CardWrapperDiv>
    );
};
