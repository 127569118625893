import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import {
    ButtonWrapper,
    CustomFooter,
    CustomTypography,
    ErrorTypography,
    LoginButton,
    RegButton,
    RegisterWrapper,
    StageContent,
    StageWrapper,
    TextFieldWrapper,
    TextWrapper,
    TitleWrapper,
} from './style';
import { Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import { error } from 'console';
import { useFirstRegMutation, useLoginMutation } from 'store/storeQuery/auth';
import { FirstStage } from 'pages/Registration/FirstStage';
import { useNavigate } from 'react-router-dom';
import { useGetTreeQuery } from 'store/storeQuery/treeApi';

export const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [isError, setIsError] = useState(false)
    const [sendLogin] = useLoginMutation()
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const { data: tree } = useGetTreeQuery(0)
    const [isErrAsync, setIsErrorAsync] = useState(false)
    const [textErrorAsync, setTextErrorAsync] = useState(false)
    const handleReg = async () => {
        const body = {
            email,
            password: pass,

        }
        const result = await sendLogin({ body }).unwrap().catch((res) => {
            if (res.error) {
                setIsErrorAsync(true)
                setTextErrorAsync(res.data);

            }
        })
        localStorage.setItem("idRoot", result.id)
        localStorage.setItem("accessToken", result.accessToken)
        localStorage.setItem("treeArray", JSON.stringify(tree))
        navigate('/tree')

    };
    return (
        <>
            <Header />
            <RegisterWrapper>
                <TitleWrapper>
                    <Typography variant={mediaQueryCheck ? 'h3' : 'subtitle2'}>Вход</Typography>

                </TitleWrapper>
                <FirstStage setIsError={setIsError} email={email} setEmail={setEmail} pass={pass} setPass={setPass} />
                <ButtonWrapper>
                    <RegButton variant="contained" onClick={handleReg} disabled={isError}>
                        <Typography variant="button" >Войти</Typography>
                    </RegButton>
                    <TextWrapper>

                        <CustomTypography variant="caption2">Ещё нет аккаунта?&nbsp;
                            <LoginButton onClick={() => navigate("/registration")}>
                                Зарегистрироваться
                            </LoginButton>
                        </CustomTypography>

                    </TextWrapper>
                    {isErrAsync && <TextWrapper><ErrorTypography variant="caption2">{textErrorAsync}</ErrorTypography></TextWrapper>}
                </ButtonWrapper>
            </RegisterWrapper>
        </>
    );
};
