import styled from 'styled-components'
import { Box, } from '@mui/material';

export const AgeDiv = styled.div`
        padding-top:12px;
        padding-bottom:12px;
        font-family: Gilroy, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
        margin:0px;
        display: flex;
        justify-content: space-between;
        align-items:center;
        position: relative;
`
export const BorderDiv = styled.div`
@media(max-width: 844px){

    position: absolute;
    bottom: 0;
    left: -10vw;
    width: 150vw;
    height: 1px;
    border-bottom: 1px solid #E5E5E5;
}
@media(min-width: 844px){
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #E5E5E5;
}
`

export const AgeContentWrapper = styled.div`
    display: flex;
    gap:16px;
    font-family: Gilroy,sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
@media(min-width: 844px){
    display: flex;
    font-family: Gilroy,sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    justify-content: space-between;
}
`

export const NumberAgeDiv = styled.div`

    display:flex;
    background-color: #DCF0ED;
    color: #149C84;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    border-radius:8px;
    padding:4px 8px 2px;
    
    align-items:center;
`
export const SettingDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap:3px;
    
`
export const CloseButton = styled.button`
    all:unset;
    background-color: black;
    width: 28px;
    height: 28px;
    border-radius: 6px;
    box-shadow:none;
    gap: 10px;

`
export const CheckBoxDiv = styled.div`
    display:flex;
    gap:12px;
    align-items:center;

`
export const TextCheckBoxSpan = styled.span`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 


`
export const BioBlockDiv = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items:center;
`
export const TextBioSpan = styled.div`
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
`
export const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const BioDiv = styled.div`
  padding: 16px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  grid-column-gap: 3%;
  
   .grid-item {
      overflow: auto; /* Используйте "auto" или "hidden" */
}
`
export const DeleteDiv = styled.div`
    border-radius: 16px;
    background-color: #FFEBEB;
    padding:16px;
    margin-bottom: 16px;
    position: relative;
`

export const BoxStyled = styled(Box)`
    @media(min-width: 844px){
        position:absolute;
        transform: translate(-50%, -50%);
        left:50%;
        top:50%;
    }
    @media(max-width: 844px){
        width:100%;
        position:absolute;
        bottom:0;
    }
`
export const StyledImg = styled('img')`
    paddingRight: 10px,
    position: absolute,
    width: 2%,
`