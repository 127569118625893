import { styled } from "@mui/material";

export const StyledLandingContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: 'calc(100vh)',
  padding: '0 32px',
  paddingTop: 120,
  paddingBottom: 60,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 60,
  overflow: 'hidden',

  [theme.breakpoints.down(844)]: {
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

export const StyledInfoContainer = styled('div')(({ theme }) => ({
  width: 510,
  zIndex: 2,

  '& > *:nth-child(2)': {
    marginTop: 16,
    color: theme.palette.text.secondary,
  },

  [theme.breakpoints.down('lg')]: {
    '& > *:nth-child(1)': {
      fontSize: 40,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& > *:nth-child(1)': {
      fontSize: 32,
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

export const StyledButtonsContainer = styled('div')(({ theme }) => ({
  marginTop: 24,
  width: '100%',
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'center',
  gap: 24,

  '& button': {
    flexGrow: 1,
    height: 56,
    width: 'auto',
    borderRadius: 16,

    '&:nth-child(2)': {
      color: theme.palette.text.primary,
      border: `2px solid ${theme.palette.text.disabled}`,
      background: theme.palette.common.white,
    }
  },

  [theme.breakpoints.down(844)]: {
    marginTop: 320,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: 12,

    '& button': {
      width: '100%',
      margin: '0 26px',
    }
  },
}));

export const StyledImgPhoto = styled('img')(({ theme }) => ({
  width: 520,
  zIndex: 2,
  [theme.breakpoints.down('lg')]: {
    width: 440,
  },
  [theme.breakpoints.down('md')]: {
    width: 320,
  },
  [theme.breakpoints.down(844)]: {
    position: 'absolute',
    marginTop: 50,
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    maxWidth: 320,
    width: '100%',
    padding: '0 12px',
  },
}));

export const StyledImgLines = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  bottom: -65,
  zIndex: 0,
  [theme.breakpoints.down(844)]: {
    width: 900,
    position: 'absolute',
    left: -50,
    bottom: 0,
  },
}));
export const StyledImgLine = styled('img')(({ theme }) => ({
  position: 'absolute',
  transform: 'rotate(-30deg)',
  display: 'flex',
  width: '100%',
  bottom: -300,
  left: 100,
  zIndex: 1,

  [theme.breakpoints.down(844)]: {
    display: 'none'
  },
}));
export const StyledBottomImgLines = styled('img')(({ theme }) => ({
  position: 'relative',
  bottom: 60,
  right: 40,
  marginBottom: -100,
  width: '100%',
  zIndex: 1,
  [theme.breakpoints.down(844)]: {
    width: 500,
    display: 'flex',
    position: 'relative',
    bottom: 100,
    right: 100,
    marginBottom: -150,
  },
}));
export const StyledTextContainer = styled('div')(({ theme }) => ({
  width: 611,
  height: 290,
  position: 'absolute',
  right: 0,
  flexDirection: 'column',
  display: 'flex',
  gap: 10,
  padding: 64,
  fontFamily: 'Gilroy',
  fontWeight: 500,
  fontSize: 24,
  lineHeight: '130%',
  border: '0.67px solid #C6C6C6',
  backgroundColor: 'rgba(255,255,255,0.6)',
  borderRadius: 60,
  [theme.breakpoints.down(844)]: {
    width: 'auto',
    height: 'auto',
    fontFamily: 'Gilroy',
    padding: 32,
    fontWeight: 400,
    border: '0.38px solid #C6C6C6',
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: 30,
    flexDirection: 'column',
    display: 'flex',
    position: 'relative',
  },
}))
export const StyledTextLADContainer = styled('div')(({ theme }) => ({
  zIndex: 3,
  width: 714,
  height: 'auto',
  flexDirection: 'column',
  display: 'flex',
  gap: 10,
  padding: 64,
  fontFamily: 'Gilroy',
  fontWeight: 500,
  fontSize: 24,
  lineHeight: '130%',
  border: '0.67px solid #C6C6C6',
  backgroundColor: '#FFFFFF',
  borderRadius: 60,
  [theme.breakpoints.down(844)]: {
    width: 'auto',
    height: 'auto',
    padding: 32,
    borderRadius: 30,
    fontFamily: 'Gilroy',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '130%',
    display: 'flex',
    position: 'relative',
    bottom: 0,
    left: 0,
  },
}));
export const StyledDemoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',


  [theme.breakpoints.down(844)]: {
    gap: 30,
    flexDirection: 'column',
    display: 'flex',
    position: 'relative',
  },
}));
export const StyledLadContainer = styled('div')(({ theme }) => ({
  gap: 10,
  height: 'auto',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
}));

export const ImgFamilyText = styled('div')(({ theme }) => ({
  width: 400,
  height: 'auto',
  borderRadius: 60,
  padding: 48,
  fontFamily: 'Gilroy',
  fontWeight: 500,
  fontSize: 24,
  backgroundColor: 'rgba(22,22,22,0.75)',
  lineHeight: '130%',
  color: '#FFFFFF',
  display: 'flex',
  position: 'absolute',
  bottom: 100,
  left: 120,
  zIndex: 3,
  [theme.breakpoints.down(844)]: {
    width: 'auto',
    height: 'auto',
    padding: 32,
    borderRadius: 30,
    fontFamily: 'Gilroy',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '130%',
    display: 'flex',
    position: 'relative',
    bottom: 0,
    left: 0,
  },
}));

export const ImgFamilyConteiner = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  [theme.breakpoints.down(844)]: {
    gap: 16,
    flexDirection: 'column',
    display: 'flex',
    position: 'relative',
  },
}));

export const StyledContainerPartners = styled('div')(({ theme }) => ({

  flexDirection: 'column',
  display: 'flex',
  gap: 120,
  position: 'relative',
  padding: '0px 20px 0px 20px',
  zIndex: 1,
  overflow: 'hidden',
  [theme.breakpoints.down(844)]: {

    gap: 48,
    flexDirection: 'column',
    display: 'flex',
    position: 'relative',
  },

}));
export const StyledLADFamily = styled('img')(({ theme }) => ({
  height: 326,
  position: 'absolute',
  right: 0,

  borderRadius: 60,
  zIndex: 2,

  [theme.breakpoints.down(844)]: {
    position: 'relative',
    borderRadius: 30,
    width: 'auto',
    height: 'auto'
  },

}));

export const StyledImgFamily = styled('img')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  borderRadius: 60,
  zIndex: 2,

  [theme.breakpoints.down(844)]: {
    display: 'flex',
    borderRadius: 30,
    position: 'relative',
  },

}));
export const StyledImgLogo = styled('img')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  zIndex: 3,
  right: 60,
  top: 65,
  width: 106,
  height: 106,
  [theme.breakpoints.down(844)]: {
    right: 20,
    top: 19,
    width: 24,
    height: 24,
    display: 'flex',
    position: 'absolute',
  },
}));
export const DivPict = styled('div')({
  flexDirection: 'row',
  display: 'flex',
  position: 'relative',
  zIndex: 2,

})