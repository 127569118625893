import React from "react"
import { UnknownBut } from "./style"
import { WarningMarkIcon } from "icons/WarningMarkIcon";

interface IUnknownButProps {
    setIsOpenPopup: (val: boolean) => void,
}
export const UnknownAgeButt = ({ setIsOpenPopup }: IUnknownButProps) => (

    <UnknownBut onClick={() => { setIsOpenPopup(true); setTimeout(() => { setIsOpenPopup(false) }, 3000) }}>
        <WarningMarkIcon viewBox="0 0 24 24" />
    </UnknownBut>

)