import { createSvgIcon } from "@mui/material";

export const ParentIconMale = createSvgIcon(
    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6924_46570)">
            <rect width="73.1613" height="73.1613" rx="6.50323" fill="#EEEEEE" />
            <path d="M48.9944 29.3944C49.7771 29.4738 50.594 29.8547 50.8607 30.4492C51.012 30.7879 51.0764 31.2574 51.0539 31.8578C51.0304 32.4774 51.0296 33.1062 51.0513 33.7442C51.0689 34.2526 51.0079 34.6536 50.8682 34.9471C50.2699 36.2114 48.9818 35.948 47.8467 35.9668C47.8032 35.9676 47.7814 35.9902 47.7814 36.0345C47.799 37.0087 47.7898 37.6446 47.7538 37.9423C47.273 41.9276 45.2716 44.95 41.7496 47.0095C41.4803 47.1433 41.2094 47.2742 40.9368 47.4021C40.6366 47.5426 40.3586 47.6572 40.1027 47.7458C37.1142 48.786 34.1586 48.6564 31.2362 47.357C30.9912 47.2483 30.7449 47.1337 30.4974 47.0133C27.3165 45.1804 25.3619 42.492 24.6336 38.9482C24.4814 38.2049 24.4225 37.2341 24.4568 36.0358C24.4576 35.9923 24.4367 35.9697 24.394 35.9681C23.2476 35.9392 21.9331 36.2189 21.3524 34.917C21.2278 34.6385 21.1726 34.2585 21.1868 33.7768C21.2044 33.1614 21.2027 32.487 21.1818 31.7537C21.1668 31.2051 21.2312 30.7707 21.375 30.4505C21.6401 29.8576 22.0307 29.4633 22.8158 29.3806L24.4028 29.4445C24.4166 29.4449 24.4297 29.4504 24.4393 29.46C24.4489 29.4696 24.4542 29.4825 24.4543 29.496C24.4526 31.5003 24.453 33.5531 24.4555 35.6545C24.4555 35.7975 24.3734 35.6344 24.452 35.5148C24.4528 35.585 24.4612 35.4997 24.452 35.5148C24.4378 35.5641 24.452 35.4638 24.452 35.5148C25.0323 33.4778 25.7575 31.364 26.3169 29.3806C26.3294 29.3354 26.3595 29.312 26.4072 29.3103C28.5988 29.2601 30.6939 29.0908 32.6924 28.8023C36.3022 28.2806 40.1027 27.1605 42.4532 24.5039C42.4975 24.4537 42.531 24.4604 42.5536 24.524C43.2158 26.4142 44.3296 28.1576 45.9778 29.2965C46.0129 29.3208 46.0359 29.3534 46.0468 29.3944L47.6823 35.588C47.7484 35.8364 47.7814 35.8322 47.7814 35.5755L47.7827 29.511C47.7827 29.4717 47.8028 29.4516 47.8429 29.4508L48.9944 29.3944Z" fill="#FFDBBD" />
            <path d="M36.0937 40.1155C35.8295 40.654 35.3725 40.9254 34.7228 40.9296C33.2519 40.9388 31.7639 40.9404 30.2588 40.9346C30.1651 40.9346 30.1568 40.9078 30.2337 40.8543C31.4763 39.9897 32.7142 39.1209 33.9476 38.2479C34.6989 37.7173 35.676 37.9883 36.1 38.722C36.1301 38.7739 36.1589 38.7734 36.1865 38.7208C36.5064 38.0949 37.2489 37.849 37.8873 38.0384C38.0855 38.097 38.3451 38.2416 38.6662 38.4724C39.7642 39.2593 40.8951 40.0549 42.0591 40.8593C42.1352 40.912 42.1268 40.9383 42.034 40.9383C40.7028 40.9333 39.2282 40.9346 37.6101 40.9421C36.988 40.9446 36.4687 40.6749 36.1828 40.1155C36.1526 40.057 36.123 40.057 36.0937 40.1155Z" fill="#7F7F7F" />
            <path d="M42.0396 46.5588V49.0091C41.8531 49.1061 41.5078 49.3042 41.3205 49.372C37.7224 50.6764 34.1481 50.6012 30.5977 49.1462L30.619 46.5586C30.8665 46.679 31.1127 46.7936 31.3578 46.9023C34.2802 48.2017 37.2357 48.3313 40.2243 47.2911C40.4802 47.2025 40.7582 47.0879 41.0584 46.9474C41.331 46.8195 41.7704 46.6926 42.0396 46.5588Z" fill="#FFDBBD" />
            <path d="M42.0395 49.0078V52.3141C41.9458 54.6077 40.4034 56.7788 38.9718 57.3923C38.1537 57.8014 37.1835 57.9668 36.1842 58.0061C36.16 58.0069 36.2143 57.986 36.1842 58.0061C36.1525 57.9743 36.2102 58.0069 36.1842 58.0061C35.1971 57.9722 34.1686 57.6599 33.3182 57.1344C31.594 56.0691 30.6948 54.4282 30.5978 52.2801L30.5977 49.1446C34.1481 50.5995 37.7224 50.6748 41.3205 49.3703C41.5078 49.3026 41.853 49.1048 42.0395 49.0078Z" fill="#FFDBBD" />
            <path d="M44.9141 53.0993L42.0508 52.2812C41.8463 54.531 40.8444 56.2953 38.9044 57.3497C38.1878 57.7386 37.3235 57.9685 36.3242 58.0078C36.3242 58.0078 42.0508 62.9163 42.2553 62.9163C42.4598 62.9163 44.9141 53.0993 44.9141 53.0993Z" fill="#E5E5E5" />
            <path d="M27.7344 53.053L30.5977 52.2812C30.6913 54.5749 31.8041 56.249 33.744 57.3034C34.4606 57.6922 35.325 57.9685 36.3242 58.0078C36.3242 58.0078 30.5977 62.87 30.3931 62.87C30.1886 62.87 27.7344 53.053 27.7344 53.053Z" fill="#E5E5E5" />
            <path d="M30.6202 62.7185L27.7282 53.0996C25.218 53.6824 22.915 54.2715 20.3888 54.8644C18.0684 55.4096 16.1912 56.5894 14.7571 58.404C13.3205 60.2219 12.6006 62.3537 12.5972 64.7996C12.5931 67.6961 12.5926 70.6634 12.596 73.7012C12.596 73.7179 12.6026 73.7338 12.6143 73.7456C12.6261 73.7573 12.6421 73.7639 12.6587 73.7639L23.1132 73.7677L52.2176 73.7665L60.1735 73.7652C60.1958 73.7652 60.2172 73.7563 60.2329 73.7406C60.2487 73.7248 60.2575 73.7034 60.2575 73.6812C60.2567 70.6006 60.2571 67.6389 60.2588 64.7958C60.26 60.7357 58.0074 57.1083 54.3135 55.4552C53.7741 55.2143 52.9764 54.9693 51.9203 54.7201C49.5932 54.1708 47.2573 53.6306 44.9126 53.0996L42.1911 62.8201L36.3181 58.0081L30.6202 62.7185Z" fill="#149C84" />
            <path d="M47.7691 29.791L48.9962 29.382C48.9996 27.6377 49.1617 26.1749 49.1576 24.4323C49.1525 22.5885 48.5421 20.891 47.3263 19.3399C46.1397 17.8272 44.51 16.8389 42.4371 16.3748C42.4011 16.3664 42.3727 16.3472 42.3518 16.3171C40.5632 13.7232 37.7737 12.4075 34.6455 12.637C32.2891 12.8101 30.1865 13.5347 28.3377 14.8107C24.9486 17.1487 22.8652 20.9341 22.8226 25.0594C22.8159 25.7602 22.8109 27.0147 22.8176 29.382L24.2751 29.6689C24.2888 29.6693 24.3019 29.6748 24.3115 29.6844C24.3211 29.694 24.3265 29.7069 24.3265 29.7204C24.3248 31.7247 24.3252 33.7775 24.3277 35.8789C24.3277 36.0219 24.367 36.0336 24.4456 35.914C25.026 33.8771 25.7712 31.766 26.2794 29.5347C28.4711 29.4846 30.5661 29.3152 32.5646 29.0267C36.1745 28.505 39.9749 27.3849 42.3255 24.7283C42.3698 24.6781 42.4032 24.6848 42.4258 24.7484C43.0881 26.6386 44.2019 28.382 45.85 29.5209C45.8851 29.5452 45.9081 29.5778 45.919 29.6188L47.7691 35.9266V35.5176V29.791Z" fill="#7F7F7F" />
        </g>
        <defs>
            <clipPath id="clip0_6924_46570">
                <rect width="73.1613" height="73.1613" rx="6.50323" fill="white" />
            </clipPath>
        </defs>
    </svg>,
    "ParentIconMale"
)

