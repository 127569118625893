import { useMemo, useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import {
    Button,
    Checkbox,
    TextField,
    Modal,
    Box,
} from '@mui/material';
import { AgeDiv } from '../style';
import {
    AgeContentWrapper,
    BioDiv,
    CheckBoxDiv,
    CloseButton,
    NumberAgeDiv,
    SettingDiv,
    TextBioSpan,
    TextCheckBoxSpan,
    DeleteDiv,
    BorderDiv,
    DateWrapper,
    BoxStyled,
    StyledImg
} from './style';
import { InputCustomSelect } from 'components/InputCustomSelect';
import { DateMaskNoField } from 'components/DateMaskField/DateMaskNoField';
import { validateDateBirthTreeCard, validateDateDeathTreeCard } from 'components/RegistarionModal/validator/validator';
import { UnknownAgeButt } from '../UnknownAgeButt';
import { IProfileInfo, IRelative } from '../types/types';
import { CloseIconProfile } from 'icons/CloseIconProfile';
import { VectorCheckboxFillNew } from 'icons/VectorCheckboxFillNew';
import { ProfileDeleteIcon } from 'icons/ProfileDeleteIcon';
import { useParams } from 'react-router-dom';
import { useGetRelativeQuery, usePutRelativeMutation } from 'store/storeQuery/relativeApi';
import { useDebounce } from 'hooks/debounce';
import { DeletePersonModal } from '../DeletePersonModal';
import Warning from 'images/Warning.svg'

interface IProfileInfoProps {
    age: string;
    relationName: string;
    handleOpenInfo: () => void;
    setIsOpenPopup: (val: boolean) => void;
}



export const ProfileInfo = ({ age, relationName, handleOpenInfo, setIsOpenPopup }: IProfileInfoProps) => {
    const [errorDateBirth, setErrorDateBirth] = useState(false)
    const [errorDateDeath, setErrorDateDeath] = useState(false)
    const [currGender, setCurrGender] = useState('')
    const [dateDeath, setDateDeath] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false)

    const params = useParams()
    const { data: rel, isSuccess } = useGetRelativeQuery(params.id)
    const [putRelative, { isError }] = usePutRelativeMutation()
    const [date, setDate] = useState(rel?.dateOfBirth)
    const [bufferdate, setbufferDate] = useState()
    const [death, setDeath] = useState(rel?.isDead);
    const putRelDebounce = useDebounce(putRelative, 500)

    useEffect(() => {

        setDate(rel?.dateOfBirth)

        setCurrGender(rel?.gender === 'male' ? 'Мужской' : 'Женский')
        setDeath(rel?.isDead);
        setDateDeath(rel?.dateOfDeath);
        setDateDeath(rel?.dateOfDeath);
    }, [isSuccess])

    useEffect(() => {

        const data: IProfileInfo = {
            gender: currGender === 'Мужской' ? 'male' : 'female',
            userIsDead: death,
            userDataBirth: errorDateBirth ? rel?.dateOfBirth : date,
        }

        if (death && dateDeath) {
            data.userDataDead = dateDeath
        }

        if (params.id)
            putRelDebounce({ body: data, id: params.id })

    }, [currGender, death, date, dateDeath]);



    const handleChangeGender = (event: string) => {
        setCurrGender(event);
    };

    const isCheckError = (e: any) => {
        if ((!validateDateBirthTreeCard(e.target.value))) {
            setErrorDateBirth(true)
        } else { setErrorDateBirth(false) }
    }

    const isDataDeathCheckError = (e: any) => {
        if ((!validateDateDeathTreeCard(e.target.value, date))) {
            setErrorDateDeath(true)
        } else { setErrorDateDeath(false) }
    }
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;



    const handleClickDeletePerson = () => {
        setIsOpenModal(true)
    }
    return (
        <>
            <AgeDiv>
                <AgeContentWrapper>
                    Возраст  {age === "Неизвестно лет" ? <UnknownAgeButt setIsOpenPopup={setIsOpenPopup} /> : <NumberAgeDiv>{(errorDateBirth) ? <StyledImg src={Warning} /> : age}</NumberAgeDiv>}
                </AgeContentWrapper>
                {!mediaQueryCheck && <SettingDiv onClick={() => console.log('asf')}>
                    <CloseButton onClick={handleOpenInfo}>
                        <CloseIconProfile style={{ paddingLeft: '1px', height: '26px', width: '26px' }} />
                    </CloseButton>
                </SettingDiv>}
            </AgeDiv>
            {mediaQueryCheck && <BorderDiv />}


            <BioDiv>
                <TextBioSpan className='greed-item'>Пол</TextBioSpan>
                <InputCustomSelect
                    currValue={(rel?.gender === "male" ? 'Мужской' : 'Женский')}
                    item={['Мужской', 'Женский']}
                    popupWidth='154px'
                    handleChange={(event) => handleChangeGender(event)}
                />

                <TextBioSpan className='greed-item'>{death ? 'Годы жизни' : 'Дата рождения'}</TextBioSpan>
                <DateWrapper>
                    <DateMaskNoField setDate={setDate} styles={{}} isCheckError={isCheckError} errorDate={errorDateBirth} defaultvalue={date} setbufferDate={setbufferDate} />
                    {death ? <DateMaskNoField setDate={setDateDeath} styles={{}} isCheckError={isDataDeathCheckError} errorDate={errorDateDeath} defaultvalue={dateDeath} setbufferDate={setbufferDate} /> : ''}
                </DateWrapper>
                <TextBioSpan className='greed-item'>Родство</TextBioSpan>
                <TextField disabled value={relationName} multiline type='empty' />

                <CheckBoxDiv>
                    <Checkbox
                        color="primary"
                        style={{ height: '22px' }}
                        checked={(rel?.isDead === false)}
                        onClick={() => { setDeath(!rel?.isDead) }}
                        icon={
                            <VectorCheckboxFillNew style={{ width: '10px', height: '8px' }} viewBox="0 0 11 8" />
                        }
                        checkedIcon={
                            <VectorCheckboxFillNew style={{ width: '10px', height: '8px' }} viewBox="0 0 11 8" />
                        }
                    />
                    <TextCheckBoxSpan>Человек жив</TextCheckBoxSpan>
                </CheckBoxDiv>

            </BioDiv>
            {mediaQueryCheck && <BorderDiv />}
            {(params.id !== localStorage.getItem("idRoot")) ?
                (<DeleteDiv>
                    <Button style={{ width: '100%' }} className='deleteProfile' variant='contained' onClick={handleClickDeletePerson}>Удалить <ProfileDeleteIcon style={{ width: 24, height: 24 }} viewBox='0 0 24 24' /></Button>
                    <BorderDiv style={{ bottom: '-16px' }} />
                </DeleteDiv>) :
                ("")
            }

            <Modal open={isOpenModal}
                onClose={() => { setIsOpenModal(false) }}
            >
                <BoxStyled>
                    {isOpenModal && <DeletePersonModal setIsOpenModal={setIsOpenModal} />}
                </BoxStyled>
            </Modal>
        </>
    );
};

