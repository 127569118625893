import { ArrowBackIcon } from 'icons/ArrowBackIcon';
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';

export const StyledSwiper = styled(Swiper)`
    @media(min-width: 844px){
        margin: 0px 0px 0px 0px;
        .swiper-pagination{
            margin:25px 0px 0px 0px;
            display:flex;
            text-align:center;
            justify-content:center;
            transition:300ms opacity;
            transform:translate3d(0,0,0);
            z-index:10;
            position:relative;
        }
        .swiper-pagination-bullet-active{
            display:flex;
            text-align:center;
            justify-content:center;
            position:relative;
            bottom:1px;
            height:12px;
            width:12px;
            background-color: #149C84;
        }
    }
    @media(max-width:844px){
        margin: 0px 0px 0px 0px;
        .swiper-pagination{
            margin:25px 0px 0px 0px;
            display:flex;
            text-align:center;
            justify-content:center;
            transition:300ms opacity;
            transform:translate3d(0,0,0);
            z-index:10;
            position:relative;
        }
        .swiper-pagination-bullet-active{
            display:flex;
            text-align:center;
            justify-content:center;
            position:relative;
            bottom:1px;
            height:12px;
            width:12px;
            background-color: #149C84;
        }
    }
`
export const Slide = styled(SwiperSlide)`
    width:100%;

`
export const GallereyDiv = styled.div`
position:relative;
width:608px;
height:650px;

@media(max-width:844px){
    width:358px;
    height:405px;
}
`

export const NextArrowButton = styled.button`
@media(min-width:844px)
{
all:unset;
    position:absolute;
    right:10px;
    bottom:-60px;
    transform: translateY(-50%);
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 40px;
    z-index:1;
}
   @media(max-width:844px)
{
all:unset;
    right:-2px;
    bottom:-36px;
    position:absolute;
    transform: translateY(-50%);
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 40px;
    z-index:1;
    
}
    
    
`
export const PrevArrowButton = styled.button`
@media(min-width:844px){
    all:unset;
    position:absolute;
    left:8px;
    bottom:-47px;
    transform: translateY(-50%) rotate(180deg);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:1;
    border-radius: 40px;
}
    
    @media(max-width:844px)
{
all:unset;
    position:relative;
    left:-6px;
    bottom:22px;
    transform: translateY(-50%) rotate(180deg);
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 40px;
    z-index:1;
    
}
`
export const GallereyImage = styled.img`
    align-items: center;
    object-fit:contain;
    border-radius:6px;
    width:100%;
    height:100%;
`