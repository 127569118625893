import React, { useState } from 'react';
import { Box, IconButton, SvgIcon } from '@mui/material';
import { sxBox } from 'components/AuthorizationModal/ErrEndAuthModal/style';
import styles from './styles.module.css';
import { baseURL, customAxios } from 'network/axiosConfig';
import { Link, useNavigate } from 'react-router-dom';
import { ChangePasswordModal } from './ChangePasswordModal';
import { ChangeSettingsModal } from './ChangeSettingsModal';
import { Email, Initials, divNameSurname, underdivNameSurname } from './style';
import { IconProfileWithoutAvatar } from 'icons/IconProfileWithoutAvatar';
import { CloseIcon } from 'icons/CloseIcon';
import { IconSettings } from 'icons/IconSettings';
import { IconLogout } from 'icons/IconLogout';
import { IconKey } from 'icons/IconKey';

interface IAuthModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}
export const ProfileModal = ({ isOpen, setIsOpen }: IAuthModalProps) => {
  const navigate = useNavigate();

  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const array = localStorage.getItem('treeArray');
  const treeArray = array ? JSON.parse(array) : null;
  const handleClickLogout = async (): Promise<void> => {
    await customAxios
      .post(`logout`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then(async (resp) => {
        localStorage.clear();
        navigate('/');
      });
  };
  const handleClickClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ ...sxBox }}>
      <div style={{ ...divNameSurname }}>
        <div style={{ ...underdivNameSurname }}>
          {treeArray[0].data.avatar ? (
            <img
              src={`${baseURL}avatar?avatarPath=${treeArray[0].data.avatar}&id=${treeArray[0].id}`}
              className={styles.ProfileModaleImage}
              style={{ margin: '0px' }}
              alt="test"
            />
          ) : (
            <SvgIcon
              component={IconProfileWithoutAvatar}
              style={{ width: '88px', height: '50px' }}
              viewBox="0 0 50 50"
            />
          )}
          <div>
            <p style={{ ...Initials }}>
              {treeArray[0].data.surname} {treeArray[0].data.name} {treeArray[0].data.middleName}
            </p>
            <p style={{ ...Email }}>{treeArray[0].data.email}</p>
          </div>
        </div>
        <IconButton
          style={{ padding: 0 }}
          onClick={() => {
            handleClickClose();
          }}
          disableRipple>
          <SvgIcon
            component={CloseIcon}
            style={{ width: '18px', height: '18px', padding: 0 }}
            viewBox="0 0 18 18"
          />
        </IconButton>
      </div>
      <div
        style={{
          padding: '24px',
          borderTop: '1px solid #E5E5E5',
          borderBottom: '1px solid #E5E5E5',
        }}>
        <IconButton
          className="buttonSettingsChangePassword"
          onClick={() => {
            setIsOpenSettings(true);
          }}
          disableRipple
          style={{ marginBottom: '34px' }}>
          <SvgIcon
            component={IconSettings}
            style={{
              width: '24px',
              height: '24px',
              paddingRight: '16px',
              boxSizing: 'content-box',
            }}
            viewBox="0 0 24 24"
          />
          Настройки
        </IconButton>
        <br />
        <IconButton
          className="buttonSettingsChangePassword"
          onClick={() => {
            setIsOpenPassword(true);
          }}
          disableRipple>
          <SvgIcon
            component={IconKey}
            style={{
              width: '24px',
              height: '24px',
              paddingRight: '16px',
              boxSizing: 'content-box',
            }}
            viewBox="0 0 24 24"
          />
          Изменить пароль
        </IconButton>
        <br />
      </div>
      <div>
        <Link to="/">
          <IconButton
            className="buttonLogout"
            onClick={() => {
              handleClickLogout();
            }}
            disableRipple
            style={{ margin: '24px' }}>
            <SvgIcon
              component={IconLogout}
              style={{
                width: '24px',
                height: '24px',
                paddingRight: '16px',
                boxSizing: 'content-box',
              }}
              viewBox="0 0 24 24"
            />
            Выход
          </IconButton>
        </Link>
      </div>
      {isOpenPassword && (
        <ChangePasswordModal isOpen={isOpenPassword} setIsOpen={setIsOpenPassword} />
      )}
      {isOpenSettings && (
        <ChangeSettingsModal isOpen={isOpenSettings} setIsOpen={setIsOpenSettings} />
      )}
    </Box>
  );
};
