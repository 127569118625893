import {useSelector, useDispatch} from 'react-redux'
import { changeNodeParents, addNodes, addEdges,changeNodePositionX,getNode} from '../../store/slices/tree/slice.js'



export function  useAutoLayout  (dispatch, nodes)  {

 
  const setNodes = (nodes) => dispatch(setNodes(nodes))
  const setEdges = (edges) => dispatch(setEdges(edges))
  const changeNodePosition = (index, x) => dispatch(changeNodePositionX({ind: index, positionX: x}))

    const changePosition = (element, index)=>{
        let x
        if(element.data.globalPos==='l'){
         x = element.position.x-250
        }
        if(element.data.globalPos==='r'){
         x = element.position.x+250
        }
        changeNodePosition(index, x)
    
    }
    const checkDist = (node) => {
        let isNeedL=true
        let isNeedR=true
        nodes.forEach((el)=>{
          if(Math.abs(Math.abs(el.position.x)-Math.abs(node.position.x))<=500 &&el.data.rank>=1){
            
            if(el.data.globalPos==='l'){
              isNeedL=false
            }else{isNeedR=false}
           
          }
        })
        let x
        nodes.forEach((el, index)=>{
          if((node.id!==el.id)&& (Math.abs(Math.abs(el.position.x)-Math.abs(node.position.x))>=500)){
              if(el.data.globalPos==='l' &&  el.data.rank>=1 && isNeedL){
                x = el.position.x+25
                changeNodePosition(index, x)
              }else
              if(el.data.globalPos==='r' &&  el.data.rank>=1 && isNeedR){
                x = el.position.x-25
                changeNodePosition(index, x)
    
              }
          
          }
        })
        
    }
    const changeGlobPos = (node,ind, glPos) => {
        let pos 
       if(node.data.globalPos==='r'){
        if(glPos==='r'){
          pos = node.position.x+155
        }else{
          pos = node.position.x
        }
      }else
        if(glPos==='l'){
          pos = node.position.x-140
        }else{
          pos = node.position.x
        }
      
      changeNodePosition(ind, pos)
    
       
       if(node.parents.length>=1){
        node.parents.forEach((el)=>nodes.filter((elem, ind)=>{if(elem.id===el){changeGlobPos(elem, ind, glPos)} return 0}) )
       }
    }
    const findElemProb = (idPar) => {
        nodes.forEach((element, ind) => {
          if(idPar[0]===element.id || idPar[1]===element.id || idPar[2]===element.id ){
            if(element.data.globalPos==='r'){
              changeNodePosition(ind, element.position.x+100)
            }else{
              changeNodePosition(ind, element.position.x-100)
            }
            if(element.id.indexOf('z')){
              if(element.parents.length>=1){
                element.parents.forEach((el)=>nodes.filter((elem,ind)=>{if(elem.id===el){changeGlobPos(elem,ind, element.data.pos)} return 0}) )
              }
            }
          }
        })
    
    }
    const searchRootProblem = ( nodeOne, nodeTwo) => {
        const newNodeOne = nodes.filter((el)=>nodeOne.children[0]===el.id)
        const newNodeTwo = nodes.filter((el)=>nodeTwo.children[0]===el.id)
        const resNodeOne=newNodeOne[0]
        const resNodeTwo=newNodeTwo[0]
        if(resNodeOne === resNodeTwo){
          findElemProb(resNodeOne.parents, resNodeOne.data.globalPos)
        } else searchRootProblem(resNodeOne, resNodeTwo)
        checkDist(resNodeOne)
        
    }
    const res = () => {
        nodes.forEach((element, ind) => {
          if(element.data.rank>=1){
            changePosition(element, ind)
          }
        });
    }
    nodes.forEach((i) => {
      nodes.forEach((j) => {
        if((i.position.y === j.position.y) && (Math.abs(i.position.x-j.position.x) <= 120) && (!i.id.includes('z') && !j.id.includes('z')) && (i.data.rank >= 1 && j.data.rank >= 1) && (i.id !== j.id)){
          
          // console.log('id i elem: '+i.id + '\nid j elem: '+ j.id)

          if(i.data.globalPos === j.data.globalPos){searchRootProblem(i,j); }else
          res()
        }
      });
    });
  }