import { Components, Theme } from '@mui/material';

/*
  Переопределение стилей негруппируемых компонентов
  Всякие хедеры, сайдбары, типографика возможно
  Деление на файлы может быть пересмотрено
*/

export const ungroupedComponents: Components<Omit<Theme, 'components'>> = {
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: '14px 24px',
        backgroundColor: theme.palette.background.default,
        border: 'none',
        boxShadow:
          '0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06)',

        [theme.breakpoints.down('md')]: {
          padding: '10px 16px',
        },
      }),
    },
  },

  MuiTypography: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },

  MuiSvgIcon: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.className === 'AddRelationIcon' && {
          width: '24px',
          height: '24px',
          color: 'unset',
        }),
      }),
    },
  },

  MuiDrawer: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        '& .MuiDrawer-paper': {
          boxShadow: 'none',
        },
        '& .MuiModal-backdrop': {
          display: 'flex',
        },
        '& .MuiToolbar-root': {
          borderRightColor: 'rgb(255,255,255)',
        },
      }),
    },
  },

  MuiMenuItem: {
    styleOverrides: {
      root: () => ({
        'fontFamily': 'Golos Text',
        'fontWeight': '500',
        'lineHeight': '21px',
        'color': '#7F7F7F',
        '&:hover': {
          backgroundColor: '#DCF0ED',
        },
        '&.Mui-selected': {
          backgroundColor: '#DCF0ED',
          color: '#1A1A1E',
        },
      }),
    },
  },
};
