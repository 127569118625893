import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { TBiography, TImage, TProfileSliceState, TRelative, TSetField, TSetFieldRelativeString } from "./types";
import { getBioAsync, getRelativeAsync } from "./asyncActions";

const initialState: TProfileSliceState = {
    data: {},
    error: null,
    status: 'init',
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setImagesArr: (state, { payload }: PayloadAction<TImage[]>) => {
            state.data.imagesArr = payload
        },
        setAvatar: (state, { payload }: PayloadAction<string>) => {
            state.data.avatar = payload
        },
        setRelativeInfo: (state, { payload }: PayloadAction<TRelative>) => {
            state.data.relativeInfo = payload
        },
        setBiography: (state, { payload }: PayloadAction<TBiography>) => {
            state.data.biography = payload
        },
        setRelStringField(state, { payload }: PayloadAction<TSetFieldRelativeString>) {
            if (state.data.relativeInfo) {
                state.data.relativeInfo[payload.fieldName] = payload.value;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBioAsync.fulfilled, (state, actions) => {
                state.status = "success"
                state.error = null
                state.data.biography = actions.payload
                console.log(state.data.biography)
            },)
            .addCase(getRelativeAsync.fulfilled, (state, actions) => {
                state.status = "success"
                state.error = null
                state.data.relativeInfo = actions.payload
                console.log(state.data.relativeInfo)
            },)

            .addMatcher(
                isAnyOf(getBioAsync.pending, getRelativeAsync.pending),
                (state) => {
                    state.status = "loading";
                    state.error = null;
                    console.log("load");
                }
            )
            .addMatcher(
                isAnyOf(getBioAsync.rejected, getRelativeAsync.rejected),
                (state, actions) => {
                    state.status = "error";
                    console.log("error");
                    if (actions.payload) {
                        state.error = actions.payload;
                        console.error(state.error?.message);
                    }
                }
            )
    }

})

export const {
    setImagesArr,
    setAvatar,
    setRelativeInfo,
    setBiography,
    setRelStringField
} = profileSlice.actions

export const profileReducer = profileSlice.reducer