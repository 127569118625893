import { useState, useEffect } from 'react';
import TreeCard from './TreeCard';
import { ZoomPan } from "./ZoomPan/index";
import ReactFamilyTree from "react-family-tree";
import styles from './index.module.css';
import { TreeHeaderComp } from 'components/Tree/TreeHeaderComp';
import { FooterTree } from 'components/FooterTree';
import { SideBar } from 'components/SideBar';
import { useNavigate } from "react-router-dom";
import { getTreeCurrentCardData } from "store/slices/tree/selectors";
import { useDispatch, useSelector } from "react-redux";

const WIDTH = 460;
const HEIGHT = 490;

const Tree = () => {

  const [rootId, setRootId] = useState(JSON.parse(localStorage.getItem("idRoot")));
  const [phantomId, setPhantomId] = useState(-6)

  const dataArr = localStorage.getItem("treeArray") ? JSON.parse(localStorage.getItem("treeArray")) : [JSON.parse(localStorage.getItem("rootUser"))]
  const [key, setKey] = useState(0)

  dataArr[0].generation = 1;

  const generationArr = [["сына", "дочь"], [["мужа", "жену"], ["брата", "сестру"]], ["отца", "мать"], ["дедушку", "бабушку"], ["прадедушку", "прабабушку"]]
  const genderArr = ["male", "female"]
  const relatiomArr = [["Cын", "Дочь"], [["Муж", "Жена"], ["Брат", "Сестра"]], ["Отец", "Мать"], ["Дедушка", "Бабушка"], ["Прадедушка", "Прабабушка"]]


  function pahntomGenerator(id, name, gender, generation, parents, siblings, spouses, children) {
    return {
      "id": id,
      "name": name,
      "gender": gender,
      "userDataBirth": "",
      "imgUrl": "https://png.pngtree.com/png-vector/20190307/ourlarge/pngtree-vector-add-user-icon-png-image_762930.jpg",
      "generation": generation,
      "parents": parents,
      "siblings": siblings,
      "spouses": spouses,
      "children": children
    };
  };

  const arraySize = dataArr.length
  let currentPhantomId = -1;

  console.log(arraySize)

  for (let i = 0; i < arraySize; i += 1) {
    const currentGeneration = dataArr[i].generation;
    if (currentGeneration >= 4) {
      const praGrandPaName = `пра(${currentGeneration - 2})дедушку`
      const praGrandMaName = `пра(${currentGeneration - 2})бабушку`
      if (dataArr[i].parents.length === 0) {
        dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${praGrandPaName}`, `${genderArr[0]}`, currentGeneration + 1, [], [], [{ "id": currentPhantomId - 1 }], [{ "id": dataArr[i].id }]));
        dataArr[i].parents.push({ "id": currentPhantomId })

        dataArr.push(pahntomGenerator(currentPhantomId - 1, `Добавить ${praGrandMaName}`, `${genderArr[1]}`, currentGeneration + 1, [], [], [{ "id": currentPhantomId }], [{ "id": dataArr[i].id }]));
        dataArr[i].parents.push({ "id": currentPhantomId - 1 })

        currentPhantomId += -2;
      }

      if (dataArr[i].parents.length === 1) {
        for (let j = 0; j < arraySize; j += 1) {
          if (dataArr[j].id === dataArr[i].parents[0].id) {
            if (dataArr[j].gender === "male") {

              dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${praGrandMaName}`, `${genderArr[1]}`, currentGeneration + 1, [], [], [{ "id": dataArr[j].id }], [{ "id": dataArr[i].id }]));
              dataArr[i].parents.push({ "id": currentPhantomId })
              currentPhantomId += -1;
              break;

            } else {

              dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${praGrandPaName}`, `${genderArr[0]}`, currentGeneration + 1, [], [], [{ "id": dataArr[j].id }], [{ "id": dataArr[i].id }]));
              dataArr[i].parents.push({ "id": currentPhantomId })
              currentPhantomId += -1;
              break;

            }
          }
        }
      }
    }

    if (currentGeneration < 4 && currentGeneration !== 0) {

      if (dataArr[i].parents.length === 0) {
        dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration + 1][0]}`, `${genderArr[0]}`, currentGeneration + 1, [], [], [{ "id": currentPhantomId - 1 }], [{ "id": dataArr[i].id }]));
        dataArr[i].parents.push({ "id": currentPhantomId })

        dataArr.push(pahntomGenerator(currentPhantomId - 1, `Добавить ${generationArr[currentGeneration + 1][1]}`, `${genderArr[1]}`, currentGeneration + 1, [], [], [{ "id": currentPhantomId }], [{ "id": dataArr[i].id }]));
        dataArr[i].parents.push({ "id": currentPhantomId - 1 })

        currentPhantomId += -2;
      }

      if (dataArr[i].parents.length === 1) {
        for (let j = 0; j < arraySize; j += 1) {
          if (dataArr[j].id === dataArr[i].parents[0].id) {
            if (dataArr[j].gender === "male") {

              dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration + 1][1]}`, `${genderArr[1]}`, currentGeneration + 1, [], [], [{ "id": dataArr[j].id }], [{ "id": dataArr[i].id }]));
              dataArr[i].parents.push({ "id": currentPhantomId })
              currentPhantomId += -1;
              break;

            } else {

              dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration + 1][0]}`, `${genderArr[0]}`, currentGeneration + 1, [], [], [{ "id": dataArr[j].id }], [{ "id": dataArr[i].id }]));
              dataArr[i].parents.push({ "id": currentPhantomId })
              currentPhantomId += -1;
              break;

            }
          }
        }
      }

      if (i === 0) {
        if (dataArr[0].spouses.length === 0) {
          if (dataArr[i].gender === "male") {
            dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration][0][1]}`, `${genderArr[1]}`, currentGeneration, [], [], [{ "id": rootId }], []));
          } else
            dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration][0][0]}`, `${genderArr[0]}`, currentGeneration, [], [], [{ "id": rootId }], []));
          dataArr[i].spouses.push({ "id": currentPhantomId })
          if (dataArr[0].children.length !== 0) {
            for (let j = 1; j < dataArr.length; j += 1) {
              if (dataArr[j].id === currentPhantomId) {
                for (let z = 0; z < dataArr[0].children.length; z += 1) {
                  dataArr[j].children.push(dataArr[0].children[z])
                }
                break;
              }
            }
          }
          currentPhantomId += -1;
        }


        if (dataArr[0].spouses.length !== 0) {
          for (let j = 1; j < dataArr.length; j += 1) {
            if (dataArr[j].id === dataArr[0].spouses[0].id) {
              dataArr[j].children.push({ "id": currentPhantomId })
              break;
            }
          }
        }
        dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration - 1][0]}`, `${genderArr[0]}`, currentGeneration - 1, [{ "id": rootId }, { "id": dataArr[0].spouses[0].id }], [{ "id": currentPhantomId - 1 }], [], []));
        dataArr[i].children.push({ "id": currentPhantomId })
        currentPhantomId += -1;


        if (dataArr[0].spouses.length !== 0) {
          for (let j = 1; j < dataArr.length; j += 1) {
            if (dataArr[j].id === dataArr[0].spouses[0].id) {
              dataArr[j].children.push({ "id": currentPhantomId })
              break
            }
          }
        }
        dataArr.push(pahntomGenerator(currentPhantomId, `Добавить ${generationArr[currentGeneration - 1][1]}`, `${genderArr[1]}`, currentGeneration - 1, [{ "id": rootId }, { "id": dataArr[0].spouses[0].id }], [{ "id": currentPhantomId + 1 }], [], []));
        dataArr[i].children.push({ "id": currentPhantomId })
        currentPhantomId += -1;
      }
    }
  }

  const [dataArrMain, setDataArr] = useState(dataArr)
  console.log(dataArrMain)
  const array = localStorage.getItem("treeArray");
  const treeArray = array ? JSON.parse(array) : null;
  const CardInfo = useSelector(getTreeCurrentCardData)
  console.log(CardInfo)


  return (
    <>
      <TreeHeaderComp />
      <div id='tree-root' className={styles.root} key={dataArrMain}>
        <SideBar />
        <ZoomPan
          captureWheel
          min={0.7}
          max={2.5}
          className={styles.wrapper}
          key={rootId}
        >
          <ReactFamilyTree
            nodes={dataArrMain}
            rootId={rootId}
            width={WIDTH}
            height={HEIGHT}
            canvasClassName={styles.tree}
            renderNode={node => (
              <TreeCard
                key={node.id}
                node={node}
                isRoot={node.id === rootId}
                setDataArr={setDataArr}
                dataArrMain={dataArrMain}
                setStateKey={setPhantomId}
                phantomId={phantomId}
                generationArr={generationArr}
                genderArr={genderArr}
                style={{
                  transform: `translate(${node.left *
                    (WIDTH / 2)}px, ${node.top * (HEIGHT / 2)}px)`
                }}
              />
            )}
          />
        </ZoomPan>
      </div>
      <FooterTree />
    </>
  );
}

export default Tree;