import { Breadcrumbs, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  && .MuiBreadcrumbs-separator {
    color: ${({ theme }) => theme.palette.text.secondary};
    margin: 0;
    padding: 0 8px;
  }

  && .MuiButtonBase-root {
    height: 18px;
    background-color: inherit;

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`;

export const StyledLink = styled(Link)`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledTypography = styled(Typography)`
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;