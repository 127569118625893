import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/dist/query"
import { baseURL, customAxios } from "network/axiosConfig"


const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {

        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`)
        }
    },
    credentials: "include",
})


const refreshQuery = fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: headers => {
        headers.set('Content-Type', 'application/json')

        headers.set('Access-Control-Allow-Origin', 'true')

    },

    credentials: "include",

})

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        const t = await customAxios.post('authentication/refresh', undefined, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            const tok = await res.data;
            localStorage.setItem('accessToken', tok.accessToken)

        })
        // localStorage.setItem("accessToken", token.accessToken)
        result = await baseQuery(args, api, extraOptions)

    }
    return result
}