import { createSvgIcon } from "@mui/material";

export const AddPartnerIcon = createSvgIcon(
    <svg width="36" height="79" viewBox="0 0 36 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M30.7058 52.5C30.0536 53.15 29.3518 53.7502 28.6066 54.2946L30.7058 52.5ZM29.0098 25.5086C29.606 25.9703 30.1725 26.4685 30.7058 27L29.0098 25.5086Z" fill="#149C84" />
        <path d="M36 39.75C36 34.7682 33.9762 30.2591 30.7058 27L29.0098 25.5086L0 0V39.75V78.75L28.6066 54.2946L30.7058 52.5C33.9762 49.2409 36 44.7318 36 39.75Z" fill="#149C84" />
        <path d="M25.875 38.625H19.125V31.875C19.125 31.5766 19.0065 31.2905 18.7955 31.0795C18.5845 30.8685 18.2984 30.75 18 30.75C17.7016 30.75 17.4155 30.8685 17.2045 31.0795C16.9935 31.2905 16.875 31.5766 16.875 31.875V38.625H10.125C9.82663 38.625 9.54048 38.7435 9.32951 38.9545C9.11853 39.1655 9 39.4516 9 39.75C9 40.0484 9.11853 40.3345 9.32951 40.5455C9.54048 40.7565 9.82663 40.875 10.125 40.875H16.875V47.625C16.875 47.9234 16.9935 48.2095 17.2045 48.4205C17.4155 48.6315 17.7016 48.75 18 48.75C18.2984 48.75 18.5845 48.6315 18.7955 48.4205C19.0065 48.2095 19.125 47.9234 19.125 47.625V40.875H25.875C26.1734 40.875 26.4595 40.7565 26.6705 40.5455C26.8815 40.3345 27 40.0484 27 39.75C27 39.4516 26.8815 39.1655 26.6705 38.9545C26.4595 38.7435 26.1734 38.625 25.875 38.625Z" fill="white" />
    </svg>,
    "AddPartnerIcon"
)
