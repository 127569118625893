import styled from "@emotion/styled";
import { Autocomplete } from "@mui/material";

interface IPropsSelect {
    nowValue: string,

}
export const CustomAutocomplete = styled(Autocomplete)`
     font-family: Gilroy, sans-serif;
    position: relative;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }
`
export const CustomInput = styled.input`
     font-family: Gilroy, sans-serif;
    position: relative;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border: none;
    background-color: transparent;
    padding:0px;
    &:focus {
        outline: none;
    }
`