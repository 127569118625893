
export const getAddSiblingsEdges = (node: any) => {
    const siblingEdges = [
        {
            type: 'smoothstep',
            pathOptions: {

                borderRadius: 20
            },
            sourceHandle: 'SourceTop',
            targetHandle: 'TarTop',
            id: `${node.id}ezs`,
            source: node.id,
            target: `${node.id}zs`,
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
                strokeLinecap: 'round',

            },
        },
        {
            type: 'smoothstep',

            sourceHandle: (node.data.pos === "l" ? 'SourceLeft' : "SourceRight"),
            targetHandle: (node.data.pos === "l" ? 'TarRight' : "TarLeft"),
            id: `${node.id}els`,
            source: `${node.id}zs`,
            target: `${node.id}ls`,
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
                strokeLinecap: 'round'
            },
        },
        {
            type: 'smoothstep',
            pathOptions: {
                borderRadius: 20,
                offset: 20
            },
            sourceHandle: 'SourceBottom',
            targetHandle: (node.data.pos === "l" ? 'TarRight' : "TarLeft"),
            id: `${node.id}erls`,
            source: `${node.id}zs`,
            target: `${node.id}rs`,
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
                strokeLinecap: 'round'

            },
        },

    ]
    return siblingEdges
}