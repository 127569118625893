/* eslint no-use-before-define: 0 */
import { useRef } from "react"


type TArgs<T> = {
    body: Partial<T>
    id: string
}

type TCallback<T> = (val: TArgs<T>) => void



export const useDebounce = <T>(callback: TCallback<T>, delay: number) => {
    const ref = useRef<ReturnType<typeof setTimeout>>();
    return (
        (args: TArgs<T>) => {
            clearTimeout(ref.current);
            ref.current = setTimeout(async () => { await callback(args) }, delay)

        })
}

