import styled from 'styled-components'
import * as t from '../../fonts/index.module.css'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { DefaultPhotoIcon } from 'icons/DefaultPhotoIcon'
import { Box } from '@mui/material';

interface IPropsContentWrapper {
    urlImg: string,

}

interface IPropsIsOpenGallery {
    isopen: boolean
}

interface ISelectedPhoto {
    isselected: any
}

interface IIsOpenInfo {
    isopeninfo: boolean
}

export const ContentWrapper = styled.div<IPropsContentWrapper & IPropsIsOpenGallery & ISelectedPhoto>`
@media(max-width: 844px){
    display:block;
    position:absolute;
    height:400px;
    max-height:250px;
    background-image: url(${props => props.urlImg});
    background-size:cover;
    background-repeat: no-repeat;
    overflow-y: ${props => props.isopen || props.isselected ? 'hidden' : ''};
    width:100%;
    background-color: #7F7F7F;
    padding-top: 90px;

}
@media(min-width: 844px){
    border-radius: 16px;
    display: flex;    
    width:260px;
    background-size:cover;
    background-repeat: no-repeat;
    justify-content: center;
    gap:16px;
    border-radius: 32px;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding-bottom: 30px;
    
}
`
export const DefaultPhotoIconWrapper = styled.div`
    width: 272px;
    height: 260px;
    position: relative;
    border-radius: 16px;
    background-color: rgba(240, 240, 240, 1);
    cursor: pointer;
`
export const DefaultPhotoIconDesktop = styled(DefaultPhotoIcon)`
    width: 185px !important; 
    height: 190px !important; 
    position: absolute; 
    top: 60%; 
    left: 60%; 
    transform: translate(-60%, -60%);
`
export const DefaultPhotoIconCust = styled(DefaultPhotoIcon)`
    width: 136px !important;
    height: 140px !important;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -60%);
`
export const DesktopImg = styled.img`
    width: 100%;
    height: 260px;
    border-radius: 16px;
`

export const NumberAgeDiv = styled.div`
display:flex;
    background-color: #DCF0ED;
    color: #149C84;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    border-radius:8px;
    padding:4px 8px 2px;
    
    align-items:center;
`

export const LeftBlockDiv = styled.div`
@media(min-width: 844px){
    display: flex;
    gap:24px;
    justify-content: center;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    width: 272px;
    height: 100%;
    flex-direction: column;
    padding: 0px;
}
@media(max-width: 844px){
    padding-top: 22px;
    padding-left: 16px;
    padding-right:17px;
    margin-top:218px;
    overflow-x: hidden;
    top:218px;
     width: 100%;
     box-sizing: border-box;
    background-color: white;
    
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
}
`
export const BreadcrumbsWrapperDiv = styled.div`
    display: flex;
    gap:8px;
    padding: 120px 24px 24px;
`
export const RightBlockDiv = styled.div<IIsOpenInfo>`
    @media(max-width: 844px){
        display: flex;
        gap:24px;
        flex-direction: column;
        padding-bottom: 32px;
        padding-left: 16px;
        padding-right:17px;
        padding-top: ${props => props.isopeninfo ? '36px' : '16px'};
        box-sizing: border-box;
    }
    @media(min-width: 844px){
        display: flex;
        gap:24px;
        flex-direction: column;
        padding-left: 16px;
        padding-bottom: 32px;
        padding-right:17px;
        padding-top: ${props => props.isopeninfo ? '36px' : '16px'};
        box-sizing: border-box;
        width:70vw;
        background-color:#F4F6F6;
        border-radius:16px;
    }
`
export const GallereyComp = styled.div`

`

export const GallereyTitle = styled.div`
    @media(max-width: 844px){
        display: flex;
        justify-content: space-between;
        align-items:center;
        padding-bottom:16px;
    }

`
export const AnotherDiv = styled.div`
@media(max-width: 844px){
    height: 300px;
    background-color: red;
}
`
export const GallereyImage = styled.img`
@media(max-width: 844px){
    align-items: center;
    object-fit:contain;
    border-radius:6px;
    width:76px;
    height:76px;
}
`
export const GallereyName = styled.h2`
    @media(max-width: 844px){
        margin:0;
        font-family: Gilroy, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
    }
`
export const GallereyButtonAdd = styled.button`

`
export const FioSpan = styled.p`

    @media(max-width: 844px){
        
        font-family: Gilroy-Black,sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
        margin:0px;
    }
    @media(min-width: 844px){
        font-family: Gilroy, sans-serif;
        line-height: 26px;
        font-weight: 500;
        font-size: 20px;
        margin:0px;
    }
`
export const CircleDiv = styled.div`
    @media(max-width: 844px){
        background-color: #1A1A1E;
        border-radius: 50%;
        width: 4px;
        height: 4px;
    }
`
export const AgeDiv = styled.div`
    @media(max-width: 844px){
        position: relative;
        padding-top:12px;
        padding-bottom:16px;
        font-family: Gilroy, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
        margin:0px;
        display: flex;
        justify-content: space-between;
        align-items:center;
    }
`
export const BorderDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: -10vw;
    width: 150vw;
    height: 1px;
    border-bottom: 1px solid #E5E5E5;
`
export const GallereyDiv = styled.div`
    @media(max-width: 844px){
        height: 96px;
        width:100%;
    }
`
export const AgeContentWrapper = styled.div`
    display: flex;
    gap:16px;
    
`
export const SettingDiv = styled.div`
    height: 28px;
    display: flex;
    flex-direction: column;
    gap:3px;
    &:hover ${CircleDiv}{
        background-color: #0E6E5D;
        
}
`
export const AddPhotoInput = styled.input`
    height: 28px;
    padding: 0;
    border-radius: 4px;
    min-width: 28px; 
`
export const PageWrapper = styled.div`
`
export const ImagesCount = styled.div`
    display: flex;
    align-items: center;
`
export const CurrentCount = styled.span`
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #FFF;
`
export const TotalCount = styled.span`
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #FFF;
`
// GALLERY

export const OpenGallery = styled.div`
    overflow: hidden;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(26, 26, 30, 0.60);
    backdrop-filter: blur(6px);
    z-index: 2;
`
export const OpenGallereyImage = styled.img`
@media(max-width: 844px){
    align-items: center;
    object-fit: contain;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    height: 100%;
}
`
export const HeaderGallery = styled.div`
    display: flex;
    height: 48px;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
`
export const FooterGallery = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    background-color: #1A1A1E;
    width: 100%;
    height: 56px;
`
export const FooterGalleryContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    padding: 0 16px;
`
export const ImagePopup = styled.div`
    z-index:3;
    padding: 8px 0px;
    position: absolute;
    width: 50vw;
    top: 5vh;
    right: 5vw;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`
export const WarningPopup = styled.div`
    z-index:3;
    padding: 8px 0px;
    position: fixed;
    width: 289px;
    bottom: 3vh;
    left: 50%;
    transform: translate(-50%);
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`
export const WarningAction = styled.p`
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #1A1A1E;
    display: flex;
    flex-direction: row;
    gap:12px;
 
`
export const ImageAction = styled.p`
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #7F7F7F;

    &:hover {
        background-color: #DCF0ED;
        color: #1A1A1E;
    }
`
// CROP
export const CropWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    height: 100%;
    gap: 24px;
`
export const CropBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(14.5px);
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
export const PhantomDiv = styled.div`
    width:100vw;
    height: 100vh;
    position: fixed;
    z-index:2;
    top:0;
    left:0;
`
export const HelperTextDiv = styled.div`
display:flex;
gap: 8px;
flex-direction:column;

`
export const HelperTextSpan = styled.span`
font-family: Gilroy, sans-serif;
display:flex;
align-items:center;
gap:8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #FA5353;
`
export const TextSpan = styled.span`
height:16px;
`
export const WrapperUpMenuDiv = styled.div`
display:flex;
align-items: center;
position: absolute;
justify-content: space-between;
width:100%;
`
export const BackDiv = styled.div`
display:flex;
margin: 12px 0px 0px 14px;
gap:4px;
align-items: center;
`
export const BackTextSpan = styled.span`
color: white;
font-family: Gilroy,sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;

`

export const SettingButDiv = styled.div`
height:16px;
align-items: flex-end;
margin-right:17px;
`
export const DefaultImageWrapper = styled.div`
    backround-color: #7F7F7F;
    z-index:2;
    display:block;
    position:absolute;
    height:400px;
    max-height:250px;

    background-size:cover;
    background-repeat: no-repeat;
    width:100%;
`
export const BoxStyled = styled(Box)`
    @media(min-width: 844px){
        position:absolute;
        transform: translate(-50%, -50%);
        left:50%;
        top:50%;
    }
    @media(max-width: 844px){
        position:absolute;
        top:75%;
    }
`