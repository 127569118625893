import { useState } from 'react';
import { Button, IconButton, SvgIcon } from '@mui/material';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow'
import { EffectCoverflow, Navigation, Keyboard, Mousewheel } from 'swiper/modules';
import {
    CircleDiv,
    CurrentCount,
    FooterGallery,
    FooterGalleryContent,
    HeaderGallery,
    ImageAction,
    ImagePopup,
    ImagesCount,
    OpenGallereyImage,
    OpenGallery,
    SettingDiv,
    TotalCount,
    SwiperStyled,
    WrapperClose,
    ImagesCountDesktop,
    FooterRightBlock,
    ButtonMakeAsMain,
    WrapperDesktopPopup,
    PopupDesktopText,
    WrapperPopupConfirmDeleteButton

} from './style';
import { customAxios } from 'network/axiosConfig';
import { CloseIconProfile } from 'icons/CloseIconProfile';
import { VerticalLine } from 'icons/VerticalLine';
import { ProfileDeleteIcon } from 'icons/ProfileDeleteIcon';
import { ArrowButtonGalleryDesktop } from 'icons/ArrowButtonGalleryDesktop';
import { StarIcon } from 'icons/StarIcon';
import { SuccessIconGalleryDesktop } from 'icons/SuccessIconGalleryDesktop';
import { useDeleteAvatarMutation, useDeleteImageMutation } from 'store/storeQuery/photo';

interface IImage {
    path: string;
    name: string;
}
interface IGalereyProps {
    currentSlide: number;
    setCurrentSlide: (val: number | ((a: number) => number)) => void;
    imagesArr: IImage[];
    setOpenGallery: (val: boolean) => void;
    setRerend: (val: boolean) => void;
    cardId: number;
    avatar: {path: string, name: string};
    setavatar: (val: {path: string, name: string}) => void
}
export const ViewImageGallerey = ({
    setavatar,
    currentSlide,
    setCurrentSlide,
    imagesArr,
    setOpenGallery,
    setRerend,
    cardId,
    avatar
}: IGalereyProps) => {
    const [isOpenImagePopup, setIsOpenImagePopup] = useState(false);
    const [isOpenConfirmDeletePopup, setIsOpenConfirmDeletePopup] = useState(false);
    const [isOpenNotePopup, setIsOpenNotePopup] = useState(false);
    const [isOpenMakeAsMainPopup, setIsOpenMakeAsMainPopup] = useState(false);
    const [delImage] = useDeleteImageMutation();
    const [delAvatar] = useDeleteAvatarMutation();

    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;

    const handleCloseGallery = () => {
        setCurrentSlide(-1);
        setOpenGallery(false);
        setIsOpenImagePopup(false);
    };
    
    const handleChosePhotoAsAvatar = async () => {
        const data = {
            userID: cardId,
            imagePath: imagesArr[currentSlide].name,
        };
        
        await customAxios.put(`avatar`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });
        if (!mediaQueryCheck) {
            setIsOpenImagePopup(false);
        }
        setavatar(imagesArr[currentSlide])
        setRerend(true);
        setIsOpenMakeAsMainPopup(false);
        setIsOpenNotePopup(true);

        setTimeout(() => {
            setIsOpenNotePopup(false);
        }, 5000);
    };
    const handleDeletePhoto = async () => {
        if (avatar.name === imagesArr[currentSlide].name) {
            const result = imagesArr[currentSlide].name.substring(imagesArr[currentSlide].name.lastIndexOf("/") + 1);
            setavatar({path: '', name: ''});
            delAvatar({id: cardId, avatarPath: result})
        } else {
            delImage({imagePath: imagesArr[currentSlide].name})
        }

        if (!mediaQueryCheck) {
            setOpenGallery(false);
            setIsOpenImagePopup(false);
        }

        setRerend(true);
        setIsOpenConfirmDeletePopup(false);

        if (imagesArr.length === 1) {
            handleCloseGallery();
        }
    };
    const handleOpenImagePopup = () => {
        if (isOpenImagePopup) {
            setIsOpenImagePopup(false);
        } else {
            setIsOpenImagePopup(true);
        }
    };

    const mobileSwiper = {
        spaceBetween: 8,
        slidesPerView: 1
    }

    const desktopSwiper = {
        effect: 'coverflow',
        centeredSlides: true,
        slidesPerView: 4,
        keyboard: true,
        mousewheel: true,
        coverflowEffect:
        {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 3,
            slideShadows: false

        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        modules: [EffectCoverflow, Navigation, Keyboard, Mousewheel]
    }
    
    const swiperParams = mediaQueryCheck ? desktopSwiper : mobileSwiper;
    return (
        <OpenGallery>
            {!mediaQueryCheck &&
                <HeaderGallery>
                    <IconButton onClick={handleCloseGallery} disableRipple>
                        <SvgIcon
                            component={CloseIconProfile}
                            style={{ width: '24px', height: '24px' }}
                            viewBox="0 0 24 24"
                        />
                    </IconButton>
                </HeaderGallery>}
            <SwiperStyled {...swiperParams}
                initialSlide={currentSlide}
                slideToClickedSlide
                onSlideChange={(e: any) => {
                    setCurrentSlide(e.activeIndex);
                    setIsOpenConfirmDeletePopup(false);
                    setIsOpenMakeAsMainPopup(false);
                    setIsOpenNotePopup(false);
                }
                }
                onSwiper={(swiper: any) => console.log(swiper)}
                ismobile={!mediaQueryCheck}>
                {imagesArr ? (
                    imagesArr.map((el: IImage, index: number) => (
                        <SwiperSlide style={{ position: 'relative' }}>
                            <OpenGallereyImage ismobile={!mediaQueryCheck} src={`${el.path}`} alt="err" />

                            {isOpenMakeAsMainPopup && index === currentSlide &&
                                <WrapperDesktopPopup ismobile={!mediaQueryCheck} isDeleteOrMakeAsMainPopup={isOpenConfirmDeletePopup || isOpenMakeAsMainPopup}>
                                    <PopupDesktopText>Вы точно хотите это фото сделать основным?</PopupDesktopText>
                                    <Button onClick={handleChosePhotoAsAvatar} style={{ height: '40px', maxWidth: '128px', fontSize: '16px' }} variant='contained'>Да</Button>
                                </WrapperDesktopPopup>}

                            {isOpenNotePopup && index === currentSlide &&
                                <WrapperDesktopPopup ismobile={!mediaQueryCheck} isDeleteOrMakeAsMainPopup={isOpenConfirmDeletePopup || isOpenMakeAsMainPopup}>
                                    <SuccessIconGalleryDesktop style={{ width: '24px', height: '24px' }} />
                                    <PopupDesktopText>Теперь это Ваша основная фотография</PopupDesktopText>
                                </WrapperDesktopPopup>}

                            {isOpenConfirmDeletePopup && index === currentSlide &&
                                <WrapperDesktopPopup ismobile={!mediaQueryCheck} isDeleteOrMakeAsMainPopup={isOpenConfirmDeletePopup || isOpenMakeAsMainPopup}>
                                    <PopupDesktopText>Вы уверены, что хотите удалить фото?</PopupDesktopText>
                                    <WrapperPopupConfirmDeleteButton>
                                        <Button onClick={() => setIsOpenConfirmDeletePopup(false)} style={{ height: '40px', maxWidth: '128px', borderRadius: '8px', fontSize: '16px' }} color='secondary' variant='outlined'>Отменить</Button>
                                        <Button onClick={handleDeletePhoto} style={{ height: '40px', maxWidth: '128px', borderRadius: '8px' }} className='delete' variant='contained'>Удалить</Button>
                                    </WrapperPopupConfirmDeleteButton>
                                </WrapperDesktopPopup>}

                        </SwiperSlide>
                    ))
                ) : (
                    <div>errr</div>
                )}

            </SwiperStyled>
            {mediaQueryCheck &&
                <>
                    <ArrowButtonGalleryDesktop className='swiper-button-next' style={{ width: '24px', height: '24px', padding: '24px' }} />
                    <ArrowButtonGalleryDesktop className='swiper-button-prev' style={{ width: '24px', height: '24px', padding: '24px', transform: 'rotate(180deg)' }} />
                </>}
            {!mediaQueryCheck && <FooterGallery style={{ backgroundColor: 'rgba(26, 26, 30, 0.8)' }}>
                <FooterGalleryContent>
                    {isOpenImagePopup ? (
                        <ImagePopup>
                            <ImageAction onClick={handleChosePhotoAsAvatar}>Сделать основной</ImageAction>
                            <ImageAction onClick={handleDeletePhoto}>Удалить</ImageAction>
                        </ImagePopup>
                    ) : (
                        ''
                    )}
                    <ImagesCountDesktop>Фотография {currentSlide + 1} из {imagesArr?.length}</ImagesCountDesktop>
                    <SettingDiv onClick={handleOpenImagePopup}>
                        <CircleDiv style={{ backgroundColor: '#FFFFFF' }} />
                        <CircleDiv style={{ backgroundColor: '#FFFFFF' }} />
                        <CircleDiv style={{ backgroundColor: '#FFFFFF' }} />
                    </SettingDiv>
                </FooterGalleryContent>
            </FooterGallery>}
            {mediaQueryCheck && <FooterGallery>
                <FooterGalleryContent>
                    <ImagesCountDesktop>Фотография {currentSlide + 1} из {imagesArr?.length}</ImagesCountDesktop>
                    <FooterRightBlock>
                        {avatar?.name !== imagesArr[currentSlide]?.name ? <ButtonMakeAsMain onClick={() => {
                            setIsOpenConfirmDeletePopup(false);
                            setIsOpenMakeAsMainPopup(true)
                        }
                        }>Сделать основной</ButtonMakeAsMain>
                            :
                            <StarIcon />
                        }

                        <VerticalLine />
                        <ProfileDeleteIcon onClick={() => {
                            setIsOpenMakeAsMainPopup(false)
                            setIsOpenConfirmDeletePopup(true)
                        }
                        } style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                    </FooterRightBlock>
                </FooterGalleryContent>
            </FooterGallery>}
            {mediaQueryCheck &&
                <WrapperClose onClick={handleCloseGallery}>
                    <CloseIconProfile />
                </WrapperClose>}
        </OpenGallery>
    );
};
