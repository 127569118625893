import { emailValidationRegExp, nameValidationRegExp, passwordValidationRegExp, firstNameValidationRegExp, dateBirthValidationRegExp, partialDateValidationRegExp, firstNameValidationRegExp2, passwordNoValidRegExp, firstNameValidationDoubleRegExp } from "./validatorExpressions"

function getDaysInMonth(month: string, year: string) {
    const Month = Number(month);
    const Year = Number(year);
    return new Date(Year, Month, 0).getDate();
}

function validateFullDate(birth: string, death: string) {
    const dateOfDeathParts = death.split('.').map(Number);
    const dateOfBirthParts = birth.split('.').map(Number);

    if ((!dateOfBirthParts[2] || dateOfBirthParts[2].toString() === '____') || (!dateOfDeathParts[2] || dateOfDeathParts[2].toString() === '____')) {
        return true;
    }

    if (dateOfDeathParts[2] > dateOfBirthParts[2] || dateOfDeathParts[2].toString() === '____' ||
        (dateOfDeathParts[2] === dateOfBirthParts[2] && dateOfDeathParts[1] > dateOfBirthParts[1]) ||
        (dateOfDeathParts[2] === dateOfBirthParts[2] && dateOfDeathParts[1] === dateOfBirthParts[1] && dateOfDeathParts[1] > dateOfBirthParts[1])) {
        return true;
    }
}

export function validateEmail(email: string) {
    if (email.match(emailValidationRegExp) && email.length >= 9 && email.length <= 150) {
        return true;
    }
    return false;
}

export function validateName(name: string) {
    if (name.match(nameValidationRegExp)) {
        return true;
    }
    return false;
}

export function validatePassword(pass: string) {
    if (pass.match(passwordValidationRegExp) && pass.length >= 8 && pass.length <= 30 && !pass.match(firstNameValidationRegExp2) && !pass.match(passwordNoValidRegExp)) {
        return true;
    }
    return false;
}
export function validateFirstName(pass: string) {
    if (pass.match(firstNameValidationRegExp) && pass.length >= 2 && pass.length <= 50) {
        return true;
    }
    return false;
}
export function validateFirstDoubleName(pass: string) {
    if (pass.match(firstNameValidationDoubleRegExp) && pass.length >= 2 && pass.length <= 50) {
        return true;
    }
    return false;
}
export function validateMidName(pass: string) {
    if ((pass.match(firstNameValidationRegExp) && pass.length <= 20) || !pass) {
        return true;
    }
    return false;
}
export function validateDateBirth(pass: string) {
    const date = new Date()
    const eardate = new Date('1920-01-01')
    if (pass.match(dateBirthValidationRegExp) && new Date(pass).getTime() < date.getTime() && new Date(pass).getTime() > eardate.getTime()) {
        return true;
    }
    return false;
}
export function validateDateBirthTreeCard(pass: string) {
    console.log(pass);
    if (!pass || pass === '__.__.____' || pass === "") {
        return true;
    }

    const parts = pass.split('.');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const date = new Date();
    const eardate = new Date('1920-01-01');

    const daysInMonth = getDaysInMonth(month, "1922");
    const inputDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);

    if (parts[0] !== "__" && parts[1] !== "__" && parts[2] !== "____") {

        if (!Number.isNaN(inputDate.getTime())) {

            if (inputDate.getTime() < date.getTime()) {

                const Year = parseInt(year, 10);
                const Month = parseInt(month, 10);
                const Day = parseInt(day, 10);

                if (inputDate.getFullYear() === Year && inputDate.getMonth() === Month - 1 && inputDate.getDate() === Day) {

                    return true;
                }
            }
            return false;

        }
    } else {

        if (Number(day) > daysInMonth) {
            return false;
        }
        if (Number(year) > date.getFullYear()) {
            return false;
        }

        if (partialDateValidationRegExp.test(pass)) {
            return true
        }

    }
}
export function validateDateDeathTreeCard(pass: string, dateBirth: string) {
    console.log('target: ', pass, ',state: ', dateBirth);
    if (dateBirth === '__.__.____' || !pass || pass === '__.__.____' || pass === "") {
        return true;
    }

    if (validateFullDate(dateBirth, pass)) {
        return true;
    }
    return false;
}



