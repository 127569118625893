export const sxBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '24px',

  boxShadow: 24,

};

export const textCont = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  height: '50px',

}
export const textNumb = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  color: '#149C84',
  paddingLeft: '150px'
}

export const textNumTwo = {
  fontFamily: 'Golos Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',

}

export const textTwo = {
  fontFamily: 'Golos Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',



}
export const sxGridThree = {

  display: 'grid',
  gridTemplateAreas: " 'fText' 'sText' 'TText' 'button' ",
  justifyContent: 'center',
  gridTemplateRows: '232px 70px 80px 65px',
  gridTemplateColumns: '360px'
}
export const finText = {

  fontFamily: 'Golos Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',

}