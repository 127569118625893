export const calculateAgeFromDateInput = (dateInput: string, dateDeath?: string): number => {
    const today: Date = new Date();
    const [day, month, year] = dateInput.split('.');
    let age: any = ''
    if (year === '____') {
        age = '?'
        return age;
    }
    const birthday: Date = new Date(parseInt(year, 10), parseInt(month, 10), parseInt(day, 10));
    if (dateDeath) {
        const [dDay, dMonth, dYear] = dateDeath.split('.');
        if (dYear === '____') {
            age = '?'
            return age;
        }
        const Deathdata: Date = new Date(parseInt(dYear, 10), parseInt(dMonth, 10), parseInt(dDay, 10));
        age = Deathdata.getFullYear() - birthday.getFullYear();
        if (dDay === '__' || dMonth === '__') {
            age = Number(dYear) - Number(year)
            const aboutAge: any = `~${age}`
            return aboutAge
        }
        const dayDiff: number = Deathdata.getDate() - birthday.getDate();
        let monthDiff: number = Deathdata.getMonth() - birthday.getMonth();
        if (dayDiff < 0 || (dayDiff === 0 && Deathdata.getDate() < birthday.getDate())) {
            monthDiff -= 1;
        }


        if (monthDiff < 0 || (monthDiff === 0 && Deathdata.getDate() < birthday.getDate())) {
            age -= 1;
        }

    }
    else {
        age = today.getFullYear() - birthday.getFullYear();

        if (day === '__' || month === '__') {
            age = today.getFullYear() - Number(year)
            const aboutAge: any = `~${age}`
            return aboutAge
        }
        const monthDiff: number = today.getMonth() - birthday.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
            age -= 1;
        }

    }
    return age;
};