import { createSvgIcon } from "@mui/material";

export const WarningSignGreen = createSvgIcon(
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_10157_36940" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0.5H19.9999V20.5H0V0.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_10157_36940)">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.664 2C3.135 2 1.5 3.733 1.5 6.416V14.584C1.5 17.267 3.135 19 5.664 19H14.332C16.864 19 18.5 17.267 18.5 14.584V6.416C18.5 3.733 16.864 2 14.334 2H5.664ZM14.332 20.5H5.664C2.276 20.5 0 18.122 0 14.584V6.416C0 2.878 2.276 0.5 5.664 0.5H14.334C17.723 0.5 20 2.878 20 6.416V14.584C20 18.122 17.723 20.5 14.332 20.5Z" fill="#149C84" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99399 15.25C9.57999 15.25 9.24399 14.914 9.24399 14.5V10.5C9.24399 10.086 9.57999 9.75 9.99399 9.75C10.408 9.75 10.744 10.086 10.744 10.5V14.5C10.744 14.914 10.408 15.25 9.99399 15.25Z" fill="#149C84" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.9989 7.7041C9.4459 7.7041 8.9939 7.2571 8.9939 6.7041C8.9939 6.1511 9.4369 5.7041 9.9889 5.7041H9.9989C10.5519 5.7041 10.9989 6.1511 10.9989 6.7041C10.9989 7.2571 10.5519 7.7041 9.9989 7.7041Z" fill="#149C84" />
    </svg>,

    "WarningSignGreen"
)