import { baseApi } from "./api";

const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        firstReg: builder.mutation({
            query: ({ body }) => ({
                url: `/register`,
                method: "POST",
                body
            })


        }),
        secondReg: builder.mutation({
            query: ({ body }) => ({
                url: `/user`,
                method: 'PUT',
                body
            }),
        }),
        login: builder.mutation({
            query: ({ body }) => ({
                url: `/authentication`,
                method: "POST",
                body
            })


        }),

        refresh: builder.mutation({
            query: () => ({
                url: `/authentication/refresh`,
                method: "POST",
            })


        }),
    })
})
export const { useRefreshMutation, useLoginMutation, useFirstRegMutation, useSecondRegMutation } = authApi