

export const getAddParentsCard = (node: any) => {
    let CONST_DIST = 0
    if (node.data.spouses.length > 0) {
        CONST_DIST = 60
    }
    const parentsArr = [
        {
            id: `${node.id}rp`,

            type: 'addCard',
            position: { x: (node.data.pos === "l" ? node.position.x + 168 - CONST_DIST : node.position.x + 158 + CONST_DIST), y: node.position.y - 300 },
            data: {
                name: 'мать для',
                middleName: node.data.name,
                gender: "female",
                rank: node.data.rank + 1,
                pos: 'r',
                globalPos: node.data.globalPos,
                parents: [],
                spouses: [{ id: `${node.id}lp` }, { id: `${node.id}zp` }],
                children: [{ id: node.id }, ...node.data.spouses],
                siblings: [],
            },


        },
        {
            id: `${node.id}lp`,
            type: 'addCard',
            position: { x: (node.data.pos === "l" ? node.position.x - 98 - CONST_DIST : node.position.x - 102 + CONST_DIST), y: node.position.y - 300 },

            data: {
                gender: "male",
                name: 'отца для ',
                middleName: node.data.name,
                rank: node.data.rank + 1,
                pos: 'l',
                globalPos: node.data.globalPos,
                parents: [],
                spouses: [{ id: `${node.id}rp` }, { id: `${node.id}zp` }],
                children: [{ id: node.id }, ...node.data.spouses],
                siblings: [],

            },

        },
        {
            id: `${node.id}zp`,
            type: 'zeroAddNode',
            position: { x: (node.data.pos === "l" ? node.position.x + 98 - CONST_DIST : node.position.x + 98 + CONST_DIST), y: node.position.y - 275 },
            data: {

                rank: node.data.rank,
                pos: 'z',
                globalPos: node.data.globalPos,
                parents: [],
                spouses: [{ id: `${node.id}rp` }, { id: `${node.id}lp` }],
                children: [{ id: node.id }, ...node.data.spouses],
                siblings: [],

            },

        },
    ]
    return parentsArr
}
