import { List } from "@mui/material"
import { useState } from "react";
import { SpanStyle } from "./style";
import { RelativeCard } from "../RelativeCard";
import styles from './style.module.css';
import { getTreeCurrentCardData } from "store/slices/tree/selectors";
import { useSelector } from "react-redux";

export const RelativeList = () => {
    const array = localStorage.getItem("treeArray");
    const treeArray = array ? JSON.parse(array) : null;
    const CardInfo = useSelector(getTreeCurrentCardData); // переделать под RTK в будущем
    const root = localStorage.getItem("idRoot")
    const rootId = root ? JSON.parse(root) : null;
    const genderArr = ["male", "female"]
    const relatiomArr = [["Cын", "Дочь"], [["Муж", "Жена"], ["Брат", "Сестра"]], ["Отец", "Мать"], ["Дедушка", "Бабушка"], ["Прадедушка", "Прабабушка"]]
    function getRelationForSign(node: any) {
        if (node.id === rootId) {
            return "Я"
        }
        let gender;
        if (node.gender === genderArr[0]) {
            gender = 0;
        } else {
            gender = 1;
        }
        if (node.generation === 1) {
            if (node.spouses[0].id === rootId) {
                return relatiomArr[node.generation][0][gender];
            }
            if (node.siblings[0].id === rootId) {
                return relatiomArr[node.generation][1][gender]
            }
        }
        if (node.generation > 4) {
            if (gender === 0) {
                return `Пра(${node.generation - 3})дедушка`
            }
            if (gender === 1) {
                return `Пра(${node.generation - 3})бабушка`
            }
        }
        return relatiomArr[node.generation][gender]
    }

    return (
        <List className={styles.Liststyle}>
            <span style={{ ...SpanStyle }}>Родственники</span>
            {treeArray.slice(1).map((item: any) => (
                <RelativeCard  {...item} relation={getRelationForSign(item)} />
            ))}
        </List>
    )
}

