import React from 'react';
import { sxSelect, sxMenuItem, TypographyCustom } from './style';
import { MenuItem, Select, Typography } from '@mui/material';
import { ArrowIcon } from 'icons/ArrowIcon';

interface ISelectProps {
  value: string;
  item: string[];
  handleChange: (val: any) => void;
}

export const CompSelect = ({ item, value, handleChange }: ISelectProps) => (
  <Select
    value={value}
    displayEmpty
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    onChange={(event) => {
      handleChange(event);
    }}
    inputProps={{ sxMenuItem }}
    style={sxSelect}
  >
    <MenuItem disabled value="">
      <TypographyCustom variant='button'>Пол</TypographyCustom>
    </MenuItem>
    {item.map((item) => (
      <MenuItem value={item}>{item}</MenuItem>
    ))}
  </Select>
);
