import { useEffect, useState } from 'react';
import { SwipeableDrawerMobile, IconSideBarThreeCircle, PopupButton, Puller, RelativeAgeText, RelativeCardDiv, RelativeDateText, RelativeFioText, RelativeImageDiv, RelativeImageInfoWrapper, RelativeStyledImage, SideBarPopup, RelativeDiv } from './style';
import { Modal } from '@mui/material';
import { DeletePersonModal } from '../NewSideBar/DeletePerson';
import { BoxStyled } from 'pages/Profile/ProfileInfo/style';
import { calculateAgeFromDateInput } from '../NewSideBar/calculate_age';
import { useSelector } from 'react-redux';
import { getIsOpenCardInfo, getTreeCurrentCardData } from 'store/slices/tree/selectors';
import { useAppDispatch } from 'store/hooks';
import { treeActions } from 'store/slices/tree';
import { IImage } from 'pages/Profile/types/types';
import { Biography } from '../NewSideBar/Biography';
import { BlockBioRelatives } from '../NewSideBar/Relatives';
import { Gallerey } from '../NewSideBar/SideBarGallerey/GallereyIndex';
import { MiniProfileDiv } from '../NewSideBar/style';
import { AddPhotoDrawer } from './Drawer';
import { customAxios } from 'network/axiosConfig';


interface IGallereyProps {
    isopen: boolean;
    setisopen: (val: boolean) => void
    imagesArr: IImage[];
    isRerend: boolean;
    setRerend: (val: boolean) => void;
    setImagesArr: any;
}


export const SwipableDrawer = ({ setImagesArr, isopen, setisopen, imagesArr, isRerend, setRerend }: IGallereyProps) => {
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const isOpenCardInfo = useSelector(getIsOpenCardInfo);
    const CardInfo = useSelector(getTreeCurrentCardData);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isAddPhoto, setisAddPhoto] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState<string | ArrayBuffer | null>(null);
    const dispatch = useAppDispatch();
    const handleClickDeletePerson = () => {
        setIsOpenModal(true)
        dispatch(treeActions.setIsOpenCardInfo(false))
    }
    useEffect(() => {
        customAxios
            .get(`images?id=${CardInfo.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then(async (resp) => {
                const data = await resp.data;
                setImagesArr(data);
            });
    }, [isRerend])
    return (
        <SwipeableDrawerMobile
            anchor="bottom"
            open={isopen}
            onClose={() => { setisopen(false); setIsOpenEdit(false) }}
            onOpen={() => setisopen(true)}
        >
            <RelativeCardDiv isOpen={isOpenCardInfo} >
                <RelativeImageInfoWrapper>
                    {!mediaQueryCheck && <Puller />}
                    <RelativeFioText>
                        {CardInfo.name} {CardInfo.fatherName} {CardInfo.surname}
                        {!mediaQueryCheck && <IconSideBarThreeCircle onClick={() => { setIsOpenPopup(!isOpenPopup) }} />}
                        {isOpenPopup && (
                            <SideBarPopup>
                                <PopupButton onClick={(e) => { setIsOpenEdit(true); setTimeout(() => { setIsOpenPopup(false) }, 50) }}>Редактировать профиль</PopupButton>
                                <PopupButton style={{ display: CardInfo.id === localStorage.getItem("idRoot") ? 'none' : 'flex' }} onClick={() => { handleClickDeletePerson(); setTimeout(() => { setIsOpenPopup(false) }, 50) }}>Удалить</PopupButton>
                            </SideBarPopup>
                        )}
                    </RelativeFioText>
                    <RelativeDateText>
                        {CardInfo.relationName}
                    </RelativeDateText>
                    <RelativeDateText>
                        {(CardInfo.dateOfBirth === null || CardInfo.dateOfBirth === undefined) ? '' :
                            <RelativeAgeText> {calculateAgeFromDateInput(CardInfo.dateOfBirth)}</RelativeAgeText>}
                        {CardInfo.isDead ? `${CardInfo.dateOfBirth}- ${CardInfo.dateOfDeath}` : CardInfo.dateOfBirth}
                    </RelativeDateText>
                </RelativeImageInfoWrapper>
            </RelativeCardDiv>
            <Modal open={isOpenModal}
                onClose={() => { setIsOpenModal(false) }}
            >
                <BoxStyled>
                    {isOpenModal && <DeletePersonModal setRerend={setRerend} setIsOpenModal={setIsOpenModal} />}
                </BoxStyled>
            </Modal>
            <RelativeDiv isOpenEdit={isOpenEdit}>
                <MiniProfileDiv isOpen={isOpenCardInfo}>
                    <Gallerey
                        setSelectedPhoto={setSelectedPhoto}
                        imagesArr={imagesArr}
                        setCurrentSlide={setCurrentSlide}
                        setisAddPhoto={setisAddPhoto}
                    />
                    <Biography />
                    <BlockBioRelatives />
                </MiniProfileDiv>
            </RelativeDiv>
            {isAddPhoto && <AddPhotoDrawer imagesArr={imagesArr} setRerend={setRerend} isopen={isAddPhoto} setisopen={setisAddPhoto} />}
        </SwipeableDrawerMobile>
    );
};