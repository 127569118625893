import React, { useEffect, useState, useRef } from 'react';
import {
    Button, IconButton, SvgIcon, Checkbox, FormControl, TextField,
    Select,
    MenuItem,
    InputLabel,
    SelectChangeEvent
} from '@mui/material';

export const SelectCustom = () => {
    const [gender, setGender] = useState('');
    const handleChangeGender = (event: SelectChangeEvent) => {
        setGender(event.target.value);
    }
    return (
        <FormControl style={{ minWidth: "140px", height: "29px !important", padding: '0px', }} >
            <InputLabel >Пол</InputLabel>
            <Select
                className='none'
                onChange={(event) => { handleChangeGender(event) }}
                inputProps={{ style: { padding: "0px, 3px", fontFamily: 'Golos Text', fontWeight: '500', lineHeight: '21px' } }}
                style={{ fontFamily: 'Golos Text', fontWeight: '500', lineHeight: '21px', padding: "0px" }}
                value={gender}
            >
                <MenuItem value='' style={{ fontFamily: 'Golos Text', fontWeight: '500', lineHeight: '21px' }}>Не выбран</MenuItem>
                <MenuItem value='male' style={{ fontFamily: 'Golos Text', fontWeight: '500', lineHeight: '21px' }}>Мужской</MenuItem>
                <MenuItem value='female' style={{ fontFamily: 'Golos Text', fontWeight: '500', lineHeight: '21px' }}>Женский</MenuItem>
            </Select>
        </FormControl>
    )
}