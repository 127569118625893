import { createSvgIcon } from "@mui/material";

export const ArrowNextPhotoSwiperButton = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5402 11.2895L9.88023 5.63955C9.78726 5.54582 9.67666 5.47143 9.5548 5.42066C9.43294 5.36989 9.30224 5.34375 9.17023 5.34375C9.03821 5.34375 8.90751 5.36989 8.78565 5.42066C8.66379 5.47143 8.55319 5.54582 8.46023 5.63955C8.27398 5.82691 8.16943 6.08036 8.16943 6.34455C8.16943 6.60873 8.27398 6.86219 8.46023 7.04955L13.4102 12.0495L8.46023 16.9995C8.27398 17.1869 8.16943 17.4404 8.16943 17.7045C8.16943 17.9687 8.27398 18.2222 8.46023 18.4095C8.55284 18.504 8.66329 18.5792 8.78516 18.6307C8.90704 18.6822 9.03792 18.709 9.17023 18.7095C9.30253 18.709 9.43342 18.6822 9.55529 18.6307C9.67717 18.5792 9.78761 18.504 9.88023 18.4095L15.5402 12.7595C15.6417 12.6659 15.7227 12.5523 15.7781 12.4258C15.8336 12.2993 15.8622 12.1627 15.8622 12.0245C15.8622 11.8864 15.8336 11.7498 15.7781 11.6233C15.7227 11.4968 15.6417 11.3832 15.5402 11.2895Z" fill="white" />
    </svg>,

    "ArrowNextPhotoSwiperButton"
)

