import { baseApi } from "./api";

const photoApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        getAvatar: builder.query({
            query: (id) => `/avatars?id=${id}`,
            providesTags: ['avatar'],
        }),

        postAvatar: builder.mutation({
            query: ({ id, body }) => ({
                url: `/relative/${id}`,
                prepareHeaders: (headers: any) => {
                    headers.set("Content-Type", "multipart/form-data")
                },

                method: 'PUT',
                body
            }),

        }),
        deleteImage: builder.mutation({
            query: ({ imagePath }) => ({
                url: `/images?imagesPath=${imagePath}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['avatar']
        }),
        deleteAvatar: builder.mutation({
            query: ({ id, avatarPath }) => ({
                url: `/avatar?avatarPath=${avatarPath}&id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['avatar']
        })
    })
})

export const { useGetAvatarQuery, useDeleteAvatarMutation, useDeleteImageMutation, usePostAvatarMutation } = photoApi
