export const Boxstyle = {
    letterSpacing: 0,
    borderRightWidth: 1,
    borderRightColor: 'rgb(224,224,224)',
    borderRightStyle: 'solid',
    height: '100%'
}
export const BigDivstyle = {
    width: '295px',
    alignItems: 'flex-start',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '4px',
    margin: '0px 0px 16px 0px'
}
export const IconButtonStyle = {
    height: '60px',
    width: '60px',
    gridArea: 'image',
    padding: 0,
    left: 24,
    top: 14,
    cursor: 'auto',
}
export const InitialsDiv = {
    marginLeft: '40px',
    marginTop: '20px'

}
export const CloseIconStyle = {
    marginTop: 19,
    right: 20,
    padding: 0
}
export const BigIconButtonStyle = {
    fontSize: '12px',
    lineHeight: '15.6px',
    fontWeight: 500,
    marginTop: 6
}
export const LastIconButtonStyle = {
    fontSize: '12px',
    lineHeight: '15.6px',
    fontWeight: 500,
    marginTop: 6,
    marginLeft: 4
}
