import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { StyledLogoImage, StyledToolbar, StyledMenuContainer, StyledAppBar } from "./styles";
import { THeaderProps } from "./types";
import { HeaderMenuItem } from "./HeaderMenuItem";
import { HeaderBurgerMenu } from "./HeaderBurgerMenu";
import imgLogo from 'images/Logo.png';


export const Header: React.FC<THeaderProps> = ({ menu = [] }) => {
  const theme = useTheme();
  const needUseBurgerMenu = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledAppBar position='fixed'>
      <StyledToolbar disableGutters>
        <StyledLogoImage src={imgLogo} alt='logo' />

        <StyledMenuContainer>
          {
            needUseBurgerMenu
              ? <HeaderBurgerMenu menu={menu} />
              : menu.map(item => <HeaderMenuItem item={item} key={item.label} />)
          }
        </StyledMenuContainer>
      </StyledToolbar>
    </StyledAppBar>
  );
};