import { BioInfoDiv, BioWrapperDiv, MainTextP, TextBioInfSpan, WrapperImg, CharactersCounterWrapper, MaxCharacter, Counter, TextWrapperDiv } from './style';
import { TextField } from '@mui/material';
import icon from './careerpng.png'
import iconDesktop from './careerDesktop.png'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetBiographyQuery, usePutBiographyMutation } from 'store/storeQuery/bioApi';
import { useDebounce } from 'hooks/debounce';


export const BlockBioCareer = () => {
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const params = useParams()
    const { data: biography, isSuccess } = useGetBiographyQuery(params.id)
    const [putBiography] = usePutBiographyMutation()
    const [education, setEducation] = useState("");
    const [career, setCareer] = useState("");
    const [hobbies, setHobbies] = useState("");
    const putBioDebounce = useDebounce(putBiography, 500)

    const handlerChangeEducation = (e: string) => {
        setEducation(e);
    };
    const handlerChangeCareer = (e: string) => {
        setCareer(e);
    };
    const handlerChangeHobbies = (e: string) => {
        setHobbies(e);
    };

    useEffect(() => {
        const data = {
            education,
            career,
            hobbies
        }
        if (params.id)
            putBioDebounce({ body: data, id: params.id })
    }, [education, career, hobbies]);

    useEffect(() => {
        if (biography && biography.education) {
            setEducation(biography.education);
        }
        if (biography && biography.career) {
            setCareer(biography.career);
        }
        if (biography && biography.hobbies) {
            setHobbies(biography.hobbies);
        }
    }, [isSuccess]);

    return (
        <BioWrapperDiv>
            <WrapperImg src={!mediaQueryCheck ? icon : iconDesktop} alt='err' />

            <TextWrapperDiv>

                <MainTextP>Карьера</MainTextP>
                <BioInfoDiv>
                    <TextBioInfSpan>Образование</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangeEducation(e.target.value)} value={education} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={education.length >= 230}>{education.length}/</Counter>
                        <MaxCharacter warning={education.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
                <BioInfoDiv>
                    <TextBioInfSpan>Карьера</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangeCareer(e.target.value)} value={career} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={career.length >= 230}>{career.length}/</Counter>
                        <MaxCharacter warning={career.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
                <BioInfoDiv>
                    <TextBioInfSpan>Хобби</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangeHobbies(e.target.value)} value={hobbies} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={hobbies.length >= 230}>{hobbies.length}/</Counter>
                        <MaxCharacter warning={hobbies.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
            </TextWrapperDiv>

        </BioWrapperDiv>
    )
}