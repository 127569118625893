import { useEffect, useMemo, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { BiographyBlockDiv, BiographyName, BiographyComp, TextFieldWrapper, CharactersCounterWrapper, Counter, MaxCharacter, WarningPopup, WarningAction } from './style';
import { IBiography } from '../types/types';
import { ArrowBackIcon } from 'icons/ArrowBackIcon';
import { ArrowNextIcon } from 'icons/ArrowNextIcon';
import { useParams } from 'react-router-dom';
import { useGetBiographyQuery, usePutBiographyMutation } from 'store/storeQuery/bioApi';
import { useDebounce } from 'hooks/debounce';
import { WarningSignGreen } from 'icons/WarningSignGreen';

export const Biography = () => {
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const [text, setText] = useState('');
    const [showFullInput, setShowFullInput] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false)
    const params = useParams()
    const { data: biography, isSuccess } = useGetBiographyQuery(params.id)
    const [putBiography] = usePutBiographyMutation()
    const putBioDebounce = useDebounce(putBiography, 200)

    const handleSetText = (e: string) => {
        setText(e);
    };

    useEffect(() => {
        if (biography && biography.aboutPerson)
            setText(biography.aboutPerson)

    }, [biography?.aboutPerson])

    useEffect(() => {
        const data = {
            aboutPerson: text
        }
        if (params.id)
            putBioDebounce({ body: data, id: params.id })
    }, [text]);


    const handleSetShowInput = () => {
        setShowFullInput(true);
        setIsBlur(false);
    };
    const handleSetHideInput = () => {
        setShowFullInput(false);
    };

    const handleSendBiography = (e: any) => { // тут отправка данных на бэк
        setIsBlur(true)
        setShowFullInput(false);
    };



    return (

        <BiographyComp>
            <BiographyBlockDiv>
                <BiographyName>Биография</BiographyName>
                {mediaQueryCheck && (
                    <WarningSignGreen onClick={() => { setIsOpenPopup(true); setTimeout(() => { setIsOpenPopup(false) }, 3000) }} viewBox='0 0 20 21' />

                )}
                {isOpenPopup && (
                    <WarningPopup>
                        <WarningAction>
                            <WarningSignGreen viewBox="0 0 20 21" /> Заполните биографию и информацию о себе, нажав на поле.
                        </WarningAction>
                    </WarningPopup>
                )}
            </BiographyBlockDiv>

            <TextFieldWrapper>
                <TextField inputProps={{ maxLength: 500 }} onBlur={(e) => handleSendBiography(e)} value={showFullInput || text?.length < 250 ? text : `${text?.slice(0, 250)}...`} onClick={handleSetShowInput} onChange={(e) => handleSetText(e.target.value)} style={{ width: '100%' }} placeholder='Не заполнено' type='empty' multiline />
                {text?.length > 250 &&
                    <Button
                        style={{ color: '#149C84', padding: '8px 0px 0px 0px' }}
                        variant='text'
                        color='secondary'
                        onClick={showFullInput && !(isBlur) ? handleSetHideInput : handleSetShowInput}
                        startIcon={showFullInput ? <ArrowBackIcon style={{ width: '24px', height: '24px' }} /> : ''}
                        endIcon={showFullInput ? '' : <ArrowNextIcon style={{ width: '24px', height: '24px' }} />}
                    >
                        {showFullInput ? "Свернуть" : "Читать далее"}
                    </Button>
                }
                <CharactersCounterWrapper isbutton={text?.length > 250}>
                    <Counter warning={text?.length >= 480}>{text?.length}/</Counter>
                    <MaxCharacter warning={text?.length >= 480}>500</MaxCharacter>
                </CharactersCounterWrapper>
            </TextFieldWrapper>
        </BiographyComp>
    );
};