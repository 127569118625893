import { PaletteOptions } from "@mui/material";

/*
  Базовые знания о mui палитре
  https://mui.com/material-ui/customization/palette/
  https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette/

  Цвета подписаны комментариями так, как они называются
  в UI Kit, логически распределены так, чтобы отображаться
  (хотя бы относительно) правильно в mui компонентах сразу, 
  без дополнительный стилизации

  Вынесены только цвета с базового блока цветовой палитры
  С вот этого: https://www.figma.com/file/7aRBlwpomiv6ZmOif8HDKv/Family-tree?type=design&node-id=313-1155&mode=dev

  Остальные добавите сами по мере того, как будете встречать их в работе
  Цвета на макете дублируются (то есть цвет с одним и тем же HEX имеет несколько названий)
  Это усложнит работу и по этому поводу нужно тыкать дизайнеров
  Для вашего комфорта так быть не должно

  Если нужно добавить кастомный цвет, которого нет в
  стандартном наборе mui (то есть с названием, которого там нет),
  вписать новое поле в декларации типов ниже, по аналогии с 
  закомментированными примерами
*/

declare module '@mui/material/styles' {
  // interface Palette {
  //   customColor: string,
  //   customGroup: {
  //     custom1: string,
  //     custom2: string,
  //   },
  // }

  // interface PaletteOptions {
  //   customColor?: string,
  //   customGroup?: {
  //     custom1?: string,
  //     custom2?: string,
  //   },
  // }
}

export const palette: PaletteOptions = {
  // Акцентный
  primary: {
    light: '#DCF0ED', // Зелёный/Светлый
    main: '#149C84', // Зелёный/Обычный
    dark: '#0E6E5D', // Зелёный/Тёмный
  },

  // Ошибки
  error: {
    light: '#FDCBCB', // Красный/Светлый (вообще не подписан был, но по идее так должен называться)
    main: '#FA5353', // Красный/Яркий
    dark: '#AF3A3A', // Красный/Тёмный
  },

  // Градация серого
  common: {
    white: '#FFFFFF', // Серый/белый
    black: '#1A1A1E', // Серый/Чёрный
  },
  background: {
    default: '#F0F0F0', // Серый/BG
    paper: '#FFFFFF', // Серый/белый (дублирует common.white для фонов по умолчанию),
  },
  text: {
    primary: '#1A1A1E', // Серый/Чёрный (дублирует common.black для шрифтов по умолчанию),
    secondary: '#7F7F7F', // Серый/Темный
    disabled: '#E5E5E5', // Серый/Светлый
  },

  // Акцентный синий
  info: {
    main: '#4590CF',
    light: '#0093F1',
    dark: '#005E98',
  }
}