import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material"
import { HelperTextDiv, HelperTextSpan, PhantomDiv, TextSpan } from "./style"
import { IRelative } from "../types/types";
import { WarningSignError } from "icons/WarningSignError";
import { baseApi, } from "store/storeQuery/api";
import { useParams } from "react-router-dom";
import { useGetRelativeQuery, usePutRelativeMutation } from 'store/storeQuery/relativeApi';
import { useDebounce } from "hooks/debounce";

interface ICardData {
    id: string;
    photo: string;
    name: string;
    surname: string;
    fatherName: string;
    relationName: string;
    dateOfBirth: string;
    dateOfDeath: string;
    gender: string;
    isDead: boolean;
}

interface IFioInputProps {
    setFioClicked: (val: boolean) => void,
}

export const FioInput = ({ setFioClicked }: IFioInputProps) => {
    const [errorText, setErrorText] = useState<boolean[]>([false, false, false]);
    const [putRelative, { isError }] = usePutRelativeMutation()
    const putRelDebounce = useDebounce(putRelative, 500)

    const params = useParams()
    const { data: relativeInfo, isLoading } = useGetRelativeQuery(params.id)
    console.log(relativeInfo)
    const [fio, setFio] = useState([relativeInfo?.lastName, relativeInfo?.firstName, relativeInfo?.middleName])
    // повесить onBlur с отправкой данных на каждое поле ввода


    useEffect(() => {
        const data = {
            userLastName: fio[0],
            userName: fio[1],
            userMiddleName: fio[2]
        }
        if (params.id)
            putRelDebounce({ body: data, id: params.id })
    }, [fio]);

    const validateFio = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ind: number,
    ) => {

        const fioArr = [...fio]
        fioArr[ind] = e.target.value;
        console.log(fioArr);

        setFio(fioArr);

    }

    const handleCloseFio = () => {
        setFioClicked(false);
    };
    const handleSendFIO = () => {
        setErrorText([false, false, false]);
    }
    return (
        <>
            <HelperTextDiv >
                <TextField onBlur={handleSendFIO} value={fio[0]} error={errorText[0]} onChange={(e) => { validateFio(e, 0) }} style={{ zIndex: "3", width: "100%" }} type='none' placeholder='Фамилия' />

                {errorText[0] && <HelperTextSpan> <WarningSignError style={{ width: "16px", height: "16px" }} viewBox="0 0 16 16" /> <TextSpan>Недопустимые символы</TextSpan></HelperTextSpan>}
            </HelperTextDiv>

            <HelperTextDiv >
                <TextField onBlur={handleSendFIO} value={fio[1]} error={errorText[1]} onChange={(e) => { validateFio(e, 1) }} style={{ zIndex: "3", width: "100%" }} type='none' placeholder='Имя' />
                {errorText[1] && <HelperTextSpan> <WarningSignError style={{ width: "16px", height: "16px" }} viewBox="0 0 16 16" /> <TextSpan>Недопустимые символы</TextSpan></HelperTextSpan>}
            </HelperTextDiv>
            <HelperTextDiv >
                <TextField onBlur={handleSendFIO} value={fio[2]} error={errorText[2]} onChange={(e) => { validateFio(e, 2) }} style={{ zIndex: "3", width: "100%" }} type='none' placeholder='Отчество' />
                {errorText[2] && <HelperTextSpan> <WarningSignError style={{ width: "16px", height: "16px" }} viewBox="0 0 16 16" /> <TextSpan>Недопустимые символы</TextSpan></HelperTextSpan>}
            </HelperTextDiv>
            <PhantomDiv onClick={handleCloseFio} />
        </>
    )
}


