import styled from 'styled-components'

interface IPropsWrapperImg {
    urlImg: string,

}

interface IWarning {
    warning: boolean
}

export const BioWrapperDiv = styled.div`
    @media(max-width: 844px){

        display:flex;
        gap:16px;
        flex-direction:column;
    }
    @media(min-width: 844px){
        background-color: #FFFFFF;
        border-radius: 16px;
        padding:24px;
        display:flex;
        gap:24px;
        flex-direction:row;
    }
`
export const WrapperImg = styled.img`
    border-radius:8px;
    object-fit:fill;
    align-items: center;
    justify-content: center;
    @media(min-width: 844px){
        max-height:246px;

    }

`
export const MainTextP = styled.p`

    font-family: Gilroy,sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin:0px;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom:16px;

`
export const BioInfoDiv = styled.div`
    position: relative;
    display:flex;
    gap:8px;
    flex-direction:column;

`
export const TextBioInfSpan = styled.span`
    //styleName: Gilroy Text/Подзаголовок 4;
    font-family: Gilroy,sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #686868;
`
export const CharactersCounterWrapper = styled.div`
    position: absolute;
    display: flex;
    right: 0;
    bottom: -20px;
`
export const Counter = styled.span<IWarning>`
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: ${props => props.warning ? '#FA5353' : '#1A1A1E'}
`
export const MaxCharacter = styled.span<IWarning>`
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: ${props => props.warning ? '#FA5353' : '#1A1A1E'}
`
export const TextWrapperDiv = styled.div`
    display:flex;
    flex-direction: column;
    gap:16px;
    @media(min-width: 844px){
        width:100vw;
    }
`