import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialNodes = [
  {
    id: '1',
    type: 'treeCard',
    position: { x: 0, y: 0 },
    data: {
      lastName: 'Иванов',
      firstName: 'Первый',
      midleName: 'Иванович',
      relation: 'Я',
      dateOfBirth: '11.11.2011',
      dateOfDeath: '12.12.2012',
      isDead: false,
      autoLayout: true,
      rank: 0,
      pos: 'l',
      globalPos: 'l',
      label: '1',
    },
    parents: [],
    partners: ['2'],
    children: ['3', '4', '5'],
  },
  {
    id: '2',
    type: 'treeCard',
    position: { x: 400, y: 0 },
    data: {
      lastName: 'Иванов',
      firstName: 'Второй',
      midleName: 'Иванович',
      relation: 'Я',
      dateOfBirth: '11.11.2011',
      dateOfDeath: '12.12.2012',
      isDead: false,
      rank: 0,
      pos: 'r',
      globalPos: 'r',
    },
    parents: [],
    partners: ['1'],
    children: ['3', '4', '5'],
  },
  {
    id: '3',
    type: 'treeCard',
    position: { x: 200, y: 600 },
    data: {
      lastName: 'Иванов',
      firstName: 'Иван',
      midleName: 'Иванович',
      relation: 'Я',
      dateOfBirth: '11.11.2011',
      dateOfDeath: '12.12.2012',
      isDead: false,
      autoLayout: true,
      rank: -1,
      pos: 'd',
      globalPos: 'm',
      label: '3',
    },
    parents: ['1', '2', 'z1'],
    partners: [],
    children: [],
  },
  {
    id: '4',
    type: 'treeCard',
    position: { x: -150, y: 600 },
    data: {
      lastName: 'Иванов',
      firstName: 'Иван',
      midleName: 'Иванович',
      relation: 'Я',
      dateOfBirth: '11.11.2011',
      dateOfDeath: '12.12.2012',
      isDead: false,
      autoLayout: true,
      rank: -1,
      pos: 'd',
      globalPos: 'l',
      label: '4',
    },
    parents: ['1', '2', 'z1'],
    partners: [],
    children: [],
  },
  {
    id: '5',
    type: 'treeCard',
    position: { x: 550, y: 600 },
    data: {
      lastName: 'Иванов',
      firstName: 'Иван',
      midleName: 'Иванович',
      relation: 'Я',
      dateOfBirth: '11.11.2011',
      dateOfDeath: '12.12.2012',
      isDead: false,
      autoLayout: true,
      rank: -1,
      pos: 'd',
      globalPos: 'r',
      label: '5',
    },
    parents: ['1', '2', 'z1'],
    partners: [],
    children: [],
  },
  {
    id: 'z0',
    type: 'zeroNode',
    position: { x: 305, y: 139 },
    data: {
      lastName: 'Иванов',
      firstName: 'Иван',
      midleName: 'Иванович',
      relation: 'Я',
      dateOfBirth: '11.11.2011',
      dateOfDeath: '12.12.2012',
      isDead: false,
      autoLayout: true,
      rank: 0,
      pos: 'z',
      globalPos: 'm',
      label: '5',
    },
    parents: [],
    partners: [],
    children: [],
  },
];

const initialEdges = [
  {
    type: 'step',
    sourceHandle: 'SourceBottom',
    targetHandle: 'TarTop',
    id: 'ez1-2',
    source: 'z0',
    target: '4',
  },
  {
    type: 'step',
    sourceHandle: 'SourceBottom',
    targetHandle: 'TarTop',
    id: 'ez1-5',
    source: 'z0',
    target: '5',
  },
  {
    type: 'step',
    sourceHandle: 'SourceBottom',
    targetHandle: 'TarTop',
    id: 'ez1-3',
    source: 'z0',
    target: '3',
  },
  {
    type: 'smoothstep',
    sourceHandle: 'SourceRight',
    targetHandle: 'TarLeft',
    id: 'e1-2',
    source: '1',
    target: '2',
  },

  /*
  {type:'smoothstep',sourceHandle:'SourceRight',targetHandle:'TarTop', id: 'e1-3', source: '1', target: '3' },
  {type:'smoothstep',sourceHandle:'SourceLeft',targetHandle:'TarTop', id: 'e2-3', source: '2', target: '3' },
  {type:'smoothstep',sourceHandle:'SourceRight',targetHandle:'TarTop', id: 'e1-4', source: '1', target: '4' },
  {type:'smoothstep',sourceHandle:'SourceRight',targetHandle:'TarTop', id: 'e1-5', source: '1', target: '5' },
  {type:'smoothstep',sourceHandle:'SourceLeft',targetHandle:'TarTop', id: 'e2-4', source: '2', target: '4' },
  {type:'smoothstep',sourceHandle:'SourceLeft',targetHandle:'TarTop', id: 'e2-5', source: '2', target: '5' },
  */
];
const treeSlice = createSlice({
  name: 'tree',
  initialState: {
    nodes: initialNodes,
    edges: initialEdges,
  },
  reducers: {
    setNodes(state, action) {
      console.log(action.payload);
      state.nodes = action.payload;
    },
    setEdges(state, action) {
      console.log(action.payload);
      state.edges = action.payload;
    },
    changeNodeParents(state, action) {
      console.log(action.payload);
      state.nodes.forEach((el) => {
        if (el.id === action.payload.id) {
          action.payload.par.forEach((elem) => el.parents.push(elem));
          console.log(el.parents);
        }
      });
      console.log(state.nodes[action.payload.id]);
    },
    addNodes(state, action) {
      state.nodes.push(action.payload.newNode);
    },
    addEdges(state, action) {
      state.edges.push(action.payload.newEdge);
    },
    changeNodePositionX(state, action) {
      state.nodes[action.payload.ind].position.x = action.payload.positionX;
    },
    getNode(state, action) {
      const cop = state.nodes.slice();
      console.log(state.nodes);
      return { ...state, parents: state.nodes[1] };
    },
  },
});

export const {
  setNodes,
  setEdges,
  changeNodeParents,
  addNodes,
  addEdges,
  changeNodePositionX,
  getNode,
} = treeSlice.actions;

export const treeReducer = treeSlice.reducer;
