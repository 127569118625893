import { createSvgIcon } from "@mui/material";

export const DefaultPhotoIcon = createSvgIcon(
    <svg width="217" height="225" viewBox="0 0 217 225" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M91.0395 181.754C141.319 181.754 182.079 141.067 182.079 90.8771C182.079 40.6871 141.319 0 91.0395 0C40.7598 0 0 40.6871 0 90.8771C0 141.067 40.7598 181.754 91.0395 181.754Z" fill="#E0E0E0"/>
        <path d="M47.5373 129.01C46.4539 129.01 45.4202 128.794 44.4756 128.404L43.8956 129.809C41.5654 128.847 39.6913 126.994 38.7144 124.687L40.1142 124.094C39.7166 123.155 39.4967 122.127 39.4967 121.05V117.278H37.9766V109.734H39.4967V102.19H37.9766V94.6461H39.4967V87.1023H37.9766V79.5584H39.4967V72.0145H37.9766V64.4706H39.4967V60.6987C39.4967 59.6214 39.7166 58.5935 40.1142 57.6543L38.7144 57.0616C39.6913 54.7546 41.5654 52.9014 43.8956 51.9395L44.4756 53.3446C45.4202 52.9547 46.4539 52.7389 47.5373 52.7389H51.2124V51.2188H58.5625V52.7389H65.9126V51.2188H73.2628V52.7389H80.6129V51.2188H87.963V52.7389H95.3131V51.2188H102.663V52.7389H110.013V51.2188H117.363V52.7389H124.714V51.2188H132.064V52.7389H135.739C136.822 52.7389 137.856 52.9547 138.8 53.3446L139.38 51.9395C141.711 52.9014 143.585 54.7546 144.562 57.0616L143.162 57.6543C143.56 58.5935 143.779 59.6214 143.779 60.6987V64.479H145.3V72.0395H143.779V79.6001H145.3V87.1607H143.779V94.7212H145.3V102.282H143.779V109.842H145.3V117.403H143.779V121.183C143.779 122.27 143.564 123.294 143.175 124.22L144.577 124.809C143.612 127.106 141.746 128.925 139.376 129.853L138.822 128.438C137.885 128.805 136.847 129.01 135.739 129.01H132.064V130.53H124.714V129.01H117.363V130.53H110.013V129.01H102.663V130.53H95.3131V129.01H87.963V130.53H80.6129V129.01H73.2628V130.53H65.9126V129.01H58.5625V130.53H51.2124V129.01H47.5373Z" fill="#F2F2F2" stroke="#CBCBCB" strokeWidth="3.04025" strokeMiterlimit="10" strokeDasharray="7.62 7.62"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M82.5568 99.0132C82.5568 98.0328 83.3892 97.2379 84.416 97.2379H100.511C101.538 97.2379 102.37 98.0328 102.37 99.0132C102.37 99.9937 101.538 100.789 100.511 100.789H84.416C83.3892 100.789 82.5568 99.9937 82.5568 99.0132Z" fill="#AAAAAA"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M92.3954 78.1715C93.4222 78.1715 94.2546 78.9664 94.2546 79.9469V92.4431C94.2546 93.4236 93.4222 94.2184 92.3954 94.2184C91.3685 94.2184 90.5361 93.4236 90.5361 92.4431V79.9469C90.5361 78.9664 91.3685 78.1715 92.3954 78.1715Z" fill="#AAAAAA"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M91.0828 78.1747C91.8042 77.4876 92.9704 77.4829 93.6978 78.1642L101.813 85.765C102.546 86.4515 102.557 87.5756 101.838 88.2756C101.119 88.9755 99.9421 88.9864 99.2091 88.2998L92.4069 81.9287L85.729 88.2893C85.002 88.9818 83.8248 88.9804 83.0997 88.2862C82.3745 87.592 82.376 86.4679 83.103 85.7754L91.0828 78.1747Z" fill="#AAAAAA"/>
        <g filter="url(#filter0_d_9791_108711)">
        <path d="M174.271 182.955H91.2053C86.2593 182.955 82.2012 178.904 82.2012 173.967V116.621C82.2012 111.684 86.2593 107.633 91.2053 107.633H174.271C179.217 107.633 183.275 111.684 183.275 116.621V173.967C183.275 178.904 179.217 182.955 174.271 182.955Z" fill="url(#paint0_linear_9791_108711)"/>
        </g>
        <path d="M182.237 163.11C183.731 163.11 184.926 164.306 184.926 165.801V183.142C184.926 187.029 181.938 190.019 178.054 190.019H166.701C165.506 190.019 164.311 189.72 163.713 188.823C161.024 186.431 155.347 181.647 154.152 179.853C153.853 179.255 153.555 178.956 153.555 178.358V174.471C153.555 172.08 155.347 170.286 157.738 170.286H159.231V177.76H160.128V162.213C160.128 160.718 161.323 159.522 162.817 159.522C164.311 159.522 165.506 160.718 165.506 162.213V165.203H166.402V161.316C166.402 159.821 167.597 158.625 169.091 158.625C170.585 158.625 171.78 159.821 171.78 161.316V165.203H172.975V163.11C172.975 161.615 174.17 160.419 175.664 160.419C177.158 160.419 178.353 161.615 178.353 163.11V165.203H179.548C179.847 164.007 180.743 163.11 182.237 163.11Z" fill="white" stroke="#C0C0C0" strokeWidth="3.04025" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M139.522 141.431C141.273 141.431 142.692 140.014 142.692 138.266C142.692 136.518 141.273 135.102 139.522 135.102C137.771 135.102 136.352 136.518 136.352 138.266C136.352 140.014 137.771 141.431 139.522 141.431Z" fill="#BBBBBB"/>
        <path d="M140.03 158.269H116.442C114.92 158.269 114.032 156.496 114.92 155.357L126.714 138.9C127.475 137.887 128.997 137.887 129.757 138.9L141.552 155.357C142.439 156.623 141.552 158.269 140.03 158.269Z" fill="#BBBBBB"/>
        <path opacity="0.7" d="M148.146 158.015H138.127C136.605 158.015 135.844 156.369 136.605 155.23L141.551 148.267C142.312 147.255 143.707 147.255 144.468 148.267L149.414 155.23C150.555 156.369 149.667 158.015 148.146 158.015Z" fill="#999999"/>
        <defs>
        <filter id="filter0_d_9791_108711" x="48.7584" y="90.9114" width="167.96" height="142.208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="16.7214"/>
        <feGaussianBlur stdDeviation="16.7214"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.183333 0 0 0 0 0.183333 0 0 0 0 0.183333 0 0 0 0.27 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9791_108711"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9791_108711" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_9791_108711" x1="132.705" y1="105.891" x2="132.705" y2="183.767" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF"/>
        <stop offset="0.9964" stopColor="#E6E6E6"/>
        </linearGradient>
        </defs>
    </svg>,
  "DefaultPhotoIcon"
)