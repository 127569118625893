

export const CheckAccess = (nodes: any, id: any) => {
    console.log(nodes);
    let bool = false
    nodes.forEach((el: any) => {
        if (String(el.id) === String(id)) {
            bool = true
        }
    })
    return bool
}