import { IconButton, SvgIcon } from "@mui/material"
import styles from './style.module.css';
import { useSelector } from "react-redux";
import { getTreeCurrentCardData, getIsOpenCardInfo } from "store/slices/tree/selectors";
import { baseURL } from "network/axiosConfig";
import { BigDivstyle, IconButtonStyle, InitialsDiv } from "./style";


interface ICardData {
    id: string;
    avatar: string;
    name: string;
    surname: string;
    middleName: string;
    relation: string;
    dateOfBirth: string;
    dateOfDeath: string;
    isDead: boolean;
}
export const RelativeCard = ({ id, avatar, name, surname, middleName, relation, dateOfBirth, dateOfDeath, isDead }: ICardData) => {
    const CardInfo = useSelector(getTreeCurrentCardData);
    const isOpenCardInfo = useSelector(getIsOpenCardInfo);
    return (
        <div>
            <div style={{ display: 'flex', ...BigDivstyle }}>
                <IconButton className="add" color="secondary" style={{ ...IconButtonStyle }}>
                    {avatar ? <img src={`${baseURL}avatar?avatarPath=${avatar}&id=${id}`} className={styles.sideBarImageArea} style={{ margin: '0px' }} alt="test" /> : <SvgIcon style={{ width: '26px', height: '26px' }} viewBox="0 0 53 53" />}
                </IconButton>
                <div style={{ direction: 'ltr', ...InitialsDiv }}>
                    <p className={styles.textDrawer}>{surname} {name} {middleName}</p>
                    {isDead ? (
                        <p className={styles.relationAreaForDead}>{relation}, {dateOfBirth === "__.__.____" ? "Неизвестно" : dateOfBirth} - {dateOfDeath}</p>
                    ) : (
                        <p className={styles.birthArea}>{relation}, {dateOfBirth === "__.__.____" ? "Неизвестно" : dateOfBirth}</p>
                    )}
                </div>
            </div>
        </div>
    )
}