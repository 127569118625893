import { Button, Typography } from '@mui/material'
import { AddPartnerIcon } from 'icons/AddPatnerIcon'
import styled from 'styled-components'

interface IPartnerIconProps {
    pos: string
}
interface IRelInfo {
    isRoot: boolean
}
export const CardWrapperDiv = styled.div`
    width: 168px;
    height:236px;
    background: #FFFFFF;
    border-radius: 24px;
    padding:16px;
    display: flex;
    flex-direction:column;
    gap:16px;
    box-shadow: 0px 6px 8px 0px #20212814;



    &:hover{
        box-shadow: 0px 4px 32px 0px rgba(6, 165, 127, 0.12);
    }

`
export const ImageInfoWrapper = styled.div`
    height:130px;
    display:flex;
    flex-direction:column;
    gap: 10px;
`
export const ImageDiv = styled.div`
    display: flex;
    justify-content:center;
`
export const StyledImage = styled.img`
    height: 100px;
    display: flex;
    justify-content:center;
    width:100px;
    background-color:black;
    border-radius:8px;
    border: 1px solid #7F7F7F
`
export const InfoRelAndAgeBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 52px;
`
export const RelationText = styled(Typography) <IRelInfo>`
    background-color: #149C84;
    border-radius:4px;
    padding: 2px 8px;
    color: white;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    && {
        background-color: ${props => props.isRoot ? "#0093F1" : '#149C84'};

    }
    
`
export const AgeText = styled(Typography) <IRelInfo>`
    padding: 2px 8px;
    border-radius:4px;
    background-color: #149C844D;
    color: #0E6E5D;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    && {
        background-color: ${props => props.isRoot ? "#0093F133" : '#149C844D'};

    }
`
export const FioAndDateWrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:6px;
`
export const FioText = styled(Typography)`

    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap; 
`
export const DateText = styled(Typography)`
    display: flex;
    justify-content: center;
    color: #7F7F7F;

`
export const ButtonWrapper = styled('div')(({ theme }) => ({
    zIndex: 0,
    width: "100%",
    '& button': {
        width: '100%',
        borderRadius: "8px",

        '&:nth-child(1)': {
            color: "#1A1A1E",
            border: `1px solid #149C84`,
        }
    },
}))
export const ButtonPartnerDiv = styled.div`
	width: 0;
	height: 0;
	
    position: absolute;
    border-radius: 0px 25px 25px 0px   ;
    left: 200px;
    bottom:100px;
`
export const AddPartnerStyled = styled(AddPartnerIcon) <IPartnerIconProps>`
    position: absolute;
    left: ${props => props.pos === 'l' ? '200px' : "-34px"};
    bottom:100px;
    width: 36px;
    height:79px;
    transform: ${props => props.pos === 'r' && 'rotate(180deg)'};
`
export const ButtonDelete = styled.button`
    background-color: #FA5353;
    width:20px;
    height:20px;
    padding: 1px;
    border-radius: 4px;
    border:none;
    position: absolute;
    left: 84%;
    bottom: 88%


`
