import { useEffect, useState } from 'react';
import {
  Select,
  SelectWrapper,
  NewOption,
  NewSelect,
  SelectIcon,
  PhantomDiv,
  SelectPopup,
} from './style';
import { OpenIconSelect } from 'icons/OpenIconSelect';

interface ISelectProps {
  currValue: string;
  popupWidth: string;
  item: string[];
  handleChange: (val: any) => void;
}

export const InputCustomSelect = ({ item, currValue = "", popupWidth, handleChange }: ISelectProps) => {
  const [nowValue, setNowValue] = useState(currValue || 'Не заполнено');
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useEffect(() => {
    setNowValue(currValue || 'Не заполнено');
  }, [currValue]);

  const handleChangeSelect = (el: string) => {
    setNowValue(el);
    setIsSelectOpen(false);
  };

  const handleToggleSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  return (
    <Select>
      <SelectWrapper onClick={handleToggleSelect}>
        <NewSelect nowValue={nowValue} color="gray">
          {nowValue}
        </NewSelect>
        <SelectIcon
          style={{
            transform: isSelectOpen ? 'rotate(180deg)' : '',
            pointerEvents: 'none',
            transition: 'transform 0.1s ease-in-out',
          }}>
          <OpenIconSelect />
        </SelectIcon>
        {isSelectOpen && <PhantomDiv className="zxc" onClick={handleToggleSelect} />}
      </SelectWrapper>
      {isSelectOpen && (
        <SelectPopup style={{ width: popupWidth }}>
          {item.map((el) => (
            <NewOption
              className="xc"
              onClick={() => {
                handleChangeSelect(el);
                handleChange(el);
              }}
              isSelected={el === nowValue}>
              {el}
            </NewOption>
          ))}
        </SelectPopup>
      )}
    </Select>
  );
};
