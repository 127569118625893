import { useEffect, useState } from 'react';
import { BioInfoDiv, BioWrapperDiv, MainTextP, TextBioInfSpan, WrapperImg, CharactersCounterWrapper, Counter, MaxCharacter, TextWrapperDiv } from './style';
import icon from './Geograph.png'
import iconDesktop from './GeoDesktop.png'
import { TextField } from '@mui/material';
import { CustomInputAutocomplete } from 'components/AutoCompleteInput';
import { useGetBiographyQuery, usePutBiographyMutation } from 'store/storeQuery/bioApi';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'hooks/debounce';



export const BlockBioGeograph = () => {
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const [languages, setLanguages] = useState("");
    const params = useParams()
    const { data: biography } = useGetBiographyQuery(params.id)
    const [putBiography] = usePutBiographyMutation()
    const putBioDebounce = useDebounce(putBiography, 500)

    const handlerChangeLanguage = (e: string) => {
        setLanguages(e);
    };

    useEffect(() => {
        if (biography && biography.languages)
            setLanguages(biography.languages)

    }, [biography?.aboutPerson])

    useEffect(() => {
        const data = {
            languages
        }
        if (params.id)
            putBioDebounce({ body: data, id: params.id })
    }, [languages]);


    return (
        <BioWrapperDiv>
            <WrapperImg src={!mediaQueryCheck ? icon : iconDesktop} alt='err' />
            <TextWrapperDiv>
                <MainTextP>География</MainTextP>
                <BioInfoDiv>
                    <TextBioInfSpan>Место проживания</TextBioInfSpan>
                    <CustomInputAutocomplete type='residence' />
                </BioInfoDiv>
                <BioInfoDiv>
                    <TextBioInfSpan>Место рождения</TextBioInfSpan>
                    <CustomInputAutocomplete type='placeOfBirth' />
                </BioInfoDiv>
                <BioInfoDiv>
                    <TextBioInfSpan>Языки</TextBioInfSpan>
                    <TextField inputProps={{ maxLength: 250 }} onChange={(e) => handlerChangeLanguage(e.target.value)} value={languages} multiline placeholder='Не заполнено' type='empty' />
                    <CharactersCounterWrapper>
                        <Counter warning={languages.length >= 230}>{languages.length}/</Counter>
                        <MaxCharacter warning={languages.length >= 230}>250</MaxCharacter>
                    </CharactersCounterWrapper>
                </BioInfoDiv>
            </TextWrapperDiv>
        </BioWrapperDiv>
    )
}