import { Style } from '@mui/icons-material'
import { TextField } from '@mui/material'
import styled from 'styled-components'

interface IIsButton {
    isbutton: boolean
}

interface IWarning {
    warning: boolean
}

export const CloseButton = styled.button`
    all:unset;
    background-color: black;
    width: 28px;
    height: 28px;
    border-radius: 6px;
    box-shadow:none;
    gap: 10px;

`
export const CheckBoxDiv = styled.div`
@media(max-width: 844px){
    display:flex;
    gap:12px;
    margin: 16px 16px 16px 0px;
    align-items:center;

}
`
export const TextCheckBoxSpan = styled.span`
@media(max-width: 844px){
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 

}
`
export const TextBioSpan = styled.div`
@media(max-width: 844px){
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
}
`
export const BiographyName = styled.h2`
    @media(max-width: 844px){
        margin:0;
        font-family: Gilroy, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
    @media(min-width: 844px){
        margin:0;
        font-family: Gilroy, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
`
export const BiographyBlockDiv = styled.div`
    padding-bottom: 16px;
@media(max-width: 844px){
    display: flex;
    justify-content: space-between;
    align-items:center;
}
@media(min-width: 844px){
    display: flex;
    flex-direction:row;
    position:relative;
    justify-content: flex-start;
    gap:12px;
    align-items:center;
}
`
export const BiographyComp = styled.div`
`
export const TextFieldWrapper = styled.div`
    position: relative;
`
export const CharactersCounterWrapper = styled.div<IIsButton>`
    position: absolute;
    display: flex;
    right: 0;
    bottom: ${props => props.isbutton ? '0' : '-20px'}

`
export const Counter = styled.span<IWarning>`
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: ${props => props.warning ? '#FA5353' : '#1A1A1E'}
`
export const MaxCharacter = styled.span<IWarning>`
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: ${props => props.warning ? '#FA5353' : '#1A1A1E'}
`
export const WarningPopup = styled.div`
    z-index:3;
    padding: 8px 0px;
    position: absolute;
    left:170px;
    top:-15px;
    width: 289px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`
export const WarningAction = styled.p`
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #1A1A1E;
    display: flex;
    flex-direction: row;
    gap:12px;
 
`