import { PaletteOptions, TypographyVariantsOptions, createTheme } from '@mui/material';
import { palette } from './palette';
import { typography } from './typography';
import { formComponents } from './components/formComponents';
import { ungroupedComponents } from './components/ungroupedComponents';

/*
    Декомпозировал элементы темы на отдельные файлы
    По мере необходимости будете создавать еще и подключать
    так, как показано здесь

    Иконки также живут теперь в отдельных файлах
*/

export const theme = createTheme({
  palette: palette as PaletteOptions,
  typography: typography as TypographyVariantsOptions,
  components: {
    ...formComponents,
    ...ungroupedComponents,
  },
});
