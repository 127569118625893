/* eslint-disable import/no-extraneous-dependencies */
import {
    ContentWrapper,
    FioSpan,
    AgeDiv,
    SettingDiv,
    AgeContentWrapper,
    CircleDiv,
    LeftBlockDiv,
    RightBlockDiv,
    NumberAgeDiv,
    PageWrapper,
    BackDiv,
    BackTextSpan,
    WrapperUpMenuDiv,
    BorderDiv,
    WarningAction,
    WarningPopup,
    DesktopImg,
    BreadcrumbsWrapperDiv,
    DefaultPhotoIconCust,
    DefaultPhotoIconWrapper,
    DefaultPhotoIconDesktop,
    BoxStyled,
} from './style';
import { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { useNavigate, useParams } from 'react-router-dom';
import { customAxios } from '../../network/axiosConfig';
import { ViewImageGallerey } from './Gallerey';
import { ProfileInfo } from './ProfileInfo';
import { CropComponent } from './CropComponent';
import { Gallerey } from './Gallerey/GallereyIndex';
import { Biography } from './Biography';
import { IImage } from './types/types';
import { BlockBioGeograph } from './BlockBioGeograph';
import { BlockBioCareer } from './BlockBioCareer';
import { BlockBioAbout } from './BlockBioAbout';
import { FioInput } from './FioInput';
import { GetAge } from './getAge';
import { UnknownAgeButt } from './UnknownAgeButt';
import { ArrowBackLeftIcon } from 'icons/ArrowBackLeftIcon';
import { WarningMarkIcon } from 'icons/WarningMarkIcon';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { SettingButPhoto } from './SettingButPhoto';

import { BlockBioRelatives } from './BlockBioRelatives';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { useGetRelativeQuery } from 'store/storeQuery/relativeApi';
import { Box, Modal } from '@mui/material';
import { DefaultPhotoIcon } from 'icons/DefaultPhotoIcon';
import { IconTree } from 'icons/IconTree';
import { IconPeople } from 'icons/IconPeople';
import { NotAccessModal } from './NotAccessModal';
import { useGetTreeQuery } from 'store/storeQuery/treeApi';
import { CheckAccess } from 'utils/checkAcces';
import { useGetAvatarQuery } from 'store/storeQuery/photo';
import { IconProfile } from 'icons/IconProfile';
import { ProfileModal } from 'components/ProfileModal';


export const Profile = () => {
    if (!localStorage.getItem('accessToken')) {
        window.location.href = '/';
    }



    // вынести в стор
    const navigate = useNavigate();
    const [isRerend, setRerend] = useState(false);
    const [isOpenGallery, setOpenGallery] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [fioClicked, setFioClicked] = useState<boolean>(false);
    let mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const params = useParams()

    const [imagesArr, setImagesArr] = useState<IImage[]>([]);
    const [avatar, setAvatar] = useState<{ path: string, name: string }>({ path: '', name: '' });
    const [currentSlide, setCurrentSlide] = useState(-1);
    const [selectedPhoto, setSelectedPhoto] = useState<string | ArrayBuffer | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenAddPhotoModal, setIsOpenAddPhotoModal] = useState(false)
    const [isOpenSet, setIsOpenSet] = useState(false)
    const { data: getAvatar, isSuccess: isSuccessAvatar, isError: isErrorAvatar, isFetching } = useGetAvatarQuery(params.id);
    const landingLinks = [
        { label: 'Древо', link: '/tree', icon: <IconTree /> },
        { label: 'Профиль', link: `setting`, icon: <IconProfile />, data: () => setIsOpenSet(true) },


    ];
    // вынести запросы лучше в ртк
    const { data: relInfo } = useGetRelativeQuery(params.id)
    const { data: nodes, isSuccess } = useGetTreeQuery(localStorage.getItem('idRoot'))

    useEffect(() => {
        if (isSuccess)
            if (CheckAccess(nodes, params.id) === false) {
                setIsOpenModal(true)
            }

    }, [isSuccess])



    useEffect(() => {

        customAxios
            .get(`images?id=${params.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then(async (resp) => {
                const data = await resp.data;
                setImagesArr(data);
            });
        mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;

        setRerend(false);
    }, [isRerend]);

    useEffect(() => {
        if (!isErrorAvatar && getAvatar) {
            setAvatar(getAvatar);
        }
    }, [isSuccessAvatar, getAvatar])

    const handleOpenInfo = () => {
        if (isOpenInfo) {
            setIsOpenInfo(false);
        } else {
            setIsOpenInfo(true);
        }
    };
    const handleClickFio = () => {
        setFioClicked(true);
    };

    const handleClickBack = () => {
        navigate(-1);
    };

    const crumbs = {
        urlPart: '/tree',
        label: 'Древо',
    };

    return (

        <PageWrapper>
            {(selectedPhoto || isError) && (
                <CropComponent
                    setIsError={setIsError}
                    isError={isError}
                    setIsOpen={setIsOpen}
                    ismobile={false}
                    setRerend={setRerend}
                    setSelectedPhoto={setSelectedPhoto}
                    selectedPhoto={selectedPhoto}
                    imagesArr={imagesArr}
                    setAvatar={setAvatar} />
            )}
            {isOpenGallery && (
                <ViewImageGallerey
                    currentSlide={currentSlide}
                    setCurrentSlide={setCurrentSlide}
                    imagesArr={imagesArr}
                    setOpenGallery={setOpenGallery}
                    setRerend={setRerend}
                    cardId={relInfo.id}
                    avatar={avatar}
                    setavatar={setAvatar} />
            )}
            {mediaQueryCheck && (
                <BreadcrumbsWrapperDiv>
                    <Breadcrumbs
                        crumbs={[crumbs]}
                        currentLabel={`${relInfo?.lastName} ${relInfo?.firstName} ${relInfo?.middleName}`} />
                </BreadcrumbsWrapperDiv>
            )}

            <Header menu={landingLinks} />


            <ContentWrapper className='check' urlImg={avatar.path} isopen={isOpenGallery} isselected={selectedPhoto}>
                {!mediaQueryCheck && (
                    <WrapperUpMenuDiv>
                        <BackDiv onClick={handleClickBack}>
                            <ArrowBackLeftIcon viewBox="0 0 16 16" /> <BackTextSpan>Назад</BackTextSpan>
                        </BackDiv>
                        <SettingButPhoto
                            imagesArr={imagesArr}
                            currentSlide={currentSlide}
                            setRerend={setRerend} />
                    </WrapperUpMenuDiv>
                )}

                {!mediaQueryCheck && !avatar.path && <DefaultPhotoIconCust onClick={() => setIsOpen(true)} viewBox="0 0 217 225" />}
                <LeftBlockDiv className='left'>
                    {mediaQueryCheck && avatar.path && <DesktopImg src={avatar.path} />}
                    {mediaQueryCheck && !avatar.path &&
                        <DefaultPhotoIconWrapper onClick={() => setIsOpenAddPhotoModal(true)}>
                            <DefaultPhotoIconDesktop viewBox="0 0 217 225" />
                        </DefaultPhotoIconWrapper>
                    }

                    {!fioClicked ? (
                        <FioSpan onClick={handleClickFio}>{`${relInfo ? relInfo.lastName : ''} ${relInfo ? relInfo.firstName : ''} ${relInfo ? relInfo.middleName : ''}`}</FioSpan>
                    ) : (
                        <div>
                            <FioInput
                                setFioClicked={setFioClicked} />
                        </div>
                    )}
                    {isOpenInfo || mediaQueryCheck ? (
                        <ProfileInfo
                            age={GetAge(relInfo && relInfo.dateOfBirth ? relInfo.dateOfBirth : '',
                                relInfo && relInfo?.dateOfDeath ? relInfo?.dateOfDeath : ''
                            )}
                            handleOpenInfo={handleOpenInfo}
                            relationName="test" // заменить на поле RelationName когда добавиться на бэке
                            setIsOpenPopup={setIsOpenPopup} />
                    ) : (
                        <AgeDiv>
                            <AgeContentWrapper>
                                Возраст{' '}
                                {GetAge(
                                    relInfo && relInfo.dateOfBirth ? relInfo.dateOfBirth : '',
                                    relInfo && relInfo?.dateOfDeath ? relInfo?.dateOfDeath : ''
                                ) === 'Неизвестно лет' ? (
                                    <UnknownAgeButt setIsOpenPopup={setIsOpenPopup} />
                                ) : (
                                    <NumberAgeDiv>
                                        {GetAge(
                                            relInfo && relInfo.dateOfBirth ? relInfo.dateOfBirth : '',
                                            relInfo && relInfo?.dateOfDeath ? relInfo?.dateOfDeath : ''
                                        )}
                                    </NumberAgeDiv>
                                )}
                            </AgeContentWrapper>
                            <SettingDiv onClick={handleOpenInfo}>
                                <CircleDiv />
                                <CircleDiv />
                                <CircleDiv />
                            </SettingDiv>
                            <BorderDiv />
                        </AgeDiv>
                    )}
                </LeftBlockDiv>
                {isOpenPopup ? (
                    <WarningPopup>
                        <WarningAction>
                            <WarningMarkIcon viewBox="0 0 24 24" /> Заполните дату рождения для отображения
                            возраста
                        </WarningAction>
                    </WarningPopup>
                ) : (
                    ''
                )}
                {isOpenPopup ? (
                    <WarningPopup>
                        <WarningAction>
                            <WarningMarkIcon viewBox="0 0 24 24" /> Заполните дату рождения для отображения
                            возраста
                        </WarningAction>
                    </WarningPopup>
                ) : (
                    ''
                )}
                <RightBlockDiv isopeninfo={isOpenInfo}>
                    <Gallerey
                        setIsError={setIsError}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setRerend={setRerend}
                        setSelectedPhoto={setSelectedPhoto}
                        imagesArr={imagesArr}
                        setCurrentSlide={setCurrentSlide}
                        setOpenGallery={setOpenGallery}
                        isOpenAddPhotoModal={isOpenAddPhotoModal}
                        setIsOpenAddPhotoModal={setIsOpenAddPhotoModal}
                        setAvatar={setAvatar}
                    />
                    <Biography />
                    <BlockBioGeograph />
                    <BlockBioCareer />
                    <BlockBioAbout />
                    <BlockBioRelatives />
                </RightBlockDiv>
            </ContentWrapper><Footer />
            <Modal open={isOpenModal}
            >
                <Box>
                    <NotAccessModal setIsOpenModal={setIsOpenModal} />
                </Box>
            </Modal>
            <Modal
                open={isOpenSet}
                onClose={() => setIsOpenSet(false)}
            >
                <Box>
                    <ProfileModal isOpen={isOpenSet} setIsOpen={setIsOpenSet} />
                </Box>
            </Modal>
        </PageWrapper >

    );
};