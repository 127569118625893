import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tree from 'components/Tree';
import TreeReactFlow from 'components/TreeReactFlow';

const TreePage = () => {
    if (!localStorage.getItem('accessToken')) {

        window.location.href = '/'
    }
    return <Tree />;
}

export default TreePage;
