import { baseApi } from "./api";

const biographyApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        getBiography: builder.query({
            query: (id) => `/biography/${id}`,
            providesTags: ['biography'],

        }),
        putBiography: builder.mutation({
            query: ({ body, id }) => ({
                url: `/biography/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['biography']
        })


    })
})
export const { useGetBiographyQuery, usePutBiographyMutation } = biographyApi