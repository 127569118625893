import { useSelector, useDispatch } from 'react-redux';
import { addNodes, addEdges, changeNodePositionX, getNode, setNodes } from '../../../store/slices/tree/slice';
import { getRectOfNodes } from 'reactflow';

export const AutoLayoutParent = ( nodes) => {
  let res = []
 

  const searchRootProblem = (nodeOne, nodeTwo) => {
      const firstChild = nodes.find(el=>el.id === String(nodeOne.data.children[0].id))
      const secondChild = nodes.find(el=>el.id === String(nodeTwo.data.children[0].id))
      let result 
      if(firstChild.id!==secondChild.id){
        result = searchRootProblem(firstChild, secondChild)
      } 
      else {
        result = firstChild
      }

      return result

  }


  const search = (sib)=>{
    const res= nodes.filter((el)=> el.id===String(sib.id))
     return res[0]
   }
  const getArrNode = (rootId,rootPos,baseRank, res=[]) =>{
    const node = nodes.find((el)=>el.id===String(rootId)  )
    let paramX
    let paramXZeroNode
 
    if(node && node.type!=="addCard" && node.type!=="zeroAddNode" && node.type!=="phantomNode")
    {
      if(rootPos==='l')
      {
        if(Number(node.data.rank)===Number(baseRank+1))
        {
          paramX = -70
          paramXZeroNode = -35
        }
        else
        {
          paramX = -150
          paramXZeroNode=-75
        }
      }
      else
        if(Number(node.data.rank)===Number(baseRank+1))
        {
          paramX = 70
          paramXZeroNode = 40
        }
        else
        {
          paramX = 150
          paramXZeroNode=150
        }
    
      
      res.push({id: Number(rootId), positionX: node.position.x+paramX, positionY: node.position.y})

      console.log(node);
      const zeroNode = nodes.find((el)=> el.id===String(node.data.zeroNodes[0]?.id))
      if(zeroNode)
        res.push({id: Number(node.data.zeroNodes[0].id), positionX: zeroNode.position.x+paramXZeroNode, positionY: zeroNode.position.y })
      node.data.parents.forEach((el)=>getArrNode(el.id,rootPos,baseRank, res))
      for(let i=0; i<node.data.siblings.length; i+=1 ){
        const sib=search(node.data.siblings[i])
        res.push({id: Number(sib.id), positionX: sib.position.x+paramX, positionY: sib.position.y})
      }

    }
   return res
  }
  const changeAllPosition = (res=[]) => {
    nodes.forEach((el)=>{
      if((el.type==="treeCard" || el.type==="zeroNode"|| el.type==="phantomNode")&& el.data.rank>0)
        res.push({id:el.id, positionX: (el.data.globalPos==='l'? el.position.x-100: el.position.x+100)})
    })
    return res
  }



  const findMainChild = (node) => {
    let resChild=0
    if(node.data.isSibling===true){
      const temp=[]
      for(let i=0; i<node.data.siblings.length; i+=1 ){
        temp.push( search(node.data.siblings[i]))
      }
      resChild = temp.find((el)=>el.data.isSibling===false)
    }
    else{
      resChild = node
    } 
    
    console.log(resChild)
    return resChild
  }
  if(nodes.length>4)
  nodes.forEach((i) => {
    nodes.forEach((j) => {
      if (
        Math.abs(i.position.y - j.position.y) <= 220 &&
        Math.abs(i.position.x - j.position.x) <= 260 &&
        i.type!=="zeroNode" &&
        j.type!=="zeroNode" &&
        i.data.rank >= 1 &&
        j.data.rank >= 1 &&
        i.id !== j.id &&
        i.type !== 'addSpousesCard' &&
        j.type !== 'addSpousesCard' &&
        i.type !== 'zeroAddNode' &&
        j.type !== 'zeroAddNode' &&
        i.id.slice(0,-2)!==j.id.slice(0,-2)
      ) {
        console.log(`id i elem: ${i.id} \nid j elem: ${j.id}`);
        
        if(i.data.globalPos === j.data.globalPos){
          const first = findMainChild(i)
          const second = findMainChild(j)
         
          const rootNode = searchRootProblem(first, second);
          console.log(rootNode)
          const parentArr = nodes.filter((element)=> (element.id===String(rootNode.data.parents[0].id) || element.id===String(rootNode.data.parents[1].id)) && element.data.pos===rootNode.data.pos)
          console.log(parentArr)
          const arrNodes = getArrNode(parentArr[0].id,rootNode.data.pos, rootNode.data.rank)
          res = arrNodes.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id && t.positionX === value.positionX
          ))
        )
          console.log(res);
          
        } else{
           res=changeAllPosition()

         }
      }
    });
  });
  return res
}
