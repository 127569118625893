import { Typography } from '@mui/material';
import { StyledNotifyContainer, StyledNotifyButton } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAcceptCookie } from 'store/slices/settings/selectors';
import { acceptCookie } from 'store/slices/settings/slice';

export const CookieNotify = () => {
  const dispatch = useDispatch();
  const isAcceptCookie = useSelector(getIsAcceptCookie);
  
  const acceptHandle = () => dispatch(acceptCookie());

  if (isAcceptCookie) return null;

  return (
    <StyledNotifyContainer>
      <Typography variant='body1'>
        Мы используем cookies, чтобы вам удобнее было пользоваться сайтом. Оставаясь на сайте, вы соглашаетесь с этой технологией
      </Typography>
      <StyledNotifyButton onClick={acceptHandle}>
        <Typography variant='button'>Согласиться</Typography>
      </StyledNotifyButton>
    </StyledNotifyContainer>
);
};
