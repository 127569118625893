import { IconButton, InputAdornment, TextField } from "@mui/material";
import { HelperTypo, HelperWrapper, PassWrapper, TextFieldWrapper } from "./style";
import { validateEmail, validatePassword } from "components/RegistarionModal/validator/validator";
import React, { useState } from "react";
import { IconCloseEye } from "icons/IconCloseEye";
import { IconOpenEye } from "icons/IconOpenEye";
import { WarningSignErrorGrey } from "icons/WarningSignErrorGrey";


interface IFirstStageProps {
    email: string
    setEmail: (val: string) => void
    pass: string
    setPass: (val: string) => void
    setIsError: (val: boolean) => void
}

export const FirstStage = ({ setIsError, email, setEmail, pass, setPass }: IFirstStageProps) => {
    const [errMail, setErrMail] = useState(false)
    const [errPass, setErrPass] = useState(false)
    const [isPass, setIsPass] = useState(true)
    const [isMarked, setIsMarked] = useState(false)
    const t = 0
    const isCheckErrorEmail = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!validateEmail(e.target.value)) {
            setIsError(true);
            setErrMail(true)
        } else {
            setIsError(false);
            setErrMail(false)

        }
    };
    const isCheckErrorPass = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (!validatePassword(e.target.value)) {
            setIsError(true);
            setErrPass(true)
        } else {
            setIsError(false);
            setErrPass(false)

        }
    };
    const inputActivated = () => {
        setIsMarked(true);
    };

    const inputBlur = () => {
        setIsMarked(false);
    };
    return (<TextFieldWrapper>
        <TextField error={errMail} onChange={(e) => { isCheckErrorEmail(e); setEmail(e.target.value) }} placeholder="Почта" />
        <PassWrapper>
            <TextField type={isPass ? 'password' : 'text'} error={errPass} onChange={(e) => { isCheckErrorPass(e); setPass(e.target.value) }}
                onFocus={() => inputActivated()}
                onBlur={() => inputBlur()}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                                style={{ left: 9 }}
                                aria-label="visible"
                                size="small"
                                onClick={() => {
                                    setIsPass(!isPass);
                                }}>
                                {isPass ? <IconCloseEye /> : <IconOpenEye />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                placeholder="Пароль" />
            {isMarked &&
                <HelperWrapper>
                    <WarningSignErrorGrey viewBox="0 0 16 16" style={{ width: "16px" }} />
                    <HelperTypo variant="caption">От 8 символов, без пробелов, одна заглавная буква</HelperTypo>
                </HelperWrapper>
            }
        </PassWrapper>
    </TextFieldWrapper>
    )
}