import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Ichildren {
    id: number;
}
interface Iparents {
    id: number;
}
interface Isiblings {
    id: number;
}
interface Ispouses {
    id: number;
}
interface ICardData {
    id: string;
    name: string;
    surname: string;
    fatherName: string;
    relationName: string;
    dateOfBirth: string;
    dateOfDeath: string;
    gender: string;
    isDead: boolean;
    children: Ichildren[];
    parents: Iparents[];
    siblings: Isiblings[];
    spouses: Ispouses[];
}


interface ITreeState {
    isOpenCardInfo: boolean;
    isClickedCard: boolean;
    currentCardData: ICardData;
}

const initialState = {
    isOpenCardInfo: false,
    isClickedCard: false,
    currentCardData: {
        id: 'id',
        name: 'name',
        surname: 'surname',
        fatherName: 'fatherName',
        relationName: 'relationName',
        dateOfBirth: 'dateOfBirth',
        dateOfDeath: 'dateOfDeath',
        children: [],
        parents: [],
        siblings: [],
        spouses: [],
        gender: 'gender',
        isDead: false

    },
} as ITreeState;

const treeSlice = createSlice({
    name: 'treeSlice',
    initialState,
    reducers: {
        setIsOpenCardInfo: (state, { payload }: PayloadAction<boolean>) => {
            state.isOpenCardInfo = payload
        },
        setIsClickedCard: (state, { payload }: PayloadAction<boolean>) => {
            state.isClickedCard = payload
        },
        setCurrenCardData: (state, { payload }: PayloadAction<ICardData>) => {
            state.currentCardData = payload
        }
    },
    extraReducers: {},
});


export const treeActions = treeSlice.actions
export const treeCardReducer = treeSlice.reducer;