import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Footer } from "components/Footer";

type TStageProps = {
    isActual: boolean
}
export const RegisterWrapper = styled.div`

@media(max-width: 844px){
    display: flex;
    justify-content:center;
    flex-direction: column;
    gap:32px;
    padding: 118px 16px 0px 16px;
}
@media(min-width: 844px){
    display: flex;
    gap:32px;
    flex-direction: column;
    position: absolute;
    width:400px;
    padding-top: 166px;
    transform: translate(-50%, -50%);
    left:50%;
    top:45%;
}

`
export const TitleWrapper = styled.div`
    display: flex;
    justify-content:center;
    align-items:center;
@media(max-width: 844px){
    flex-direction: column;
    gap:16px;
}
@media(min-width: 844px){
    flex-direction: row;
    gap:48px;
}
`
export const StageWrapper = styled.div`
display: flex;
justify-content:center;
flex-direction: row;
align-items:center;

gap:8px;
`

export const StageContent = styled.div <TStageProps> `
    border-bottom: 1px solid ${props => props.isActual ? "#149C84" : "#DDDDDD"};
    padding-bottom:4px;
    display: flex;
    justify-content: center;
@media(max-width: 844px){
    width:140px;
}
@media(min-width: 844px){
    width:71px;

}

`
export const TextFieldWrapper = styled.div`
    display: flex;
    justify-content:center;
    flex-direction: column;

    gap:16px;
`
export const RegButton = styled(Button)`
 &&{
    width: 100%;
    height:56px;
    &.Mui-disabled{
        background-color:#C5C5C5;
        color:#FFFFFF

    }
 }
`
export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction:column;
    gap:16px;
`
export const TextWrapper = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:center;

`
export const LoginButton = styled.button`

    all: unset;
    cursor: pointer;
    color: #149C84;
`

export const CustomTypography = styled(Typography)`
    &&{
        margin:0px;
    }
`
export const ErrorTypography = styled(Typography)`
    &&{
        margin:0px;
        color: red;
    }
`
export const CustomFooter = styled(Footer)`

    position:absolute;
    max-height:80px;
    bottom:-20;
`