import { Style } from '@mui/icons-material'
import { TextField, makeStyles } from '@mui/material'
import styled from 'styled-components'

interface IIsButton {
    isbutton: boolean
}

interface IWarning {
    warning: boolean
}

export const BioWrapperDiv = styled.div`
    @media(max-width: 844px){
        display:grid;
        grid-template-columns: 1fr;
        gap:16px;
    }
    @media(min-width: 844px){
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }
`
export const GridItem = styled.div`
    @media(max-width: 844px){
       width:100%;
    }
    @media(min-width: 844px){
        width:100%;
    }
`
export const BiographyName = styled.h2`
    @media(max-width: 844px){
        margin:8px 0px 8px 0px;
        font-family: Gilroy, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
    @media(min-width: 844px){
        margin:24px 0px 8px 0px;
        font-family: Gilroy, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }
`
export const RelativeList = styled.div`
    @media(max-width: 844px){

        }
    @media(min-width: 844px){
        position:relative;
        padding:0px 0px 12px 0px;
        }

`

