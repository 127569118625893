import { Typography } from "@mui/material";
import styled from "styled-components";

export const TextFieldWrapper = styled.div`
display: flex;
justify-content:center;
flex-direction: column;

gap:16px;
`
export const PassWrapper = styled.div`
    display:flex;
    gap:8px;
    flex-direction:column;
    justify-content:center;

`
export const HelperWrapper = styled.div`
display:flex;
gap:8px;
flex-direction:row; 
`
export const HelperTypo = styled(Typography)`
    &&{
        padding-top:5px;
        color:#7F7F7F;
    }
`