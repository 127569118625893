import styled from 'styled-components'
import { ErrorAddingPhotoGallery } from 'icons/ErrorAddingPhotoGallery'

interface IIsMobile {
    ismobile: boolean
}

export const CropBackground = styled.div<IIsMobile>`
    position: ${props => props.ismobile ? '' : 'fixed'};
    width: 100%;
    height: 100%;
    background-color: ${props => props.ismobile ? 'rgba(26, 26, 30, 1)' : 'rgba(0, 0, 0, 0.7)'};
    top: ${props => props.ismobile ? '' : '50%'};
    left: ${props => props.ismobile ? '' : '50%'};
    transform: ${props => props.ismobile ? '' : 'translate(-50%, -50%)'};
    z-index: 1200;
`
export const CropWrapper = styled.div<IIsMobile>`
    position: ${props => props.ismobile ? '' : 'absolute'};
    top: ${props => props.ismobile ? '' : '50%'};
    left: ${props => props.ismobile ? '' : '50%'};
    transform: ${props => props.ismobile ? '' : 'translate(-50%, -50%)'};
    max-height: ${props => props.ismobile ? '75vh' : ''};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: ${props => props.ismobile ? '100%' : '573px'};
    height: ${props => props.ismobile ? '100%' : '382px'};
    background-color: ${props => props.ismobile ? '' : 'rgba(64, 64, 64, 1)'};
    gap: 24px;
`
export const CropDesktopTitle = styled.h2`
    margin: 0;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
`
export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
`
export const CropDesktopTopElem = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 69px;
    top: -69px;
    left: 0;
    border-radius: 16px 16px 0px 0px;
    background-color: rgba(255, 255, 255, 1);
` 
export const CropDesktopBottomElem = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 69px;
    bottom: -69px;
    left: 0;
    border-radius: 0px 0px 16px 16px;
    background-color: rgba(255, 255, 255, 1);
` 
export const WrapperClose = styled.div`
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -100px;
    right: -40px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #1A1A1E;
`
export const CircularWrapper = styled.div`
    margin-right: 20px; 
    position: absolute; top: 40%;
    left: 50%; 
    transform: translate(-40%, -50%);
    z-index: 1200;
`
export const ErrorWrapper = styled.div`
    margin-right: 20px;
    position: absolute; 
    top: 40%;
    left: 50%; 
    transform: translate(-40%, -50%);
`
export const ErrorIcon = styled.div`
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: rgba(250, 83, 83, 1);
`
export const ErrorAddingPhoto = styled(ErrorAddingPhotoGallery)`
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`