export const delText = {
    fontFamily: 'Golos Text',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '31px'

}

export const sxBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24px',
    borderRadius: '24px',
    border: 'none',
    outline: 'none',
    p: '24px'
};

export const sxBoxForPreview = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24px',
    borderRadius: '24px',
    border: 'none',
    outline: 'none',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    gridTemplateAreas: '  "image"   "button"  "del"',
    gridTemplateRows: '296px 80px 30px',
};

export const sxBoxForChangeModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24px',
    borderRadius: '24px',
    border: 'none',
    outline: 'none',
    width: '326px',
    height: '472px',
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    gridTemplateAreas: '  "image"  "text"  "button"  "del"',
    gridTemplateRows: '250px auto 60px 30px',
    gap: '24px'
};

export const sxDelBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24px',
    borderRadius: '24px',
    border: 'none',
    outline: 'none',
};