import { ISpouseData, IZeroData } from "components/CurrentNewTree/type"

export const getZeroData = ({ rank, globalPos, pos, xPos, yPos, relationNodes }: IZeroData) => (
    {
        type: 'zeroNode',
        rank,
        globalPosition: globalPos,
        position: 'z',
        positionX: pos === 'l' ? xPos + 280 : xPos - 72,
        positionY: yPos - 45,
        relationNodes: [...relationNodes],
    }
)