/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import { create } from 'pinch-zoom-pan';
import css from './index.module.css';
// create({ element: document.getElementById('root') });

type TProps = {
  min?: number;
  max?: number;
  captureWheel?: boolean;
  className?: string;
  style?: React.CSSProperties;
} & React.PropsWithChildren;

export const ZoomPan = ({ children, min, max, captureWheel, className, style }: TProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    return create({ element, minZoom: min, maxZoom: max, captureWheel });
  }, [min, max, captureWheel]);

  return (
    <div className={`${className} ${css.root}`} ref={ref} style={style}>
      <div className={css.point}>
        <div className={css.canvas}>
          { children }
        </div>
      </div>
    </div>
  );
}