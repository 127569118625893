import { NodeProps } from "reactflow"
import { RootCard } from "../RootCard"
import { NodeData } from "../../type"
import { CardWrapperDiv, PlusDiv } from "./style"
import { Box, Button, Modal, Typography } from "@mui/material"
import { useState } from "react"
import { AddPersonModal } from "./AddPersonModal"
import { AddIconPlus } from "icons/AddIconPlus"
import { ParentIconMale } from "icons/ParentIconMale"
import { ParentIconFemale } from "icons/ParentIconFemale"


export const AddCard = ({ data, xPos, yPos, id }: NodeProps<any>) => (
    <>
        <CardWrapperDiv>
            {data.gender === "male" ? <ParentIconMale viewBox="0 0 74 74" /> : <ParentIconFemale viewBox="0 0 73 74" />}
            <Typography variant="caption2">Добавить {data.gender === "male" ? "отца" : "мать"} </Typography>
            <PlusDiv pos={data.pos}>
                <AddIconPlus style={{ color: "white", height: '10px', width: '10px' }} />
            </PlusDiv>
        </CardWrapperDiv >
        <RootCard isConnectable />

    </>
)

