import styled from "styled-components";

export const SettingButDiv = styled.div`
    height:16px;
    align-items: flex-end;
    margin-right:17px;
`

export const ImagePopup = styled.div`
    z-index:3;
    padding: 8px 0px;
    position: absolute;
    width: 50vw;
    top: 5vh;
    right: 5vw;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`

export const ImageAction = styled.p`
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #7F7F7F;

    &:hover {
        background-color: #DCF0ED;
        color: #1A1A1E;
    }
`

export const PhantomDiv = styled.div`
    width:100vw;
    height: 100vh;
    position: fixed;
    z-index:2;
    top:0;
    left:0;
`