import { AddPartnerIcon } from 'icons/AddPatnerIcon'
import styled from 'styled-components'

interface IPartnerIconProps {
    pos: string
}
export const AddPartnerStyled = styled(AddPartnerIcon) <IPartnerIconProps>`
    position: absolute;
    left: ${props => props.pos === 'l' ? '200px' : "-34px"};
    bottom:1px;
    width: 36px;
    height:79px;
    transform: ${props => props.pos === 'r' && 'rotate(180deg)'};
`
export const DivWrapper = styled.div<IPartnerIconProps>`
width:1px;
height:1px;

`