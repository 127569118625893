
export function header(mediaQueryCheck: boolean) {

    if (mediaQueryCheck) {
        return ({
            height: '40px',
            marginLeft: '30px',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexShrink: '0',
            fontFamily: 'Golos Text',

        })
    }
    return ({
        width: '100%',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: '0',
        fontFamily: 'Golos Text',

    })

}

export function logoTextBlack() {

    return ({
        margin: '0',
        color: 'black',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '130%'

    })

}

export function logoTextGreen() {

    return ({
        margin: '0',
        color: '#149C84',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '130%'

    })

}

export function buttonText() {
    return ({
        margin: '0',
        color: "black",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '130%'
    })
}