import { useDelPersonMutation, useGetTreeTestQuery } from "store/storeQuery/delApi"
import { DelButton, BackButton, ButtonDiv, DeleteWrapper, TextWrapper, LineDiv } from "./style"
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getTreeCurrentCardData } from "store/slices/tree/selectors";
import { CustomTypography } from "pages/Registration/style";
import {
    Typography,
} from '@mui/material';

interface IDelProps {
    setIsOpenModal: (val: boolean) => void
}

export const NotAccessModal = ({ setIsOpenModal }: IDelProps) => {
    const [delPersonAsync] = useDelPersonMutation()
    const CardInfo = useSelector(getTreeCurrentCardData);
    const params = useParams()
    const { data: tree } = useGetTreeTestQuery(params.id)
    const navigate = useNavigate()
    const handleClickDelete = () => {
        if (CardInfo.id !== localStorage.getItem('idRoot')) {
            delPersonAsync({ id: CardInfo.id })
        }
        setIsOpenModal(false)

        navigate('/tree')
    }
    return (
        <DeleteWrapper>
            <LineDiv />
            <TextWrapper>
                Вы зашли в карточку чужого древа, в будущем мы вам разрешим на нее посмотреть, но сейчас вам придется вернуться к древу
            </TextWrapper>
            <ButtonDiv>
                <BackButton variant='contained' onClick={() => { navigate('/tree') }}><Typography variant="button">Вернуться</Typography> </BackButton>

            </ButtonDiv>
        </DeleteWrapper>
    )
}