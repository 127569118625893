import { IEdgeData } from "components/CurrentNewTree/type"

export const getSpousesEdgeData = ({ sourceId, targetId, pos }: IEdgeData) => (
    {
        type: 'smoothstep',
        sourceHandle: pos === 'l' ? 'SourceRight' : 'SourceLeft',
        targetHandle: pos === 'l' ? 'TarLeft' : 'TarRight',
        source: sourceId,
        target: targetId,
    }
)