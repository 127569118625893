import { Style } from '@mui/icons-material'
import { TextField, makeStyles } from '@mui/material'
import styled from 'styled-components'

interface IIsButton {
    isbutton: boolean
}

interface IWarning {
    warning: boolean
}

export const BioRelativeWrapper = styled.div`
@media(min-width:844px){
    position:relative;
    margin:20px 0px 0px 0px;
}
@media(max-width:844px){
    position:relative;
    padding-bottom:20px;
    margin:32px 0px 0px 0px;
}
`

export const BioWrapperDiv = styled.div`
        margin:0px 20px 0px 20px;
        display:grid;
        grid-template-columns: 1fr;
        gap:8px;
    
`
export const GridItem = styled.div`
    @media(max-width: 844px){
       width:100%;
    }
    @media(min-width: 844px){
        width:100%;
    }
`
export const BiographyName = styled.h2`
        margin:0px 23px 0px 23px;
        font-family: Gilroy, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    
`
export const RelativeList = styled.div`
    @media(max-width: 844px){

        }
    @media(min-width: 844px){
        position:relative;
        padding:0px 0px 12px 0px;
        }

`