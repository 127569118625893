/* eslint-disable */
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

export const DateMaskFieldupd = ({ setDate, styles, errorDate, isCheckError, defaultvalue, CoincidenceCheck }) => {

  return (
    <InputMask  style={{ width: '100%', margin: 0, ...styles }} onChange={(e) => {
      setDate(e.target.value);
      isCheckError(e, 3);
      CoincidenceCheck(e, 4);
    }}
      defaultValue={defaultvalue}
      mask="99.99.9999"
      placeholder="Дата рождения"
      error={errorDate}
      margin='dense'
      id='dateOfBirth'
      size="medium"
      variant="outlined"
    >


      {() => <TextField style={{ ...styles }} error={errorDate} placeholder='Дата рождения'  />}

    </InputMask>
  )
}