import { Typography } from "@mui/material";
import { StyledFooterContainer, StyledFooterLink } from "./styles";
import { useSelector } from "react-redux";
import { getIsOpenCardInfo } from "store/slices/tree/selectors";

const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
export const Footer = () => (

  <StyledFooterContainer>
    <Typography variant='subtitle4'>
      Разработано
      <StyledFooterLink href="https://lad-academy.ru/main"> LAD Academy</StyledFooterLink> и
      <StyledFooterLink href="http://www.itmm.unn.ru/"> ИИТММ ННГУ</StyledFooterLink>
    </Typography>
    {mediaQueryCheck ? <Typography variant='subtitle4'>
      Есть вопросы или нашли баг?
      <StyledFooterLink href="mailto:lad-academy@lad24.ru"> Свяжитесь с нами</StyledFooterLink>
    </Typography> : ''}
  </StyledFooterContainer>
);