import { useEffect, useMemo, useState } from 'react';
import { BioRelativeWrapper, BioWrapperDiv, BiographyName, GridItem, RelativeList } from './style';
import { BioRelativeCard } from './RelativeCard'
import { useGetTreeTestQuery } from 'store/storeQuery/delApi';


export const BlockBioRelatives = () => {

    const { data: treeArray, isSuccess, isLoading } = useGetTreeTestQuery(0);
    const [tree, setTree] = useState(treeArray)
    const root = localStorage.getItem("idRoot")
    const rootId = root ? JSON.parse(root) : null;
    const genderArr = ["male", "female"]
    const relatiomArr = [["Cын", "Дочь"], [["Муж", "Жена"], ["Брат", "Сестра"]], ["Отец", "Мать"], ["Дедушка", "Бабушка"], ["Прадедушка", "Прабабушка"]]
    useEffect(() => {
        setTree(treeArray)
    }, [isSuccess, treeArray])
    function getRelationForSign(node: any) {
        if (node.id === rootId) {
            return "Я"
        }
        let gender;
        if (node.data.gender === genderArr[0]) {
            gender = 0;
        } else {
            gender = 1;
        }
        if (node.data.rank === 1) {
            if (node.data.spouses[0]?.id === rootId) {
                return relatiomArr[node.data.rank][0][gender];
            }
            if (node.data.siblings[0]?.id === rootId) {
                return relatiomArr[node.data.rank][1][gender]
            }
        }
        if (node.data.rank > 4) {
            if (gender === 0) {
                return `Пра(${node.data.rank - 3})дедушка`
            }
            if (gender === 1) {
                return `Пра(${node.data.rank - 3})бабушка`
            }
        }
        return relatiomArr[node.data.rank][gender]
    }
    return (
        <BioRelativeWrapper>
            <BiographyName>Близкие родственники</BiographyName>

            <BioWrapperDiv>

                {treeArray === undefined || treeArray === null ? '' : treeArray.slice(1).map((item: any) => (
                    item.type === 'treeCard' &&
                    <GridItem>
                        <BioRelativeCard {...item.data} id={item.id} />
                    </GridItem>
                ))}
            </BioWrapperDiv>

        </BioRelativeWrapper>
    );
};
