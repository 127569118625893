import { Handle, Position } from 'reactflow';

interface IRootCardProps {
  isConnectable: boolean
}
const handleStyle = { left: 10, };
export const RootCard = ({ isConnectable }: IRootCardProps) => {
  const t = 0
  return (
    <>
      <Handle
        type="target"
        style={{ visibility: 'hidden', top: 2 }}
        id="TarTop"
        position={Position.Top}
      />
      <Handle
        type="target"
        style={{ visibility: 'hidden', right: 2 }}
        position={Position.Right}
        id="TarRight"
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="TarLeft"
        style={{ visibility: 'hidden', left: 2 }}
        position={Position.Left}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        id="TarBottom"
        style={{ visibility: 'hidden', bottom: 2 }}
        position={Position.Bottom}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="SourceLeft"
        style={{ visibility: 'hidden', left: 2 }}
        position={Position.Left}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="SourceRight"
        style={{ visibility: 'hidden', right: 2 }}
        position={Position.Right}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="SourceTop"
        style={{ visibility: 'hidden', top: 2 }}
        position={Position.Top}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id="SourceBottom"
        style={{ visibility: 'hidden', bottom: 2 }}
        position={Position.Bottom}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
    </>
  )
}