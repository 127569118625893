import { CustomAutocomplete, CustomInput } from './style';
import { useState, useEffect } from 'react';
import { IBiography } from 'pages/Profile/types/types';
import { useGetBiographyQuery, usePutBiographyMutation } from 'store/storeQuery/bioApi';
import { useParams } from 'react-router-dom';

interface IAutoCompProps {
    options: string[],
    setVal: (val: string) => void,
}


interface Props {
    type: string
}

export const CustomInputAutocomplete = ({ type }: Props) => {
    const [residence, setResidence] = useState("")
    const [placeOfBirth, setPlaceOfBirth] = useState("")
    const [cityArr, setCityArr] = useState<string[]>([])
    const params = useParams()
    const { data: biography } = useGetBiographyQuery(params.id)
    const [putBiography] = usePutBiographyMutation()

    useEffect(() => {
        if (biography && biography.placeOfBirth)
            setPlaceOfBirth(biography.placeOfBirth)

    }, [biography?.placeOfBirth])

    useEffect(() => {
        if (biography && biography.residence)
            setResidence(biography.residence)

    }, [biography?.residence])


    useEffect(() => {
        if (residence || placeOfBirth) {
            console.log("res: ", residence);
            console.log("place: ", placeOfBirth);


            fetch(`https://nominatim.openstreetmap.org/search?city=${type === 'residence' ? residence : placeOfBirth}&format=json&limit=5`)
                .then(response => response.json())
                .then(data => {
                    // Обработка полученных данных
                    console.log(data);
                    const tempArr: any[] | ((prevState: string[]) => string[]) = []

                    data.forEach((el: any) => {
                        tempArr.push(el.display_name)
                    })
                    setCityArr(tempArr)
                })
                .catch(error => {
                    // Обработка ошибок
                    console.error(error);
                });
        }
    }, [residence, placeOfBirth])

    useEffect(() => {
        const debounceTimer = setTimeout(async () => {
            const data = {
                [type]: type === 'residence' ? residence : placeOfBirth
            }

            await putBiography({ id: params.id, body: data });
        }, 500);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [residence, placeOfBirth]);


    const handleChange = (e: any) => {
        if (e) {

            if (type === 'residence') {
                setResidence(e.target.value);
            }
            if (type === 'placeOfBirth') {
                setPlaceOfBirth(e.target.value);
            }
        }
    }

    const handleOptionChange = (event: any, value: any) => {
        if (type === 'residence') {
            setResidence(value);
        }
        if (type === 'placeOfBirth') {
            setPlaceOfBirth(value);
        }
    };
    const handleCloseInput = () => {
        setCityArr([])
        console.log(cityArr)
    }
    return (

        <CustomAutocomplete
            options={cityArr}
            onInputChange={(e) => handleChange(e)}
            onChange={handleOptionChange}
            noOptionsText="Не найдено"
            value={type === 'residence' ? residence : placeOfBirth}
            onBlur={handleCloseInput}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <CustomInput placeholder='Не заполнено' type="text" {...params.inputProps} />
                </div>
            )}
        />

    );
}