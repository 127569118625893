import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import 'swiper/css';
import { GallereyDiv, GallereyImage, GallereyName, GallereyTitle, NextArrowButton, StyledSwiper, PrevArrowButton, Slide } from "./style"
import { AddIconPlus } from 'icons/AddIconPlus';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { ArrowNextPhotoSwiperButton } from 'icons/ArrowNextPhotoSwiperButton';
import { customAxios } from 'network/axiosConfig';
import { useSelector } from 'react-redux';
import { getTreeCurrentCardData } from 'store/slices/tree/selectors';

interface IImage {
    path: string;
    name: string;

}
interface IGallereyProps {
    setSelectedPhoto: (val: string | ArrayBuffer | null) => void;
    imagesArr: IImage[];
    setCurrentSlide: (val: number | ((a: number) => number)) => void;
    setisAddPhoto: (val: boolean) => void;
}
export const Gallerey = ({ setSelectedPhoto, imagesArr, setCurrentSlide, setisAddPhoto }: IGallereyProps) => {

    const fileInputRef = useRef<HTMLInputElement>(null);
    const CardInfo = useSelector(getTreeCurrentCardData);
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;

    const handleClickRef = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleAddPhotos = (event: any) => {

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedPhoto(reader.result);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleOpenGallery = (index: number) => {
        setCurrentSlide(index);
    };

    return (
        <GallereyDiv>
            <GallereyTitle>
                <GallereyName>Фото</GallereyName>

                {mediaQueryCheck && <Button
                    variant="contained"
                    size="small"
                    style={{ height: '28px', padding: '0px', borderRadius: '4px', minWidth: '28px' }}>
                    <input
                        hidden
                        accept="image/*,.png,.jpeg,.jpg"
                        style={{ width: '2px' }}
                        ref={fileInputRef}
                        type="file"
                        onChange={(e) => handleAddPhotos(e)}
                    />
                    <AddIconPlus onClick={handleClickRef} style={{ height: '16px', width: '16px' }} />
                </Button>}

                {!mediaQueryCheck && <Button
                    onClick={() => setisAddPhoto(true)}
                    variant="contained"
                    size="small"
                    style={{ height: '28px', padding: '0px', borderRadius: '4px', minWidth: '28px' }}>
                    <AddIconPlus onClick={handleClickRef} style={{ height: '16px', width: '16px' }} />
                </Button>}

            </GallereyTitle>
            <StyledSwiper
                navigation={{ nextEl: '.next-arrow', prevEl: '.prev-arrow' }}
                modules={[Navigation]}
                slidesPerView='auto'
                spaceBetween={16}
                centeredSlides={false}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper: any) => console.log(swiper)}>
                {imagesArr ? (
                    imagesArr.map((el: IImage, index: number) => (
                        <Slide style={{ flexShrink: 1 }}>
                            <GallereyImage src={`${el.path}`} alt="err" />
                        </Slide>
                    ))
                ) : (
                    <div>errr</div>
                )}
            </StyledSwiper>
            <NextArrowButton className='next-arrow'><ArrowNextPhotoSwiperButton /></NextArrowButton>
            <PrevArrowButton className='prev-arrow'><ArrowNextPhotoSwiperButton /></PrevArrowButton>
        </GallereyDiv>

    )
}