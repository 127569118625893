import { baseApi } from "./api";

const relativeApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getRelative: build.query({
            query: (id) => `/relative/${id}`,
            providesTags: ['relative']
        }),
        putRelative: build.mutation({
            query: ({ body, id }) => ({
                url: `/relative/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['relative']

        }),
    })
})

export const { useGetRelativeQuery, usePutRelativeMutation } = relativeApi