import React, { useEffect, useState, useRef } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Button } from '@mui/material';
import { CropBackground, CropWrapper } from "./style";
import { customAxios } from 'network/axiosConfig';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getTreeCurrentCardData } from 'store/slices/tree/selectors';
import { IImage } from 'pages/Profile/types/types';


interface ICropProps {
    ismobile: boolean;
    setIsOpen: (val: boolean) => void;
    setRerend: (val: boolean) => void;
    setSelectedPhoto: (val: string | ArrayBuffer | null) => void;
    selectedPhoto: string | ArrayBuffer | null;
    imagesArr: IImage[];
    setAvatar: (val: string) => void;
}

export const CropComponentModal = React.forwardRef(({ setIsOpen, imagesArr, ismobile, setRerend, setSelectedPhoto, selectedPhoto, setAvatar }: ICropProps, ref) => {
    const imageRef = useRef<any>(null);
    const elemImageRef = useRef<any>(null);
    const cropRef = useRef<any>(null);
    const CardInfo = useSelector(getTreeCurrentCardData);
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        x: 25,
        y: 25,
        width: 300,
        height: 300,
    });
    const params = useParams()
    const handleCropComplete = () => {
        if (imageRef.current && crop.width && crop.height && CardInfo.id) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context) {
                const image = new Image();

                image.onload = () => {
                    const scaleX = image.naturalWidth / elemImageRef.current.clientWidth;
                    const scaleY = image.naturalHeight / elemImageRef.current.clientHeight;

                    const ctx = canvas.getContext('2d');
                    const pixelRatio = window.devicePixelRatio;
                    canvas.width = crop.width * pixelRatio;
                    canvas.height = crop.height * pixelRatio;

                    if (ctx) {
                        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                        ctx.imageSmoothingQuality = 'high';
                        ctx.drawImage(
                            image,
                            crop.x * scaleX,
                            crop.y * scaleY,
                            crop.width * scaleX,
                            crop.height * scaleY,
                            0,
                            0,
                            crop.width,
                            crop.height,
                        );
                    }

                    canvas.toBlob(async (blob) => {
                        if (blob) {
                            const formData = new FormData();
                            formData.append('id', String(CardInfo.id));
                            formData.append('images', blob);

                            await customAxios.post('images', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                },
                            });

                            if (imagesArr.length === 0) {
                                const Data = {
                                    userID: CardInfo.id,
                                    imagePath: "",
                                };
                                await customAxios
                                    .get(`images?id=${CardInfo.id}`, {
                                        headers: {
                                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                            'Content-Type': 'application/json',
                                        },
                                        withCredentials: true,
                                    })
                                    .then(async (resp) => {
                                        const data = await resp.data;
                                        Data.imagePath = data[0].name
                                    });


                                await customAxios
                                    .put(`avatar`, Data, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                        },

                                    });
                                setRerend(true);
                                setSelectedPhoto(null);
                            }
                            setRerend(true);
                            setSelectedPhoto(null);
                        }
                    }, 'image/jpeg');
                };

                image.src = String(selectedPhoto);
            }
        }
    };

    React.useImperativeHandle(ref, () => ({
        handleCropComplete
    }));

    useEffect(() => {
        function updateCropSize() {
            const cropWidth = Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9;
            const cropHeight = cropWidth;

            const imageWidth = elemImageRef.current.width;
            const imageHeight = elemImageRef.current.height;

            const x = (imageWidth - cropWidth) / 2;
            const y = (imageHeight - cropHeight) / 2;

            setCrop({ unit: 'px', x, y, width: cropWidth, height: cropHeight });
        }

        updateCropSize();

        window.addEventListener('resize', updateCropSize);

        return () => {
            window.removeEventListener('resize', updateCropSize);
        };
    }, []);

    return (
        <CropBackground ismobile={ismobile}>
            <CropWrapper ismobile={ismobile}>
                <ReactCrop
                    ref={cropRef}
                    style={{ maxHeight: '90vh' }}
                    keepSelection
                    aspect={1 / 1}
                    onComplete={(crop) => {
                        imageRef.current = crop;
                    }}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    locked>
                    <img ref={elemImageRef} src={String(selectedPhoto)} alt="error" onLoad={(image) => {
                        imageRef.current = image;
                        setCrop(
                            {
                                unit: 'px',
                                x: (elemImageRef.current.width - Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9) / 2,
                                y: (elemImageRef.current.height - Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9) / 2,
                                width: Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9,
                                height: Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9,
                            }
                        )
                        document.querySelector(".ReactCrop__crop-mask")?.setAttribute("height", "100%") // проблема: при первом добавлении фотографии не появляется затемненный фон. Эта затычка решает все. Как исправить без нее?
                    }
                    } />
                </ReactCrop>
                {!ismobile && <Button onClick={handleCropComplete} variant="contained" size="medium">
                    Добавить фотографию
                </Button>}
            </CropWrapper>
        </CropBackground>)
}) 
