export function root() {
  return ({
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }as const);
}

export function card(cardHovered:boolean, cardHeight:string) {
    return ({
        transition: '.5s cubic-bezier(.3, 0, 0, 1.3)',
        backgroundColor: 'white',
        borderRadius: '16px',
        border: '2px solid var(--unnamed, #E5E5E5)',
        padding: '16px',
        display: 'flex',
        fontFamily: 'Golos Text',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        height: cardHeight
    } as const);
}


export function textName(){
    return({
        fontFamily: 'Golos Text',
        gridArea: 'nameText',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '130%',
        paddingBottom: '2px',
        paddingTop: '3px',
        margin: '0px'
    });
  }
  
export function textSName(){
    return({
    fontFamily: 'Golos Text',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '130%',
    margin: '0px',
    gridArea: 'SnameText',
    fontSize: '16px',
    paddingBottom: '3px'
    });
  }
export function textMName(){
    return({
    fontFamily: 'Golos Text',
    fontWeight: '400',
    lineHeight: '130%',
    margin: '0px',
    gridArea: 'MnameText',
    fontSize: '16px'
    });
  }

export function birthArea(){
    return({
    fontFamily: 'Golos Text',
    color:  '#7F7F7F',
    gridArea: 'birth',
    fontSize: '12px',
    margin: '4px 0px 0px 0px'
    });
  }

export function imageArea(){
  return({
    gridArea: 'image',
    borderRadius: '16px',
    width: '180px',
    height: '180px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    top: '-10px',
    margin:'0'
  });
}

export function imageButton(buttonsStatus:string[]){
  return({
    lineHeight: '130%',
    fontSize: '12px',
    fontStyle: 'normal',
    fontFamily: 'Golos Text',
    fontWeight: '500',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    /* width: 208px;
    height:70px; */
    /* border: 2px solid #E5E5E5; */
    /* margin-bottom: 16px; */
    color:'#149C84',
    textDecoration: 'none',
    border: '0px',
    cursor: 'pointer',
    display: buttonsStatus[0],
    opacity: buttonsStatus[1],
    transition: '.3s cubic-bezier(.3, 0, 0, 1.3)'
  
  /* transform: translateY(0px); */
}as const);
}

export function imageChangeButton(){
  return({
    visibility: 'hidden',
    bottom:'40px',
  } as const)
}

export function AddDelete(buttonsStatus:string[]){
  return({
    transition: '.3s cubic-bezier(.3, 0, 0, 1.3)',
    opacity: buttonsStatus[1],
    display: buttonsStatus[0],
    flexDirection: 'column',
    alignItems: 'center'
    /* display: none; */
  } as const)
}
