import styled from "@emotion/styled"

export const HelperTextDiv = styled.div`
display:flex;
gap: 8px;
flex-direction:column;

`

export const HelperTextSpan = styled.span`
font-family: Gilroy, sans-serif;
display:flex;
align-items:center;
gap:8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #FA5353;
`

export const TextSpan = styled.span`
height:16px;
`
export const PhantomDiv = styled.div`
    width:100vw;
    height: 100vh;
    position: fixed;
    z-index:2;
    top:0;
    left:0;
`