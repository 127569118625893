import React, { useState } from 'react';
import { Box, Button, IconButton, InputAdornment, TextField, SvgIcon } from '@mui/material';
import { sxBox, sxGrid, textNumTwo, textNumb } from 'components/RegistarionModal/style';
import { validatePassword } from 'components/RegistarionModal/validator/validator';
import {
  ChangePasswordStepOneCloseIcon,
  ChangePasswordStepOneHeader,
  ChangePasswordStepOneSpan,
} from 'components/ProfileModal/style';
import { CloseIcon } from 'icons/CloseIcon';
import { IconCloseEye } from 'icons/IconCloseEye';
import { IconOpenEye } from 'icons/IconOpenEye';

interface IModalProps {
  step: number;
  setStep: (val: number) => void;
  handleClickClose: () => void;
}

export const StepOneModal = ({ step, setStep, handleClickClose }: IModalProps) => {
  const [errorPass, setErrorPass] = useState(false);
  const [isPass, setIsPass] = useState(true);
  const [Pass, setPass] = useState('');
  const array = localStorage.getItem('treeArray');
  const treeArray = array ? JSON.parse(array) : null;
  const onClickHandler = (): void => {
    setStep(2);
  };
  const isCheckErrorPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!validatePassword(e.target.value)) {
      setErrorPass(true);
    } else {
      setErrorPass(false);
    }
  };

  return (
    <Box sx={{ ...sxBox, ...sxGrid, width: '656px', height: '472px' }}>
      <IconButton
        onClick={() => {
          handleClickClose();
        }}
        disableRipple
        style={{ position: 'absolute', ...ChangePasswordStepOneCloseIcon }}>
        <SvgIcon
          component={CloseIcon}
          style={{ width: '18px', height: '18px' }}
          viewBox="0 0 18 18"
        />
      </IconButton>
      <h2 id="parent-modal-title" style={{ ...ChangePasswordStepOneHeader }}>
        Изменение пароля
      </h2>
      <span style={{ ...ChangePasswordStepOneSpan }}>
        <h2 style={{ ...textNumb }}>01</h2>
        <h2 style={{ ...textNumTwo, marginTop: '28px' }}>/02</h2>
      </span>
      <TextField
        onChange={(e) => {
          setPass(e.target.value);
          isCheckErrorPassword(e);
        }}
        InputLabelProps={{ shrink: false }}
        style={{ gridArea: 'field', width: '100%', marginTop: '-20px' }}
        margin="dense"
        id="Pass"
        placeholder="Старый пароль"
        type={isPass ? 'password' : 'text'}
        InputProps={{
          style: { fontFamily: 'Golos Text', fontWeight: '500', lineHeight: '21px' },
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                style={{ left: 12 }}
                aria-label="visible"
                size="small"
                onClick={() => {
                  setIsPass(!isPass);
                }}>
                {isPass ? <IconCloseEye /> : <IconOpenEye />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={{ width: '360px', height: '56px', marginTop: '-30px' }}>
        <Button
          disabled={Pass === '' || Pass === treeArray[0].password}
          variant="contained"
          color="primary"
          size="large"
          style={{ width: '100%' }}
          onClick={onClickHandler}>
          Продолжить
        </Button>
      </div>
    </Box>
  );
};
