

export const getAddChildCard = (node: any) => {
    const parentsArr = [
        {
            id: `${node.id}rc`,

            type: 'addChildCard',
            position: { x: node.position.x + 350, y: node.position.y + 400 },
            data: {
                name: 'дочь для ',
                middleName: node.data.name,
                gender: "female",
                isSibling: false,
                rank: node.data.rank - 1,
                pos: 'r',
                globalPos: 'r',
                parents: [{ id: node.id }, ...node.data.spouses],
                spouses: [],
                children: [],
                siblings: [{ id: `${node.id}lci` }],
                zeroNodes: [...node.data.zeroNodes]

            },


        },
        {
            id: `${node.id}lc`,
            type: 'addChildCard',
            position: { x: node.position.x + 63, y: node.position.y + 400 },

            data: {
                name: 'сына для ',
                middleName: node.data.name,
                gender: "male",
                isSibling: false,
                rank: node.data.rank - 1,
                pos: 'l',
                globalPos: 'l',
                parents: [{ id: node.id }, ...node.data.spouses],
                spouses: [],
                children: [],
                siblings: [{ id: `${node.id}rci` }],
                zeroNodes: [...node.data.zeroNodes]
            },

        },
        {
            id: `${node.id}zc`,
            type: 'zeroAddNode',
            position: { x: node.position.x + 280, y: node.position.y + 430 },
            data: {

                rank: node.data.rank,
                pos: 'z',
                globalPos: node.data.globalPos,
                parents: [],
                spouses: [{ id: `${node.id}rp` }, { id: `${node.id}lp` }],
                children: [{ id: node.id }, ...node.data.spouses],
                siblings: [],

            },

        },
    ]
    return parentsArr
}
