/* eslint-disable */
import InputMask from 'react-input-mask';
import { NewTextField } from './style'


export const DateMaskNoField = ({ setDate, styles, errorDate, isCheckError, defaultvalue, setbufferDate }) => {

  return (
    <InputMask style={{ width: '100%', padding: 0, ...styles }} onChange={(e) => {

      setbufferDate(e.target.value)
      setDate(e.target.value)
      isCheckError(e);
    }}
      value={defaultvalue}
      defaultValue={defaultvalue}
      mask="99.99.9999"
      placeholder="Не заполнено"
      error={errorDate}
      margin='dense'
      id='dateOfBirth'
      size="medium"
      variant="outlined"
    >


      {() => <NewTextField type='newNone' style={{ ...styles, padding: 0 }} placeholder='Не заполнено' error={errorDate} />}

    </InputMask>
  )
}