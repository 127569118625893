

export const getAddSpousesCard = (node: any) => {
    const siblingsArr = [
        {
            id: `${node.id}sp`,

            type: 'addSpousesCard',
            position: { x: node.position.x, y: node.position.y + 175 },

            data: {
                gender: node.data.gender === "male" ? "female" : "male",
                name: 'партнера для ',
                middleName: node.data.name,
                rank: node.data.rank,
                pos: node.data.pos,
                globalPos: node.data.globalPos,
                parents: [],
                spouses: [],
                children: [...node.data.children],
                siblings: [],
            },


        }


    ]
    return siblingsArr
}
