import { useState } from 'react';
import ReactFlow, { Controls, Background, NodeTypes, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import { TreeHeaderComp } from 'components/TreeReactFlow/TreeHeaderComp';
import TreeCard from './TreeCard';
import { FooterTree } from 'components/FooterTree';
import { useSelector } from 'react-redux';
import ZeroNode from './algoritm/ZeroNode';
import FloatingEdge from './algoritm/FloatingEdge';
import FloatingConnectionLine from './algoritm/FloatingConnectionLine';




const nodeTypes = {
  treeCard: TreeCard, newNodeCustom: TreeCard,
  zeroNode: ZeroNode,
};
const edgeTypes = {
  floating: FloatingEdge,
};


const TreeReactFlow = () => {
  const [selectedCard, setSelectedCard] = useState('1');
  const nodes = useSelector((state: any) => state.tree.nodes)
  const edges = useSelector((state: any) => state.tree.edges)


  return (
    <>
      <TreeHeaderComp />
      <div style={{ height: '87.2vh', width: '100%' }}>
        <ReactFlowProvider>

          <ReactFlow nodes={nodes} nodeTypes={nodeTypes} edges={edges} >
            <Background />
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>

      </div>
      <FooterTree />
    </>
  );

}

export default TreeReactFlow;