import { baseApi } from "./api";

const treeApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        getTree: builder.query({
            query: (id) => `/tree`,

            providesTags: ['node'],
        }),
        getEdge: builder.query({
            query: (id) => `/edges?id=${id}`,
            providesTags: ['edge'],
        }),
        addParent: builder.mutation({
            query: ({ id, body }) => ({
                url: `/parent/${id}`,

                method: 'POST',
                body
            }),
            invalidatesTags: ['node']

        }),
        addPartner: builder.mutation({
            query: ({ id, body }) => ({
                url: `/partner/${id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['node']

        }),
        addZero: builder.mutation({
            query: ({ body }) => ({
                url: `/zero`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['node', 'edge']

        }),
        addParentEdge: builder.mutation({
            query: ({ id, body }) => ({
                url: `/edges/${id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['edge']

        }),
        layout: builder.mutation({
            query: ({ body }) => ({
                url: `/zero`,
                method: 'PUT',
                body: {
                    zeroNodesData: body
                }
            }),

            invalidatesTags: ['node']

        }),
        addSiblings: builder.mutation({
            query: ({ id, body }) => ({
                url: `/child/${id}?isSibling=true`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['node']

        }),
        addChild: builder.mutation({
            query: ({ id, body }) => ({
                url: `/child/${id}?isSibling=false`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['node']

        }),
        delPerson: builder.mutation({
            query: ({ id }) => ({
                url: `/relative/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['node', 'edge']

        }),
        putPerson: builder.mutation({
            query: ({ id, body }) => ({
                url: `/relative/${id}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['node']

        }),
        deleteEdge: builder.mutation({
            query: ({ source, target }) => ({
                url: `/edges`,
                method: 'DELETE',
                body: {
                    source,
                    target
                }
            }),
            invalidatesTags: ['edge']

        })


    })
})
export const { usePutPersonMutation, useAddChildMutation, useAddSiblingsMutation, useLayoutMutation, useAddZeroMutation, useDeleteEdgeMutation, useAddPartnerMutation, useAddParentEdgeMutation, useAddParentMutation, useDelPersonMutation, useGetTreeQuery, useGetEdgeQuery } = treeApi