import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { ArrowNextPhotoSwiperButton } from 'icons/ArrowNextPhotoSwiperButton';
import { GallereyDiv, GallereyImage, NextArrowButton, PrevArrowButton, Slide, StyledSwiper } from './style';
import Slide1 from 'images/Slide1.png'
import Slide2 from 'images/Slide2.png'
import Slide3 from 'images/Slide3.png'
import { ArrowSliderLanding } from 'icons/ArrowSliderLanding';

export const LandingSlider = () =>
(
    <GallereyDiv>
        <StyledSwiper

            navigation={{ nextEl: '.next-arrow', prevEl: '.prev-arrow' }}
            pagination
            modules={[Pagination, Navigation]}
            centeredSlides={false}
        >
            <Slide>
                <GallereyImage src={Slide1} alt="err" />
            </Slide>
            <Slide>
                <GallereyImage src={Slide2} alt="err" />
            </Slide>
            <Slide>
                <GallereyImage src={Slide3} alt="err" />
            </Slide>
        </StyledSwiper>
        <NextArrowButton className='next-arrow'><ArrowSliderLanding /></NextArrowButton>
        <PrevArrowButton className='prev-arrow'><ArrowSliderLanding /></PrevArrowButton>
    </GallereyDiv>

);
