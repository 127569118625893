import React from 'react';
import { TypographyVariantsOptions } from '@mui/material';

/*
  Базовые знания о mui типографике
  https://mui.com/material-ui/react-typography/

  Шрифты подписаны комментариями так, как они называются
  в UI Kit

  Если нужно добавить кастомный вид шрифта, которого нет в
  стандартном наборе mui (то есть с названием, которого там нет),
  вписать новое поле в декларации типов ниже, по аналогии с тем,
  что уже добавлено
*/

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    button2: React.CSSProperties;
    caption2: React.CSSProperties;
    textOnPhoto: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    button2?: React.CSSProperties;
    caption2?: React.CSSProperties;
    textOnPhoto?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    button2: true;
    caption2: true;
    textOnPhoto: true;
  }
}

const baseFontFamily = ['Gilroy', 'Arial', 'sans-serif'].join(', ');

export const typography: TypographyVariantsOptions = {
  fontFamily: baseFontFamily,
  fontSize: 16,

  h1: { // Заголовок 1
    fontSize: 80,
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h2: { // Заголовок 2
    fontSize: 54,
    fontWeight: 500,
    lineHeight: 1.15,
  },
  h3: { // Заголовок 3
    fontSize: 32,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  subtitle1: { // Подзаголовок 1
    fontSize: 40,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  subtitle2: { // Подзаголовок 2
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  subtitle3: { // Подзаголовок 3 (кастомный)
    fontFamily: baseFontFamily,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  subtitle4: { // Подзаголовок 4 (кастомный)
    fontFamily: baseFontFamily,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  body1: { // Основной текст 1
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  body2: { // Основной текст 2
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
  },
  button: { // Кнопки 1 / Поля ввода 
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  button2: { // Кнопки 2 (кастомный)
    fontFamily: baseFontFamily,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  caption: { // Подписи
    fontFamily: baseFontFamily,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.3,
  },
  caption2: { // Подписи (родственник в дереве карточки человека )
    fontFamily: baseFontFamily,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '15.6px',
  },
  textOnPhoto: { // Текст отображающийся на заблюреных и затемненных фотках
    fontFamily: baseFontFamily,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3
  }
}