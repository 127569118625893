import { useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import {
    SwipeableDrawerMobile, DrawerWrapper, DrawerContent, DrawerTitle, DrawerSubtitle, DrawerText, CropDrawer, CropDrawerContent,
    CropDrawerContentWrapper, CropDrawerButtonsWrapper, CircularWrapper, ErrorWrapper, ErrorIcon, ErrorAddingPhoto, ErrorPopup, ErrorPopupText
} from "./style";
import { CropComponent } from "../CropComponent";
import { IImage } from "../types/types";
import { setAvatar } from "store/slices/profile/slice";

interface IIsOpen {
    setRerend: (val: boolean) => void;
    isopen: boolean,
    setisopen: (val: boolean) => void;
    imagesArr: IImage[];
    setAvatar: (val: {path: string, name: string}) => void;
}

export const SwipableDrawer = ({ setRerend, isopen, setisopen, imagesArr, setAvatar }: IIsOpen) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const cropRef = useRef<{ handleCropComplete: () => void } | null>(null);
    const [isInProgressSelectPhoto, setIsInProgressSelectPhoto] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState<string | ArrayBuffer | null>(null);
    const [isError, setIsError] = useState(false);
    const [errorPhotoType, setErrorPhotoType] = useState(false); 
    const [errorPhotoSize, setErrorPhotoSize] = useState(false); 

    const handleCloseAndSetErrorPopup = () => {
        setSelectedPhoto(null);
    }

    const handleAddPhotos = (event: any) => {
        setIsError(false);
        setErrorPhotoSize(false);
        setErrorPhotoType(false);
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setIsInProgressSelectPhoto(true);
            setSelectedPhoto('inProgress')
            if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {

                setTimeout(() => {
                    setIsInProgressSelectPhoto(false);
                    setIsError(true);
                    setTimeout(() => {
                        handleCloseAndSetErrorPopup();
                        setErrorPhotoType(true);
                    }, 1000)
                }, 300);
            }

            else if (file.size / (1024) > 10*1024) {
                
                setTimeout(() => {
                    setIsInProgressSelectPhoto(false);
                    setIsError(true);
                    setTimeout(() => {
                        handleCloseAndSetErrorPopup();
                        setErrorPhotoSize(true);
                    }, 1000);
                }, 300);
            }
            
            else {
                const reader = new FileReader();

                reader.onload = () => {
                    setSelectedPhoto(reader.result);
                    setTimeout(() => {
                        setIsInProgressSelectPhoto(false);
                    }, 300)
                };

                reader.readAsDataURL(file);
            }
        }
    };

    const handleClickRef = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSetPhoto = () => {
        if (cropRef.current) {
            cropRef.current.handleCropComplete();
        }
    };
    const handleSwipeOnCrop = (event: any) => {
        event.stopPropagation();
    };
        
    return (
        <>
            {(errorPhotoType || errorPhotoSize) && 
            <ErrorPopup onTouchStart={(e) => handleSwipeOnCrop(e)}>
                {errorPhotoSize && <ErrorPopupText>Фото не должно превышать размер 10MB</ErrorPopupText>}
                {errorPhotoType && <ErrorPopupText>Неверный формат фото. Пожалуйста, используйте только формат JPEG, PNG или GIF</ErrorPopupText>}
            </ErrorPopup>
            }
        <SwipeableDrawerMobile
            isselectedphoto={selectedPhoto}
            anchor="bottom"
            open={isopen}
            onClose={() => {
                setisopen(false);
                setErrorPhotoSize(false);
                setErrorPhotoType(false);
              }
            }
            onOpen={() => setisopen(true)}
        >   
            <DrawerWrapper>
                {!selectedPhoto ?
                    <DrawerContent>
                        <DrawerTitle>Добавление новой фотографии</DrawerTitle>
                        <DrawerSubtitle>Фотография должна быть в формате JPEG, PNG или GIF</DrawerSubtitle>
                        <Button onClick={handleClickRef} style={{ width: '256px', height: '40px', borderRadius: '8px' }} color='primary' variant='contained' size='large'>
                            <input
                                hidden
                                accept="image/*,.png,.jpeg,.jpg"
                                style={{ width: '2px' }}
                                type="file"
                                ref={fileInputRef}
                                onChange={(e) => handleAddPhotos(e)}
                            />
                            Выбрать фото
                        </Button>
                        <DrawerText>Если возникают проблемы с загрузкой, попробуйте выбрать фотографию меньшего размера</DrawerText>
                    </DrawerContent>
                    :
                    <>
                        <CropDrawer>
                            {!isInProgressSelectPhoto && isError &&
                                <ErrorWrapper>
                                    <ErrorIcon>
                                        <ErrorAddingPhoto viewBox="0 0 28 28" style={{width: '28px', height: '28px', position: 'absolute'}}/>
                                    </ErrorIcon>
                                </ErrorWrapper> 
                            }
                            {!isInProgressSelectPhoto && !isError &&
                                <div onTouchStart={(e) => handleSwipeOnCrop(e)}>
                                    <CropComponent setIsError={setIsError} isError={isError} ref={cropRef} setIsOpen={setisopen} ismobile selectedPhoto={selectedPhoto} setSelectedPhoto={setSelectedPhoto} setRerend={setRerend} imagesArr={imagesArr} setAvatar={setAvatar} />
                                </div> 
                            }
                            {isInProgressSelectPhoto &&
                                <CircularWrapper>
                                    <CircularProgress />
                                </CircularWrapper>
                            }
                        </CropDrawer>
                        <CropDrawerContent>
                            <CropDrawerContentWrapper>
                                <DrawerTitle>Добавление новой фотографии</DrawerTitle>
                                <CropDrawerButtonsWrapper>
                                    <Button onClick={() => setSelectedPhoto(null)} style={{ whiteSpace: 'nowrap', width: '163px', height: '40px', borderRadius: '8px' }} color='secondary' variant='outlined' size='large'>Вернуться назад</Button>
                                    <Button onClick={handleSetPhoto} style={{ width: '163px', height: '40px', borderRadius: '8px' }} color='primary' variant='contained' size='large'>Сохранить</Button>
                                </CropDrawerButtonsWrapper>
                            </CropDrawerContentWrapper>
                        </CropDrawerContent>
                    </>
                }
            </DrawerWrapper>
        </SwipeableDrawerMobile>
        </>)

} 
