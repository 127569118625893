import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAuthSliceState, TSetField } from './types';
import { registrationAsync } from './asyncActions';


const initialState: TAuthSliceState = {
  data: {},
  error: null,
  status: 'init',
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setField(state, { payload }: PayloadAction<TSetField>) {
      state.data[payload.fieldName] = payload.value;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(registrationAsync.pending,
        (state, { payload }) => {
          state.status = 'loading';
          state.error = null;
        }
      )
      .addCase(registrationAsync.fulfilled,
        (state, { payload }) => {
          state.status = 'success';
          state.error = null;
        }
      )
      .addCase(registrationAsync.rejected,
        (state, { payload }) => {
          state.status = 'error';

          if (payload) state.error = payload;
        }
      )
  },
});

export const {
  setField,
} = authSlice.actions;

export const authReducer = authSlice.reducer;