import React from 'react';
import { Typography } from "@mui/material";
import { THeaderMenuItem } from "../types";
import { StyledWithIconLink, StyledBaseLink, StyledSettButt } from "./styles";
import { useLocation } from "react-router-dom";

type TProps = {
  item: THeaderMenuItem
}

export const HeaderMenuItem: React.FC<TProps> = ({ item }) => {
  const location = useLocation();

  if (item.link === 'setting') {
    return (
      <StyledSettButt onClick={item.data} >
        {item.icon}
        <Typography variant='button'>{item.label}</Typography>
      </StyledSettButt>

    )
  }

  if (item.icon) {
    return (
      <StyledWithIconLink to={item.link} $isActive={location.pathname === item.link}>
        {item.icon}
        <Typography variant='button'>{item.label}</Typography>
      </StyledWithIconLink>
    );
  }

  return (
    <StyledBaseLink to={item.link} $isActive={location.pathname === item.link}>
      <Typography variant='button'>{item.label}</Typography>
    </StyledBaseLink>
  );
}