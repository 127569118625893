import { IconButton, Modal, SvgIcon, Box } from '@mui/material';
import { useState } from 'react';
import { NotCompleteModal } from 'components/NotCompleteModal';
import { ProfileModal } from 'components/ProfileModal';
import styles from './index.module.css';
import { IconTree } from 'icons/IconTree';
import { IconPeople } from 'icons/IconPeople';
import { IconProfile } from 'icons/IconProfile';

export const TreeHeaderComp = () => {
  const [isOpenNotCompl, setIsOpenNotCompl] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickClose = () => {
    setIsOpenNotCompl(false);
    setIsOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          fontFamily: 'Golos Text',
          justifyContent: 'space-between',
          position: 'relative',
          marginLeft: '24px',
          alignItems: 'center',
        }}>
        <h3>
          <span style={{ color: 'black', fontSize: '20px', fontWeight: 500 }}>Family</span>
          <span style={{ color: '#149C84', fontSize: '20px', fontWeight: 500 }}>Tree</span>
        </h3>
        <div
          style={{
            paddingRight: '24px',
            paddingTop: '8px',
            paddingBottom: '8px',
            display: 'flex',
            gap: '8px',
          }}>
          <IconButton
            className={styles.headerButton}
            style={isOpen || isOpenNotCompl ? { color: '#7F7F7F' } : { color: '#149C84' }}>
            <SvgIcon component={IconTree} />
            <span style={{ fontSize: '12px', lineHeight: '15.6px', fontWeight: 500 }}>Древо</span>
          </IconButton>
          <IconButton
            className={styles.headerButton}
            style={isOpenNotCompl ? { color: '#149C84' } : { color: '#7F7F7F' }}
            onClick={() => {
              setIsOpenNotCompl(true);
            }}>
            <SvgIcon component={IconPeople} />
            <span style={{ fontSize: '12px', lineHeight: '15.6px', fontWeight: 500 }}>Люди</span>
          </IconButton>
          <IconButton
            className={styles.headerButton}
            style={isOpen ? { color: '#149C84' } : { color: '#7F7F7F' }}
            onClick={() => {
              setIsOpen(true);
            }}>
            <SvgIcon component={IconProfile} />
            <span style={{ fontSize: '12px', lineHeight: '15.6px', fontWeight: 500 }}>Профиль</span>
          </IconButton>
        </div>
      </div>
      <Modal
        open={isOpenNotCompl || isOpen}
        onClose={handleClickClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Box>
          {isOpenNotCompl && (
            <NotCompleteModal isOpen={isOpenNotCompl} setIsOpen={setIsOpenNotCompl} />
          )}
          {isOpen && <ProfileModal isOpen={isOpen} setIsOpen={setIsOpen} />}
        </Box>
      </Modal>
    </div>
  );
};
