import { SerializedError, createAsyncThunk, miniSerializeError } from "@reduxjs/toolkit";
import { getBiography, getRelative } from "network/axiosConfig";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { TBiography } from "./types";

export const getBioAsync = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
    'profile/getBioAsync',
    async (_, { rejectWithValue }) => {
        try {
            const params = useParams()
            console.log(params.id)
            const response = getBiography(Number(params.id))
            return (await response).data;
        } catch (error) {
            const serializedError = miniSerializeError(error);
            return rejectWithValue(serializedError);
        }
    }
);
export const getRelativeAsync = createAsyncThunk<any, void, { rejectValue: SerializedError, state: RootState }>(
    'profile/getRelativeAsync',
    async (_, { rejectWithValue }) => {
        try {
            const params = useParams()
            console.log(params.id)
            const response = getRelative(Number(params.id))
            return (await response).data;
        } catch (error) {
            const serializedError = miniSerializeError(error);
            return rejectWithValue(serializedError);
        }
    }
);