import { createSvgIcon } from "@mui/material";

export const SideBarDeleteIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="20 8 100 100" fill="none">
        <g filter="url(#filter0_ddd_11270_74712)">
            <rect x="20" y="8" width="40" height="40" rx="8" fill="#FA5353" shapeRendering="crispEdges" /><path fillRule="evenodd" clipRule="evenodd" d="M48.2871 21.243C48.6761 21.243 49 21.566 49 21.977V22.357C49 22.758 48.6761 23.0909 48.2871 23.0909H31.7139C31.3239 23.0909 31 22.758 31 22.357V21.977C31 21.566 31.3239 21.243 31.7139 21.243H34.6296C35.2218 21.243 35.7373 20.822 35.8705 20.228L36.0232 19.546C36.2605 18.617 37.0415 18 37.9353 18H42.0647C42.9488 18 43.7385 18.617 43.967 19.497L44.1304 20.227C44.2627 20.822 44.7781 21.243 45.3714 21.243H48.2871ZM46.8069 35.1351C47.1114 32.2982 47.6443 25.5582 47.6443 25.4902C47.6638 25.2842 47.5967 25.0892 47.4634 24.9323C47.3204 24.7853 47.1396 24.6983 46.9402 24.6983H33.0696C32.8693 24.6983 32.6787 24.7853 32.5464 24.9323C32.4122 25.0892 32.3461 25.2842 32.3558 25.4902C32.3576 25.5027 32.3767 25.7401 32.4087 26.1371C32.5507 27.9003 32.9463 32.8113 33.2019 35.1351C33.3828 36.8471 34.5061 37.9231 36.1332 37.9621C37.3887 37.9911 38.6822 38.0011 40.0049 38.0011C41.2507 38.0011 42.516 37.9911 43.8105 37.9621C45.494 37.9331 46.6163 36.8761 46.8069 35.1351Z" fill="white" />
        </g>

    </svg>
    ,
    "SideBarDeleteIcon"
)