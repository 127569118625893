import styled from "@emotion/styled"
import {
    Button,
} from '@mui/material';

export const DeleteWrapper = styled.div`
    @media(max-width: 844px){
        padding: 39px 16px 39px 16px;
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction:column;
        border-radius: 16px 16px 0px 0px;
        background-color: white;
        gap:24px;

    }
    @media(min-width: 844px){
        outline:none;

        padding: 48px;
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction:column;
        border-radius: 16px;
        background-color: white;
        gap:24px;
        width:648px;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
`
export const TextWrapper = styled.p`
@media(max-width: 844px){

    font-family: Gilroy;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}
@media(min-width: 844px){
    //styleName: Gilroy Text/Подзаголовок 2;
    font-family: Gilroy;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    
}
`
export const ButtonDiv = styled.div`


    display: flex;
    align-items:center;
    gap: 16px;

`

export const BackButton = styled(Button)`
@media(max-width: 844px){

    height: 40px;
    width:320px;
    border-radius:8px;
}
@media(min-width: 844px){
    width: 129px;
    border-radius:16px;
    height:56px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;


}
`
export const DelButton = styled(Button)`
@media(max-width: 844px){

    height: 40px;
    width:136px;
    border-radius:8px;
    background: #FA5353;
}
@media(min-width: 844px){
    width: 129px;
    border-radius:16px;
    height:56px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    background: #FA5353;
  
}
&:hover {
    background: #FA5353;
    box-shadow:none;
}
`
export const LineDiv = styled.div`
    @media(max-width: 844px){
        border-radius:2px;
        position: absolute;
        border: 2px solid #C5C5C5;
        width: 40px;
        top:6px;
    }
`