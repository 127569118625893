import { type ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import { StepOneModal } from './StepOneModal';
import { StepTwoModal } from 'components/ProfileModal/ChangePasswordModal/StepTwoModal';
import { StepThreeModal } from './StepThreeModal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { regAsyncAxios } from 'store/slices/auth/asyncActions';
import { miniSerializeError } from '@reduxjs/toolkit';
import { config } from 'localforage';
import axios from 'axios';
import { customAxios } from 'network/axiosConfig';

interface IModalProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
}

export const ChangePasswordModal = ({ isOpen, setIsOpen }: IModalProps): ReactJSXElement => {
    const [step, setStep] = useState(1);
    const handleClickClose = (): void => {
        setIsOpen(false);
        setStep(1);
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleClickClose}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
        >
            <Box>
                {step === 1 && <StepOneModal step={step} setStep={setStep} handleClickClose={handleClickClose} />}
                {step === 2 && <StepTwoModal step={step} setStep={setStep} handleClickClose={handleClickClose} />}
                {step === 3 && <StepThreeModal isOpen={isOpen} setIsOpen={setIsOpen} />}
            </Box>
        </Modal>
    );
};