import { treeActions } from "store/slices/tree"
import { useEffect, useState } from "react"
import { CardWrapperDiv, FioText, FioAndDateWrapper, ImageDiv, ImageInfoWrapper, InfoRelAndAgeBlock, RelationText, StyledImage, AgeText, DateText, ButtonPartnerDiv, ButtonDelete, ButtonWrapper } from "./style"
import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { DeleteTrashIcon } from "icons/DeleteTrashIcon"
import { useGetAvatarQuery } from "store/storeQuery/photo"
import { useAppDispatch } from "store/hooks"
import { IconOnCard } from "components/CurrentNewTree/IconOnCard"
import { RootCard } from "../../RootCard"

export const PhantomCard = ({ data, xPos, yPos, id }: any) => {
    const navigate = useNavigate()
    const [hover, setHover] = useState(false)
    const dispatch = useAppDispatch();



    return (
        <>
            <CardWrapperDiv role="button" tabIndex={0} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                <ImageInfoWrapper>
                    <ImageDiv>
                        <IconOnCard gender={data.gender} />

                    </ImageDiv>
                    <InfoRelAndAgeBlock>
                        <RelationText variant="caption2" >Неизвестно</RelationText>
                        <AgeText variant="caption2">?</AgeText>
                    </InfoRelAndAgeBlock>

                </ImageInfoWrapper>
                <FioAndDateWrapper>
                    <FioText variant="button">
                        Неизвестный родственик
                    </FioText>

                    <DateText variant="caption2">Неизвестно</DateText>

                </FioAndDateWrapper>


            </CardWrapperDiv>
            <RootCard isConnectable={data.isConnectable} />

        </>
    )
}