import { baseApi } from "./api";

const deleteApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({

        getTreeTest: builder.query({
            query: () => `/tree`,
            providesTags: ['tree'],
        }),
        delPerson: builder.mutation({
            query: ({ id }) => ({
                url: `/relative/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['tree']

        })


    })
})
export const { useDelPersonMutation, useGetTreeTestQuery } = deleteApi