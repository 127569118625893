/* eslint-disable */
import { TextField  } from '@mui/material';
import InputMask from 'react-input-mask';

export const DateMaskField = ( {setDate, styles, errorDate, isCheckError}) => {
    
    return (
        <InputMask  style={{ width: '100%', margin: 0, ...styles }} onChange={(e) => {
            setDate(e.target.value);
            isCheckError(e, 3);
          }}
        mask="99.99.9999"
        placeholder="дд.мм.гггг"
        error={errorDate}
        margin='dense'
        id='dateOfBirth'
        
        size="medium"
        variant="outlined"
      >

        
        {() => <TextField placeholder='Дата рождения' style={{...styles}} error={errorDate}  />}

      </InputMask>
    )
}