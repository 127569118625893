import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { BiographyBlockDiv, BiographyName, BiographyComp, TextFieldWrapper, ToProfileButton, IconArrowRight } from './style';
import { ArrowBackIcon } from 'icons/ArrowBackIcon';
import { ArrowNextIcon } from 'icons/ArrowNextIcon';
import { useNavigate } from 'react-router-dom';
import { useGetBiographyQuery } from 'store/storeQuery/bioApi';
import { useSelector } from 'react-redux';
import { getTreeCurrentCardData } from 'store/slices/tree/selectors';
import { useAppDispatch } from 'store/hooks';
import { treeActions } from 'store/slices/tree';


export const Biography = () => {
    const navigate = useNavigate()
    const CardInfo = useSelector(getTreeCurrentCardData);
    const [showFullInput, setShowFullInput] = useState(false);
    const [isBlur, setIsBlur] = useState(false);
    const { data: biography } = useGetBiographyQuery(CardInfo.id)
    const [text, setText] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {

        if (biography && biography?.aboutPerson)
            setText(biography.aboutPerson)
        if (biography?.aboutPerson === undefined || biography === null || biography?.aboutPerson.length === 0)
            setText('')
    }, [biography?.aboutPerson, biography])
    const handleSetShowInput = () => {
        setShowFullInput(true);
        setIsBlur(false);
    };
    const handleSetHideInput = () => {
        setShowFullInput(false);
    };

    const handleSendBiography = (e: any) => { // тут отправка данных на бэк
        setIsBlur(true)
        setShowFullInput(false);
    };

    const goToProfilePage = (event: any) => {
        event.stopPropagation();
        dispatch(treeActions.setIsOpenCardInfo(false));
        navigate(`/profile/${CardInfo.id}`);
    };
    return (

        <BiographyComp>
            <BiographyBlockDiv>
                <BiographyName>Биография</BiographyName>
            </BiographyBlockDiv>


            <TextFieldWrapper InputProps={{ readOnly: true }} onBlur={(e) => handleSendBiography(e)} value={showFullInput || text?.length < 250 ? text : `${text?.slice(0, 250)}...`} onClick={handleSetShowInput} style={{ width: '100%', fontSize: '8px' }} placeholder='Здесь пока нет материалов...' type='empty' multiline />
            {text?.length > 250 &&
                <Button
                    style={{
                        height: 30,
                        width: 168, color: '#149C84', padding: '8px 0px 0px 0px'
                    }}
                    variant='text'
                    color='secondary'
                    onClick={showFullInput && !(isBlur) ? handleSetHideInput : handleSetShowInput}
                    startIcon={showFullInput ? <ArrowBackIcon style={{ width: '24px', height: '24px' }} /> : ''}
                    endIcon={showFullInput ? '' : <ArrowNextIcon style={{ width: '24px', height: '24px' }} />}
                >
                    {showFullInput ? "Свернуть" : "Читать далее"}
                </Button>
            }

            <ToProfileButton onClick={goToProfilePage} >
                {(biography?.aboutPerson === undefined || biography === null || biography?.aboutPerson.length === 0) ? 'Заполнить' : 'В профиль'}
                <IconArrowRight />
            </ToProfileButton>
        </BiographyComp>
    );
};