import React from "react";
import { StyledLink, StyledTypography, StyledBreadcrumbs } from './styles';
import { BreadcrumbsIcon } from "icons/BreadcrumbsIcon";

type TProps = {
  crumbs: Array<{
    urlPart: string,
    label: string,
  }>,
  currentLabel: string,
}

export const Breadcrumbs = ({ crumbs, currentLabel }: TProps) => {
  let currentPath = '/';

  return (
    <>
      <StyledLink key={currentPath} to={currentPath}>
        <BreadcrumbsIcon />
      </StyledLink>
      <StyledBreadcrumbs
        aria-label="breadcrumb"
        maxItems={4}

        itemsBeforeCollapse={2}
        itemsAfterCollapse={2}
        expandText="show more"
      >

        {
          crumbs.map(crumb => {
            const currentCrumb = crumb.urlPart.replace(/\//g, '');
            currentPath += currentCrumb.length ? `${currentCrumb}/` : '';
            return <StyledLink key={currentPath} to={currentPath}>{crumb.label}</StyledLink>;
          })
        }
        <StyledTypography>{currentLabel}</StyledTypography>
      </StyledBreadcrumbs>
    </>
  )
};