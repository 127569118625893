import { ProfileModal } from "."

export const sxBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,

}

export const textCont = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  height: '50px'
}
export const textNumb = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  color: '#149C84',
  paddingLeft: '150px'
}

export const textNumTwo = {
  fontFamily: 'Golos Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21px',

}

export const sxGridThree = {

  display: 'grid',
  gridTemplateAreas: " 'text' 'field1' ' field2' 'button2' ' button1 '  ",
  justifyContent: 'center',
  gridTemplateRows: '155px 90px 120px 2px 53px',
  gridTemplateColumns: '360px'
}

export const divNameSurname = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '24px',
  gap: '4px'
}
export const underdivNameSurname = {
  display: 'flex',
  gap: '16px',
  alignItems: 'center'
}
export const Initials = {
  margin: 0,
  paddingBottom: '4px',
  fontFamily: 'Golos Text',
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: 500,
  color: '#1A1A1E',
  letterSpacing: '0'
}
export const Email = {
  margin: 0,
  paddingBottom: '4px',
  fontFamily: 'Golos Text',
  fontSize: '12px',
  lineHeight: '15.6px',
  fontWeight: 400,
  color: '#7F7F7F',
  letterSpacing: '0'
}

export const ChangeSettingsModalheader = {
  fontSize: '24px',
  lineHeight: '31.2px',
  paddingBottom: '30px',
  margin: 0,
  marginTop: "21px",
  marginLeft: '22px',
  fontFamily: 'Golos Text',
  fontWeight: 500,
  color: '#1A1A1E'
}
export const ChangeSettingsHeaderDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start'
}
export const ChangeSettingsIconClose = {
  width: '24px',
  height: '24px',
  marginTop: '27px',
  marginRight: '18px'
}
export const ChangeSettingsImage = {
  margin: '0px',
  width: '216px',
  height: '216px'
}
export const ChangeSettingsChangeImageButton = {
  width: '120px',
  height: '32px',
  top: -45,
  left: '50px'
}

export const ChangePasswordStepOneCloseIcon = {
  margin: 0,
  padding: 0,
  right: '28px',
  top: '28px'
}
export const ChangePasswordStepOneHeader = {
  fontFamily: 'Golos Text',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '31px',
  height: '50px',
  width: '360px',
  gridArea: 'text',
  paddingTop: '132px',
  margin: '0px'
}
export const ChangePasswordStepOneSpan = {
  gridArea: 'number',
  paddingTop: '107px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'start',
  height: '50px',
}
export const ChangePasswordStepTwoHeader = {
  width: '360px',
  gridArea: 'text',
  paddingTop: '92px',
  ...textCont,
  margin: '0px'
}
export const ChangePasswordStepTwoSpan = {
  gridArea: 'number',
  paddingTop: '67px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'start',
  height: '50px'
}
export const ChangePasswordStepThreeHeader = {
  width: '360px',
  gridArea: 'text',
  paddingTop: '92px',
  ...textCont,
  margin: '0px'
}
export const ChangePasswordStepThreeSpan = {
  paddingTop: '132px',
  ...textCont,
  display: 'inline',
  fontSize: '20px'
}