import React, { useEffect, useState, useRef } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Button, CircularProgress } from '@mui/material';
import { CropBackground, CropWrapper, CropDesktopTopElem, CropDesktopBottomElem, WrapperClose, CropDesktopTitle, ButtonsWrapper, CircularWrapper, ErrorWrapper, ErrorIcon, ErrorAddingPhoto } from "./style";
import { customAxios } from 'network/axiosConfig';
import { useParams } from 'react-router-dom';
import { CloseIconProfile } from 'icons/CloseIconProfile';
import { IImage } from '../types/types';
import { usePutRelativeMutation } from 'store/storeQuery/relativeApi';
import { useGetAvatarQuery } from 'store/storeQuery/photo';

interface ICropProps {
    setIsError: (val: boolean) => void;
    isError: boolean;
    setIsOpen: (val: boolean) => void;
    ismobile: boolean;
    setRerend: (val: boolean) => void;
    setSelectedPhoto: (val: string | ArrayBuffer | null) => void;
    selectedPhoto: string | ArrayBuffer | null;
    imagesArr: IImage[];
    setAvatar: (val: {path: string, name: string}) => void
}

export const CropComponent = React.forwardRef(({ setIsError, isError, setIsOpen, ismobile, setRerend, setSelectedPhoto, selectedPhoto, imagesArr, setAvatar }: ICropProps, ref) => {
    const imageRef = useRef<any>(null);
    const elemImageRef = useRef<any>(null);
    const cropRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams()

    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        x: 25,
        y: 25,
        width: 300,
        height: 300,
    });
    const handleCropComplete = () => {
        if (imageRef.current && crop.width && crop.height && params.id) {
 
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context) {
                const image = new Image();

                image.onload = () => {

                    const scaleX = image.naturalWidth / elemImageRef.current.clientWidth;
                    const scaleY = image.naturalHeight / elemImageRef.current.clientHeight;

                    const ctx = canvas.getContext('2d');
                    const pixelRatio = window.devicePixelRatio;
                    canvas.width = crop.width * pixelRatio;
                    canvas.height = crop.height * pixelRatio;

                    if (ctx) {
                        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                        ctx.imageSmoothingQuality = 'high';
                        ctx.drawImage(
                            image,
                            crop.x * scaleX,
                            crop.y * scaleY,
                            crop.width * scaleX,
                            crop.height * scaleY,
                            0,
                            0,
                            crop.width,
                            crop.height,
                        );
                    }

                    canvas.toBlob(async (blob) => {
                        if (blob) {
                            const formData = new FormData();

                            if (imagesArr.length === 0) {
                                const file = new File([blob], 'filename.jpg', { type: blob.type });
                                formData.append('userAvatar', file);
 
                                await customAxios.put(`relative/${String(params.id)}`, formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                    },
                                });
                                const res: any = await customAxios.get(`/avatars?id=${params.id}`);
                                
                                setAvatar({path: res.data.path, name: res.data.name});

                            } else {
                                formData.append('id', String(params.id));
                                formData.append('images', blob);
                                await customAxios.post('images', formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                                    },
                                });
                            }
                            setRerend(true);
                            setSelectedPhoto(null);
                            setIsOpen(true);
                        }
                    }, 'image/jpeg');
                };

                image.src = String(selectedPhoto);

            }
        }
    };

    React.useImperativeHandle(ref, () => ({
        handleCropComplete
    }));
    
    useEffect(() => {
        function updateCropSize() {
            const cropWidth = Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9;
            const cropHeight = cropWidth;

            const imageWidth = elemImageRef.current.width;
            const imageHeight = elemImageRef.current.height;

            const x = (imageWidth - cropWidth) / 2;
            const y = (imageHeight - cropHeight) / 2;

            setCrop({ unit: 'px', x, y, width: cropWidth, height: cropHeight });
        }

        if (!isError) {
            updateCropSize();

            window.addEventListener('resize', updateCropSize);
    
            return () => {
                window.removeEventListener('resize', updateCropSize);
            };
        }
    }, []);

    const cropBack = () => {
        setSelectedPhoto(null);
        setIsError(false);
        setIsOpen(true);
    }

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsLoading(false);
                setTimeout(() => {
                    cropBack();
                }, 1000)
            }, 500)
        }
    }, [isError])
    
    return (
        <CropBackground ismobile={ismobile}>
            <CropWrapper ismobile={ismobile}>
                {!isError && <ReactCrop
                    ref={cropRef}
                    style={{ maxHeight: ismobile ? '77vh' : '382px'}}
                    keepSelection
                    aspect={1 / 1}
                    onComplete={(crop) => {
                        imageRef.current = crop;
                    }}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    locked>
                  
                    <img ref={elemImageRef} src={String(selectedPhoto)} alt="error" onLoad={(image) => {
                        imageRef.current = image;
                        setCrop(
                            {
                                unit: 'px',
                                x: (elemImageRef.current.width - Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9) / 2,
                                y: (elemImageRef.current.height - Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9) / 2,
                                width: Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9,
                                height: Math.min(elemImageRef.current.width, elemImageRef.current.height) * 0.9,
                            }
                        )
                        document.querySelector(".ReactCrop__crop-mask")?.setAttribute("height", "100%") // проблема: при первом добавлении фотографии не появляется затемненный фон. Эта затычка решает все. Как исправить без нее?
                    }
                    } />
                </ReactCrop>}
                {isError && isLoading &&
                    <CircularWrapper>
                       <CircularProgress/>
                    </CircularWrapper>
                }
                {isError && !isLoading &&
                    <ErrorWrapper>
                        <ErrorIcon>
                            <ErrorAddingPhoto viewBox="0 0 28 28" style={{width: '28px', height: '28px', position: 'absolute'}}/>
                        </ErrorIcon>
                    </ErrorWrapper> 
                }
            {!ismobile && 
                    <>
                        <WrapperClose onClick={() => {
                            setIsError(false);
                            setSelectedPhoto(null);
                        }
                    }>
                            <CloseIconProfile />
                        </WrapperClose>
                        <CropDesktopTopElem>
                            <CropDesktopTitle>Добавление новой фотографии</CropDesktopTitle>
                        </CropDesktopTopElem>
                        <CropDesktopBottomElem>
                            <ButtonsWrapper>
                                <Button onClick={() => cropBack()} style={{fontSize: '16px', width: '152px', height: '40px', borderRadius: '8px'}} variant='outlined' color='secondary' size='medium'>Вернуться назад</Button>
                                <Button disabled={isError} onClick={() => handleCropComplete()} style={{fontSize: '16px', width: '152px', height: '40px', borderRadius: '8px'}} variant='contained' color='primary' size='medium'>Сохранить</Button>
                            </ButtonsWrapper>
                        </CropDesktopBottomElem>
                    </>
            }
            </CropWrapper>
        </CropBackground>)
}) 
