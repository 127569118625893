
export const getAddParentsEdges = (node: any) => {
    const parentEdges = [
        {
            type: 'smoothstep',
            sourceHandle: 'SourceTop',
            targetHandle: 'TarBottom',
            id: `${node.id}ezp`,
            source: node.id,
            target: `${node.id}zp`,
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
            },
        },

        {
            type: 'smoothstep',
            sourceHandle: 'SourceRight',
            targetHandle: 'TarLeft',
            id: `${node.id}erlp`,
            source: `${node.id}lp`,
            target: `${node.id}rp`,
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
            },
        },

    ]
    return parentEdges
}