
export const getAddChildEdges = (node: any) => {
    console.log(node)
    const parentEdges = [
        {
            type: 'smoothstep',
            sourceHandle: 'SourceTop',
            targetHandle: 'TarBottom',
            id: `${node.id}ezc`,
            source: `${node.id}zc`,
            target: String(node.data.zeroNodes[0]?.id),
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
            },
        },

        {
            type: 'smoothstep',
            sourceHandle: 'SourceRight',
            targetHandle: 'TarLeft',
            id: `${node.id}erlc`,
            source: `${node.id}lc`,
            target: `${node.id}rc`,
            style: {
                stroke: '#87969380',
                strokeWidth: 1, // Thickness of the line
                strokeDasharray: '6 6', // Define the dash pattern (4 pixels solid, 2 pixels gap)
            },
        },

    ]
    return parentEdges
}