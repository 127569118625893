import { NodeProps } from "reactflow"
import { AddPartnerStyled, DivWrapper } from "./style"
import { Box, Button, Modal, Typography } from "@mui/material"
import { useState } from "react"
import { AddIconPlus } from "icons/AddIconPlus"
import { ParentIconMale } from "icons/ParentIconMale"
import { ParentIconFemale } from "icons/ParentIconFemale"
import { RootCard } from "../../RootCard"
import { AddPersonModal } from "../AddPersonModal"


export const AddSpousesCard = ({ type, data, xPos, yPos, id }: NodeProps<any>) => (
    <DivWrapper pos={data.pos}>
        <AddPartnerStyled pos={data.pos} viewBox="0 0 36 79" style={{ width: "36px", height: "79px" }} />

    </DivWrapper>
)
