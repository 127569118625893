import styled from 'styled-components'

interface IIsMobile {
    ismobile: boolean
}

export const CropBackground = styled.div<IIsMobile>`
    position: ${props => props.ismobile ? '' : 'fixed'};
    width: 100%;
    height: 100%;
    background-color: ${props => props.ismobile ? 'rgba(26, 26, 30, 1)' : 'rgba(0, 0, 0, 0.7)'};
    backdrop-filter: blur(14.5px);
    z-index: 1111;
    top: ${props => props.ismobile ? '' : '50%'};
    left: ${props => props.ismobile ? '' : '50%'};
    transform: ${props => props.ismobile ? '' : 'translate(-50%, -50%)'};
`
export const CropWrapper = styled.div<IIsMobile>`
    position: ${props => props.ismobile ? '' : 'fixed'};
    top: ${props => props.ismobile ? '' : '50%'};
    left: ${props => props.ismobile ? '' : '50%'};
    transform: ${props => props.ismobile ? '' : 'translate(-50%, -50%)'};
    max-height: ${props => props.ismobile ? '75vh' : ''};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;
`