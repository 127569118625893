import { IconButton, SvgIcon } from "@mui/material"
import styles from './style.module.css';
import { useSelector } from "react-redux";
import { getTreeCurrentCardData, getIsOpenCardInfo } from "store/slices/tree/selectors";
import { baseURL } from "network/axiosConfig";
import { IconButtonStyle, InitialsDiv, CardDiv } from "./style";
import { useGetAvatarQuery } from "store/storeQuery/photo";
import { MaleIconOnCard } from "icons/MaleIconOnCard";
import { FemaleIconOnCard } from "icons/FemaleIconOnCard";



interface ICardData {
    id: string;
    avatar: string;
    name: string;
    surname: string;
    middleName: string;
    relation: string;
    dateOfBirth: string;
    dateOfDeath: string;
    isDead: boolean;
    gender: string;
}
const IconOnCard = ({ gender }: any) => (
    gender === "male" ? <MaleIconOnCard viewBox="0 0 74 74" style={{ height: "100%", width: "100%" }} /> : <FemaleIconOnCard viewBox="0 0 74 74" style={{ height: "100%", width: "100%" }} />
)
export const RelativeCard = ({ id, avatar, name, surname, middleName, relation, dateOfBirth, dateOfDeath, isDead, gender }: ICardData) => {
    const CardInfo = useSelector(getTreeCurrentCardData);
    const isOpenCardInfo = useSelector(getIsOpenCardInfo);
    const { data: avatarAsync, isSuccess, isError, isFetching } = useGetAvatarQuery(id)
    return (
        <CardDiv>
            <IconButton className="add" color="secondary" style={{ ...IconButtonStyle }}>
                {avatarAsync ? <img src={avatarAsync.path} className={styles.sideBarImageArea} style={{ margin: '0px' }} alt="test" /> : <IconOnCard gender={gender} />}
            </IconButton>
            <InitialsDiv>
                <p className={styles.textDrawer}>{surname} {name} {middleName}</p>
                {isDead ? (
                    <p className={styles.relationAreaForDead}>{relation}, {dateOfBirth === "__.__.____" ? "Неизвестно" : dateOfBirth} - {dateOfDeath}</p>
                ) : (
                    <p className={styles.birthArea}>{relation}, {dateOfBirth === "__.__.____" ? "Неизвестно" : dateOfBirth}</p>
                )}
            </InitialsDiv>
        </CardDiv>
    )
}