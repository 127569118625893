import { IEdgeData } from "components/CurrentNewTree/type"

export const getParentEdgeData = ({ sourceId, targetId, pos }: IEdgeData) => (
    {
        type: 'smoothstep',
        sourceHandle: 'SourceTop',
        targetHandle: 'TarBottom',
        source: sourceId,
        target: targetId,
    }
)