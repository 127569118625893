import { treeActions } from "store/slices/tree"
import { useEffect, useState } from "react"
import { RootCard } from "./RootCard"
import { CardWrapperDiv, FioText, FioAndDateWrapper, ImageDiv, ImageInfoWrapper, InfoRelAndAgeBlock, RelationText, StyledImage, AgeText, DateText, ButtonPartnerDiv, ButtonDelete, ButtonWrapper } from "./style"
import { Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useGetAvatarQuery } from "store/storeQuery/photo"
import { IconOnCard } from "../IconOnCard"
import { useAppDispatch } from "store/hooks"
import { calculateAgeFromDateInput } from "../NewSideBar/calculate_age"

export const TreeCard = ({ data, xPos, yPos, id }: any) => {
    const navigate = useNavigate()
    const [hover, setHover] = useState(false)
    const { data: avatarAsync, isSuccess, isError, isFetching } = useGetAvatarQuery(id)
    const [avatar, setAvatar] = useState()
    const dispatch = useAppDispatch();
    const [dateBirth, setDateBirth] = useState(data.dateOfBirth ? data.dateOfBirth : "__.__.____")
    const [dateDeath, setDateDeath] = useState(data.dateOfDeath ? data.dateOfDeath : "__.__.____")

    useEffect(() => {
        if (isSuccess === true) {
            console.log(avatar);
            setAvatar(avatarAsync.path)
        }
        setDateBirth(data.dateOfBirth ? data.dateOfBirth : "__.__.____")
        setDateDeath(data.dateOfDeath ? data.dateOfDeath : "__.__.____")
    }, [isSuccess, avatarAsync])
    const root = localStorage.getItem("idRoot")


    const goToProfilePage = (event: any) => {
        event.stopPropagation();
        dispatch(treeActions.setIsOpenCardInfo(false));

        navigate(`/profile/${id}`);
    };
    return (
        <>
            <CardWrapperDiv role="button" tabIndex={0} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                <ImageInfoWrapper>
                    <ImageDiv>
                        {isError || isFetching ? <IconOnCard gender={data.gender} /> : <StyledImage src={avatar} />}

                    </ImageDiv>
                    <InfoRelAndAgeBlock>
                        <RelationText isRoot={root === id} variant="caption2" >{id === root ? "Пользователь" : "Родственник"}</RelationText>
                        {data.isDead ? <AgeText isRoot={root === id} variant="caption2">{calculateAgeFromDateInput(dateBirth, dateDeath)}</AgeText> :
                            <AgeText isRoot={root === id} variant="caption2">{calculateAgeFromDateInput(dateBirth)}</AgeText>}

                    </InfoRelAndAgeBlock>

                </ImageInfoWrapper>
                <FioAndDateWrapper>
                    <FioText variant="button">
                        {data.name} {data.middleName} {data.surname}
                    </FioText>
                    {data.isDead ?
                        <DateText variant="caption2">{dateBirth} - {dateDeath}</DateText>
                        :
                        <DateText variant="caption2">{dateBirth}</DateText>
                    }
                </FioAndDateWrapper>
                <ButtonWrapper>
                    <Button variant='outlined' onClick={goToProfilePage}><Typography variant="button"> В профиль</Typography></Button>
                </ButtonWrapper>

            </CardWrapperDiv>
            <RootCard isConnectable={data.isConnectable} />

        </>
    )
}