import { styled, css, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { transientOptions } from "styles/types";

type LinkProps = {
  $isActive?: boolean,
}

export const StyledBaseLink = styled(Link, transientOptions)<LinkProps>(({
  theme,
  $isActive
}) => css`
  margin: 0 20px;
  text-decoration: none;
  color: ${$isActive ? theme.palette.primary.main : theme.palette.text.primary};

  &:hover {
    color: ${theme.palette.primary.main};
  }
`);

export const StyledWithIconLink = styled(Link, transientOptions)<LinkProps>(({
  theme,
  $isActive = false,
}) => css`
  margin: 0 4px;
  padding: 10px;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${$isActive ? theme.palette.primary.main : theme.palette.text.secondary};
    }
  }

  span {
    padding-top: 4px;
    color: ${$isActive ? theme.palette.primary.main : theme.palette.text.secondary};
  }

  &:hover {
    svg path {
      fill: ${theme.palette.primary.main};
    }
    span {
      color: ${theme.palette.primary.main};
    }
  }
`);

export const StyledSettButt = styled(Button, transientOptions)<LinkProps>(({
  theme,
  $isActive = false,
}) => css`
  margin: 0 4px;
  padding: 10px;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${$isActive ? theme.palette.primary.main : theme.palette.text.secondary};
    }
  }

  span {
    padding-top: 4px;
    color: ${$isActive ? theme.palette.primary.main : theme.palette.text.secondary};
  }
  &&{
    background-color:white;
  }
  &:hover {
    svg path {
      fill: ${theme.palette.primary.main};
    }
    span {
      color: ${theme.palette.primary.main};
    }
  }
`);