import { Modal, Button, Card } from '@mui/material';
import { useSelector } from "react-redux";
import { getTreeCurrentCardData, getIsOpenCardInfo } from "store/slices/tree/selectors";
import { useEffect, useState } from "react";
import { treeActions } from "store/slices/tree";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { CloseIconArrowCust, DeleteIcon, EditIcon, IconDiv, IconSideBarThreeCircle, MiniProfileDiv, NewSideBar, PopupButton, Puller, RelativeAgeText, RelativeCardDiv, RelativeDateText, RelativeFioText, RelativeImageDiv, RelativeImageInfoWrapper, RelativeStyledImage, SideBarPopup } from './style';
import { customAxios } from 'network/axiosConfig';
import { IImage } from 'pages/Profile/types/types';
import { DeletePersonModal } from './DeletePerson';
import { BoxStyled } from 'pages/Profile/ProfileInfo/style';
import { calculateAgeFromDateInput } from './calculate_age';
import { Biography } from './Biography';
import { BlockBioRelatives } from './Relatives';
import { Gallerey } from './SideBarGallerey/GallereyIndex';
import { useGetAvatarQuery } from 'store/storeQuery/photo';
import { IconOnCard } from '../IconOnCard';

interface IGallereyProps {
    avatar: string;
    imagesArr: IImage[];
    setImagesArr: any;
    setSelectedPhoto: (val: string | ArrayBuffer | null) => void;
    setAvatar: (val: string) => void;
    isRerend: boolean;
    setRerend: (val: boolean) => void;
}
export const SideBar = ({ isRerend, setRerend, setSelectedPhoto, setAvatar, avatar, imagesArr, setImagesArr }: IGallereyProps) => {
    const isOpenCardInfo = useSelector(getIsOpenCardInfo);
    const navigate = useNavigate()
    const CardInfo = useSelector(getTreeCurrentCardData);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(-1);
    const [isplug, setisplug] = useState(false); // заглушка
    const { data: avatarAsync, isSuccess, isError, isFetching } = useGetAvatarQuery(CardInfo.id)
    const dispatch = useAppDispatch();
    const goToProfilePage = (event: any) => {
        event.stopPropagation();
        dispatch(treeActions.setIsOpenCardInfo(false));
        navigate(`/profile/${CardInfo.id}`);
    };

    const onCardInfoClose = () => {
        dispatch(treeActions.setIsOpenCardInfo(false));
        dispatch(treeActions.setIsClickedCard(false));
    };
    const handleClickDeletePerson = () => {
        setIsOpenModal(true)
        dispatch(treeActions.setIsOpenCardInfo(false))
    }

    return (

        <NewSideBar isOpen={isOpenCardInfo} isOpenEdit={isOpenEdit}>
            <RelativeCardDiv isOpen={isOpenCardInfo} >
                <RelativeImageInfoWrapper>
                    {mediaQueryCheck && <RelativeImageDiv>
                        < EditIcon onClick={goToProfilePage} />
                        {avatar === null ? <IconOnCard gender={CardInfo.gender} /> : <RelativeStyledImage src={avatar} />}
                        <DeleteIcon onClick={handleClickDeletePerson} isOwner={CardInfo.id === localStorage.getItem("idRoot")} />

                    </RelativeImageDiv>}
                    {mediaQueryCheck && <IconDiv isOpen={isOpenCardInfo} onClick={onCardInfoClose}>
                        <CloseIconArrowCust />
                    </IconDiv>}
                    {!mediaQueryCheck && <Puller />}
                    <RelativeFioText>

                        {CardInfo.name} {CardInfo.fatherName} {CardInfo.surname}
                        {!mediaQueryCheck && <IconSideBarThreeCircle onClick={() => { setIsOpenPopup(!isOpenPopup) }} />}
                        {isOpenPopup && (
                            <SideBarPopup>
                                <PopupButton onClick={() => { setIsOpenEdit(true); setTimeout(() => { setIsOpenPopup(false) }, 50) }}>Редактировать профиль</PopupButton>
                                <PopupButton style={{ display: CardInfo.id === localStorage.getItem("idRoot") ? 'none' : 'flex' }} onClick={() => { handleClickDeletePerson(); setTimeout(() => { setIsOpenPopup(false) }, 50) }}>Удалить</PopupButton>
                            </SideBarPopup>
                        )}
                    </RelativeFioText>
                    <RelativeDateText>
                        {CardInfo.id === localStorage.getItem("idRoot") ? 'Пользователь' : 'Родственник'}
                    </RelativeDateText>
                    <RelativeDateText>
                        {(CardInfo.dateOfBirth === null || CardInfo.dateOfBirth === undefined) ? '' :
                            <RelativeAgeText> {calculateAgeFromDateInput(CardInfo.dateOfBirth, CardInfo?.dateOfDeath)}</RelativeAgeText>}
                        {CardInfo.isDead ? `${CardInfo.dateOfBirth}- ${CardInfo.dateOfDeath}` : CardInfo.dateOfBirth}
                    </RelativeDateText>
                </RelativeImageInfoWrapper>
            </RelativeCardDiv>
            <Modal open={isOpenModal}
                onClose={() => { setIsOpenModal(false) }}
            >
                <BoxStyled>
                    {isOpenModal && <DeletePersonModal setRerend={setRerend} setIsOpenModal={setIsOpenModal} />}
                </BoxStyled>
            </Modal>
            <MiniProfileDiv isOpen={isOpenCardInfo}>
                <Gallerey
                    setSelectedPhoto={setSelectedPhoto}
                    imagesArr={imagesArr}
                    setCurrentSlide={setCurrentSlide}
                    setisAddPhoto={setisplug}
                />
                <Biography />
                <BlockBioRelatives />
            </MiniProfileDiv>
        </NewSideBar>
    );
};
