import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, IconButton, SvgIcon } from '@mui/material';
import { customAxios } from 'network/axiosConfig';
import { delText, sxBox, sxBoxForChangeModal, sxBoxForPreview, sxDelBox } from './style';
import { CloseIcon } from 'icons/CloseIcon';

interface IModalProps {
  step: number;
  setStep: (val: number) => void;
  setPreview: (val: string | ArrayBuffer | null) => void;
  setFile: (val: File | null) => void;
}

function arrayBufferToString(buffer: ArrayBuffer | string) {
  if (typeof buffer === 'string') {
    return buffer;
  }

  let str = '';
  const array = new Uint8Array(buffer);
  for (let i = 0; i < array.length; i += 1) {
    str += String.fromCharCode(array[i]);
  }
  return str;
}

const GetAllTreeAxios = async () => {
  const response = await customAxios
    .get('tree', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    })
    .then(async (resp) => {
      const data = await resp.data;
      localStorage.setItem('treeArray', JSON.stringify(data));
    });
};

export const AddPhotoModal = ({ step, setStep, setPreview, setFile }: IModalProps) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFiles = async (e: any) => {
    const f = e.target.files[0];

    setSelectedFile(e.target.files[0]);
    console.log(f);
    const formData = new FormData();
    setFile(f);
    if (selectedFile) {
      console.log(step);
      console.log(selectedFile);
    }
    const reader = new FileReader();
    console.log(f);

    reader.readAsDataURL(f);

    reader.onload = async (e) => {
      await setPreview(reader.result);
    };

    if (+f.size >= 2 * 1024 * 1024) {
      setStep(5);
      return;
    }
    setStep(2);
  };

  return (
    <Box sx={{ ...sxBox }}>
      <span style={{ width: '350px', height: '152px' }}>
        <p style={{ ...delText, width: '100%', textAlign: 'center', fontSize: '20px' }}>
          Формат загружаемого файла: png, jpeg <br />
          Размер: не более 2 МБ
        </p>
      </span>
      <Button
        style={{ width: '100%' }}
        variant="contained"
        color="primary"
        size="large"
        component="label">
        Выбрать файл
        <input
          hidden
          accept="image/*,.png,.jpeg,.jpg"
          style={{ width: '2px' }}
          type="file"
          onChange={(e) => handleFiles(e)}
        />
      </Button>
    </Box>
  );
};

export const ErrorPhotoModal = ({ step, setStep, setPreview, setFile }: IModalProps) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFiles = async (e: any) => {
    const f = e.target.files[0];

    setSelectedFile(e.target.files[0]);
    console.log(f);
    const formData = new FormData();
    if (selectedFile) {
      console.log(step);
      console.log(selectedFile);
    }
    const reader = new FileReader();
    console.log(f);

    reader.readAsDataURL(f);

    reader.onload = async (e) => {
      await setPreview(reader.result);
    };

    if (+f.size >= 2 * 1024 * 1024) {
      setStep(5);
      return;
    }
    setFile(f);

    setStep(2);
  };

  return (
    <Box sx={{ ...sxBox, width: '320px' }}>
      <span
        style={{
          ...delText,
          margin: '0px 39px',
          fontSize: '16px',
          color: '#FA5353',
          fontWeight: '500',
          lineHeight: '20px',
        }}>
        Введен некорректный формат
      </span>
      <span style={{ width: '350px' }}>
        <p
          style={{
            ...delText,
            width: '100%',
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '15px',
            margin: '16px 0px',
          }}>
          Формат загружаемого файла: png, jpeg <br />
          Размер: не более 2 МБ
        </p>
      </span>
      <Button
        style={{ width: '100%' }}
        variant="contained"
        color="primary"
        size="large"
        component="label">
        Выбрать файл
        <input
          hidden
          accept="image/*,.png,.jpeg,.jpg"
          style={{ width: '2px' }}
          type="file"
          onChange={(e) => handleFiles(e)}
        />
      </Button>
    </Box>
  );
};
interface IPreviewModalProps {
  setStep: (val: number) => void;
  preview: string | ArrayBuffer | null;
  setIsOpenAddPhoto: (val: boolean) => void;
  userID: any;
  file: File | null;
}

export const PreviewPhotoModal = ({
  userID,
  setStep,
  preview,
  setIsOpenAddPhoto,
  file,
}: IPreviewModalProps) => {
  const navigate = useNavigate();

  const handleFiles = async () => {
    console.log('save');
    console.log(file);
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('userAvatar', file as Blob);

    const prom = await customAxios
      .put('avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then(async (r) => {
        await GetAllTreeAxios().then(() => {
          navigate(0);
        });
      });
  };

  return (
    <Box sx={{ ...sxBoxForPreview, width: '296px', height: '419px' }}>
      <div style={{ width: '248px', height: '248px', margin: '24px 24px 24px 24px', left: '0px' }}>
        <img
          src={preview === null ? undefined : arrayBufferToString(preview)}
          alt="test"
          style={{
            width: '248px',
            height: '248px',
            objectFit: 'fill',
            padding: '0px',
            borderRadius: '16px',
          }}
        />
      </div>
      <Button
        style={{ width: '248px', marginBottom: '24px' }}
        variant="contained"
        color="primary"
        size="large"
        component="label"
        onClick={() => handleFiles()}>
        Сохранить фото
      </Button>
      <Button
        style={{ padding: '0px', fontSize: '12px', color: 'red', border: 0, bottom: 7 }}
        variant="outlined"
        onClick={() => {
          setIsOpenAddPhoto(false);
          setStep(1);
        }}>
        Отменить
      </Button>
    </Box>
  );
};
interface IChangeModalProps {
  setStep: (val: number) => void;
  setPreview: (val: string | ArrayBuffer | null) => void;
  preview: string | ArrayBuffer | null;
  setIsOpenAddPhoto: (val: boolean) => void;
  setFile: (val: File | null) => void;
  avatarURL: string;
}
export const ChangePhotoModal = ({
  avatarURL,
  setStep,
  preview,
  setPreview,
  setIsOpenAddPhoto,
  setFile,
}: IChangeModalProps) => {
  const navigate = useNavigate();

  const handleFiles = async (e: any) => {
    const f = e.target.files[0];
    console.log(f);
    const reader = new FileReader();
    setFile(f);
    reader.readAsDataURL(f);
    reader.onload = async (e) => {
      await setPreview(reader.result);
    };
    setPreview(preview);
    if (+f.size >= 2 * 1024 * 1024) {
      setStep(5);
      return;
    }
    setStep(2);
  };
  const handlerDelPhoto = () => {
    const prom = customAxios
      .delete(`avatar?avatarPath=${avatarURL}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then(async () => {
        await GetAllTreeAxios().then(() => {
          navigate(0);
        });
      });
    setIsOpenAddPhoto(false);
  };
  return (
    <Box sx={{ ...sxBoxForChangeModal, width: '296px', height: '472px' }}>
      <div style={{ width: '248px', height: '248px', padding: '24px 24px 24px 24px', left: '0px' }}>
        <img
          src={preview === null ? undefined : arrayBufferToString(preview)}
          alt="test"
          style={{
            width: '248px',
            height: '248px',
            marginTop: '24px',
            objectFit: 'fill',
            padding: '0px',
            borderRadius: '16px',
          }}
        />
      </div>
      <span
        style={{
          paddingTop: '19px',
          width: '234px',
          fontFamily: 'Golos Text',
          fontSize: '12px',
          color: '#7F7F7F',
        }}>
        Формат загружаемого файла: png, jpeg Размер: не более 2 МБ
      </span>
      <Button
        style={{ width: '248px', top: -6 }}
        variant="contained"
        color="primary"
        size="large"
        component="label">
        Загрузить фотографию
        <input
          hidden
          accept="image/*,.png,.jpeg,.jpg"
          type="file"
          onChange={(e) => handleFiles(e)}
        />
      </Button>
      <Button
        style={{ top: -15, fontSize: '12px', color: 'red', border: 0 }}
        variant="outlined"
        onClick={() => {
          handlerDelPhoto();
        }}>
        Удалить фотографию
      </Button>
    </Box>
  );
};
interface IModalDelProps {
  setIsOpenAddPhoto: (val: boolean) => void;
  delId: number;
}

export const DeleteModal = ({ setIsOpenAddPhoto, delId }: IModalDelProps) => {
  const navigate = useNavigate();

  const GetAllTreeAxios = async () => {
    const response = await customAxios
      .get('tree', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(async (resp) => {
        const data = await resp.data;
        localStorage.setItem('treeArray', JSON.stringify(data));
      });
  };
  const delClickHandler = async (delId: number) => {
    const rootId = localStorage.getItem('idRoot');

    if (rootId !== null) {
      if (delId !== +rootId) {
        const prom = await customAxios
          .delete(`relative/${delId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          .then(async (res) => {
            const { data } = await res.data;
            GetAllTreeAxios().then(() => {
              navigate(0);
            });
            console.log(data);
            setIsOpenAddPhoto(false);
          });
      }
    }
  };
  return (
    <Box sx={{ ...sxDelBox, width: '600px', height: '320px' }}>
      <IconButton
        onClick={() => {
          setIsOpenAddPhoto(false);
        }}
        disableRipple
        style={{ margin: 0, padding: 0, position: 'absolute', right: '28px', top: '28px' }}>
        <SvgIcon
          component={CloseIcon}
          style={{ width: '18px', height: '18px' }}
          viewBox="0 0 18 18"
        />
      </IconButton>

      <div style={{ padding: '0px 74px' }}>
        <p style={{ ...delText, textAlign: 'center', margin: '85px 0px 32px' }}>
          Вы уверены, что хотите безвозвратно удалить карточку этого человека?
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ fontSize: '16px', width: '108px', height: '56px', margin: '0px 16px 0px 0px' }}
            onClick={() => {
              setIsOpenAddPhoto(false);
            }}>
            Отмена
          </Button>
          <Button
            style={{ fontSize: '16px', border: 0, width: '108px', height: '56px', margin: '0px' }}
            variant="contained"
            className="delete"
            color="primary"
            onClick={() => {
              delClickHandler(delId);
            }}>
            Удалить
          </Button>
        </div>
      </div>
    </Box>
  );
};
