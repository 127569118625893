export const SpanStyle = {
    font: 'Golos Text VF',
    lineHeight: '18.2px',
    display: 'flex',
    fontWeight: 700,
    margin: 12,
    marginTop: 18,
    marginLeft: 23,
    fontSize: '14px'
}
