export const emailValidationRegExp = /^[a-zA-Z0-9]+[-_.]?[a-zA-Z0-9]*@[a-zA-Z0-9]+[.][a-zA-Z0-9]+$/gm

export const nameValidationRegExp = /^[A-Z][a-z]+$/gm

export const passwordValidationRegExp = /((?=.*)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
export const passwordNoValidRegExp = /[.,\-/|'"><?+№#!$%^&@ */\\[/;|:{}=\-_`~()\]]/gm
export const firstNameValidationRegExp = /^[а-яА-ЯЁё]+$/gm
export const firstNameValidationDoubleRegExp = /^[а-яА-ЯЁё]+[-]+[а-яА-ЯЁё]+$/gm
export const firstNameValidationRegExp2 = /[А-Я]|[а-я]$/gm
export const dateBirthValidationRegExp = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
export const partialDateValidationRegExp = /^((0[1-9]|[12][0-9]|3[01])\.|(__)\.)((0[1-9]|1[012])\.|(__)\.)((\d{4})|(____))?$/
/*
NOTE: Validation variant:

[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+

*/