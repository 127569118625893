import styled from "styled-components";
import { SwipeableDrawer, Typography } from "@mui/material";
import { CloseIconArrow } from "icons/CloseIconArrow";
import { SideBarDeleteIcon } from "icons/SideBarDeleteIcon";
import { SideBarEditIcon } from "icons/SideBarEditIcon";
import { SideBarThreeCircle } from "icons/SideBarThreeCircle";

interface IPropsSideBar {
    isOpen: boolean,
}
interface IsOwnerSideBar {
    isOwner: boolean,
}
interface isOpenEditSideBar {
    isOpenEdit: boolean,
}

export const SwipeableDrawerMobile = styled(SwipeableDrawer)`
    position:relative !important;
    z-index:2 !important;
& .MuiPaper-root {
    border-radius: 16px 16px 0px 0px;
}
& .MuiModal-backdrop{
    position:absolute !important;
    z-index:inherit !important;
    opacity:0 !important;
}

`

export const DrawerWrapper = styled.div`
    border-radius: 16px;
`
export const DrawerContent = styled.div`
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const DrawerTitle = styled.p`
    margin: 0;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
`
export const DrawerSubtitle = styled.p`
    margin: 0;
    max-width: 315px;
    padding-top: 12px;
    padding-bottom: 40px;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: rgba(127, 127, 127, 1);
    text-align: center;
`
export const DrawerText = styled.p`
    margin: 0;
    padding-top: 40px;
    max-width: 343px;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(127, 127, 127, 1);
    text-align: center;
`

export const RelativeCardDiv = styled.div<IPropsSideBar>`
@media(max-width: 844px){
    display:${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction:column;
    align-items:flex-start;
    box-shadow: 0px 100px 95px -74px rgba(20, 156, 132, 0.5) inset,0px 15px 25px -11px rgba(34, 60, 80, 0.2);  
    background-color: #FFFFFF;
    position: relative;
    width: 100%; 
    border-radius:20px;
    z-index:2;
}
`
export const RelativeImageInfoWrapper = styled.div`
    @media(max-width:844px){
    margin:20px 20px 20px 20px;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    gap: 10px;
}
`

export const CloseIconArrowCust = styled(CloseIconArrow)`
    margin-bottom:15px;
    margin-right:18px;
`
export const IconSideBarThreeCircle = styled(SideBarThreeCircle)`
display:flex;
position: absolute;
right:17px;
flex-direction:row;
margin:3px auto 0px auto;
height:30px !important;
width:30px !important;
cursor: pointer;
`
export const DeleteIcon = styled(SideBarDeleteIcon) <IsOwnerSideBar>`
    visibility:${props => (props.isOwner ? 'hidden' : 'visible')};
    pointer-events: ${props => (props.isOwner ? 'none' : 'auto')};
    height:40px !important;
    width:40px !important;
    cursor: pointer;
`

export const EditIcon = styled(SideBarEditIcon)`
    height:40px !important;
    width:40px !important;
    cursor: pointer;
`
export const IconDiv = styled.div<IPropsSideBar>`
    display:${props => (props.isOpen ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    position: absolute;
    top:17px;
    width:24px;
    height: 32px; 
    background-color: #FFFFFF; 
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0px 2px 5px 1px rgba(34, 60, 80, 0.25);
    z-index: 10; 
    right:0px;
`

export const RelativeAgeText = styled(Typography)`
@media(min-width:844px){
padding: 2px 8px;
    border-radius:4px;
    background-color: #149C844D;
    color: #0E6E5D;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}
    @media(max-width:844px){
    opacity:0.9;
    padding: 2px 8px;
    border-radius:4px;
    background-color: #149C844D;
    color: #FFFFFF;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

`
export const RelativeImageDiv = styled.div`
    gap:34px;
    display: flex;
    justify-content:center;
    align-items:center;
    padding-bottom:22px;
`
export const RelativeStyledImage = styled.img`
    height: 100px;
    display: flex;
    justify-content:center;
    width:100px;
    background-color:black;
`
export const RelativeFioAndDateWrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:6px;
`
export const RelativeFioText = styled.div`
@media(max-width: 844px){
    width:80%;
    flex-direction:row;
    font-family: Gilroy,Arial,sans-serif;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    margin:3px 0px 0px 0px;
    gap:16px;
    font-weight:500;
    font-size:20px;
    line-height:130%;
}
`
export const RelativeDateText = styled(Typography)`
@media(max-width:844px){
    width:100%;
    gap:16px;
    display: flex;
    flex-direction:row;
    color: #7F7F7F;
}
`
export const SideBarPopup = styled.div`
    display:flex;
    flex-direction:column;
    z-index:3;
    position: absolute;
    right:80px;
    width: 211px;
    justify-content:center;
    border-radius: 12px;
    background: #FFF;
    padding:4px 0px 4px 0px;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`
export const PopupButton = styled.button`
padding:0px 0px 4px 12px;
border-radius: 12px;
    display:flex;
    flex-direction:row;
    border:0px #FFFFFF;
    font-family: Gilroy,Arial,sans-serif;
    background-color:#FFFFFF;
    width:100%;
    height:39px;
    font-weight:500;
    font-size:16px;
    color:#6C727F;
    line-height:130%;
    align-items:center;
    &:hover{
        background-color: #DCF0ED;
        color:#202128;
    }
`
export const Puller = styled.div`
  left:calc(50% - 21.5px);
  width: 43px;
  height: 2px;
  border-radius: 3px;
  background-color:#FFFFFF;
  position: absolute;
  top: 4px;
`
export const RelativeDiv = styled.div<isOpenEditSideBar>`
display:${props => (props.isOpenEdit ? 'flex' : 'none')};
`