import { ArrowBackIcon } from 'icons/ArrowBackIcon';
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';

export const OpenGallery = styled.div`
    overflow: hidden;
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(26, 26, 30, 0.60);
    backdrop-filter: blur(6px);
    z-index: 2;
`
export const OpenGallereyImage = styled.img`
    align-items: center;
    object-fit: contain;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    height: 100%;
`
export const HeaderGallery = styled.div`
    display: flex;
    height: 48px;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
`
export const FooterGallery = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    background-color: #1A1A1E;
    width: 100%;
    height: 56px;
`
export const FooterGalleryContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    padding: 0 16px;
`
export const ImagePopup = styled.div`
    padding: 8px 0px;
    position: absolute;
    width: 50vw;
    top: -10vh;
    right: 5vw;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 20px 0px rgba(32, 33, 40, 0.08), 0px 0px 1px 0px rgba(32, 33, 40, 0.12), 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
`
export const ImageAction = styled.p`
    margin: 0;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #7F7F7F;

    &:hover {
        background-color: #DCF0ED;
        color: #1A1A1E;
    }`
export const ImagesCount = styled.div`
    display: flex;
    align-items: center;
`
export const CurrentCount = styled.span`
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #FFF;
`
export const TotalCount = styled.span`
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    color: #FFF;
`
export const CircleDiv = styled.div`
    @media(max-width: 844px){
        background-color: #1A1A1E;
        border-radius: 50%;
        width: 4px;
        height: 4px;
    }
`
export const SettingDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap:3px;
    &:hover ${CircleDiv}{
        background-color: #0E6E5D;
        
}
`
export const GallereyComp = styled.div`

`
export const GallereyTitle = styled.div`
@media(min-width:844px)
{
display: flex;
        justify-content: space-between;
        align-items:center;
        padding-bottom:16px;
        margin: 0px 8px 0px 23px;
}
@media(max-width:844px)
{
        display: flex;
        justify-content: space-between;
        align-items:center;
        padding-bottom:16px;
        margin: 0px 23px 0px 23px;
}        

`
export const GallereyName = styled.h2`
        margin:0;
        font-family: Gilroy, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
`
export const GallereyDiv = styled.div`
    position:relative;
    top:25px;
    width:100%;
`
export const GallereyImage = styled.img`
    align-items: center;
    object-fit:contain;
    border-radius:6px;
    width:76px;
    height:76px;
`
export const GallereyButtonNext = styled.button`
@media(min-width: 844px){
    diplay:flex;
    position:absolute;
    width:50px;
    backgrond-color:black;
    z-index:5;
}
`

export const StyledSwiper = styled(Swiper)`
    @media(min-width: 844px){
        margin: 0px 20px 0px 23px;
    }
    @media(max-width:844px){
        margin: 0px 20px 0px 20px;
    }
`
export const NextArrowButton = styled.button`
@media(min-width:844px)
{
all:unset;
    position:absolute;
    right: 8px;
    top: 81px;
    transform: translateY(-50%);
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#1A1A1ECC;
    color: white;
    border-radius: 40px;
    z-index:1;
    &.swiper-button-disabled {
        visibility:hidden ;
    }
}
   @media(max-width:844px)
{
all:unset;
    position:absolute;
    right: 23px;
    top: 81px;
    transform: translateY(-50%);
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#1A1A1ECC;
    color: white;
    border-radius: 40px;
    z-index:1;
    &.swiper-button-disabled {
        visibility:hidden ;
    }
}
    
    
`
export const PrevArrowButton = styled.button`
    all:unset;
    position:absolute;
    left:25px;
    top: 81px;
    transform: translateY(-50%) rotate(180deg);
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#1A1A1ECC;
    color: white;
    z-index:1;
    border-radius: 40px;
    &.swiper-button-disabled {
        visibility:hidden ;
    }
`
export const AddButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border: 1.5px dashed #149C84;
    border-radius: 12px;
    padding: 17px 23px;
    cursor: pointer;

    @media(max-width: 844px){
        flex-direction: row;
        justify-content: center;
        padding: 18px 60px;
    }
`
export const AddButtonText = styled.p`
    margin: 0;
    width: 86px;
    text-align: center;
    color: #0E6E5D;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;

    @media(max-width: 844px){
        display: inline-block;
        width: auto;
        white-space: nowrap;
    }
`
export const AddButtonWrapper = styled.div`
    position: relative;
    border-radius: 12px;
    background-color: rgba(20, 156, 132, 0.1);
    padding: 10px;
    box-sizing: border-box;
    width: 140px;
    height: 140px;

    @media(max-width: 844px){
        width: 100%;
        height: 80px;
    }
`

export const Slide = styled(SwiperSlide)`
    width:auto;

`