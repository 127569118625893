import React from 'react';
import { Box, Button, IconButton, SvgIcon } from '@mui/material';
import {
  sxGridThree,
  sxBox,
  textCont,
  textTwo,
} from 'components/AuthorizationModal/ErrEndAuthModal/style';
import fonImage from './notComleteImg.png';
import { CloseIcon } from 'icons/CloseIcon';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

export const NotCompleteModal = ({ isOpen, setIsOpen }: IModalProps) => {
  const handleClickClose = (): void => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ ...sxBox, width: '816px', height: '569px', ...sxGridThree }}>
      <IconButton
        onClick={() => {
          handleClickClose();
        }}
        disableRipple
        style={{ margin: 0, padding: 0, position: 'absolute', right: '28px', top: '28px' }}>
        <SvgIcon
          component={CloseIcon}
          style={{ width: '18px', height: '18px' }}
          viewBox="0 0 18 18"
        />
      </IconButton>

      <span
        style={{
          paddingTop: '180px',
          ...textCont,
          textAlign: 'center',
          display: 'inline',
          fontSize: '20px',
        }}>
        Family <p style={{ color: '#149C84', display: 'inline-block' }}>Tree</p>
      </span>

      <span style={{ textAlign: 'center', margin: '11px 0px 0px' }}>
        <p style={{ ...textTwo }}>Данная страница находится в разработке</p>
      </span>

      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ zIndex: 1, width: '100%', margin: '5px 0px 0px' }}
        onClick={() => {
          handleClickClose();
        }}>
        Перейти к древу
      </Button>
      <img src={fonImage} alt="" style={{ position: 'absolute', top: 320, width: '70%' }} />
    </Box>
  );
};
