import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from 'components/Header';
import { RegistrationModal } from 'components/RegistarionModal';
import { AuthorizationModal } from 'components/AuthorizationModal';
import { IconTree } from 'icons/IconTree';
import imgLandingLines from 'images/landingLines.svg';
import imgLandingBottomLines from 'images/landingBottomlines.svg'
import imgLandingPhoto from 'images/optimized_landingPhoto.png';
import imgFamilyPhoto from 'images/optimizedfamily.png';
import imgLandingLine from 'images/LandingLine.svg'
import imgLADPhoto from 'images/optimized_LAD.png'
import imgBigLogo from 'images/BigLogo.png'
import imgMobileLines from 'images/MobileLandingLines.svg'
import {
  StyledLandingContainer,
  StyledImgLines,
  StyledImgPhoto,
  StyledInfoContainer,
  StyledButtonsContainer,
  StyledTextContainer,
  StyledImgFamily,
  StyledContainerPartners,
  StyledDemoContainer,
  StyledLadContainer,
  StyledLADFamily,
  StyledTextLADContainer,
  StyledBottomImgLines,
  StyledImgLine,
  ImgFamilyConteiner,
  ImgFamilyText,
  StyledImgLogo,
} from './styles';
import { Button, Typography } from '@mui/material';
import { Footer } from 'components/Footer';
import { LandingSlider } from 'components/LandingSlider';



export const Landing = () => {
  const navigate = useNavigate();
  const mediaQueryCheck = window.matchMedia('(min-width: 844px)').matches;
  const firstRef = useRef<HTMLInputElement>(null)
  const secRef = useRef<HTMLInputElement>(null)
  const handleClickFirstRef = () => {
    if (firstRef.current) {
      firstRef.current.scrollIntoView()
    }
  }
  const handleClickSecRef = () => {
    if (secRef.current) {
      secRef.current.scrollIntoView()
    }
  }
  const landingLinks = [
    { label: 'Новости проекта', link: 'setting', data: () => handleClickFirstRef() },
    { label: 'Наша команда', link: 'setting', data: () => handleClickSecRef() },
  ];
  // TODO: нужна декомпозиция
  // if (localStorage.getItem('accessToken')) {
  //   navigate('/tree');
  // } else {
  //   localStorage.removeItem('accessToken');
  //   localStorage.removeItem('treeArray');
  //   localStorage.removeItem('rootUser');
  //   localStorage.removeItem('idRoot');
  // }


  return (
    <div>
      <Header menu={landingLinks} />
      <StyledLandingContainer>
        <StyledInfoContainer>
          <Typography variant="h2">Сохраните память о вашей семье</Typography>
          <Typography variant="body1">
            Погрузитесь в мир своей семейной истории вместе с Family Tree
          </Typography>
          <StyledButtonsContainer>
            <Button style={{ width: '240px' }} variant='contained' onClick={() => navigate('/registration')}>
              <Typography variant='button'>Зарегистрироваться</Typography>
            </Button>
            <Button style={{ width: '240px' }} variant='outlined' onClick={() => navigate('/login')}>
              <Typography variant='button'>Войти</Typography>
            </Button>
          </StyledButtonsContainer>
        </StyledInfoContainer>
        <StyledImgPhoto src={imgLandingPhoto} alt="Family Tree" />
        <StyledImgLines src={imgLandingLines} alt="" />
      </StyledLandingContainer>
      <StyledContainerPartners ref={firstRef}>
        <StyledDemoContainer >
          <LandingSlider />
          <StyledTextContainer>
            <div >
              Family Tree - уникальный сервис для составления генеалогического древа семьи, который позволяет пользователям не только воссоздать своё семейное древо, но и сохранить историю своей семьи для будущих поколений.
            </div>
            <div>
              Команда проекта состоит из талантливых разработчиков, дизайнеров и исследователей, которые вкладывают свои знания и страсть к генеалогии в каждый аспект работы
            </div>
          </StyledTextContainer>
          <StyledImgLine src={imgLandingLine} alt="" />
        </StyledDemoContainer>
        <ImgFamilyConteiner>

          <StyledImgLogo src={imgBigLogo} alt="" />
          <StyledImgFamily src={imgFamilyPhoto} alt="Family Tree" />
          <ImgFamilyText>
            Наш проект - это место, где семейные узы становятся крепче,
            и истории о ваших предках оживают.
          </ImgFamilyText>
        </ImgFamilyConteiner>

        <StyledLadContainer ref={secRef}>
          <StyledTextLADContainer>
            <div >
              IT-академия Lad - это образовательный проект компании Lad, основным направлением которого является обучение работе в IT-сфере с нуля.
            </div>
            <div>
              Обучение проводится в командах на полезных проектах с реальными заказчиками, где процесс разработки ведётся в точности как в IT-компаниях
            </div>
          </StyledTextLADContainer>
          <StyledLADFamily src={imgLADPhoto} alt="Family Tree" />
          <StyledBottomImgLines src={mediaQueryCheck ? imgLandingBottomLines : imgMobileLines} alt="" />
        </StyledLadContainer>
      </StyledContainerPartners>

      <Footer />
    </div>
  );
};
